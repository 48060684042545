<template>
  <div class="contaniner">
    <page-main class="content customize-page-main">
      <el-tabs v-model="searchParams.sceneType" @tab-click="handleClick">
        <el-tab-pane
          v-for="item in tabList"
          :key="item.name"
          :label="item.label"
          :name="item.name"
          :disabled="loading"
        />
      </el-tabs>
      <div :class="{'common-btn':!isMobile, 'common-btn-mg': isMobile}">
        <el-button
          v-if="$auth('acadSaas.actMgmt.publicResLibr')"
          type="info"
          plain
          icon="el-icon-reading"
          @click="onJumpCommonList"
        >
          公共资源库
        </el-button>
      </div>
      <div v-loading="loading">
        <!-- 问卷调研 组件 -->
        <template v-if="searchParams.sceneType === 'pureSurvey'">
          <PureSurveyList />
        </template>
        <!-- 其它Tab内容 -->
        <template v-else>
          <!-- 搜索栏 -->
          <div class="nav-wrapper">
            <div v-if="showBatchOperation" class="left">
              <el-button
                v-if="$auth('acadSaas.actMgmt.share')"
                class="reset-btn"
                type="primary"
                plain
                @click="onBatchShare"
              >
                批量分享
              </el-button>
            </div>
            <div v-if="!showBatchOperation" class="left">
              <div v-if="searchParams.sceneType === 'MEDICAL_PODCAST' && searchParams.podcastListType === '2'">
                <!-- todo -->
                <el-input
                  v-model="searchParams.title"
                  v-debounce="[onSearch, 'input']"
                  class="input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="搜索作品/投稿人"
                  @clear="onSearch"
                  @keydown.enter.native="onSearch"
                />
              </div>
              <div v-else>
                <el-input
                  v-model="searchParams.title"
                  v-debounce="[onSearch, 'input']"
                  class="input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="inputHolder"
                  @clear="onSearch"
                  @keydown.enter.native="onSearch"
                />
              </div>
              <div v-if="searchParams.sceneType !== 'MEDICAL_PODCAST'">
                <el-input
                  v-model="searchParams.itemId"
                  v-debounce="[onSearch, 'input']"
                  class="input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="inputIdHolder"
                  @clear="onSearch"
                  @keydown.enter.native="onSearch"
                />
              </div>
              <div v-if="searchParams.sceneType === 'MEDICAL_PODCAST' && searchParams.podcastListType === '1'">
                <el-select
                  v-model="searchParams.challengeStatus"
                  clearable
                  placeholder="请选择活动状态"
                  @change="onSearch"
                >
                  <el-option
                    v-for="item in challengeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
              <div v-if="searchParams.sceneType === 'MEDICAL_PODCAST' && searchParams.podcastListType === '2'">
                <el-select
                  v-model="searchParams.reviewStatus"
                  clearable
                  placeholder="请选择作品状态"
                  @change="onSearch"
                >
                  <el-option
                    v-for="item in reviewList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
              <div>
                <el-select
                  v-model="academicSourceType"
                  class="select"
                  placeholder="活动来源"
                  @change="onSelectSource"
                >
                  <el-option :label="searchParams.sceneType !== 'MEDICAL_PODCAST' ? '活动来源' : '我的项目'" value="ower" />
                  <el-option v-if="searchParams.sceneType !== 'MEDICAL_PODCAST'" label="其他组织分享" value="other" />
                  <el-option label="下级组织活动" value="down" />
                </el-select>
              </div>
              <template v-if="academicSourceType === 'ower'">
                <div v-for="group in tagGroupListFilter" :key="group.id">
                  <div :class="{ 'select-complex': true }">
                    <el-select
                      v-model="selectedTagMap[group.id]"
                      class="select"
                      clearable
                      collapse-tags
                      :multiple="group.groupCode !== 'PRODUCT_TAG_GROUP'"
                      :placeholder="group.groupName"
                      @change="onSelectTag"
                    >
                      <template v-for="item in tagList">
                        <el-option
                          v-if="group.id === item.groupId"
                          :key="group.id + item.tagId"
                          :value="item.tagId"
                          :label="item.tagName"
                        />
                      </template>
                    </el-select>
                    <template>
                      <div class="right-btn" @click.stop.prevent="onManageTag(group)">
                        <svg-icon name="manage-icon" />
                      </div>
                    </template>
                  </div>
                </div>
              </template>
              <div v-if="searchParams.sceneType === 'MEDICAL_PODCAST'">
                <el-select
                  v-model="searchParams.podcastListType"
                  class="select"
                  @change="onSearch"
                >
                  <el-option label="播客活动" value="1" />
                  <el-option label="作品内容" value="2" />
                </el-select>
              </div>
              <div v-if="searchParams.sceneType === 'MEDICAL_PODCAST' && searchParams.podcastListType === '2'">
                <!-- 排序 -->
                <el-select
                  v-model="searchParams.podcastSortType"
                  @change="onSearch"
                >
                  <el-option
                    v-for="item in sortList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
              <div v-if="searchParams.sceneType === 'live' || searchParams.sceneType === 'webinar'">
                <el-select
                  v-model="searchParams.liveStatus"
                  class="select"
                  clearable
                  :placeholder="searchParams.sceneType === 'live' ? '直播状态' : '会议状态'"
                  @change="onSearch"
                >
                  <el-option label="即将开始" :value="0" />
                  <el-option label="进行中" :value="1" />
                  <el-option label="录播处理中" :value="2" />
                  <el-option label="已结束" :value="3" />
                </el-select>
              </div>
              <div v-if="searchParams.sceneType !== 'MEDICAL_PODCAST'">
                <el-date-picker
                  :value="[searchParams.startTime, searchParams.endTime]"
                  :default-time="['00:00:00', '23:59:59']"
                  class="picker-layout"
                  type="daterange"
                  unlink-panels
                  range-separator="—"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  :editable="false"
                  @change="onChangeDate"
                />
              </div>
            </div>
            <div class="right">
              <el-row class="action-fix">
                <el-button
                  v-if="searchParams.sceneType !== 'MEDICAL_PODCAST'"
                  class="reset-btn"
                  type="primary"
                  plain
                  @click="onBatchOperation"
                >
                  {{ !showBatchOperation ? '批量操作' : '取消批量操作' }}
                </el-button>
                <el-button
                  v-if="searchParams.sceneType === 'MEDICAL_PODCAST' && searchParams.podcastListType === '2'"
                  plain
                  type="primary"
                  @click="onPodcastListDown"
                >
                  导出Excel
                </el-button>
                <!-- 暂时关闭创建拜访 -->
                <el-button
                  v-if="$auth('acadSaas.actMgmt.create')"
                  class="reset-btn"
                  type="primary"
                  icon="el-icon-plus"
                  @click="onCreate"
                >
                  创建{{ labelText }}
                </el-button>
              </el-row>
            </div>
          </div>

          <!-- 容器元素 -->
          <!-- 下级数据table组件 -->
          <template v-if="searchParams.sceneType === 'MEDICAL_PODCAST'">
            <SceneChildTable
              v-if="searchParams.podcastListType === '1'"
              :academic-source-type="academicSourceType"
              :is-show-select="showBatchOperation"
              :scene-type="searchParams.sceneType"
              :is-creator="searchParams.isCreator"
              :list="tableList"
              @on-change="onSearch"
              @onSelect="onSelectList"
            />
            <WorkListTable
              v-if="searchParams.podcastListType === '2'"
              :list="tableList"
              :academic-source-type="academicSourceType"
              @on-change="getCurrentList"
              @onSelect="onSelectList"
            />
          </template>
          <template v-else>
            <SceneDownChildTable
              v-if="academicSourceType === 'down'"
              :is-show-select="showBatchOperation"
              :scene-type="searchParams.sceneType"
              :is-creator="searchParams.isCreator"
              :list="tableList"
              :target-org-id.sync="searchParams.targetOrgId"
              @on-change="onSearch"
              @onSelect="onSelectList"
            />
            <!-- 当前厂家card组件 -->
            <SceneChildCard
              v-if="showCard && academicSourceType !== 'down'"
              ref="sceneChildCard"
              :is-show-select="showBatchOperation"
              :is-creator="searchParams.isCreator"
              :scene-type="searchParams.sceneType"
              :list="tableList"
              :default-checked-keys="selectListIds"
              @onSelect="onSelectList"
            />
            <!-- 当前厂家table组件 -->
            <SceneChildTable
              v-if="showTable && academicSourceType !== 'down'"
              :academic-source-type="academicSourceType"
              :is-show-select="showBatchOperation"
              :scene-type="searchParams.sceneType"
              :is-creator="searchParams.isCreator"
              :list="tableList"
              @on-change="onSearch"
              @onSelect="onSelectList"
            />
          </template>
          <!-- 分页器 -->
          <el-pagination
            style="margin-top: 20px;"
            :current-page="pagination.pageNo"
            :total="pagination.total"
            :page-size="pagination.pageSize"
            :page-sizes="pagination.sizes"
            :layout="pagination.layout"
            :hide-on-single-page="false"
            :small="pagination.small"
            class="pagination"
            background
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          />
        </template>
      </div>
    </page-main>

    <!-- 批量分享 -->
    <SceneShareDepModal
      :visible="isShowSharePop"
      :scene-title="labelText"
      :is-creator="searchParams.isCreator"
      :is-submit="isShareLoading"
      :scene-id="sceneIdsStr"
      :scene-type="searchParams.sceneType"
      @onSubmit="onSubmitShareDep"
      @onClose="isShowSharePop = false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { getLocalSceneTimeDate } from '@/utils/util'
import SceneChildCard from './child/SceneChildCard.vue'
import SceneChildTable from './child/SceneChildTable.vue'
import SceneDownChildTable from './child/SceneDownChildTable'
import WorkListTable from './child/WorkListTable'
import SceneShareDepModal from '@/bizComponents/SceneShareDepModal'
import apiObjs from '@/apis/api-objs.js'
import paginationMixin from '@/mixins/pagination'
import { isMobile } from '@/utils/util.js'
import PureSurveyList from './components/PureSurveyList.vue'
export default {
  name: 'AcademicList',
  components: {
    SceneChildTable,
    SceneDownChildTable,
    SceneChildCard,
    WorkListTable,
    SceneShareDepModal,
    PureSurveyList
  },
  mixins: [paginationMixin],
  beforeRouteLeave(to, from, next) {
    next()
  },
  data() {
    return {
      isMobile: isMobile(),
      selectedTagMap: {},
      loading: false,
      academicSourceType: 'ower', // 活动来源类型：ower：当前厂家，down：下级
      format: 'YYYY-MM-DD',
      tagList: [], // 标签列表
      liveApprovelNum: 0, // 直播未审批数量
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.searchParams.startTime = `${this.$moment(minDate).format('YYYY-MM-DD')} 00:00:00`
          this.searchParams.endTime = `${this.$moment(maxDate).format('YYYY-MM-DD')} 23:59:59`
        }
      },
      tableList: [],
      pagination: {
        pageSize: 10
      },
      searchParams: {
        challengeStatus: '',
        endTime: '',
        startTime: '',
        title: '',
        itemId: '',
        sceneType: 'meeting',
        isCreator: true,
        liveStatus: null,
        tagAndGroupIds: [],
        targetOrgId: '',
        questionnaireSubType: '2',
        podcastListType: '1',
        reviewStatus: '',
        podcastSortType: '1'
      },
      showBatchOperation: false,
      selectList: [],
      isShowSharePop: false,
      isShareLoading: false,
      challengeList: [
        {
          label: '审核失败',
          value: '-1'
        }, {
          label: '草稿',
          value: '1'
        }, {
          label: '待审核',
          value: '2'
        }, {
          label: '待开始',
          value: '3'
        }, {
          label: '进行中',
          value: '4'
        }, {
          label: '已结束',
          value: '5'
        }
      ],
      reviewList: [
        {
          label: '审核失败',
          value: '-2'
        },
        {
          label: '待报名',
          value: '-1'
        },
        {
          label: '报名成功，待提交作品',
          value: '0'
        },
        {
          label: '平台审核中',
          value: '1'
        },
        {
          label: '审核成功待确认',
          value: '2'
        },
        {
          label: '内容不合适',
          value: '4'
        },
        {
          label: '采购成功',
          value: '5'
        },
        {
          label: '审核超时',
          value: '6'
        }
      ],
      sortList: [
        {
          label: '投稿报名时间排序',
          value: '1'
        },
        {
          label: '活动创建时间排序',
          value: '2'
        }
      ],
      tabList: [
        {
          label: '线上科室会',
          name: 'meeting'
        },
        {
          label: '病例互动',
          name: 'questionnaire'
        },
        {
          label: '定向直播',
          name: 'live'
        },
        {
          label: '多人会议',
          name: 'webinar'
        },
        {
          label: '学术拜访',
          name: 'academicVisit'
        },
        {
          label: '医学播客',
          name: 'MEDICAL_PODCAST'
        },
        {
          label: '问卷调研',
          name: 'pureSurvey'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['tagGroupList']),
    tagGroupListFilter() {
      if (this.searchParams.sceneType === 'MEDICAL_PODCAST') {
        return this.tagGroupList.filter(item => item.groupCode === 'PRODUCT_TAG_GROUP')
      } else {
        return this.tagGroupList
      }
    },
    selectListIds() {
      if (this.academicSourceType === 'down') {
        return this.selectList.map(item => item.sceneId)
      }
      return this.selectList.map(item => item.id)
    },
    showCard() {
      return this.searchParams.sceneType === 'meeting' || this.searchParams.sceneType === 'live' || this.searchParams.sceneType === 'webinar'
    },
    showTable() {
      return this.searchParams.sceneType === 'questionnaire' || this.searchParams.sceneType === 'academicVisit' || this.searchParams.sceneType === 'MEDICAL_PODCAST'
    },
    labelText() {
      const nameData = {
        meeting: '科会',
        questionnaire: '病例',
        live: '直播',
        webinar: '会议',
        academicVisit: '拜访',
        MEDICAL_PODCAST: '医学播客',
        pureSurvey: '问卷调研'
      }
      return nameData[this.searchParams.sceneType]
    },
    inputHolder() {
      return `搜索${this.labelText}名称`
    },
    inputIdHolder() {
      return `搜索${this.labelText}ID`
    },
    sceneIdsStr() {
      return this.selectListIds.join(',')
    }
  },
  activated() {
    if (this.searchParams.sceneType === 'pureSurvey') return
    this.initDate()

    this.getDataList()
    this.handelEditSceneTagModalClose()
  },
  methods: {
    ...mapActions(['getTagGroup']),
    // ---------------------------- 批量操作 start-------------------------------
    onSelectList(list) {
      this.selectList = list
    },
    onBatchOperation() {
      this.showBatchOperation = !this.showBatchOperation
      this.selectList = []
    },
    onBatchShare() {
      if (!this.selectListIds.length) return this.$message.error('请先勾选活动')
      this.isShowSharePop = true
    },
    // ---------------------------- 批量操作 end-------------------------------
    onSubmitShareDep(arr, isSelectedPubResLib) {
      this.isShareLoading = true
      const req = {
        sceneIds: this.selectListIds,
        sceneType: this.searchParams.sceneType,
        factoryIds: arr,
        isSelectedPubResLib
      }
      this.$requireApi(apiObjs.sceneShare, req).then(() => {
        this.isShareLoading = false
        this.isShowSharePop = false
        this.$message.success('分享成功')
      }, rea => {
        this.isShareLoading = false
        this.$message.error(rea.message)
      })
    },
    onJumpCommonList() {
      this.$router.push({ name: 'CommonList' })
    },

    onSelectSource(val) {
      this.selectedTagMap = {}
      this.searchParams.tagAndGroupIds = []

      this.searchParams.isCreator = val === 'ower'
      this.searchParams.targetOrgId = ''
      this.onSearch()
    },
    // 搜索
    onSearch() {
      this.pagination.pageNo = 1
      this.$nextTick(() => {
        this.getDataList()
      })
    },
    onSelectTag() {
      const output = []
      Object.keys(this.selectedTagMap).map(v => {
        output.push({ groupId: v, tagIds: Array.isArray(this.selectedTagMap[v]) ? this.selectedTagMap[v] : this.selectedTagMap[v] ? [this.selectedTagMap[v]] : [] })
      })
      this.searchParams.tagAndGroupIds = output
      this.getDataList()
    },
    initDate() {
      this.searchParams.startTime = `${this.$moment(getLocalSceneTimeDate(this.searchParams.sceneType).startDate).format(this.format)} 00:00:00`
      this.searchParams.endTime = `${this.$moment(getLocalSceneTimeDate(this.searchParams.sceneType).endDate).format(this.format)} 23:59:59`
    },
    onCreate() {
      if (this.searchParams.sceneType === 'questionnaire') {
        this.$router.push(`/academic/scene/${this.searchParams.sceneType}/create?projectType=2`)
      } else if (this.searchParams.sceneType === 'meeting') {
        this.$router.push({name: 'CreateMeeting'})
      } else if (this.searchParams.sceneType === 'MEDICAL_PODCAST') {
        this.$router.push({name: 'CreatePodcast'})
      } else {
        this.$router.push(`/academic/scene/${this.searchParams.sceneType}/create`)
      }
    },
    onChangeDate(val) {
      if (val === null) {
        this.initDate()
      }
      this.onSearch()
    },
    getDataList() {
      this.selectList = []
      if (this.academicSourceType !== 'down' || this.searchParams.sceneType === 'MEDICAL_PODCAST') {
        this.getCurrentList()
      } else {
        this.getDownList()
      }
    },
    // 获取下级组织数据
    async getDownList() {
      try {
        this.loading = true
        const params = this.getParams(this.searchParams)
        delete params.isCreator
        const { data: { total, pageData } } = await this.$axios.get(this.$API.getDownOrgList, { params })
        this.pagination.total = total
        this.tableList = pageData
        this.loading = false
      } catch (error) {
        this.$message.error(error)
        this.loading = false
      }
    },
    onPodcastListDown() {
      this.$axios.post(this.$API.podcastListDown, this.searchParams).then(res => {
        this.$message.success(res.data)
      }, err => {
        this.$message.error(err.message)
      })
    },
    // 获取当前组织数据
    async getCurrentList() {
      try {
        this.loading = true
        const params = this.getParams(this.searchParams)
        const { data: { data, totalCount } } = await this.$axios.post(this.$API.getAcademicSceneList, params)
        this.tableList = data
        this.pagination.total = totalCount
      } catch (error) {
        error && error.message && this.$message.error(error.message)
      } finally {
        this.loading = false
      }
    },
    // 切换tab
    handleClick() {
      this.academicSourceType = 'ower'
      this.selectedTagMap = {}
      this.searchParams.title = ''
      this.searchParams.itemId = ''
      this.searchParams.liveStatus = null
      this.searchParams.questionnaireSubType = '2'
      this.searchParams.isCreator = true
      this.tableList = []
      this.showBatchOperation = false
      if (this.searchParams.sceneType === 'pureSurvey') return
      this.onSearch()
    },
    // 管理标签
    onManageTag(row) {
      this.$router.push({name: 'ProductLabel', query: {activeTabName: row.id}})
    },
    // 标签弹窗关闭后
    handelEditSceneTagModalClose() {
      // 搜索栏数据
      this.getTagGroup().then(() => {
        this.tagList = []
        this.tagGroupList.forEach(item => {
          this.getTagList(item.id)
        })
      })
    },
    // 获取我使用的标签列表
    async getTagList(tagGroupId) {
      try {
        const params = { tagGroupId }
        const { data } = await this.$axios.get(this.$API.tagList, { params })
        this.tagList = [...this.tagList, ...data]
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.contaniner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.action-fix {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
}
.picker-layout {
  width: 250px;
  background: #fbfbfb;
  ::v-deep .el-range-input {
    background: #fbfbfb;
  }
}
.search-input ::v-deep {
  width: 200px;
  margin-right: 10px;
  .el-input__inner {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .el-icon-search {
    font-weight: bold;
  }
}
.create-wrapper {
  cursor: pointer;
  color: #1e1e1e;
  margin: 30px 0;
  img {
    height: 60px;
    margin-bottom: 5px;
  }
}
.reset-btn {
  margin-left: 10px;
}
.nav-wrapper {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
  }
  .left {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .select {
      width: 120px;
      ::v-deep .el-select__tags {
        >span {
          width: 100%;
          display: grid;
          grid-template-columns: auto auto;
          >span {
            border: none;
            overflow: hidden;
            .el-tag__close.el-icon-close {
              background-color: transparent;
              &:hover {
                background-color: #c0c4cc;
                color: #909399;
              }
            }
          }
        }
      }
    }
    .select-complex {
      display: flex;
      box-sizing: border-box;
      border: 1px solid #dcdfe6;
      background-color: #fbfbfb;
      transition: all 0.3s;
      &:hover {
        border-color: #c0c4cb;
      }
      &:focus {
        border-color: $color-primary;
      }
      ::v-deep .select {
        line-height: 26px;
        .el-input {
          .el-input__inner {
            height: 26px;
            line-height: 26px;
            background-color: transparent;
            border: 1px solid transparent;
            border-right: none;
          }
        }
      }
      .right-btn {
        width: 28px;
        line-height: 26px;
        text-align: center;
        color: #a3a3a3;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: 12px;
          background-color: #e1e1e1;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        &:hover {
          background-color: #ddd;
          cursor: pointer;
        }
      }
    }
    .input {
      width: 160px;
      ::v-deep .el-input__inner {
        background-color: #fafafa;
        border-radius: 20px;
      }
    }
  }
  .right {
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    gap: 9px;
  }
}
.content {
  flex: 1;
  padding-top: 0;
}
.common-btn {
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 100;
  height: 40px;
  line-height: 40px;
}
.common-btn-mg {
  margin: 10px 0;
}
</style>
