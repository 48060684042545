<template>
  <div class="watch-list" :loading="loading">
    <el-table v-loading="loading" :data="tableData" style="width: 100%;">
      <el-table-column prop="onlineTime" label="上线时间" />
      <el-table-column prop="representName" label="代表" />
      <el-table-column prop="viewSeconds" label="观看时长">
        <template slot-scope="scoped">
          {{ timeToMinute(scoped.row.viewSeconds) }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
  </div>
</template>
<script>
import paginationMixin from '@/mixins/pagination'
import { getRepresentLiveRecordList } from '@/services/liveService'
export default {
  name: 'RepresentWatchList',
  mixins: [paginationMixin],
  data() {
    return {
      tableData: [],
      loading: false,
      search: {
        liveId: '',
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  mounted() {
    this.search.liveId = this.$route.params.liveId
    this.getDataList()
  },
  methods: {
    /**
         * 获取表格数据
         * 调用时机：页面加载时
         */
    async getDataList() {
      this.loading = true
      try {
        const params = this.getParams(this.search)
        const {
          data: { data, totalCount }
        } = await getRepresentLiveRecordList(params)
        this.tableData = data || []
        this.pagination.total = totalCount || 0
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },

    // 秒转换分钟00:00:00格式
    timeToMinute(times) {
      let t
      if (times > -1) {
        const hour = Math.floor(times / 3600)
        const min = Math.floor(times / 60) % 60
        const sec = times % 60
        t = hour < 10 ? `0${hour}:` : `${hour}:`
        if (min < 10) {
          t += '0'
        }
        t += `${min}:`
        if (sec < 10) {
          t += '0'
        }
        t += sec.toFixed(2)
      }
      t = t.substring(0, t.length - 3)
      return t
    }
  }
}
</script>
<style scoped lang="scss">
// scss
.watch-list {
  ::v-deep .el-table__header-wrapper {
    th {
      background-color: #eff0f2;
    }
  }
  .search-packet {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .el-input {
        width: 365px;
        margin-right: 40px;
        ::v-deep input {
          border-radius: 20px;
        }
      }
    }
    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .pagination {
    margin-top: 20px;
  }
}
</style>
