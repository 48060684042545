<template>
  <div class="watch-list" :loading="loading">
    <div class="search-packet">
      <div class="left">
        <el-input
          v-model="search.keyWord"
          size="small"
          prefix-icon="el-icon-search"
          placeholder="搜索医生姓名或医院名称"
          @keydown.enter.native="onSearch"
        />
      </div>
      <div class="right">
        <!--                <el-button type="text">导出数据</el-button>-->
        <el-popover
          v-model="showExportPop"
          width="200"
          trigger="manual"
          placement="left"
        >
          <div class="lay-box">是否导出数据?</div>
          <el-row type="flex" justify="end">
            <el-button size="mini" type="text" @click="showExportPop = false">取消</el-button>
            <el-button type="primary" size="mini" @click="onExport">确定</el-button>
          </el-row>
          <el-button
            slot="reference"
            type="primary"
            plain
            @click="onShowExportModal"
          >
            导出数据
          </el-button>
        </el-popover>
        <el-button
          plain
          type="primary"
          style="margin-left: 10px;"
          @click="exportInfoCollectionRecords"
        >
          导出信息采集表单
        </el-button>
      </div>
    </div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%;">
      <el-table-column prop="onlineTime" label="上线时间" width="180" />
      <el-table-column prop="doctorName" label="医生" width="180" />
      <el-table-column prop="certifyStatus" label="认证状态" width="180">
        <template slot-scope="{ row: { certifyStatus } }">
          {{ certifyStatus === 0 ? '未认证' : certifyStatus === 1 ? '已认证' : certifyStatus === 2 ? '审核中' : certifyStatus === 3 ? '认证未通过' : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="titleName" width="180">
        <template #header>
          <el-select
            v-model="search.titleName"
            style="width: 120px;"
            size="small"
            placeholder="请选择"
            @change="onSearch"
          >
            <el-option label="所有职称" value="" />
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="hospital" label="医院名称" />
      <el-table-column prop="department" width="150" label="科室" />
      <el-table-column prop="viewSeconds" width="150" label="观看时长">
        <template slot-scope="scoped">
          {{ timeToMinute(scoped.row.viewSeconds) }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
  </div>
</template>
<script>
import paginationMixin from '@/mixins/pagination'
import { getLiveRecordList, downLoadLiveRecordList } from '@/services/liveService'
import moment from 'moment'
export default {
  name: 'WatchList',
  mixins: [paginationMixin],
  props: {
    options: {
      type: Array,
      default: Array
    }
  },
  data() {
    return {
      activeName: 'packet',
      tableData: [],
      loading: false,
      moment,
      search: {
        liveId: '',
        pageNo: 1,
        pageSize: 10,
        keyWord: '',
        titleName: ''
      },
      showExportPop: false
    }
  },
  mounted() {
    this.search.liveId = this.$route.params.liveId
    this.getDataList()
  },
  methods: {
    /**
         * 获取表格数据
         * 调用时机：页面加载时
         */
    async getDataList() {
      this.loading = true
      try {
        const params = this.getParams(this.search)
        const {
          data: { data, totalCount }
        } = await getLiveRecordList(params)
        this.tableData = data || []
        this.pagination.total = totalCount || 0
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    onSearch() {
      this.search.pageNo = 1
      this.getDataList()
    },
    // 秒转换分钟00:00:00格式
    timeToMinute(times) {
      let t
      if (times > -1) {
        const hour = Math.floor(times / 3600)
        const min = Math.floor(times / 60) % 60
        const sec = times % 60
        t = hour < 10 ? `0${hour}:` : `${hour}:`
        if (min < 10) {
          t += '0'
        }
        t += `${min}:`
        if (sec < 10) {
          t += '0'
        }
        t += sec.toFixed(2)
      }
      t = t.substring(0, t.length - 3)
      return t
    },
    onShowExportModal() {
      this.showExportPop = true
    },
    onExport() {
      if (!this.tableData.length) return this.$message.error('暂无需要导出的数据')
      downLoadLiveRecordList(this.search).then(res => {
        this.$message.success(res.data)
        this.showExportPop = false
      }, rea => {
        this.$message.error(rea.message)
        this.showExportPop = false
      })
    },
    async exportInfoCollectionRecords() {
      try {
        const res = await this.$axios.get(this.$API.infoCollectionRecords, {params: {liveId: this.$route.params.liveId}})
        this.$message.success(res.data)
      } catch (error) {
        this.$message.error(error.message)
      }
    }
  }
}
</script>
<style scoped lang="scss">
// scss
.watch-list {
  ::v-deep .el-table__header-wrapper {
    th {
      background-color: #eff0f2;
    }
  }
  .search-packet {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .el-input {
        width: 365px;
        margin-right: 40px;
        ::v-deep input {
          border-radius: 20px;
        }
      }
    }
    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .pagination {
    margin-top: 20px;
  }
}
</style>
