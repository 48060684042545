<template>
  <div class="add-exam-box">
    <div class="left-box content-box">
      <div>
        <el-row type="flex" justify="space-between" class="pdg-lr10 mgn-b10">
          <div class="col272e40 font-bold">题库</div>
          <span
            class="text-primary-link"
            @click="onCreate"
          >新建{{ examTitle }}</span>
        </el-row>
        <el-row type="flex" justify="end" class="pdg-lr10 mgn-b10">
          <el-input
            v-model="searchData.searchKey"
            placeholder="搜索内容"
            suffix-icon="el-icon-search"
            class="mgn-r10"
            @change="onSearchChange"
          />
          <el-select
            v-model="searchData.categoryId"
            placeholder="选择分类"
            @change="onSelectChange"
          >
            <el-option
              v-for="item in examClassifyList"
              :key="item.categoryId"
              :label="item.title"
              :value="item.categoryId"
            />
          </el-select>
        </el-row>
      </div>
      <div class="content-box" style="flex: 1; overflow: auto;">
        <div v-if="!total > 0" class="col909399 text-ct pdg30">暂无数据</div>
        <div style="height: 100%;">
          <template v-for="(item, index) of sourceList">
            <el-row
              :key="index"
              type="flex"
              justify="space-between"
              align="middle"
              class="pdg-tb10 pdg-lr10"
              :class="index % 2 === 0 ? 'bgf6f7fb' : ''"
            >
              <div class="overflow-text grow-shrink0" style="width: 310px;">
                {{ item.title | textFilter
                }}{{ item.examTypeEnum | examTypeFilter }}
              </div>
              <el-row
                type="flex"
                justify="center"
                class="flex-shrink"
                style="width: 50px; height: 20px;"
              >
                <span
                  v-if="isSelect(item.examId)"
                  style="color: #c8c9ce;"
                >已选择</span>
                <div v-else class="cursor" @click="onAdd(item)">
                  <i class="el-icon-circle-plus-outline opt-label" />
                </div>
              </el-row>
            </el-row>
          </template>
        </div>
        <!-- <el-pagination class="sorter-box"
              @current-change="onCurrentChange"
              :current-page="searchData.pageNo"
              :page-size="searchData.pageSize"
              :total="total"
              :small="true"
              :pager-count="pagerCount"
              background
              hide-on-single-page
              layout="total, prev, pager, next, jumper"
            ></el-pagination> -->
      </div>
    </div>
    <div class="right-box content-box">
      <el-row
        type="flex"
        justify="space-between"
        class="pdg-lr20"
        style="height: 100px;"
      >
        <div class="col272e40 font-bold">当前选择{{ examTitle }}：</div>
        <div class="col7784a1">请从左侧{{ examTitle }}列表选择添加</div>
      </el-row>
      <div class="content-box check-box" style="flex: 1; overflow: auto;">
        <div>
          <template v-for="(item, index) of targetList">
            <div
              :key="index"
              class="flex-between vertical-center pdg-tb10 pdg-lr20"
              :class="index % 2 === 0 ? 'bgf6f7fb' : ''"
            >
              <div class="overflow-text grow-shrink0" style="width: 310px;">
                {{ item.title | textFilter
                }}{{ item.examTypeEnum | examTypeFilter }}
              </div>
              <div
                class="cursor"
                style="height: 20px;"
                @click="onDel(item.examId)"
              >
                <i class="el-icon-remove-outline opt-label" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MaterialExamChild',
  filters: {
    textFilter(text) {
      return text.replace(/<filter><\/filter>/g, ' ______ ')
    },
    examTypeFilter(type) {
      switch (type) {
        case 'single':
          return '（单选题）'
        case 'multiple':
          return '（多选题）'
        case 'text_fill':
          return '（问答题）'
        case 'blank_fill':
          return '（填空题）'
        case 'sort':
          return '（排序题）'
        case 'scale':
          return '（量表题）'
      }
    }
  },
  components: {},
  props: {
    selectList: {
      type: Array,
      default() {
        return []
      }
    },
    examType: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      // -------------------- submit ----------------
      btnLoading: false,
      // -------------------- 搜索、页数 -----------------
      pagerCount: 5,
      small: true,
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 2000,
        type: '',
        searchKey: '',
        categoryId: ''
      },
      // ---------------------- select -----------------
      examClassifyList: [],
      // --------------------- questionList ----------------------
      sourceList: [],
      targetList: []
    }
  },
  computed: {
    isSelect() {
      return currentId => {
        const targetSelect = [...this.targetList].map(v => v.examId)
        return targetSelect.indexOf(currentId) > -1
      }
    },
    examTitle() {
      return this.examType === 0 ? '题目' : '问卷'
    }
  },
  watch: {
    selectList(val) {
      if (val) {
        this.targetList = val
      }
    }
  },
  mounted() {
    this.initPage()
  },
  methods: {
    ...mapActions(['onGetTrainExamList', 'onGetCategoryList']),
    onClose() {
      this.$emit('onClose', false)
    },
    onCreate() {
      let routeUrl = ''
      if (this.examType === 0) {
        routeUrl = this.$router.resolve('/material/list?activeName=exam')
      } else {
        routeUrl = this.$router.resolve('/material/edit/question')
      }
      window.open(routeUrl.href, '_blank')
    },
    getValue() {
      return this.targetList
    },
    initPage() {
      this.searchData = {
        pageNo: 1,
        pageSize: 2000,
        type: this.examType,
        searchKey: '',
        categoryId: ''
      }
      this.sourceList = []
      this.targetList = this.selectList
      this.onGetQuestionList()
      this.onGetExamCategoryList()
    },
    onGetQuestionList() {
      this.onGetTrainExamList(this.searchData).then(
        ({ data: { data, totalCount } }) => {
          this.total = totalCount || 0
          this.sourceList = data || []
        }
      )
    },
    onGetExamCategoryList() {
      const categoryType = this.examType === 0 ? 'exam' : 'questionnaireExam'
      this.onGetCategoryList({ categoryType: categoryType }).then(res => {
        const list = [
          {
            categoryId: '',
            title: '全部'
          }
        ]
        this.examClassifyList = list.concat(res.data || [])
      })
    },
    onSearchChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onGetQuestionList()
    },
    onSelectChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onGetQuestionList()
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
      this.onGetQuestionList()
    },
    onAdd(item) {
      this.targetList = [...this.targetList, item]
    },
    onDel(currentId) {
      this.targetList = [...this.targetList].filter(
        v => v.examId !== currentId
      )
    }
  }
}
</script>
<style lang='scss' scoped>
.add-exam-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
.opt-label {
  color: #3d61e3;
  font-size: 20px;
}
.item-box {
  width: 50;
}
.left-box {
  width: 50%;
  border-right: 1px solid #e9eaeb;
}
.right-box {
  width: 50%;
}
.content-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}
.check-box {
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.check-box::-webkit-scrollbar {
  width: 0 !important;
}
.sorter-box {
  padding: 10px;
  text-align: right;
  background: #fff;
}
</style>
