<template>
  <div class="packet-conditional">
    <el-dialog
      title="发送红包"
      :visible.sync="dialogVisible"
      destroy-on-close
      width="40%"
      @open="open"
    >
      <el-form
        ref="form"
        v-loading="loading"
        :model="form"
        hide-required-asterisk
        :rules="formRules"
        label-width="85px"
        label-position="left"
        label-suffix="："
      >
        <el-form-item label="参与用户" prop="targetUser">
          <el-select v-model="form.targetUser" class="form-label" style="width: 320px;">
            <!-- <el-option :value="0" label="所有用户" /> -->
            <el-option :value="1" label="平台已认证医生" />
            <el-option :value="2" label="企业绑定医生" />
            <el-option :value="3" label="组织绑定医生" />
            <el-option :value="4" label="平台已认证医生+认证审核中的医生" />
          </el-select>
        </el-form-item>
        <el-form-item label="红包类型" prop="redPacketType">
          <el-select
            v-model="form.redPacketType"
            class="form-label"
            style="width: 320px;"
            @change="packetTypeChange"
          >
            <el-option :value="1" label="答题红包" />
            <el-option :value="2" label="拼手气积分红包" />
            <el-option :value="3" label="观看时长限制积分红包" />
          </el-select>
        </el-form-item>
        <el-form-item label="红包个数" prop="count">
          <el-input
            v-model.number="form.count"
            style="width: 320px;"
            class="form-label input"
            placeholder="请输入红包个数"
          />
        </el-form-item>
        <el-form-item v-if="form.redPacketType !== 2" label="单个面额" prop="oneAmount">
          <el-input
            v-model.number="form.oneAmount"
            style="width: 320px;"
            class="form-label input"
            placeholder="请输入单个面额"
          />
        </el-form-item>
        <el-form-item
          v-if="form.redPacketType === 2"
          prop="amount"
          label="总金额"
        >
          <el-input v-model.number="form.amount" placeholder="请输入总金额" style="width: 320px;" />
        </el-form-item>
        <el-form-item v-if="form.redPacketType === 1" label="添加题目" prop="examBOS">
          <div>
            <el-radio-group v-model="form.examType" @change="examList = []">
              <el-radio :label="0">分对错</el-radio>
              <el-radio :label="1">不分对错</el-radio>
            </el-radio-group>
          </div>
          <div v-for="item in examList" :key="item.id" class="exam-list">
            <p class="title">
              <span>{{ item.title | titleFilter }}</span>
              <span class="color-info">（{{ item.examTypeEnum | examTypeFilter }}）</span>
            </p>
            <i class="el-icon-delete" @click="onDelExam(item.examId)" />
          </div>
          <el-button size="small" plain @click="isShowAddExamModal = true">
            添加题目<i class="el-icon-plus el-icon--right" />
          </el-button>
        </el-form-item>
        <el-form-item label="领取条件" prop="viewTime">
          观看满
          <el-input-number
            v-model.number="form.viewTime"
            :step="1"
            step-strictly
            style="width: 100px;"
            controls-position="right"
            :min="0"
            :max="30"
            class="form-label input"
          />
          分钟
          <span class="note" style="margin-left: 30px;">
            备注：观看时长代表医生观看该场直播的时长
          </span>
        </el-form-item>
        <el-form-item label="祝福语" prop="blessWorld">
          <el-input
            v-model="form.blessWorld"
            style="width: 320px;"
            :maxlength="20"
            class="form-label input"
            placeholder="最多20字"
          />
        </el-form-item>
        <div class="note">
          备注：请保证您的积分账户可用余额充足，观众领取的红包积分将会自动存入账户。
        </div>
        <div class="count-num">
          <span class="label">本次红包累计：</span><span class="num">
            <span v-if="form.redPacketType === 2">{{ form.amount || 0 }}</span>
            <span v-else>{{ form.oneAmount * form.count || 0 }}</span>
            <span class="unit">积分</span></span>
        </div>
      </el-form>
      <div slot="footer" style="text-align: center;" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="onSubmit">确认发布红包</el-button>
      </div>
    </el-dialog>
    <AddExamModal
      :visible.sync="isShowAddExamModal"
      :limit="6"
      :select-list="examList"
      :exam-type="form.examType"
      @onSubmit="choseExam"
      @onClose="isShowAddExamModal = false"
    />
  </div>
</template>

<script>
import AddExamModal from '@/bizComponents/AddExamModal'
import { submitRedEnvelope } from '@/services/liveService'
import { useSendMsg } from '@/views/academic/tim/components/MsgSendBox/useSendMsg'
export default {
  name: 'PacketConditional',
  components: { AddExamModal },
  filters: {
    titleFilter(title) {
      const titleNew = title?.replace(/<filter><\/filter>/g, '__ ')?.replace(/,/g, '，')
      return titleNew
    },
    examTypeFilter(type) {
      const obj = {
        single: '单选',
        multiple: '多选',
        text_fill: '问答',
        blank_fill: '填空',
        sort: '排序',
        scale: '量表'
      }
      return obj[type]
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateExamBOS = (rule, value, callback) => {
      this.form.examBOS.length !== 0 ? callback() : callback(new Error('请添加题目'))
    }
    return {
      loading: false,
      isShowAddExamModal: false,
      examList: [],
      form: {
        amount: '',
        count: '',
        redPacketType: 1,
        liveId: '',
        blessWorld: '',
        targetUser: 1,
        oneAmount: '',
        examType: 0,
        viewTime: undefined,
        interval: 0 // 条件红包固定传 0
      },
      formRules: {
        amount: [
          { required: true, message: '请填写金额', trigger: 'blur' },
          { type: 'number', message: '总金额应该是数字', trigger: 'blur' }
        ],
        examBOS: [{ validator: validateExamBOS, required: true, message: '请添加题目', trigger: 'blur' }],
        oneAmount: [{ required: true, message: '请填写单个面额', trigger: 'blur' }],
        count: [
          { type: 'number', required: true, message: '请填写红包个数', tr58igger: 'blur' },
          { type: 'number', message: '红包个数应该是数字', trigger: 'blur' }
        ],
        viewTime: [{required: true, type: 'number', message: '请设置红包领取条件', trigger: 'blur'}],
        blessWorld: [{ required: true, message: '请填写祝福语', trigger: 'blur' }]
      }
    }
  },
  computed: {
    dialogVisible: {
      get: function() {
        return this.value
      },
      set: function(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    examList: {
      handler(newVal) {
        if (newVal) {
          this.form.examBOS = this.examList.map((item, index) => ({
            examId: item.examId,
            examIndex: index + 1
          }))
        }
      },
      immediate: true
    }
  },
  methods: {
    onSubmit() {
      if (this.loading) return
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            const params = Object.assign({}, this.form, { liveId: this.$route.params.liveId })
            if (params.redPacketType !== 2) {
              delete params.amount
              params.amount = params.count * params.oneAmount
            }
            params.viewTime = params.viewTime * 60
            if (params.redPacketType !== 1) {
              params.examBOS && delete params.examBOS
            }
            delete params.oneAmount

            await submitRedEnvelope(params)
            this.$message.success('红包发放成功！')
            this.sendPacketMsg()
            this.form = this.$options.data().form
            this.loading = false
            this.dialogVisible = false
          } catch (res) {
            this.loading = false
            this.$message({
              type: 'error',
              message: res.message
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 发送红包自定义消息
    sendPacketMsg() {
      const { onSend } = useSendMsg({
        type: 'createCustomMessage',
        payload: {
          description: 'send_chatroom_red_envelope'
        }
      })
      onSend()
    },
    /**
     * 选择题目
     * 调用时机：点击选择题目弹窗确认按钮
     */
    choseExam(arr) {
      if (arr.length > 6) return this.$message.error('最多只能选择6道题目')
      this.examList = arr
    },
    /**
     * 重置题目类型、题目列表
     * 调用时机：切换红包类型
     */
    packetTypeChange() {
      if (this.form.redPacketType === 3) {
        this.examList = []
        this.form.examType = 0
      }
    },
    onDelExam(currentId) {
      this.examList = [...this.examList].filter(v => v.examId !== currentId)
    },
    open() {
      this.form = this.$options.data().form
      this.examList = []
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.packet-conditional {
  .exam-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    border-radius: 4px;
    transition: all 0.2s;
    &:hover {
      background-color: #ddd;
    }
    .el-icon-delete {
      transition: all 0.2s;
      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
  .note {
    font-size: 14px;
    font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
    font-weight: 400;
    color: #b4b4b4;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .count-num {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e1e1e1;
    padding: 20px 0;
    .num {
      color: #ff9100;
      font-size: 20px;
      display: flex;
      align-items: center;
      .unit {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }
}
</style>
