<template>
  <div>
    <el-dialog
      title="问卷调研"
      :visible.sync="dialogVisible"
      width="50%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <EntrustListDetail
        :id="entrustId"
        :scene-id="sceneId"
      />
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EntrustListDetail from './EntrustListDetail.vue'
export default {
  name: '',
  components: {
    EntrustListDetail
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    entrustId: {
      type: String,
      default: ''
    },
    sceneId: {
      type: String,
      default: ''
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('close', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.exam-content {
  max-height: 500px;
  overflow-y: auto;
}
</style>
