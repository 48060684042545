<template>
  <div class="material-video-box">
    <div class="flex-start mgn-b10 pdg-lr20">
      <div class="material-video-search-box">
        <el-input
          v-model="searchData.searchKey"
          placeholder="搜索内容"
          suffix-icon="el-icon-search"
          @change="onSearchChange"
        />
      </div>
      <div class="w100v pdg-lr10">
        <el-select
          v-model="searchData.categoryId"
          placeholder="选择分类"
          @change="onSelectChange"
        >
          <el-option
            v-for="item in videoClassifyList"
            :key="item.categoryId"
            :label="item.title"
            :value="item.categoryId"
          />
        </el-select>
      </div>
      <span class="text-primary-link" @click="onCreate">本地上传</span>
    </div>
    <div class="video-list" style="border-bottom: 1px solid #e9eaeb;">
      <el-checkbox
        v-for="(item, index) of tableDataList"
        :key="item.videoId"
        class="flex-start vertical-center pdg-tb10 pdg-lr20"
        :class="index % 2 === 0 ? 'bgf6f7fb' : ''"
        :value="isSelect(item.videoId)"
        @change="onCheckedChange(item)"
      >
        <div class="video-info-box flex-start mgn-l20">
          <div class="video-cover">
            <img
              :src="item.customCoverUrl"
              alt=""
              style="width: 100%; height: 100%;"
            >
          </div>
          <div class="video-describe-box">
            <div class="pdg-b12 col272e40">{{ item.title }}</div>
            <div class="col7784a1 font-s14">
              <span>视频时长(min)：{{
                parseFloat(((item.duration || 0) / 60).toFixed(1))
              }}</span>
            </div>
          </div>
        </div>
      </el-checkbox>
      <div v-if="tableDataList.length === 0" class="col909399 text-ct pdg30">
        暂无数据
      </div>
    </div>
    <el-pagination
      class="sorter-box"
      :current-page="searchData.pageNo"
      :page-size="searchData.pageSize"
      :total="total"
      background
      hide-on-single-page
      layout="total, prev, pager, next"
      small
      @current-change="onCurrentChange"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MaterialVideoChild',
  props: {
    visible: {
      // 是否显示，默认true
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 100
    },
    initCheckedList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      // -------------------- 搜索、页数 -----------------
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 5,
        categoryId: '',
        searchKey: ''
      },
      // ---------------------- select -----------------
      videoClassifyList: [],
      // -------------------- submit ----------------
      btnLoading: false,
      // ----------------------- vedio table -----------------
      tableDataList: [],
      selectedList: []
      //
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.initPage()
      }
    },
    initCheckedList(val) {
      if (val) {
        this.selectedList = val
      }
    }
  },
  mounted() {
    this.initPage()
  },
  methods: {
    ...mapActions(['onGetTrainVideoList', 'onGetCategoryList']),
    onClose() {
      this.$emit('onClose', false)
    },
    onCreate() {
      const routeUrl = this.$router.resolve('/material/list?activeName=video')
      window.open(routeUrl.href, '_blank')
    },
    getValue() {
      return this.selectedList
    },
    onSearchChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onVideoList()
    },
    onSelectChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onVideoList()
    },
    initPage() {
      this.searchData = {
        pageNo: 1,
        pageSize: 5,
        categoryId: '',
        searchKey: ''
      }
      this.selectedList = this.initCheckedList
      this.tableDataList = []
      this.onVideoList()
      this.onGetVideoCategoryList()
    },
    onGetVideoCategoryList() {
      this.onGetCategoryList({ categoryType: 'video' }).then(res => {
        const list = [
          {
            categoryId: '',
            title: '全部'
          }
        ]
        this.videoClassifyList = list.concat(res.data || [])
      })
    },
    onVideoList() {
      this.onGetTrainVideoList(this.searchData).then(
        ({ data: { data, totalCount } }) => {
          this.total = totalCount || 0
          this.tableDataList = data || []
        }
      )
    },
    isSelect(currentId) {
      return (
        [...this.selectedList].map(v => v.videoId).indexOf(currentId) > -1
      )
    },
    onCheckedChange(item) {
      if (this.isSelect(item.videoId)) {
        this.selectedList = [...this.selectedList].filter(
          v => v.videoId !== item.videoId
        )
      } else {
        if (this.limit === 1) {
          this.selectedList = []
        }
        this.selectedList = [...this.selectedList, item]
      }
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
      this.onVideoList()
    }
  }
}
</script>

<style>
.material-video-search-box .el-input input {
  width: 250px;
  border-radius: 20px;
}
</style>
<style lang='scss' scoped>
.material-video-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  .video-list {
    flex: 1;
    overflow-y: auto;
    .el-checkbox {
      width: 100%;
      margin-right: unset;
      display: flex;
      align-items: center;
    }
  }
}
.video-cover {
  width: 107px;
  height: 60px;
  background: #d8d8d8;
  margin-right: 20px;
}
.bgf6f7fb {
  background: #f6f7fb;
}
.sorter-box {
  padding: 10px;
  text-align: right;
  background: #fff;
}
</style>
