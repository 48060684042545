<template>
  <div style="width: 100%;">
    <page-main back title>
      <template slot="top">
        <Steps :value="2" :list="stepList" />
      </template>
      <el-row type="flex" class="row-line">
        <span class="scene-label">任务名称：</span>
        <span class="col272e40 font-weight">
          {{ sceneTitle }}
        </span>
      </el-row>
      <div class="tabs-wrapper">
        <el-menu
          v-if="showAllotedCode"
          mode="horizontal"
          :default-active="activeTab"
          @select="onSelectTab"
        >
          <el-menu-item index="allot">待分配任务</el-menu-item>
          <el-menu-item index="create">新建任务规则</el-menu-item>
        </el-menu>
      </div>
      <el-row v-if="activeTab === 'allot'" class="scene-content">
        <div>请选择待分配任务，进行分配：</div>
        <el-row type="flex">
          <code-label
            v-for="(item, i) in allotCodeList"
            :key="i"
            :item="item"
            :less-count="item.lessCount"
            @onClick="onSelectAllotCode(i)"
          />
        </el-row>
      </el-row>
      <el-row v-if="activeTab === 'create'" type="flex" class="scene-item">
        <span class="scene-label">新建任务规则：</span>
        <div class="scene-content">
          <el-row type="flex" align="middle" class="scene-child-wrapper">
            <span>代表执行的有效期：任务分配后</span>
            <el-input-number
              v-model="ruleRepresentVaildDay"
              class="input-cover"
              :min="0"
              :max="60"
              step-strictly
              :step="1"
              placeholder="请输入有效期天数"
            />
            <span>天内有效</span>
          </el-row>
          <el-row type="flex" align="middle" class="scene-child-wrapper">
            <span>医生学习的有效期：收到任务后</span>
            <el-input-number
              v-model="ruleDoctorStudyDay"
              class="input-cover"
              :min="0"
              :max="30"
              step-strictly
              :step="1"
              placeholder="请输入有效期天数"
            />
            <span>天内有效</span>
          </el-row>
          <div class="text-tips">
            备注：任务有两个有效期。代表未在有效期内把任务分配给医生，任务会自动过期。<br>
            医生在收到学习任务后，未在有效期内完成学习，会显示任务过期。
          </div>
          <el-row type="flex" justify="space-between">
            <span>点击使用历史任务规则：</span>
            <span class="text-info-link" @click="onClearHistory">清空历史记录</span>
          </el-row>
          <el-row type="flex" class="history-wrapper">
            <div
              v-for="item in historyList"
              :key="item.id"
              class="history-rule-item"
              :class="{ active: selectedHistoryId === item.id }"
              @click="onSelectHistory(item)"
            >
              <div>
                <div class="font-s12">{{ item.representValidDay }}天代表执行</div>
                <div class="font-s12">{{ item.doctorValidDay }}天医生学习</div>
              </div>
              <i class="el-icon-close rule-close" @click.stop="onDelHistoryRule(item.id)" />
            </div>
          </el-row>
        </div>
      </el-row>
      <div class="text-tips" />
      <el-row v-if="activeTab === 'create'" type="flex" class="scene-item">
        <span class="scene-label">配置活动链接：</span>
        <div class="scene-content">
          <el-radio-group v-model="activityType">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
          <div v-if="activityType === 0" class="link-tips">
            备注：该活动广告，将会出现在委托调研服务的积分领取环节。
          </div>
          <div v-else>
            <div class="mgn-tb10">
              <el-button type="info" plain @click="addActivity('add')">创建活动<svg-icon name="el-icon-plus" /></el-button>
            </div>
            <el-table :data="activityList">
              <el-table-column prop="activityName" label="活动名称" fixed />
              <el-table-column prop="activityImageUrl" label="活动海报">
                <template slot-scope="scope">
                  <img :src="scope.row.activityImageUrl" alt="">
                </template>
              </el-table-column>
              <el-table-column prop="activityLink" label="活动链接" />
              <el-table-column prop="createdAt" label="创建时间" />
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button v-if="!isUsing(scope.row.id)" type="text" @click="onUseActivity(scope.row.id, '1')">选择使用</el-button>
                  <el-button
                    v-else
                    type="text"
                    style="color: #e8312f;"
                    @click="onUseActivity(scope.row.id, '0')"
                  >
                    取消使用
                  </el-button>
                  <el-button type="text" @click="addActivity('edit', scope.row)">编辑</el-button>
                  <el-button type="text" style="color: #e8312f;" @click="onDelActivity(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              :current-page="pageNo"
              :page-size="pageSize"
              layout="prev, pager, next, jumper"
              :total="total"
              hide-on-single-page
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </el-row>
    </page-main>
    <fixed-action-bar>
      <el-button
        v-if="!disabledBack"
        plain
        style="width: 90px;"
        @click="onBackLast"
      >
        上一步
      </el-button>
      <el-button
        type="primary"
        style="width: 90px;"
        :loading="isSubmit"
        @click="onSubmit"
      >
        下一步
      </el-button>
    </fixed-action-bar>
    <create-activity
      v-if="showActivityModel"
      :visible="showActivityModel"
      :activity-info="activity"
      :title="title"
      @cancel="showActivityModel = false"
      @save="onSaveActivity"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import CodeLabel from '@/baseComponents/CodeLabel'
import CreateActivity from './components/CreateActivity.vue'

export default {
  name: 'AcademicRule',
  components: {
    CodeLabel,
    CreateActivity: CreateActivity
  },
  data() {
    return {
      sceneTitle: '',
      sceneType: '', // 'meeting(1, "科会", "meeting"), survey(2, "调研", "survey"), visit(3, "拜访", "old_visit"), questionnaire(4, "问卷调研", "questionnaire"), live(5, "定向直播", "live"), webinar(6, "多人会议")	'
      sceneId: '',
      showSetRuleModal: false,
      disabledBack: false,
      activeTab: '',
      isSubmit: false,
      ruleDoctorStudyDay: 4,
      ruleId: '',
      ruleRepresentVaildDay: 3,
      allotCodeList: [],
      selectedHistoryId: '',
      historyList: [],
      limitCodeMoney: null,
      projectType: '',
      showActivityModel: false,
      activityType: 0,
      activityList: [],
      pageNo: 1,
      pageSize: 5,
      total: 0,
      activity: {
        activityName: '',
        activityImageUrl: '',
        activityLink: ''
      },
      title: '',
      advertActivityId: ''
    }
  },
  computed: {
    isUsing() {
      return row => {
        return this.advertActivityId === row
      }
    },
    showAllotedCode() {
      return this.allotCodeList.length > 0
    },
    message() {
      switch (this.sceneType) {
        case 'live':
        case 'webinar':
        case 'MEDICAL_PODCAST':
          return '设置任务规则前需先添加相关问卷题目'
        default:
          return ''
      }
    },
    sceneRuleType() {
      switch (this.sceneType) {
        case 'meeting':
          return 1
        case 'questionnaire':
          return 4
        case 'live':
          return 5
        case 'webinar':
          return 6
        case 'academicVisit':
          return 8
        case 'training':
          return 7
        case 'MEDICAL_PODCAST':
          return 12
        default:
          return ''
      }
    },
    sceneRuleChinese() {
      switch (this.sceneType) {
        case 'meeting':
          return '科会'
        case 'questionnaire':
          return '调研/病例'
        case 'live':
          return '直播'
        case 'webinar':
          return '会议'
        case 'academicVisit':
          return '拜访'
        case 'training':
          return 7
        case 'MEDICAL_PODCAST':
          return '播客'
        default:
          return ''
      }
    },
    stepList() {
      const arr = [`新建${this.sceneRuleChinese}`, '设置任务规则', '发布委托需求', '选择任务对象并分配']
      return arr.filter(item => {
        // 拜访不需要委托
        if (this.sceneRuleChinese === '拜访' && item === '发布委托需求') {
          return false
        }
        return true
      }).map((item, index) => {
        return `${index + 1}.${item}`
      })
    }
  },
  activated() {
    this.initHistory()
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['onCreateSceneRule', 'onDelSceneRule', 'getFactoryAllotCode']),
    init() {
      this.sceneType = this.$route.params.sceneType
      this.disabledBack = this.$route.query.disabledBack == 'true'
      this.sceneId = this.$route.params.id
      this.projectType = this.$route.query.projectType
      this.initCode()
      this.initHistory()
      this.activityType = 0
      this.advertActivityId = ''
      this.initActivity()
    },
    handleCurrentChange(val) {
      this.pageNo = val
      this.initActivity()
    },
    addActivity(type, row) {
      if (type === 'edit') {
        // 深拷贝
        this.activity = JSON.parse(JSON.stringify(row))
        this.title = '编辑活动'
      } else {
        this.activity = {}
        this.title = '创建活动'
      }
      this.showActivityModel = true
    },
    onDelActivity(id) {
      this.$axios.get(this.$API.delAdvertActivity, {params: {advertActivityId: id}}).then(res => {
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.initActivity()
        }
      })
    },
    onSaveActivity(form) {
      this.$axios.post(this.$API.saveActivity, form).then(() => {
        this.$message.success('保存成功')
        this.showActivityModel = false
        this.initActivity()
      })
    },
    onUseActivity(id, type) {
      this.activityList.forEach(v => {
        if (v.id === id) {
          if (type === '1') {
            v.isOpen = 1
            this.advertActivityId = id
          } else {
            v.isOpen = ''
            this.advertActivityId = ''
          }
        } else {
          v.isOpen = 0
        }
      })
    },
    onBackLast() {
      this.$router.push(`/academic/scene/${this.sceneType}/edit/${this.sceneId}?projectType=${this.projectType}`)
    },
    onNext(ruleId) {
      if (this.activeTab === 'create') {
        if (this.activityType === 1 && !this.advertActivityId) {
          this.$message.error('请配置活动链接')
          return false
        }
        // 如果关闭，清空advertActivityId
        if (this.activityType === 0) {
          this.advertActivityId = ''
        }
        this.$router.push(`/academic/scene/${this.sceneType}/entrust/${this.sceneId}?ruleId=${ruleId}&projectType=${this.projectType}&disabledBack=${this.disabledBack}&advertActivityId=${this.advertActivityId}`)
      } else {
        this.activityType = 0
        this.advertActivityId = ''
        this.$router.push({
          path: `/academic/scene/${this.sceneType}/allot/${this.sceneId}`,
          query: {
            ruleId: ruleId,
            projectType: this.projectType,
            disabledBack: this.disabledBack,
            includeDelegation: false,
            isExistingCodeAssign: true
          }
        })
      }
    },
    onValid() {
      if (this.activeTab === 'allot') {
        const current = this.allotCodeList.filter(v => v.isSelected)[0] || {}
        this.ruleDoctorStudyDay = current.validDay || ''
        this.ruleRepresentVaildDay = current.representValidDay || ''
      }
      if (!this.ruleDoctorStudyDay) {
        this.$message.error('请输入医生学习的有效期')
        return false
      }
      if (!this.ruleRepresentVaildDay) {
        this.$message.error('请输入代表执行的有效期')
        return false
      }
      return true
    },
    onSubmit() {
      if (!this.onValid()) return
      this.isSubmit = true
      const req = {
        sceneType: this.sceneRuleType,
        representValidDay: this.ruleRepresentVaildDay,
        doctorValidDay: this.ruleDoctorStudyDay
      }
      this.onCreateSceneRule(req).then(
        res => {
          this.selectedHistoryId = res.data
          this.onNext(res.data)
          this.isSubmit = false
        },
        rea => {
          this.isSubmit = false
          this.$message.error(rea)
        }
      )
    },
    onSelectHistory(item) {
      this.ruleRepresentVaildDay = item.representValidDay
      this.ruleDoctorStudyDay = item.doctorValidDay
      this.selectedHistoryId = item.id
    },
    onSelectTab(current) {
      this.activeTab = current
    },
    onSelectAllotCode(currentIndex) {
      this.allotCodeList = [...this.allotCodeList].map((v, i) => {
        v = { ...v, isSelected: currentIndex === i }
        return v
      })
    },
    initCode() {
      this.getFactoryAllotCode({ sceneType: this.sceneType, sceneId: this.sceneId }).then(res => {
        const { data } = res
        this.sceneTitle = data.title
        this.limitCodeMoney = data.limitCodeMoney || 9999
        this.allotCodeList = data.codeUsedVos.filter(c => c.lessCount > 0)
        if (this.showAllotedCode) {
          this.activeTab = 'allot'
        } else {
          this.activeTab = 'create'
        }
      })
    },
    initHistory() {
      this.$axios.get(this.$API.getSceneRuleHistoryList, { params: { sceneType: this.sceneRuleType } }).then(
        res => {
          this.historyList = res.data
        },
        rea => {
          this.$message.error(rea)
        }
      )
    },
    initActivity() {
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      this.$axios.get(this.$API.advertActivityList, { params })
        .then(res => {
          this.activityList = res.data.data
          if (this.advertActivityId) {
            this.onUseActivity(this.advertActivityId, '1')
          }
          this.total = res.data.totalCount
        })
        .catch(() => {})
    },
    onClearHistory() {
      if (this.historyList.length === 0) return
      this.$axios.post(this.$API.onDelSceneRule, null, { params: {sceneType: this.sceneRuleType} }).then(() => {
        this.historyList = []
        this.$message.success('删除成功')
      })
    },
    onDelHistoryRule(currentId = '') {
      this.$axios.post(this.$API.onDelSceneRule, null, { params: {sceneRuleId: currentId, sceneType: this.sceneRuleType} }).then(() => {
        this.$message.success('删除成功')
        this.historyList = [...this.historyList].filter(v => v.id !== currentId)
      },
      () => {}
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs-wrapper ::v-deep {
  padding-bottom: 20px;
  margin-left: -60px;
  margin-right: -60px;
  .el-menu.el-menu--horizontal {
    margin-left: 60px;
  }
  .el-menu-item {
    padding: 0;
    margin-right: 40px;
    &.is-active {
      border-bottom: 4px solid #3d61e3;
    }
  }
}
.row-line {
  border-bottom: 1px solid #e9eaeb;
  padding-top: 5px;
  padding-bottom: 15px;
}
.scene-label {
  color: #5a5a5a;
  flex-shrink: 0;
  min-width: 100px;
  text-align: right;
}
.scene-content {
  color: #7784a1;
  flex: 1;
  .text-info-link {
    color: #c8c9ce;
    &:hover {
      color: #3d61e3;
    }
  }
}
.link * {
  margin-bottom: 10px;
}
.scene-child-wrapper {
  padding-bottom: 20px;
  margin-top: -10px;
}
.input-cover {
  margin-left: 6px;
  margin-right: 6px;
}
.text-tips {
  color: #c8c9ce;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e9eaeb;
}
.link-tips {
  padding-top: 20px;
  color: #c8c9ce;
}
.history-wrapper {
  padding-top: 15px;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
  }
}
.history-rule-item {
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  background: #f8f8f8;
  color: #c8c9ce;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 5px;
  &.active {
    border-color: #3d61e3;
    color: #3d61e3;
    background: #fff;
  }
  .history-rule-title {
    font-size: 16px;
    font-weight: bolder;
  }
  .rule-close {
    cursor: pointer;
    margin-left: 12px;
    font-size: 14px;
    margin-top: 4px;
    font-weight: bolder;
    &:hover {
      color: #3d61e3;
    }
  }
}
// 修改表格里activityImageUrl图片的宽高
.el-table__body .cell img {
  width: 105px;
  height: 30px;
}
</style>
