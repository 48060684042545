<!--
 * @Author: your name
 * @Date: 2021-08-05 11:54:28
 * @LastEditTime: 2021-08-23 11:50:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/bizComponents/SceneShareDepModal.vue
-->
<template>
  <div class="share-course-box">
    <el-dialog :visible.sync="visible" :close-on-click-modal="false" :show-close="false">
      <div class="font-weight" slot="title">选择组织</div>
      <div class="content-tree-box">
        <el-tree ref="tree"
          :data="treeList"
          stripe
          show-checkbox
          check-strictly
          node-key="orgId"
          check-on-click-node
          :default-expanded-keys="defaultSelectList"
          :props="defaultProps">
          <template slot-scope="{data}">
            <div>
              <span class="col272e40" :class="{'font-weight': data.child && data.child.length>0 || data.level ===2}">{{data.orgName}}</span>
              <!-- <span v-if="data.child && data.child.length>0 && data.level ===2" class="col272e40 font-weight pdg-lr50">共（{{data.child?data.child.length:0}}个地区组织）</span> -->
            </div>
          </template>
        </el-tree>
      </div>
      <div slot="footer" class="flex-center mgn-t20">
        <el-button @click="onClose">取消</el-button>
        <el-button :loading="loading"  type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'


export default {
  name: "SelectSceneDepModal",
  data(){
    return {
      // -------------------- submit ----------------
      loading: false,
      // --------------------- tree data ------------------------
      defaultProps:{
        children:'child',
        label:'orgName',
        disabled: 'disabled'
      },
      treeList:[],
    }
  },
  props:{
    visible:{       // 是否显示，默认true
      type: Boolean,
      default: true,
    },
    sceneId:{
      type: String,
      default: '',
    },
    sceneType: {
      type: String,
      default: ''
    },
    defaultSelectList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch:{
    visible(newVal,oldVal){
      if(newVal){
        this.init()
      }
    }
  },
  methods:{
    ...mapActions(['getSceneDepList']),
    init () {
      this.treeList = []
      let sceneIds = []
      if(this.sceneId) {
        sceneIds = [this.sceneId]
      }
      this.getSceneDepList({sceneType:this.sceneType, sceneIds}).then(res=>{
  			let call=(data)=>{
  			 return	data.map(k => {
            k.disabled = this.defaultSelectList.indexOf(k.orgId) > -1
  					if(k.child){
  						call(k.child)
            }
  					return k
  				})
        }
        console.log(this.defaultSelectList)
        this.treeList = call(res)
      },rea=>{

      })
    },
    onClose(){
      this.$emit("onClose")
    },
    onSubmit(){
      let output = this.$refs.tree.getCheckedNodes().map(v=>v.orgId).filter(k=>this.defaultSelectList.indexOf(k) < 0 )
      if (!output.length) return this.$message.error('请选择需要分配的组织')
      this.$emit('onSubmit',output)
    },
  }
}
</script>

<style lang='scss' scoped>
.share-course-box .el-dialog{
  width: 850px;
}
.share-course-box .el-dialog__body{
  padding: 0px;
}
.check-tream-search-box .el-input input{
  width: 250px;
  border-radius: 20px;
}
.content-tree-box ::v-deep {
  position: relative;
  height: 540px;
  overflow-y:scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  .el-tree-node__content {
    height: 60px;
    line-height: 60px;
  }
  .el-tree-node[role="treeitem"]:nth-child(2n-1) > div.el-tree-node__content{
    background:#F6F7FB;
  }
  .el-tree-node[role="treeitem"] > div.el-tree-node__content{
    background:#ffffff;
  }
}
.content-tree-box::-webkit-scrollbar{
  width: 0 !important;
}
.tree-item-box{
  height: 60px;
  line-height: 60px;
  position:relative;
  z-index:10;
}
.table-empry{
  height: 50px;
  line-height: 50px;
  background: #ffffff;
  text-align: center;
  border-bottom: 1px solid #E9EAEB;
}
</style>
