<template>
  <div>
    <page-main back title>
      <template slot="top">
        <Steps :value="1" :list="[`1.新建直播`, '2.设置任务规则', '3.发布委托需求', '4.选择任务对象并分配']" />
      </template>
      <live-form
        ref="liveForm"
        v-model="form"
        v-loading="loading"
        :default-view-min="defaultViewMin"
      />
    </page-main>
    <fixed-action-bar>
      <el-button
        class="w100"
        plain
        type="info"
        @click="onCancelPop"
      >
        取消
      </el-button>
      <el-button
        class="w100"
        type="primary"
        plain
        :loading="loading"
        @click="onSaveData"
      >
        保存
      </el-button>
      <el-button
        class="w100"
        type="primary"
        :loading="loading"
        @click="onSaveData(true)"
      >
        下一步
      </el-button>
    </fixed-action-bar>
  </div>
</template>
<script>
import LiveForm from './components/liveForm.vue'
import { mapActions } from 'vuex'
export default {
  name: 'EditLive',
  components: {
    LiveForm
  },
  data() {
    return {
      loading: false,
      form: {
        projectId: '',
        strategyId: '',
        strategyName: ''
      },
      defaultViewMin: 10
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.getDetails()
    }
    this.$store.dispatch('getCertifyRule', 5).then(() => {
      if (!this.$route.params.id) {
        this.$refs.liveForm.defaultForm.mustViewMin = this.$store.state.live.liveDefaultReviewSec
        this.form = this.$refs.liveForm?.defaultForm
      }
      this.defaultViewMin = this.$store.state.live.liveDefaultReviewSec
    })

  },
  methods: {
    ...mapActions(['onEditLive', 'getSceneDetailTag']),
    async getDetails() {
      this.loading = true
      try {
        const { data } = await this.$axios.get(this.$API.getLiveDetail, {
          params: {
            liveId: this.$route.params.id
          }
        })
        this.loading = false
        data.occupationImg = data.waitingImg
        data.mustViewMin = Math.max(data.mustViewMin, 10)
        this.form = Object.assign(this.$refs.liveForm?.defaultForm, data)
        this.form.speakers = data.speakers.map(item => {
          delete item.avatar
          return {
            ...item,
            avatar: item.avatarVO?.imageName,
            avatarUrl: item.avatarVO?.middle
          }
        })
        this.form.strategyDisabled = !!this.form.strategyId
        this.$refs.liveForm?.initTag()
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
    },
    onSaveData(flag) {
      this.$refs.liveForm.onSaveData(flag).then(params => {
        this.loading = true
        // 判断直播场次是否过多
        this.checkLiveSpeaks(params, flag)
      })
    },
    saveLive(params, flag) {
      this.onEditLive(params).then(res => {
        this.isShowToast = true
        this.isErrorBg = false
        this.form.id = res.data.id
        this.isSaveConfirm = false
        this.$emit('upload:isSubmit', false)
        if (flag === true) {
          this.loading = false
          this.$refs.liveForm?.onNext()
        } else {
          this.loading = false
          this.$refs.liveForm.isShowToast = true
          this.$refs.liveForm.isErrorBg = false
          this.$refs.liveForm.toastMessage = '保存成功'
          setTimeout(() => {
            // 判断历史记录是否有上一页
            if (window.history.length) {
              this.$router.push({
                name: 'Academic'
              })
            } else {
              this.$router.back()
            }
          }, 500)
        }
      }).catch(error => {
        this.loading = false
        this.$message.error(error)
        this.isSaveConfirm = false
        this.$emit('upload:isSubmit', false)
      })
    },
    checkLiveSpeaks(params, flag) {
      console.log(params)
      this.$axios.post(this.$API.checkLiveSpeaksRule, {
        memberTagInfo: params.memberTagInfo[0],
        speakers: params.speakers.map(item => {
          return {
            userId: item.userId,
            name: item.name
          }
        }),
        startDate: params.startAt,
        isCreat: !this.$route.params.id
      }).then(res => {
        if (res.data.length > 0) {
          this.$confirm(`播客${res.data}近期直播场次过多，可能会造成直播质量较差，审核不通过，建议修改直播时间`, '创建直播失败', {
            confirmButtonText: '确定',
            type: 'warning',
            showCancelButton: false,
            center: true
          }).then(() => {
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        } else {
          this.saveLive(params, flag)
        }
      })
    },
    onCancelPop() {
      this.$refs.liveForm.onCancelPop()
    }
  }
}
</script>
