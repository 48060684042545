<template>
  <el-dialog
    title="回放设置"
    lock-scroll
    :modal-append-to-body="false"
    :visible.sync="showLivePlayback"
    width="900px"
  >
    <div class="live-playback">
      <div class="live-playback-body" :loading="loading">
        <div class="current-video">
          <span>当前回放视频：从缓存库中选择合适的视频，设为直播回放</span>
          <div v-if="replayUseList.length === 0" class="current-playback">暂无回放视频</div>
          <el-scrollbar v-else style="height: 120px;" class="live-list">
            <div class="live-list-wrapper">
              <live-item
                v-for="item in replayUseList"
                :key="item.id"
                :live-id="liveId"
                replay-use
                :need-check="false"
                :item="item"
              />
            </div>
          </el-scrollbar>
        </div>

        <span>直播缓存库：最多保留15天</span>
        <div class="live-list">
          <div class="live-list-deal-with">
            <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选（已选{{ choosList.length }}）</el-checkbox>
            <div>
              <ksh-button
                class="btn"
                size="small"
                icon="el-icon-delete"
                @click="onDel"
              >
                删除
              </ksh-button>
              <ksh-button
                class="btn"
                size="small"
                icon="el-icon-copy-document"
                @click="openMergeModal"
              >
                剪辑
              </ksh-button>
            </div>
          </div>
          <el-scrollbar v-loading="loading" class="scroll-bar">
            <el-checkbox-group
              v-if="liveList.length"
              v-model="choosList"
              class="live-list-wrapper"
              @change="handleChoosListChange"
            >
              <live-item
                v-for="item in liveList"
                :key="item.id"
                :live-id="liveId"
                :live-status="liveStatus"
                need-check
                :item="item"
                @setPlaySuccess="getDataList"
              >
                <template #title>
                  <el-checkbox
                    class="title"
                    :title="item.title"
                    :disabled="item.transcodeStatus !== 2"
                    :label="item"
                  >
                    {{ item.title }}
                  </el-checkbox>
                </template>
              </live-item>
            </el-checkbox-group>
            <div v-else style="height: 100%; text-align: center; margin-top: 140px;">暂无数据</div>
          </el-scrollbar>
        </div>
      </div>

      <el-dialog
        width="85%"
        :show-close="false"
        :close-on-click-modal="false"
        lock-scroll
        class="video-clip-dialog"
        title="剪辑"
        :visible.sync="drawer"
        append-to-body
      >
        <div class="dialog-body">
          <div class="left-body">
            <p class="title">合并视频：（请注意合并片段的先后顺序）</p>
            <ksh-input v-model="mergeForm.title" placeholder="请输入合并后新视频的标题" :maxlength="100" />
            <div class="live-edit-list">
              <el-scrollbar class="scroll-live-list" style="height: 100%;">
                <live-item
                  v-for="(item, index) in choosList"
                  :key="item.id"
                  :live-status="liveStatus"
                  :live-id="liveId"
                  class="item"
                  :class="{ active: activeItem.id === item.id }"
                  no-btn
                  style="margin-bottom: 20px;"
                  :item="item"
                  @click.native="activeItem = item"
                >
                  <template #title>
                    <div class="headline">
                      <span class="headline-title" :title="item.title">{{ item.title }}</span>
                      <span v-if="choosList.length !== 1">
                        <svg-icon
                          name="up"
                          class="icon"
                          :class="index !== 0 ? 'usable' : ''"
                          @click.stop="index !== 0 ? onMoveItem('up', index) : () => {}"
                        />
                        <svg-icon
                          :class="index !== choosList.length - 1 ? 'usable' : ''"
                          name="down"
                          class="icon"
                          @click.stop="index !== choosList.length - 1 ? onMoveItem('down', index) : () => {}"
                        />
                      </span>
                    </div>
                  </template>
                </live-item>
              </el-scrollbar>
            </div>
          </div>
          <div class="right-body">
            <div v-if="Object.values(activeItem).length === 0" class="empty">请选择要剪辑的视频</div>
            <VideoClip v-else-if="drawer" :current-item.sync="activeItem" @change="upDataItem" />
          </div>
        </div>
        <div slot="footer" class="dialog-footer video-clip">
          <ksh-button @click="onCancel">取消剪辑</ksh-button>
          <ksh-button :loading="mergeLoading" type="primary" @click="onMerge">保存并返回</ksh-button>
        </div>
      </el-dialog>
    </div>
    <!-- <LivePlayback :liveStatus="details.liveStatus" v-if="showLivePlayback" /> -->
    <div style="text-align: center; margin-top: 20px;">
      <el-button style="width: 160px;" type="primary" @click="showLivePlayback = false">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import LiveItem from './live-item.vue'
import { getLiveCacheList, onMergeVideo, onDelVideoCache } from '@/services/liveService'
import VideoClip from '../video-clip/index.vue'

export default {
  name: 'LivePlayback',
  components: { LiveItem, VideoClip },
  props: {
    liveStatus: {
      type: Number,
      default: 0
    },
    value: {
      type: Boolean,
      default: false
    },
    liveId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checkAll: false,
      loading: false,
      mergeLoading: false,
      choosList: [],
      isIndeterminate: false,
      drawer: false,
      search: {},
      liveList: [],
      replayUseList: [],
      activeItem: {},
      mergeForm: {
        title: ''
      }
    }
  },
  computed: {
    showLivePlayback: {
      get() {
        if (this.value) {
          this.getDataList()
        }
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    // this.search.liveId = this.$route.params.liveId
  },
  methods: {
    async getDataList() {
      if (this.liveId) {
        this.search.liveId = this.liveId
      } else {
        this.search.liveId = this.$route.params.liveId
      }
      try {
        this.loading = true
        const params = Object.assign({}, this.search)
        const { data } = await getLiveCacheList(params)
        this.liveList = data
        this.replayUseList = data.filter(item => item.replayUse)
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    async onMerge() {
      if (!this.mergeForm.title) {
        this.$message({
          type: 'warning',
          message: '请输入合并后新视频的标题'
        })
        return
      }
      try {
        this.mergeloading = true
        const params = {
          ...this.mergeForm,
          ...this.search,
          editItems: this.formatDelList(this.choosList)
        }
        console.log('params', params)

        await onMergeVideo(params)
        this.getDataList()
        this.drawer = false
        this.$message.success('保存成功')
        // 清除已选的视频
        this.mergeLoading = false
        this.onCancel()
      } catch (error) {
        this.mergeLoading = false
        this.$message.error(error.message)
        console.log(error)
      }
    },
    onDel() {
      if (this.choosList.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择要删除的视频'
        })
        return
      }
      this.$MessageDialog({
        title: '提示',
        message: '此操作将永久删除该视频, 是否继续?'
      }).then(async() => {
        let showError = false
        this.choosList.map(v => {
          console.log('v', v)
          if (v.saveType === 1) {
            showError = true
            return
          }
          return
        })
        if (showError) {
          this.$message({
            type: 'error',
            message: '原视频不可删除'
          })
          return
        }
        const params = Object.assign({ videoIds: this.choosList.map(item => item.id) }, this.search)
        try {
          const { data } = await onDelVideoCache(params)
          if (data) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.choosList = this.$options.data().choosList
            this.getDataList()
          }
        } catch (error) {
          this.$message({
            type: 'error',
            message: error.message
          })
        }
      })
    },
    /**
     * 打开弹窗
     * 调用时机：点击合并
     * @returns
     */
    openMergeModal() {
      if (this.choosList.length !== 0) {
        this.activeItem = this.choosList[0] || {}
        this.drawer = true
      } else {
        this.$message({
          type: 'warning',
          message: '请选择要剪辑的视频'
        })
      }
    },
    upDataItem(delFragment) {
      this.choosList.map((item, index) => {
        if (item.id === this.activeItem.id) {
          // this.choosList[index].fragment = delFragment
          this.$set(this.choosList[index], 'fragment', delFragment)
        }
      })
    },
    // 关闭视频剪辑弹窗
    onCancel() {
      this.drawer = false
      this.mergeForm.title = ''
      this.onCloseClip()
    },
    /**
         * 格式化 delList
         */
    formatDelList(arr) {
      const array = []
      arr &&
        arr.map(item => {
          const obj = {
            startTimeOffset: 0,
            endTimeOffset: item.duration,
            videoId: item.id
          }
          if (!!item.fragment && item.fragment.length !== 0) {
            item.fragment.map(fragmentItem => {
              array.push({
                startTimeOffset: fragmentItem.startTime,
                videoId: item.id,
                endTimeOffset: fragmentItem.endTime
              })
            })
          } else {
            array.push(obj)
          }
        })
      return array
    },
    // 重置已选择的视频
    onCloseClip() {
      this.choosList = this.choosList.map(item => {
        item.fragment = []
        return item
      })
      this.choosList = []
    },
    /**
     * 移动当前项 上/下
     * 调用时机：点击 up/down 时
     * @params {String} type
     */
    onMoveItem(type, index) {
      const currentItem = this.choosList[index]
      this.choosList.splice(index, 1)
      if (type === 'up') {
        this.choosList.splice(index - 1, 0, currentItem)
      } else if (type === 'down') {
        this.choosList.splice(index + 1, 0, currentItem)
      }
    },
    handleCheckAllChange(val) {
      this.choosList = val ? this.liveList.filter(v => v.transcodeStatus === 2) : []
      this.isIndeterminate = false
    },
    handleChoosListChange(value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.liveList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.liveList.length
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.live-playback-body {
  .current-video {
    .current-playback {
      width: 390px;
      height: 100px;
      text-align: center;
      line-height: 100px;
      background-color: #f5f5f5;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
  .scroll-bar {
    height: 300px;
  }
  .live-list {
    margin-top: 15px;
    .live-list-deal-with {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0;
      .btn {
        background-color: #fbfbfb;
        color: #5a5a5a;
      }
    }
    .live-list-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 19px;
      font-size: 14px;
    }
  }
}
.dialog-body {
  display: flex;
  min-width: 900px;
  .left-body {
    min-width: 453px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 14px;
      font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
      font-weight: 400;
      color: #5a5a5a;
      margin-bottom: 13px;
    }
    .live-edit-list {
      margin-top: 20px;
      position: relative;
      flex: 1;
      .scroll-live-list {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        .item {
          cursor: pointer;
          &.active {
            border-color: #3d61e3;
            background-color: rgba(61, 97, 227, 0.06);
            .headline-title {
              color: #3d61e3;
            }
          }
          &:hover {
            border-color: #3d61e3;
          }
          &:nth-last-child(1) {
            margin-bottom: 0 !important;
          }
        }
        .headline {
          display: flex;
          justify-content: space-between;
          .icon {
            font-size: 24px;
            color: #c8c8c8;
            cursor: not-allowed;
            opacity: 1;
            &.usable {
              color: #3d61e3;
              cursor: pointer;
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }
  .right-body {
    flex: 1;
    min-height: 500px;
    overflow: hidden;
    .empty {
      text-align: center;
      margin-top: 100px;
    }
  }
}
.video-clip-dialog {
  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
  }
}
.dialog-footer {
  text-align: center;
  padding-top: 20px;
}
</style>
