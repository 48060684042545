<template>
  <div class="live-form">
    <div class="approver-box">
      <div class="approver-left">
        <el-form
          ref="form"
          v-loading="loading"
          class="customize-form cs-in-bold-title"
          :model="form"
          :rules="formRules"
          label-suffix="："
          :show-message="false"
          label-width="140px"
          style="margin-top: 30px; width: 900px;"
          :disabled="isDisabled"
        >
          <el-form-item prop="title" label="直播标题">
            <el-input
              v-model="form.title"
              class="scene-form-input title-input"
              placeholder="请输入直播标题，标题不能包含产品名称和组织名称"
              maxlength="40"
              show-word-limit
            />
          </el-form-item>
          <el-form-item prop="liveDesc" label="直播简介">
            <el-input
              v-model="form.liveDesc"
              class="scene-form-input"
              type="textarea"
              placeholder="请输入直播简介，简介不能包含产品名称和组织名称"
              rows="8"
              maxlength="400"
              show-word-limit
            />
          </el-form-item>
          <el-form-item prop="startAt" label="直播开始时间">
            <el-row type="flex" align="middle" class="scene-form-content">
              <el-date-picker
                v-model="form.startAt"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
                :editable="false"
                placeholder="选择日期时间"
              />
              <el-popover placement="bottom" width="350" trigger="click">
                <el-form
                  ref="form"
                  size="small"
                  label-suffix="："
                  label-width="140px"
                >
                  <el-form-item label="直播倒计时开关">
                    <el-switch v-model="form.autoStart" />
                  </el-form-item>
                  <el-form-item label="距直播开始还有">
                    {{ countdownStr }}
                  </el-form-item>
                  <div style="color: #ccc; margin-bottom: 10px;">备注：设置开启后，观众端只有到了直播开始时间，才正式开始直播推流。</div>
                </el-form>
                <el-button slot="reference" type="text" style="margin-left: 10px;">更多设置</el-button>
              </el-popover>
            </el-row>
          </el-form-item>
          <!-- <el-form-item prop="liveType" label="直播类型">
            <el-radio v-model="form.liveType" :label="1">医生宣教</el-radio>
          </el-form-item> -->
          <el-form-item prop="isPublic" label="是否公开">
            <el-radio-group v-model="form.isPublic">
              <el-radio :label="0">非公开（仅限代表邀约和白名单观众）</el-radio>
              <el-radio :label="1">公开（非认证医生也可以观看）</el-radio>
              <el-radio :label="2">半公开（医师认证即可观看）</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item v-if="form.isPublic === 1" prop="" label="表单功能">
            <el-switch v-model="form.isOpenForm" /> <el-button
              style="margin-left: 10px;"
              :disabled="!form.isOpenForm"
              type="text"
              @click="liveFormVisible = true"
            >
              表单设置
            </el-button>
            <el-popover placement="top" width="300" trigger="hover">
              <svg-icon slot="reference" name="question-circle" style="font-size: 14px; color: #ababab; margin-left: 5px;" />
              <div>
                1.表单功能针对目标用户进行信息表单收集
                <br>
                2.开启之后，目标用户在进入直播间时，要求填写信息表单
              </div>
            </el-popover>
          </el-form-item>

          <el-form-item required prop="coverImg" label="直播封面">
            <el-radio-group v-model="coverImg.radio">
              <el-radio :label="0">默认</el-radio>
              <el-radio :label="1">自定义</el-radio>
            </el-radio-group>
            <el-row type="flex" align="middle" class="scene-form-content tips">
              <img-upload :value="coverImg.radio ? form.coverImg : coverImg" :disabled="coverImg.radio === 0" @input="row => uploadImg('coverImg', row)">
                <div>上传封面</div>
              </img-upload>
              <div class="colc8c9ce" style="margin-left: 30px;">备注：封面可在直播未开始时在背景上显示，可添加直播主题/时间等信息，建议尺寸16:9</div>
            </el-row>
          </el-form-item>
          <el-form-item required label="直播占位图">
            <el-radio-group v-model="occupationImg.radio">
              <el-radio :label="0">默认</el-radio>
              <el-radio :label="1">自定义</el-radio>
            </el-radio-group>
            <el-row type="flex" align="middle" class="tips">
              <img-upload :value="occupationImg.radio ? form.occupationImg : occupationImg" :disabled="occupationImg.radio === 0" @input="row => uploadImg('occupationImg', row)">
                <div>上传占位图</div>
              </img-upload>
              <div class="colc8c9ce" style="margin-left: 30px;">备注：占位图建议尺寸16:9，主要展示直播相关信息，简介日期等<el-button type="text" :disabled="false" @click="showFigure = true">查看示例</el-button></div>
            </el-row>
          </el-form-item>
          <el-form-item required label="标签/关键信息">
            <div class="scene-form-content">
              <ProductLabelKnowledge :selected-tag-list="selectedTagList" :disabled-ids="disabledIds" @change="onTagChange" />
              <div>
                <el-button plain type="info" @click="onOpenTagModal">添加标签 <svg-icon name="el-icon-plus" /></el-button>
              </div>
            </div>
          </el-form-item>
          <StrategyProjectFormItem
            :is-need-project="true"
            :disabled="strategyProjectDisabled || isDisabled"
            :form.sync="form"
            @projectChange="form.speakers = []"
          />
          <el-form-item required label="直播嘉宾">
            <el-row class="scene-form-content">
              <el-row type="flex" align="middle" class="tips">
                <el-button
                  plain
                  type="info"
                  :disabled="isDisabled"
                  @click="isShowSpeakerModal = true"
                >
                  添加播客 <svg-icon name="el-icon-plus" />
                </el-button>
                <div class="colc8c9ce" style="margin-left: 30px;">备注：直播嘉宾必须是平台厂家签约播客</div>
              </el-row>
              <el-table class="cover-table customize-el-table" :data="form.speakers">
                <el-table-column
                  prop="avatar"
                  label="头像"
                  align="center"
                  current-row-key="avatar"
                >
                  <template #default="scope">
                    <el-upload
                      :action="uploadApi"
                      :headers="headers"
                      name="image"
                      :show-file-list="false"
                      :before-upload="beforeUpload"
                      :on-success="(res, file) => handleSuccess(scope.$index, res, file)"
                      accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                      class="upload-img"
                    >
                      <el-avatar
                        v-if="scope.row.avatarUrl"
                        :size="42"
                        :src="scope.row.avatarUrl"
                        class="img-item"
                      />
                      <template v-if="!scope.row.avatarUrl" #trigger>
                        <el-avatar
                          :size="42"
                          :src="require('@/assets/doctor/speaker-avatar.png')"
                        />
                      </template>
                    </el-upload>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="姓名"
                  width="120"
                  align="center"
                  show-overflow-tooltip
                />
                <el-table-column width="150" align="center" label="所属职称">
                  <template slot-scope="scope">
                    <span>{{ scope.row.titleName || '/' }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="昵称">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.nickName"
                      size="mini"
                      controls-position="right"
                      :min="40"
                      :disabled="speakerDisable(scope.row)"
                    />
                  </template>
                </el-table-column>
                <el-table-column width="150" align="center" label="权限身份">
                  <template>
                    <span>主讲人</span>
                  </template>
                </el-table-column>
                <el-table-column width="150" align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      :disabled="speakerDisable(scope.row) || isDisabled"
                      class="text-primary-link"
                      @click="onDelSpeaker(scope.row)"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-form-item>
          <el-form-item label="直播详情">
            <Editor
              ref="editor"
              :init-content="form.liveDetail"
              :height="400"
              style="line-height: initial; width: 100%;"
            />
          </el-form-item>
          <el-form-item label="直播跟进员工">
            <span v-if="form.followerName">
              {{ form.followerName }}（{{ form.followerOrgName }}）
              <el-button type="text" :disabled="isDisabled" @click="isShowPop = true">更换</el-button>
            </span>
            <span v-else>
              <el-button
                type="info"
                :disabled="isDisabled"
                plain
                @click="isShowPop = true"
              >添加员工 <svg-icon name="el-icon-plus" /></el-button></span>
            <span style="margin-left: 20px;" class="colc8c9ce">备注：选择员工后，员工可在趣科会手机端进入活动中控台</span>
          </el-form-item>
          <el-form-item label="观看时长不低于">
            <el-input-number
              v-model="form.mustViewMin"
              controls-position="right"
              :min="defaultViewMin"
            />
          </el-form-item>
        </el-form>
      </div>
      <div v-if="sceneSource" class="approver-right">
        <div style="padding-bottom: 10px;">{{ form.followerName }}最近3个月的任务申请：</div>
        <el-table :data="tableData">
          <el-table-column label="申请日期" prop="applyTime" />
          <el-table-column
            label="播客姓名"
            align="center"
            prop="speakerNames"
            show-overflow-tooltip
          />
          <el-table-column label="申请结果" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status === 0" style="color: #ff7600;">待审核</span>
              <span v-if="scope.row.status === 1" style="color: #5a5a5a;">审核成功</span>
              <span v-if="scope.row.status === 2" style="color: #e8312f;">审核失败</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-show="isShowToast" class="toast-box" :class="{ errorBg: isErrorBg }">{{ toastMessage }}</div>
    <ProductLabel
      :visible.sync="showTagModal"
      :disabled-ids="disabledIds"
      :form.sync="form"
      :selected-tag-list="selectedTagList"
      @onSubmit="onSubmitTag"
    />
    <AddSpeakerModal
      :disabled-list="disableSpeakerList"
      :max="10000"
      :scene-type="sceneTypeId"
      :project-id="form.projectId"
      :selected="addSpeakerModalSelected"
      :visible="isShowSpeakerModal"
      @onClose="isShowSpeakerModal = false"
      @onSubmit="onSubmitSpeaker"
    />
    <OperationConfirmPop
      :visible="isShowOperationConfirmPop"
      :message="message"
      title="操作确认"
      cancel-font="继续编辑"
      confirm-font="确定离开"
      @onClose="isShowOperationConfirmPop = false"
      @onSubmit="$router.push('/academic/list')"
    />
    <!-- 直播占位图 -->
    <live-map
      v-model="showCover"
      label="封面图"
      top="125px"
      left="80px"
      title="封面图示例"
      :src="require('@/assets/academic/cover.png')"
    />
    <live-map
      v-model="showFigure"
      label="占位图"
      top="65px"
      left="80px"
      title="占位图示例"
      :src="require('@/assets/academic/figure.png')"
    />
    <!-- 选择代表 -->
    <SelectOrgRepresentModel
      is-single
      :init-select-list="initSelectList"
      :is-show-pop="isShowPop"
      @onConfirmSelect="onConfirmSelect"
      @onClose="val => (isShowPop = val)"
    />

    <!-- 直播表单弹窗 -->
    <InformationCollectionForm
      ref="InformationCollectionForm"
      :form-id.sync="form.formId"
      :visible.sync="liveFormVisible"
    />
  </div>
</template>

<script>
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop.vue'
import AddSpeakerModal from '@/bizComponents/AddSpeakerModal.vue'
import LiveMap from '../live-map.vue'
import Editor from '@/baseComponents/Editor.vue'
import SelectOrgRepresentModel from '@/bizComponents/SelectOrgRepresentModel.vue'
import ImgUpload from './imgUpload.vue'
import InformationCollectionForm from './InformationCollectionForm.vue'
import ProductLabel from '@/bizComponents/ProductLabel'
import ProductLabelKnowledge from '@/bizComponents/ProductLabelKnowledge'
import StrategyProjectFormItem from './strategy-project-form-item.vue'
import { mapActions, mapGetters } from 'vuex'
import { deepClone } from '@/utils/util'
import { getToken } from '@/utils/auth'
import API from '@/apis/api-types'

export default {
  name: 'LiveForm',
  components: {
    ProductLabel,
    ProductLabelKnowledge,
    AddSpeakerModal,
    OperationConfirmPop,
    LiveMap,
    Editor,
    SelectOrgRepresentModel,
    ImgUpload,
    InformationCollectionForm,
    StrategyProjectFormItem
  },
  props: {
    // 提交 loading
    isSubmit: {
      type: Boolean,
      default: false
    },
    // 是否是审批
    isApprove: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      require: true
    },
    defaultViewMin: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      selectedTagList: [],
      disabledIds: [],
      tableData: [],
      speakerType: 1, //  1 医生 2代表
      userTagType: 7, // 标签分类
      sceneTypeId: 5, // 直播
      sceneType: 'live',
      showCover: false,
      showFigure: false,
      loading: false,
      liveFormVisible: false,
      headers: {
        token: getToken()
      },
      isShowToast: false,
      toastMessage: '',
      isErrorBg: false,
      isSaveConfirm: false,
      showTagModal: false,
      isShowSpeakerModal: false,
      isShowOperationConfirmPop: false,
      pickerOptions: {
        disabledDate: current => {
          return (current && current < this.$moment(new Date()).add(-1, 'days').endOf('day') - 1) || current > this.$moment(new Date()).add(90, 'days').endOf('day') - 1
        }
      },
      message: '当前页面未编辑完成，您确定要离开当前编辑页面吗？',
      countdownStr: '',
      disableSpeakerList: [],
      isShowPop: false,
      coverImg: {
        radio: 0,
        middle: 'https://cdnimg1.yaomaitong.cn/ksh/cover/53413688a1c84ec7928f96a1eb0c6c5b.png?x-oss-process=style/nologo_m', // 默认封面
        imageName: 'ksh/cover/53413688a1c84ec7928f96a1eb0c6c5b.png'
      },
      occupationImg: {
        // 直播占位图名字
        radio: 0,
        middle: 'https://cdnimg1.yaomaitong.cn/ksh/cover/live-will-start.png?x-oss-process=style/nologo_m',
        imageName: 'ksh/cover/live-will-start.png'
      },
      defaultForm: {
        canEdit: true,
        sceneType: 5,
        title: '',
        liveDesc: '',
        startAt: '',
        isOpenForm: false,
        autoStart: false,
        liveType: 1,
        isPublic: 2,
        coverImg: {},
        speakers: [],
        mustViewMin: 10,
        memberTagInfo: [],
        occupationImg: {}, // 直播占位图名字
        formId: '',
        projectId: '',
        strategyId: '',
        strategyName: ''
      },
      formRules: {
        title: [{ required: true, message: '请填写直播标题', trigger: 'blur' }],
        liveDesc: [{ required: true, message: '请填写直播简介', trigger: 'blur' }],
        startAt: [{ required: true, message: '请选择直播开始时间', trigger: 'blur' }],
        liveType: [{ required: true, message: '请选择直播类型', trigger: 'change' }],
        isPublic: [{ required: true, message: '请选择是否公开', trigger: 'change' }]
      }
    }
  },
  computed: {
    ...mapGetters(['tagGroupList']),
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    isDisabled() {
      return this.form.auditStatus === 2 || this.form.auditStatus === -1 || this.form.auditStatus === 1
    },
    initSelectList() {
      const arr = [
        {
          id: this.form.representOrgId,
          key: `${this.form.followerId}-${this.form.representOrgId}`,
          representId: this.form.followerId,
          representName: this.form.followerName,
          orgName: this.form.followerOrgName,
          orgId: this.form.representOrgId,
          isSelect: true
        }
      ]
      return arr
    },
    addSpeakerModalSelected() {
      return this.form.speakers
        ? this.form.speakers.map(item => {
          item.id = item.userId
          return item
        })
        : []
    },
    sceneSource() {
      return this.$route.query.from === 'common' && this.form.auditStatus === 0
    },
    strategyProjectDisabled() {
      return this.form.speakers?.some(item => this.speakerDisable(item))
    },
    uploadApi() {
      return `${API.uploadImage}?bucketType=104`
    }
  },
  watch: {
    isShowToast(isShow) {
      if (isShow) {
        setTimeout(() => {
          this.toastMessage = ''
          this.isShowToast = false
        }, 3000)
      }
    },
    form: {
      handler(newVal) {
        if (newVal) {
          if (!Object.keys(newVal?.occupationImg || {}).length || newVal.occupationImg?.imageName === this.occupationImg.imageName) {
            this.occupationImg.radio = 0
            this.form.occupationImg = {}
          } else {
            this.occupationImg.radio = 1
          }
          if (!Object.keys(newVal?.coverImg || {}).length || newVal.coverImg?.imageName === this.coverImg.imageName) {
            this.coverImg.radio = 0
            this.form.coverImg = {}
          } else {
            this.coverImg.radio = 1
          }
        }
      },
      immediate: true
    },
    ['form.followerId']() {
      this.onGetLastTask()
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  mounted() {
    this.timer = setInterval(() => {
      let str = ''
      const start_data = this.$moment(this.form.startAt, 'YYYY-MM-DD HH:mm:ss')
      const end_data = this.$moment(new Date(), 'YYYY-MM-DD HH:mm:ss')
      const seconds = start_data.diff(end_data, 'seconds')
      if (seconds < 0 || !this.form.startAt) {
        str = '00天00时00分00秒'
      } else {
        str = this.secondsFormat(seconds)
      }
      this.countdownStr = str
    }, 1000)

    this.getTagGroup()
  },
  methods: {
    ...mapActions(['onEditLive', 'getSceneDetailTag', 'getTagGroup']),
    onGetTagName(list = [], groupCode = 'PRODUCT_TAG_GROUP') {
      return list?.filter(v => v.groupCode === groupCode)?.map(v => v.tagName).join('-') || '-'
    },
    onGetLastTask() {
      if (!this.form.followerId) return
      const params = {
        sceneType: this.sceneType === 'live' ? 5 : 6,
        representId: this.form.followerId
      }
      this.$axios.get(this.$API.repLastMonthsLiveTask, {params}).then(res => {
        this.tableData = res.data
      })
    },
    onDelSpeaker(row) {
      this.form.speakers = this.form.speakers.filter(v => row.userId !== v.userId)
    },
    // 标签
    initTag(id) {
      const memberId = id || this.$route.params.id
      const groupIds = this.tagGroupList.map(v => v.id)
      const req = {
        memberIdList: [memberId],
        userTagType: this.userTagType,
        tagType: 1,
        groupIds: groupIds
      }
      this.getSceneDetailTag(req).then(res => {
        this.disabledIds = res.map(v => v.tagId)
        this.selectedTagList = res.map(v => {
          v = { ...v, disabled: true }
          return v
        })
      })
    },
    onSubmitTag(selectedList) {
      this.showTagModal = false
      this.selectedTagList = [...selectedList]
    },
    onTagChange(tagList) {
      this.selectedTagList = tagList
    },
    onOpenTagModal() {
      this.showTagModal = true
    },
    onSubmitSpeaker(arr) {
      console.log('arr', arr)
      const list = deepClone(arr)
      this.form.speakers = list.map(item => {
        delete item.speakerStatus
        return {
          ...item,
          nickName: item.nickName || item.name,
          userType: this.speakerType,
          isPresenter: true,
          userId: item.id,
          avatar: item.avatar || item.avatarVO?.imageName,
          avatarUrl: item.avatarUrl || item.avatarVO?.middle
        }
      })
      this.isShowSpeakerModal = false
    },
    onOpenOperationConfirmPop() {
      if (!this.isSaveConfirm) {
        this.isShowOperationConfirmPop = true
      } else {
        this.$router.back()
      }
    },
    // 下一步
    onNext() {
      this.$router.push(`/academic/scene/${this.sceneType}/rule/${this.form.id}`)
    },
    onCancelPop() {
      this.onOpenOperationConfirmPop()
    },
    onSaveData(isNext = false) {
      return new Promise((reslove, reject) => {
        if (this.form.isPublic !== 1) {
          this.form.isOpenForm = false
        }
        if (!this.form.canEdit) return this.$message.error('当前直播状态无法编辑')
        this.form.memberTagInfo = this.selectedTagList.map(v => {
          return {
            userTagType: this.userTagType,
            tagId: v.tagId,
            groupId: v.groupId,
            tagName: v.tagName,
            knowledgePointIds: v.knowledgePointIds,
            memberId: this.sceneId
          }
        })
        if (!this.onValid(this.form) || this.isSubmit) {
          reject(false)
          return false
        }
        if (isNext === true && !this.form.canEdit) {
          this.onNext()
          return
        }

        const params = Object.assign({}, this.form)
        if (this.coverImg.radio === 0) {
          params.coverImg = this.coverImg
        }
        if (this.occupationImg.radio === 0) {
          params.occupationImg = this.occupationImg
        }
        params.coverImg = params.coverImg.imageName
        params.occupationImg = params.occupationImg.imageName
        params.liveDetail = this.$refs.editor.getValue()
        if (!params.id) delete params.id
        this.$emit('upload:isSubmit', true)
        reslove(params)
      })
    },
    // 验证表单
    onValid(form) {
      console.log('form', form)
      if (!form.title) {
        this.isShowToast = true
        this.toastMessage = '请填写直播标题'
        this.isErrorBg = true
        return false
      }
      if (!form.liveDesc) {
        this.isShowToast = true
        this.toastMessage = '请填写直播简介'
        this.isErrorBg = true
        return false
      }
      if (!form.startAt) {
        this.isShowToast = true
        this.toastMessage = '请选择直播开始时间'
        this.isErrorBg = true
        return false
      }
      if (form.isPublic === 1 && form.isOpenForm) {
        if (!form.formId) {
          this.isShowToast = true
          this.toastMessage = '请点击表单设置，创建表单题目'
          this.isErrorBg = true
          return false
        }
      }
      if (!form.coverImg?.imageName && this.coverImg.radio !== 0) {
        this.isShowToast = true
        this.toastMessage = '请添加直播封面'
        this.isErrorBg = true
        return false
      }
      if (!form.occupationImg?.imageName && this.occupationImg.radio !== 0) {
        this.isShowToast = true
        this.toastMessage = '请添加直播占位图'
        this.isErrorBg = true
        return false
      }
      if (this.onCheckReq()) {
        this.isShowToast = true
        this.toastMessage = '请选择产品标签'
        this.isErrorBg = true
        return false
      }
      if (!form.speakers.length) {
        this.isShowToast = true
        this.toastMessage = '请选择直播嘉宾'
        this.isErrorBg = true
        return false
      }
      if (!this.$refs.editor.getValue()) {
        this.isShowToast = true
        this.toastMessage = '请填写直播详情'
        this.isErrorBg = true
        return false
      }
      return true
    },
    onCheckReq() {
      for (const item of this.form.memberTagInfo || []) {
        if (this.tagGroupList[0].id === item.groupId) {
          return false
        }
      }
      return true
    },
    // 上传图片
    uploadImg(type, row) {
      this.form[type] = row
    },
    /*
     *@param s : 时间秒
     */
    secondsFormat(s) {
      const day = Math.floor(s / (24 * 3600)) // Math.floor()向下取整
      const hour = Math.floor((s - day * 24 * 3600) / 3600)
      const minute = Math.floor((s - day * 24 * 3600 - hour * 3600) / 60)
      const second = s - day * 24 * 3600 - hour * 3600 - minute * 60
      return `${day}天${hour}时${minute}分${second}秒`
    },
    speakerDisable(row) {
      const { reviewStatus } = row
      const flag = !this.form.canEdit || reviewStatus === 1 || reviewStatus === 2 || reviewStatus === 4 || reviewStatus === 6
      if (flag && !this.disableSpeakerList.includes(row.id)) this.disableSpeakerList.push(row.id)
      return flag
    },
    // 选择代表
    onConfirmSelect([row = {}]) {
      const { representId = '', representName = '', orgName = '', orgId = '' } = row
      if (representId) {
        this.form.followerId = representId
        this.form.followerName = representName
        this.form.followerOrgName = orgName
        this.form.representOrgId = orgId
        this.onGetLastTask()
      }
    },
    handleSuccess(index, res, file) {
      this.$set(this.form.speakers, index, {
        ...this.form.speakers[index],
        avatar: res.data.imageName,
        avatarUrl: URL.createObjectURL(file.raw)
      })

    },
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>

<style scoped lang="scss">
.live-form {
  .scene-form-item {
    margin-bottom: 12px;
  }
  .scene-form-label {
    flex-shrink: 0;
    color: #272e40;
    line-height: 50px;
    min-width: 120px;
    padding-right: 10px;
    text-align: right;
  }
  .scene-form-content {
    flex: 1;
  }
  ::v-deep .scene-form-input {
    flex: 1;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
      padding-left: 20px;
    }
    .el-textarea__inner {
      padding-top: 15px;
      padding-left: 20px;
    }
    .el-input__count {
      font-size: 14px;
      color: #c8c9ce;
    }
  }
  .custom-tag {
    margin-right: 10px;
  }
  .wait-btn-wrapper {
    padding-top: 10px;
  }
  .cover-table {
    width: 100%;
    margin-top: 20px;
  }
  .exam-item {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #272e40;
    font-size: 14px;
    line-height: initial;
    .el-icon-delete {
      color: #e1e2e6;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
.toast-box {
  color: #fff;
  background: green;
}
.errorBg {
  background: #d8384a;
}
.auto-start-icon {
  color: rgb(138, 138, 138);
  margin-left: 10px;
  font-size: 20px;
}
.collection-form {
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
  }
}
.el-button--mini {
  font-size: 14px;
}
.input_number {
  ::v-deep .el-input--mini .el-input__inner {
    font-weight: bold;
  }
}
.approver-box {
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.approver-left {
  flex: auto;
  min-width: 400px;
  padding-right: 10px;
}
.approver-right {
  flex-shrink: 0;
  width: 400px;
  padding-left: 10px;
  border-left: 1px solid #eee;
}
.tips {
  @media screen and (max-width: 768px) {
    display: block;
  }
}
.upload-img {
  width: 42px;
  height: 42px;
  margin: 0 auto;
  ::v-deep .el-upload {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    &:hover {
      position: relative;
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        font-size: 12px;
        color: #fff;
        text-align: center;
        content: "编辑";
        background: rgba(0, 0, 0, 0.49);
      }
    }
  }
}
</style>
