<template>
  <div class="face-element-wrapper">
    <img :src="url">
  </div>
</template>

<script>
export default {
  name: 'MsgFace',
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    },
    notip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    url() {
      let name = ''
      if (this.payload.data.indexOf('@2x') > 0) {
        name = this.payload.data
      } else {
        name = `${this.payload.data  }@2x`
      }
      return `https://webim-1252463788.file.myqcloud.com/assets/face-elem/${name}.png`
    }
  }
}
</script>

<style lang="scss" scoped>
.face-element-wrapper {
  img {
    max-width: 90px;
  }
}
</style>
