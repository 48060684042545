<template>
  <div class="user-item">
    <div class="info">
      <div class="auther">
        <img class="img" :src="item.avatar">
      </div>
      <div class="user-info">
        <div class="user-title">
          <p class="user-name" :title="item.name">{{ item.name }}</p><span v-if=" item.titleName" class="tag">{{ item.titleName }}</span>
        </div>
        <p class="online-hospital" :title="item.hospital">{{ item.hospital }}</p>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UserItem',
  props: {
    item: {
      type: Object,
      default: Object
    }
  },
  data() {
    return {
      loading: false
    }
  }
}
</script>

<style lang='scss' scoped>
.user-item {
  display: flex;
  justify-content: space-between;
  grid-template-columns: 44px auto;
  align-items: center;
  padding: 9px 15px;
  box-sizing: border-box;
  gap: 0 8px;
  width: 100%;
  overflow-x: hidden;
  .info{
    display: flex;
    flex: 1;
    flex-shrink: 0;
    align-items: center;
  }
  .auther {
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    text-align: center;
    line-height: 44px;
    background-color: #ddd;
    border-radius: 50%;
    margin-right: 5px;
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .user-info {
    flex: 1;
    box-sizing: border-box;
    .user-title {
      display: flex;
      .user-name {
        max-width: 60px;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0f0f0f;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .tag {
        padding: 3px 7px;
        font-size: 11px;
        color: #ff7600;
        line-height: 11px;
        border-radius: 20px;
        background-color: rgba(255, 118, 0, 0.2);
        margin-left: 5px;
      }
    }
    .online-hospital {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
