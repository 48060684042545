<template>
  <div class="msg-list-box">
    <msg-list-scroll
      ref="listWrap"
      :loading="search.loading"
      class="tim-list-wrap"
      :is-completed="search.isCompleted"
      @load="getDataList"
    >
      <template v-for="(item,index) in list">
        <div v-if="item.isRevoked" :key="item.ID" class="center"> {{ message.from }} 撤回了一条消息 </div>
        <div v-else-if="item.type === TIM.TYPES.MSG_GRP_TIP" :key="item.ID" class="center">
          <!--  群提示消息 -->
          <span v-if="item.payload.operationType === TIM.TYPES.GRP_TIP_MBR_JOIN && $store.state.tim.settings.liveStatus === 1" class="join center">{{ item.nick }}加入了直播间</span>
        </div>
        <MsgLayout
          v-else-if="item.type === TIM.TYPES.MSG_TEXT"
          :key="item.ID"
          class="msg msg-image"
          :message="item"
        >
          <msg-text :payload="item.payload" :message="item" />
          <template #handle>
            <Handle :message="item" />
          </template>
        </MsgLayout>
        <MsgLayout
          v-else-if="item.type === TIM.TYPES.MSG_FACE"
          :key="item.ID"
          class="msg msg-image"
          :message="item"
        >
          <msg-face :payload="item.payload" :message="item" />
          <template #handle>
            <Handle :message="item" />
          </template>
        </MsgLayout>
        <MsgLayout
          v-else-if="item.type === TIM.TYPES.MSG_IMAGE"
          :key="item.ID"
          class="msg msg-image"
          :message="item"
        >
          <msg-image :is-last="index === list.length-1" :payload="item.payload" :message="item" />
          <template #handle>
            <Handle :message="item" />
          </template>
        </MsgLayout>

        <!-- <div v-else-if="item.type === TIM.TYPES.MSG_GRP_NOTICE" :key="item.ID">群系统通知消息</div> -->
        <!-- <div v-else-if="item.type === TIM.TYPES.MSG_CUSTOM" :key="item.ID">自定义消息</div> -->
        <!-- <div v-else :key="item.ID" class="center">暂未支持的消息类型：{{ item.type }}</div> -->
      </template>
      <div id="last-stance" style="height: 1px" />
    </msg-list-scroll>
    <ImageViewer
      v-model="showImageViewer"
      :url-list="imageViewerList"
      :src="currentSrc"
      :infinite="false"
    />
  </div>
</template>

<script>
import ImageViewer from '@/components/ImageViewer'
import MsgListScroll from '../components/MsgListScroll'

import MsgText from '../components/MsgItem/msg-text.vue'
import MsgFace from '../components/MsgItem/msg-face.vue'
import MsgImage from '../components/MsgItem/msg-image.vue'
import MsgLayout from '../components/msg-layout'
import Handle from './handle.vue'

import { useList } from '../useList'
import { useEmitter } from '../config/useEmitter'
import { TIM } from '../config/tim-config'

export default {
  name: 'MsgListBox',
  components: {
    MsgListScroll,
    ImageViewer,
    MsgText,
    MsgFace,
    MsgImage,
    MsgLayout,
    Handle
  },
  data() {
    return {
      loading: false,
      showImageViewer: false,
      imageViewerList: [],
      currentSrc: '',
      visible: false,
      TIM,
      search: {
        pageNo: 1,
        pageSize: 10,
        isFavorite: false,
        loading: false,
        isCompleted: false
      }
    }
  },
  computed: {
    list() {
      // speaker 播客
      // mainSpeaker 主讲人
      return this.$store.state.tim.currentSession.conversation.filter(item => item.type !== TIM.TYPES.MSG_CUSTOM)
    }
  },
  mounted() {
    useList(this.search, this.$refs.listWrap)
      .getDataList()
      .then(() => {
        this.$EventBus.$emit('scrollToBottom', 'init')
      })
    this.visible = true
    useEmitter({ proxyPreviewImages: this.proxyPreviewImages }, this)
  },
  methods: {
    getDataList() {
      useList(this.search, this.$refs.listWrap).getDataList()
    },
    /**
     * 预览图片
     * 调用时机：点击图片消息
     * @param {String} src
     * @param {Array} list
     */
    proxyPreviewImages(src, list) {
      this.showImageViewer = true
      this.imageViewerList = list
      this.currentSrc = src
    }
  }
}
</script>

<style lang='scss' scoped>
.msg-list-box {
  position: relative;
  height: 100%;
  .tim-list-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    .msg-list-box {
      flex: 1;
      flex-shrink: 0;
    }
  }
  .center {
    text-align: center;
    font-size: 12px;
    color: #a5b5c1;
    margin: 15px 0;
  }
}
</style>
