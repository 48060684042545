<template>
  <div>
    <page-main title back>
      <template slot="top">
        <Steps :value="4" :list="stepList" />
      </template>
      <el-row type="flex" class="scene-item row-line">
        <span class="scene-label">任务名称：</span>
        <div class="font-weight col272e40">
          {{ sceneTitle }}
        </div>
      </el-row>
      <el-row type="flex" class="scene-item">
        <span class="scene-label">任务规则：</span>
        <div class="scene-content">
          <span class="pdg-r30">代表执行有效期：{{ representValidDay }}天</span>
          <span class="pdg-lr30">医生学习有效期：{{ doctorStudyDate }}天</span>
          <span v-if="userLessCount > 0" class="pdg-lr30">待分配任务数：{{ userLessCount }}个</span>
        </div>
      </el-row>
      <el-row type="flex" class="scene-item">
        <span class="scene-label">任务对象：</span>
        <el-row class="scene-content" style="margin-top: -5px">
          <el-row type="flex" align="middle">
            <el-radio-group
              v-model="allotType"
              class="radio-switch"
              size="mini"
              @change="onSelectAllotType"
            >
              <el-radio-button label="represent">分配给代表</el-radio-button>
              <el-radio-button v-if="!projectDetail.projectTargetId && sceneType !== 'MEDICAL_PODCAST'" label="department">分配给子部门</el-radio-button>
              <el-radio-button label="doctor">分配给医生</el-radio-button>
            </el-radio-group>
          </el-row>
          <el-row v-if="allotType === 'doctor' && sceneType === 'questionnaire'" class="doctor-type">
            <el-radio-group v-model="selectDoctorType" size="mini" @change="changeDoctorType">
              <el-radio label="custom">自定义选择</el-radio>
              <el-radio label="tag">用户标签选择</el-radio>
            </el-radio-group>
          </el-row>
          <el-row type="flex" justify="space-between">
            <div>
              <el-button
                v-if="allotType === 'doctor' && selectDoctorType !== 'tag'"
                size="mini"
                type="primary"
                plain
                icon="el-icon-plus"
                @click="onShowAddExcelModal('allotDoctor')"
              >
                导入医生分配表
              </el-button>
              <el-button
                v-if="allotType === 'represent' || allotType === 'department'"
                size="mini"
                type="primary"
                plain
                icon="el-icon-plus"
                @click="onAddAllotUser"
              >
                添加任务对象
              </el-button>
              <el-button
                v-if="allotType === 'doctor' && selectDoctorType !== 'tag'"
                size="mini"
                type="primary"
                plain
                icon="el-icon-plus"
                @click="selectDoctorListVisible = true"
              >
                选择医生
              </el-button>
              <el-button
                v-if="allotType === 'doctor' && selectDoctorType === 'tag'"
                size="mini"
                type="primary"
                plain
                icon="el-icon-plus"
                @click="openSelectDoctorListByTag(true)"
              >
                {{ filterList.length === 0 ? '设置标签条件' : '重新设置标签条件' }}
              </el-button>
            </div>
            <el-row>
              <el-popover
                v-if="allotType === 'doctor' && sceneType !== 'academicVisit'"
                v-model="popoverForm.visible"
                placement="bottom-end"
                width="400"
                trigger="manual"
              >
                <el-form
                  ref="form"
                  size="small"
                  :model="popoverForm"
                  label-suffix="："
                  label-width="140px"
                >
                  <el-form-item label="直接分配给医生">
                    <el-switch v-model="popoverForm.isAutoAllot" />
                  </el-form-item>
                  <el-form-item label="选择自动分配时间">
                    <el-date-picker
                      v-model="popoverForm.autoTime"
                      :disabled="!popoverForm.isAutoAllot"
                      :picker-options="pickerOptions"
                      :editable="false"
                      type="datetime"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      placeholder="选择日期时间"
                      default-time="12:00:00"
                    />
                  </el-form-item>
                  <div style="color: #ccc; margin-bottom: 10px">备注：一经开启，学术任务将会在设置的时间内由系统自动推送给医生，如有特殊审核情况，将会有延期情况。</div>
                  <div style="text-align: center">
                    <el-button style="margin-right: 10px" size="small" @click="onCancel">取消</el-button>
                    <!-- <el-popconfirm confirm-button-text="是" cancel-button-text="否" @confirm="onCancel" icon="el-icon-info" icon-color="red" title="取消将清空时间，是否继续">
                                    </el-popconfirm> -->
                    <el-button size="small" type="primary" @click="onOpenAutoTime">确定</el-button>
                  </div>
                </el-form>
                <el-button
                  slot="reference"
                  style="margin-right: 10px"
                  size="mini"
                  @click="popoverForm.visible = true"
                >
                  更多设置
                </el-button>
              </el-popover>
              <el-button size="mini" @click="onDownAllotData">下载任务分配表</el-button>
              <el-button v-if="allotType === 'represent'" size="mini" @click="onShowAddExcelModal('allotRepresent')"> 批量导入 </el-button>
            </el-row>
          </el-row>
          <template>
            <div class="allot-body">
              <template v-if="allotType === 'represent'">
                <div v-if="isLoading" style="text-align: center">
                  <i class="el-icon-loading" style="font-size: 46px" />
                </div>
                <scene-allot-for-rep-item
                  v-for="current in allotRepMap"
                  :key="current.value"
                  :title="current.name"
                  :code-money="money"
                  :represent-list="representList | allotCardFilter(current.value)"
                  @onDelCard="onDelCard(current.value)"
                  @onDelRep="onDelRep"
                  @onChangeInvite="e => onChangeRepInvite(e, current.value)"
                />
              </template>
              <template v-if="allotType === 'department'">
                <scene-allot-for-dep-item
                  :code-money="money"
                  :department-list="departmentList"
                  @onChangeInvite="onChangeDepInvite"
                  @onDelDep="onDelDep"
                />
              </template>
              <template v-if="allotType === 'doctor'">
                <scene-allot-for-doc-item :code-money="money" :doctor-list="doctorList" @onDelDoc="onDelDoc" />
              </template>
            </div>
          </template>
        </el-row>
      </el-row>
    </page-main>
    <fixed-action-bar class="allot-footer">
      <el-row class="footer-title">任务总数：{{ totalInviteCount }}</el-row>
      <div>
        <el-button
          plain
          style="width: 90px"
          @click="onBackLast"
        >
          上一步
        </el-button>
        <el-button
          type="primary"
          style="width: 90px"
          @click="onNext"
        >
          下一步
        </el-button>
      </div>
      <div />
    </fixed-action-bar>
    <select-scene-dep-modal
      :visible="showDepModal"
      :scene-type="sceneType"
      :default-select-list="departmentIdList"
      :scene-id="sceneId"
      @onSubmit="onAddDepList"
      @onClose="onCloseModal"
    />
    <SceneAllotModal
      :visible="showAllotModal"
      :scene-data="allotData"
      :allot-list="allotList"
      :allot-type="allotType"
      :loading="isSubmit"
      @onSubmit="onSubmit"
      @onClose="onCloseModal"
    />
    <payment-captcha-modal
      :visible="showWechatValidModal"
      code-type="4"
      :check-value="totalCost"
      @onSuccess="onWxPaySuccess"
      @onClose="onCloseModal"
    />
    <payment-limit-modal :visible="showPayLimitModal" @closeModal="onCloseModal" />
    <upload-excel-to-json-modal
      :visible="showUploadExcelModal"
      :title="excelUploadModalTitle"
      :file-url="excelFile"
      @success="onUploadedExcel"
      @onClose="onCloseModal"
    />
    <user-error-import-modal
      :visible="showErrorRepModal"
      :error-list="errorRepList"
      @onClose="onCloseModal"
      @onExport="onExportErrorRep"
    />
    <DoctorErrorImportModel
      :visible="showErrorDoctorModal"
      :error-list="errorDoctorList"
      @onClose="onCloseModal"
      @onExport="onExportErrorDoctor"
    />
    <SelectDoctorList
      v-model="selectDoctorListVisible"
      :selected="doctorList"
      :filter-list="filterList"
      :project-target-id="projectDetail.projectTargetId"
      :max="100000"
      :include-delegation="includeDelegation"
      @onSubmit="onDoctorListSubmit"
      @onClose="closeDoctorListModal"
    />
    <FilterDoctorByTag
      v-model="selectDoctorListByTag"
      :reset="reset"
      @onSubmit="searchDoctorList"
      @close="onCloseModal"
    />
    <can-has-invite-modal v-model="canNotInviteVisible" :list="canNotInviteDoctorList" :max="100000" />
    <SelectOrgRepresentModel
      v-if="showReprementModal"
      :is-show-pop="isShowSelectModel"
      :init-select-list="representList"
      type="produced-project"
      :project-target-id="projectDetail.projectTargetId"
      @onConfirmSelect="onConfirmSelect"
      @onClose="
        val => {
          isShowSelectModel = val
        }
      "
    />
    <SelectOrgItemModel
      v-if="showOrgModal"
      :is-show-pop="isShowSelectModel"
      :init-select-list="checkNodeKeyArr"
      @onConfirmSelect="onAddDepList"
      @onClose="
        val => {
          isShowSelectModel = val
        }
      "
    />
    <ImportExcelDialog
      v-if="isShowImport"
      :is-show-pop="isShowImport"
      :model-type="modelType"
      @onComplete="onComplete"
      @onClose="
        val => {
          isShowImport = val
        }
      "
    />
  </div>
</template>
<script>
import SceneAllotForRepItem from './child/SceneAllotForRepItem'
import SceneAllotForDepItem from './child/SceneAllotForDepItem'
import SceneAllotForDocItem from './child/SceneAllotForDocItem'
import SceneAllotModal from './child/SceneAllotModal'
import UserErrorImportModal from './child/UserErrorImportModal'
import DoctorErrorImportModel from './child/DoctorErrorImportModel'
import SelectSceneDepModal from '@/bizComponents/SelectSceneDepModal'
import PaymentCaptchaModal from '@/bizComponents/PaymentCaptchaModal'
import PaymentLimitModal from '@/bizComponents/PaymentLimitModal'
import UploadExcelToJsonModal from '@/bizComponents/UploadExcelToJsonModal'
import { mapActions } from 'vuex'
import { JsonToExcel } from '@/utils/util'
import SelectOrgRepresentModel from '@/bizComponents/SelectOrgRepresentModel'
import SelectOrgItemModel from '@/bizComponents/SelectOrgItemModel'
import SelectDoctorList from '../../bizComponents/SelectDoctorModal'
import ImportExcelDialog from '@/baseComponents/ImportExcelDialog'
import CanHasInviteModal from '@/bizComponents/CanHasInviteModal'
import FilterDoctorByTag from './components/FilterDoctorByTag.vue'
export default {
  name: 'AcademicAllot',
  filters: {
    allotCardFilter(data, currentKey) {
      return data.filter(v => v.orgId === currentKey || v.teamId === currentKey)
    }
  },
  components: {
    SceneAllotForDocItem,
    SceneAllotForRepItem,
    SceneAllotForDepItem,
    SelectSceneDepModal,
    SceneAllotModal,
    UploadExcelToJsonModal,
    PaymentCaptchaModal,
    PaymentLimitModal,
    UserErrorImportModal,
    DoctorErrorImportModel,
    SelectDoctorList,
    SelectOrgRepresentModel,
    SelectOrgItemModel,
    ImportExcelDialog,
    CanHasInviteModal,
    FilterDoctorByTag
  },
  data() {
    return {
      sceneType: '',
      sceneTitle: '',
      ruleId: '', // 任务规则Id
      sceneId: '', // 场景id
      allotType: 'represent', // represent department doctor
      showPayLimitModal: false,
      showUploadExcelModal: false, // 显示上传excel弹窗
      showWechatValidModal: false,
      payTicket: '', // 支付扫码密钥
      userLessCount: 0,
      representList: [],
      isLoading: false,
      departmentList: [],
      errorDoctorList: [],
      canNotInviteDoctorList: [],
      errorRepList: [], // 失败代表lieb
      showErrorRepModal: false, // 导入代表失败弹窗
      showAddModal: false,
      showErrorDoctorModal: false,
      isSubmit: false,
      showAllotModal: false,
      doctorList: [],
      doctorStudyDate: 0,
      money: 0, // 任务额度
      lessCount: 0, // 可分配个数
      representValidDay: 0, // 代表执行时间
      popoverForm: {
        visible: false,
        autoTime: '',
        isAutoAllot: false
      },
      selectDoctorListVisible: false,
      selectDoctorListByTag: false,
      canNotInviteVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date() - 8.64e7
        }
      },
      isShowSelectModel: false,
      checkNodeKeyArr: [],
      projectType: '',
      // 批量导入
      isShowImport: false,
      modelType: 'allotRepresent',
      advertActivityId: '',
      includeDelegation: false,
      isExistingCodeAssign: false,
      delegationBO: {},
      projectDetail: {},
      selectDoctorType: 'custom',
      filterList: [],
      reset: false
    }
  },
  computed: {
    excelUploadModalTitle() {
      if (this.allotType === 'represent') {
        return '批量导入代表'
      } else if (this.allotType === 'doctor') {
        return '批量导入医生'
      }
      return '批量'
    },
    excelFile() {
      if (this.allotType === 'represent') {
        return 'https://yao-file.oss-cn-hangzhou.aliyuncs.com/excel/codeToRepresentModel.xlsx'
      } else if (this.allotType === 'doctor') {
        return 'https://yao-file.oss-cn-hangzhou.aliyuncs.com/excel/codeToDoctorModelNew.xlsx'
      }
      return ''
    },
    sceneRuleType() {
      switch (this.sceneType) {
        case 'meeting':
          return 1
        case 'questionnaire':
          return 4
        case 'live':
          return 5
        case 'webinar':
          return 6
        case 'academicVisit':
          return 8
        case 'training':
          return 7
        case 'MEDICAL_PODCAST':
          return 12
        default:
          return null
      }
    },
    sceneRuleChinese() {
      switch (this.sceneType) {
        case 'meeting':
          return '科会'
        case 'questionnaire':
          return '调研/病例'
        case 'live':
          return '直播'
        case 'webinar':
          return '会议'
        case 'academicVisit':
          return '拜访'
        case 'training':
          return 7
        case 'MEDICAL_PODCAST':
          return '播客'
      }
      return ''
    },
    allotUserType() {
      switch (this.allotType) {
        case 'doctor':
          return 1
        case 'represent':
          return 2
        case 'department':
          return 3
        default:
          return null
      }
    },
    totalInviteCount() {
      let total = 0
      if (this.allotType === 'represent') {
        this.representList.map(v => {
          total += v.inviteCount || 0
        })
      } else if (this.allotType === 'department') {
        this.departmentList.map(v => {
          total += v.inviteCount || 0
        })
      } else if (this.allotType === 'doctor') {
        total = this.doctorList.length
      }
      return total
    },
    totalCost() {
      return this.totalInviteCount * this.money
    },
    departmentIdList() {
      return [...this.departmentList].map(v => v.factoryId)
    },
    showDepModal() {
      return this.showAddModal && this.allotType === 'department'
    },
    showReprementModal() {
      return this.isShowSelectModel && this.allotType === 'represent'
    },
    showOrgModal() {
      return this.isShowSelectModel && this.allotType === 'department'
    },
    allotList() {
      if (this.allotType === 'represent') {
        return this.representList.filter(v => v.inviteCount > 0)
      } else if (this.allotType === 'department') {
        return this.departmentList.filter(v => v.inviteCount > 0)
      } else if (this.allotType === 'doctor') {
        return this.doctorList
      }
      return []
    },
    allotData() {
      return {
        totalInviteCount: this.totalInviteCount,
        money: this.money,
        title: this.sceneTitle,
        representValidDay: this.representValidDay,
        doctorStudyDate: this.doctorStudyDate,
        totalCost: this.totalCost
      }
    },
    allotRepMap() {
      // 返回需要遍历的卡片
      const obj = {}
      this.representList.map(v => {
        const key = v.orgId || v.teamId
        const name = v.orgName || v.teamName
        if (!obj[key]) {
          obj[key] = name
        }
      })
      let output = []
      for (const i in obj) {
        output = [...output, { value: i, name: obj[i] }]
      }
      return output || []
    },
    rulers() {
      return `任务奖励：${this.money}积分,代表执行有效期：${this.representValidDay}天，医生学习有效期：${this.doctorStudyDate}天`
    },
    stepList() {
      const arr = [`新建${this.sceneRuleChinese}`, '设置任务规则', '发布委托需求', '选择任务对象并分配']
      return arr.filter(item => {
        // 拜访不需要委托
        if (this.sceneRuleChinese === '拜访' && item === '发布委托需求') {
          return false
        }
        return true
      }).map((item, index) => {
        return `${index + 1}.${item}`
      })
    }
  },
  mounted() {
    this.sceneType = this.$route.params.sceneType
    this.sceneId = this.$route.params.id
    this.ruleId = this.$route.query.ruleId
    this.projectType = this.$route.query.projectType
    this.advertActivityId = this.$route.query.advertActivityId || ''
    this.activityQuestId = this.$route.query.activityQuestId || ''
    this.includeDelegation = this.$route.query.includeDelegation === 'true'
    this.isExistingCodeAssign = this.$route.query.isExistingCodeAssign === 'true'
    this.init()
    this.hasActiveProject()
  },
  methods: {
    ...mapActions(['getFactoryOrgUserList', 'onEnsetRepDoctorList', 'getAllotRuleDetail', 'getFactoryAllotDepList', 'onAllotSceneCode', 'onGetJsonRepresentList']),
    onComplete(data) {
      if (this.modelType === 'allotRepresent') {
        this.confirmAllotRepresent(data)
      } else if (this.modelType === 'allotDoctor') {
        this.confirmAllotDoctor(data)
      }
    },
    init() {
      const req = {
        sceneAssignRuleId: this.ruleId,
        sceneId: this.sceneId,
        sceneType: this.sceneRuleType
      }
      this.getAllotRuleDetail(req).then(
        res => {
          this.representValidDay = res.representValidDay
          this.doctorStudyDate = res.validDay
          this.money = res.money
          this.userLessCount = res.leftCount
          this.sceneTitle = res.sceneTitle
        },
        rea => {
          this.$message.error(rea)
        }
      )
    },
    openSelectDoctorListByTag(reset) {
      this.reset = reset
      if (this.filterList.length && this.doctorList.length && reset) {
        this.filterList = []
        this.doctorList = []
      }
      this.selectDoctorListByTag = true
    },
    closeDoctorListModal() {
      this.selectDoctorListVisible = false
      if (this.selectDoctorType === 'tag') {
        this.openSelectDoctorListByTag(false)
      }
    },
    onBackLast() {
      this.$router.go(-1)
    },
    onNext() {
      if (!this.allotList.length) {
        return this.$message.error('分配任务数不能为0')
      }
      if (!this.totalInviteCount) {
        return this.$message.error('请设置分配任务')
      }
      this.showAllotModal = true
    },
    onSubmit() {
      if (this.isExistingCodeAssign && (this.totalInviteCount > this.userLessCount)) return this.$message.error('分配任务数不能大于可分配任务数')
      if (this.isSubmit) return
      const representMap = {}
      let quotaBos = [...this.allotList].map(v => {
        const output = { codeCount: v.inviteCount, childOrgId: v.orgId || v.factoryId }
        if (this.allotType === 'represent' || this.allotType === 'doctor') {
          output.representId = v.representId
        }
        if (this.allotType === 'doctor') {
          if (!representMap[v.representId + v.orgId]) {
            representMap[v.representId + v.orgId] = { ...output, doctorIds: [v.doctorId], codeCount: 1 }
          } else {
            representMap[v.representId + v.orgId] = {
              ...representMap[v.representId + v.orgId],
              doctorIds: [...representMap[v.representId + v.orgId].doctorIds, v.doctorId],
              codeCount: ++representMap[v.representId + v.orgId].codeCount
            }
          }
        }
        return output
      })
      if (this.allotType === 'doctor') {
        quotaBos = []
        for (const c in representMap) {
          quotaBos.push(representMap[c])
        }
      }
      const req = {
        ticket: this.payTicket,
        body: {
          quotaSceneInfo: {
            sceneId: this.sceneId,
            money: this.money,
            validDay: this.doctorStudyDate,
            representValidDay: this.representValidDay,
            assignToUserType: this.allotUserType,
            sceneType: this.sceneType
          },
          quotaBos: quotaBos,
          taskNumber: this.totalInviteCount,
          includeDelegation: this.includeDelegation,
          isExistingCodeAssign: this.isExistingCodeAssign,
          advertActivityId: this.advertActivityId,
          activityQuestId: this.activityQuestId
        }
      }
      if (this.popoverForm.isAutoAllot) {
        req.body.autoTime = this.popoverForm.autoTime
      }
      this.isSubmit = true
      this.onAllotSceneCode(req).then(
        () => {
          this.isSubmit = false
          this.showAllotModal = false
          this.goList()
        },
        rea => {
          this.isSubmit = false
          if (rea === '需要微信扫码校验') {
            this.showWechatValidModal = true
          } else if (rea === '需要绑定手机号' || rea === '需要绑定微信号') {
            this.showPayLimitModal = true
          } else if (rea) {
            this.$message.error(rea)
          }
        }
      )
    },
    goList() {
      this.$message.success(this.includeDelegation ? '学术任务分配进入审核中，请耐心等待' : '任务正在分配中···')
      setTimeout(() => {
        this.$router.push('/academic/list')
      }, 2000)
    },
    onSelectAllotType(type) {
      this.allotType = type
      this.representList = []
      this.departmentList = []
      this.checkNodeKeyArr = []
      this.doctorList = []
    },
    onExportErrorRep() {
      const json = [...this.errorRepList].map(item => {
        return [item.name, item.repMobile, item.giftCount, item.errorReason]
      })
      const header = ['代表姓名', '手机号', '分配任务数', '错误原因']
      JsonToExcel(`${this.sceneTitle} - 代表导入异常表`, [header, ...json])
    },
    onExportErrorDoctor() {
      const json = [...this.errorDoctorList].map(item => {
        return [item.doctorName, item.doctorCode, item.repName, item.errorReason]
      })
      const header = ['医生姓名', '医生编码', '绑定的代表', '错误原因']
      JsonToExcel(`${this.sceneTitle} - 代表导入异常表`, [header, ...json])
    },
    onDownAllotData() {
      if (!this.allotList.length) {
        return this.$message.error('请添加任务对象')
      }
      //  根据类型导出数据
      let title = ''
      let json = []
      let header = []
      if (this.allotType === 'represent') {
        title = `${this.sceneTitle} - 代表分配表`
        json = [...this.allotList].map(item => {
          return [item.jobNo, item.orgName, item.name, item.doctorCount, item.inviteCount || 0]
        })
        header = ['工号', '团队', '姓名', '绑定医生数', '分配任务数']
      } else if (this.allotType === 'department') {
        title = `${this.sceneTitle} - 部门分配表`
        json = [...this.allotList].map(item => {
          return [item.factoryName, item.representCount, item.doctorCount, item.inviteCount || 0]
        })
        header = ['组织', '组织人数', '绑定医生数', '分配任务数']
      } else if (this.allotType === 'doctor') {
        title = `${this.sceneTitle} - 医生分配表`
        json = [...this.allotList].map(item => {
          return [item.doctorName, item.department, item.title || '—', item.hospital || '—', item.doctorCode || '—', item.repName || '—', item.repMobile || '—', item.orgName || '—', item.orgName || '—', this.rulers || '-', this.sceneTitle || '']
        })
        header = ['医生姓名', '科室', '职称', '医院', '医生编码', '代表姓名', '代表手机号', '团队姓名', '组织名称', '任务规则', '活动名称']
      }
      JsonToExcel(title, [header, ...json])
    },
    onAddDepList(arr) {
      this.isShowSelectModel = false
      this.checkNodeKeyArr = [...this.checkNodeKeyArr, ...arr]
      this.getFactoryOrgUserList({orgIds: arr}).then(
        res => {
          this.departmentList = [...res, ...this.departmentList]
        },
        rea => {
          this.$message.error(rea)
        }
      )
    },
    onDelRep(currentRepId, currentOrgId) {
      this.representList = [...this.representList].filter(v => v.representId + v.orgId !== currentRepId + currentOrgId)
    },
    onDelCard(currentCardId) {
      this.representList = [...this.representList].filter(v => v.orgId !== currentCardId)
    },
    onDelDep(currentOrgId) {
      this.checkNodeKeyArr = []
      this.departmentList = [...this.departmentList].filter(v => {
        if (v.factoryId !== currentOrgId) {
          this.checkNodeKeyArr.push(v.factoryId)
          return true
        }
      })
    },
    onDelDoc(currentId) {
      this.doctorList = [...this.doctorList].filter(v => v.doctorId !== currentId)
    },
    onChangeRepInvite(count, key) {
      this.representList = [...this.representList].map(v => {
        if (v.orgId === key || v.teamId === key) {
          v.inviteCount = count
        }
        return v
      })
    },
    onChangeDepInvite(count) {
      this.departmentList = [...this.departmentList].map(v => {
        v.inviteCount = count
        return v
      })
    },
    onCloseModal() {
      this.isShowSelectModel = false
      this.showAllotModal = false
      this.showWechatValidModal = false
      this.showPayLimitModal = false
      this.showErrorDoctorModal = false
      this.showErrorRepModal = false
      this.showUploadExcelModal = false
      this.selectDoctorListByTag = false
      this.selectDoctorListVisible = false
    },
    changeDoctorType(type) {
      this.selectDoctorType = type
      this.doctorList = []
    },
    onWxPaySuccess(ticket) {
      this.payTicket = ticket
      this.onSubmit()
    },
    onAddAllotUser() {
      this.isShowSelectModel = true
    },
    onConfirmSelect(list) {
      const initSelectList = []
      for (const v of list) {
        initSelectList.push({
          ...v,
          id: v.representId,
          doctorCount: v.representDoctorCount,
          name: v.representName,
          repMobile: v.representMobile,
          giftCount: v.giftCount
        })
      }
      this.representList = initSelectList
    },
    onShowAddExcelModal(type) {
      this.isShowImport = true
      this.modelType = type
    },
    onUploadedExcel(arr) {
      this.showUploadExcelModal = false
      if (this.allotType === 'doctor') {
        this.onUploadedDoctor(arr)
      } else if (this.allotType === 'represent') {
        this.onUploadedRep(arr)
      }
    },
    onUploadedRep(json) {
      let req = []
      let errorValid = false
      for (const k of json) {
        if (!k['姓名（必填）'] && !k['手机号（必填）'] && !k['所属组织ID（必填）'] && !k['任务数量（必填）']) {
          console.log('没有相应处理')
        } else if (k['姓名（必填）'] && k['手机号（必填）'] && k['所属组织ID（必填）'] && k['任务数量（必填）']) {
          req = [...req, k]
        } else {
          errorValid = true
        }
      }
      req = req.map(k => {
        return {
          repName: (k['姓名（必填）'] || '').toString().replace(/(\s*)/g, ''),
          repMobile: (k['手机号（必填）'] || '').toString().replace(/(\s*)/g, ''),
          giftCount: (k['任务数量（必填）'] || '').toString().replace(/(\s*)/g, ''),
          orgId: (k['所属组织ID（必填）'] || '').toString().replace(/(\s*)/g, '')
        }
      })
      if (errorValid) {
        this.$message.error('您上传的文件格式有误或内容格式和模板不一致')
        return
      }
      this.onGetJsonRepresentList(req).then(
        res => {
          const { data } = res
          if (data.code === 1) {
            this.$message.success('导入成功')
            const arr = [...this.representList].map(v => `${v.representId || v.id}-${v.orgId}`)
            data.results.map(v => {
              const key = `${v.id}-${v.orgId}`
              if (arr.indexOf(key) < 0 && !v.errorReason) {
                this.representList = [
                  ...this.representList,
                  {
                    ...v,
                    representId: v.id,
                    representName: v.name,
                    representStatus: null,
                    representDoctorCount: v.doctorCount,
                    representMobile: v.repMobile,
                    inviteCount: v.giftCount,
                    key: key,
                    isSelect: true
                  }
                ]
              }
            })
          } else if (data.code === 0) {
            this.errorRepList = data.results
            this.showErrorRepModal = this.errorRepList.length > 0
            // this.$message.success('导入失败')
          }
        },
        rea => {
          this.$message.error(rea)
        }
      )
    },
    confirmAllotRepresent(data) {
      const arr = [...this.representList].map(v => `${v.representId || v.id}-${v.orgId}`)
      data?.results?.map(v => {
        const key = `${v.id}-${v.orgId}`
        if (arr.indexOf(key) < 0 && !v.errorReason) {
          arr.push(key)
          this.representList = [
            ...this.representList,
            {
              ...v,
              representId: v.id,
              representName: v.name,
              representStatus: null,
              representDoctorCount: v.doctorCount,
              representMobile: v.repMobile,
              inviteCount: v.giftCount,
              key: key,
              isSelect: true
            }
          ]
        }
      })
    },
    onUploadedDoctor(json) {
      let errorValid = false
      let req = []
      for (const v of json) {
        if (!v['医生编码（必填）'] && !v['代表手机号（必填）'] && !v['所属组织ID（必填）']) {
          console.log('没有相应处理')
        } else if (v['医生编码（必填）'] && v['代表手机号（必填）'] && v['所属组织ID（必填）']) {
          req = [...req, v]
        } else {
          errorValid = true
        }
      }
      req = req.map(v => {
        return {
          doctorName: (v['医生姓名'] || '').toString().replace(/(\s*)/g, ''),
          department: (v['科室'] || '').toString().replace(/(\s*)/g, ''),
          titleName: (v['职称'] || '').toString().replace(/(\s*)/g, ''),
          hospital: (v['医院'] || '').toString().replace(/(\s*)/g, ''),
          doctorCode: (v['医生编码（必填）'] || '').toString().replace(/(\s*)/g, ''),
          representName: (v['代表姓名'] || '').toString().replace(/(\s*)/g, ''),
          representMobile: (v['代表手机号（必填）'] || '').toString().replace(/(\s*)/g, ''),
          orgId: (v['所属组织ID（必填）'] || '').toString().replace(/(\s*)/g, '')
        }
      })
      if (errorValid) {
        this.$message.error('您上传的文件格式有误或内容格式和模板不一致')
        return
      }
      this.onEnsetRepDoctorList(req).then(
        res => {
          const arr = this.doctorList.map(v => v.doctorId)
          res.results?.map(v => {
            if (arr.indexOf(v.doctorId) < 0 && !v.errorReason) {
              arr.push(v.doctorId)
              this.doctorList = [
                ...this.doctorList,
                {
                  ...v,
                  code: v.doctorCode,
                  selectId: v.representId + v.orgId
                }
              ]
            }
          })
          this.errorDoctorList = res.results.filter(v => v.errorReason)
          this.showErrorDoctorModal = this.errorDoctorList.length > 0
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    confirmAllotDoctor(data) {
      const arr = this.doctorList.map(v => v.doctorId)
      data?.results?.map(v => {
        if (arr.indexOf(v.doctorId) < 0 && !v.errorReason) {
          arr.push(v.doctorId)
          this.doctorList = [
            ...this.doctorList,
            {
              ...v,
              code: v.doctorCode,
              selectId: v.representId + v.orgId
            }
          ]
        }
      })
    },
    getTime(type) {
      const date = new Date() // 1. js获取当前时间
      const min = date.getMinutes() // 2. 获取当前分钟
      if (type == 'endtime') {
        date.setMinutes(min + 5)
      } // 3. 设置当前时间+5分钟：把当前分钟数+5后的值重新设置为date对象的分钟数
      const h = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
      const f = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
      const s = date.getSeconds() < 10 ? `0${date.getseconds()}` : date.getSeconds()
      const formatdate = `${h}:${f}:${s}`
      return formatdate
      // console.log(formatdate) // 获取5分钟后的时间，格式为 h:f:s
    },
    onOpenAutoTime() {
      if (this.popoverForm.isAutoAllot) {
        if (!this.popoverForm.autoTime) {
          this.$message.error('请选择自动分配时间')
          return
        }
        const nowDate = this.$moment(new Date())
        const selectData = this.$moment(this.popoverForm.autoTime)
        // 能开启半个小时后的
        if (selectData.diff(nowDate, 'seconds', true) > 0) {
          this.popoverForm.visible = false
        } else {
          this.$message.error('您选择的自动分配时间已经过了哦~')
        }
      } else {
        this.popoverForm.visible = false
        this.popoverForm.autoTime = ''
      }
    },
    onDoctorListSubmit(list) {
      this.onCloseModal()
      const canInviteList = list.filter(doctor => doctor.isCanDistribute)
      if (this.allotType === 'doctor') {
        if (list.length !== canInviteList.length) {
          this.canNotInviteDoctorList = list.filter(doctor => !doctor.isCanDistribute)
          this.canNotInviteVisible = true
        }
        this.doctorList = canInviteList
      }
    },
    searchDoctorList(list) {
      this.onCloseModal()
      this.filterList = list
      this.selectDoctorListVisible = true
    },
    // 设置自动分配
    onCancel() {
      this.popoverForm.visible = false
      this.popoverForm.autoTime = ''
      this.popoverForm.isAutoAllot = false
    },
    // 查询当前活动有没有走项目
    async hasActiveProject() {
      try {
        const params = {
          sceneId: this.sceneId
        }
        const {data} = await this.$axios.get(this.$API.delegationFormInfo, {params})
        this.projectDetail = data
      } catch (error) {
        console.log('error: ', error)
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.scene-item {
  padding-top: 10px;
  padding-bottom: 10px;
  &.row-line {
    border-bottom: 1px solid #e9eaeb;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 15px;
  }
}
.scene-label {
  color: #7784a1;
  flex-shrink: 0;
}
.scene-content {
  color: #7784a1;
  flex: 1;
}
.common-margin {
  margin-bottom: 10px;
}
.allot-body {
  padding-top: 20px;
  min-width: 600px;
}
.allot-footer {
  ::v-deep .actionbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: left;
    >div{
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .footer-title {
      font-size: 14px;
      font-weight: bold;
      color: #0F0F0F;
      line-height: 1.5;
    }
    @media screen and (max-width: 768px) {
    display: block;
  }
  }
}
.radio-switch {
  margin-bottom: 10px;
  ::v-deep {
    .el-radio-button .el-radio-button__inner {
      margin-right: 10px;
      border: none;
      background: #fafafa;
    }
    .el-radio-button .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background: rgba(61, 97, 227, 0.12);
    }
  }
}
.doctor-type {
  margin-bottom: 10px;
}
</style>
