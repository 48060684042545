<!--
 * @Author: your name
 * @Date: 2021-07-23 10:58:13
 * @LastEditTime: 2021-12-29 18:31:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/academic/AcademicSceneLayout.vue
-->
<template>
  <div class="container">
    <el-steps align-center :active="activeStep">
       <el-step :title="sceneTitle"></el-step>
       <el-step title="2.设置任务规则" ></el-step>
       <el-step title="3.发布委托需求"></el-step>
       <el-step title="4.选择任务对象并分配"></el-step>
    </el-steps>
    <el-card class="card-wrapper">
      <router-view>
      </router-view>
    </el-card>
  </div>
</template>
<script>
export default {
  name: 'AcademicSceneLayout',
  data () {
    return {
      sceneTitleData: {
        meeting:'科会',
        questionnaire: '调研',
        bingli: '病例',
        live:'直播',
        webinar:'会议',
        academicVisit:'拜访',
        MEDICAL_PODCAST: '播客',
      },
      actionObj: {
        edit:'编辑',
        create: '创建',
        allot: '分配',
        entrust: '委托',
        rule:'任务规则',
        finish: '完成'
      }
    }
  },
  watch: {
    navSceneText(newVal){
      this.$route.meta.title = newVal
    }
  },
  computed: {
    navSceneText () {
        console.log('this.$route.query.projectType',this.$route.query.projectType)
      let sceneType = this.$route.path.split('/')[3] || this.$route.params.sceneType
      let actionType = this.$route.path.split('/')[4]
      let actionFill = actionType === 'rule' ? '设置' : ''
      if (this.$route.query.projectType == 2) {
        return `-  ${ actionFill + this.actionObj[actionType]}病例`
      }
      return `-  ${ actionFill + this.actionObj[actionType] + this.sceneTitleData[sceneType]}`
    },
    sceneTitle () {
      let sceneType = this.$route.path.split('/')[3] || this.$route.params.sceneType
      let actionText = this.$route.path.split('/')[4] === 'edit' ? '编辑' : '创建'
      if (this.$route.query.projectType == 2) {
        return `1.${actionText}病例`
      }
      return `1.${actionText + this.sceneTitleData[sceneType]}`
    },
    activeStep() {
      let actionType = this.$route.path.split('/')[4]
      switch (actionType) {
          case 'edit':
          case 'create':
            return 1
          case 'rule':
            return 2
          case 'entrust':
            return 3
          case 'allot':
            return 4
          case 'finish':
            return 5
          default:
            return 1
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  .container ::v-deep {
    padding-top: 0;
    .page-nav-box {
      margin-left: -60px;
      margin-right: -60px;
      margin-bottom: 25px;
    }
    .el-card {
      background: transparent;
      display: flex;
      flex-direction: column;
    }
    .el-step .el-step__head,.el-step__title {
      &.is-process {
        color: #C0C4CC;
        border-color: #C0C4CC;
        font-weight: normal;
      }
    }
  }
  .card-wrapper ::v-deep {
    margin-top: 30px;
    min-height: 80vh;
    .el-card__body {
      padding: 0;
      flex: 1;
      position: relative;
    }
  }
</style>
