import store from '@/store'

export const useEmitter = function({ proxyPreviewImages, proxyContextmenu }, self) {
  /**
   * 发布 scrollToBottom 事件
   * 调用时机：收/发消息事件触发时 回顶
   * @param {Object} state
   * @param {Message[]|Message} data
   * @returns
   */
  const scrollToBottom = function(type) {
    const target = document.querySelector('#last-stance')
    const targetBase = document.querySelector('.el-scrollbar__view.scroll-content')
    const targetView = document.querySelector('.msg-scrollbar>.el-scrollbar__wrap')
    try {
      const bottomDistance = targetBase.offsetHeight - targetView.scrollTop - targetView.offsetHeight
      if (bottomDistance > 300 && type !== 'init') return
      self.$nextTick(() => {
        if (target) {
          targetView.scrollTop = targetBase.scrollHeight
        } else {
          targetView.scrollTop = targetBase.offsetHeight
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const previewImages = ({ currentImage }) => {
    const srcList = store.getters['tim/currentSession/previewImgList']
    if (proxyPreviewImages) proxyPreviewImages(currentImage, srcList)
  }

  const contextmenu = (ref, message) => {
    if (proxyContextmenu)proxyContextmenu(ref, message)
  }
  self.$EventBus.$on('scrollToBottom', scrollToBottom)
  self.$EventBus.$on('previewImages', previewImages)
  self.$EventBus.$on('contextmenu', contextmenu)
}
