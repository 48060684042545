<!-- 原版-剪裁 -->

<template>
  <div class="Cropper">
    <el-upload
      action=""
      name="image"
      :http-request="onHttpRequest"
      :show-file-list="false"
      accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
      v-bind="$attrs"
      class="upload-wrapper"
      :style="{width, height}"
    >
      <div class="img-wrap" :class="{'shadow': Object.keys(file).length&&file.middle}">
        <img v-if="Object.keys(file).length&&file.middle" :src="file.middle" class="proview-img">
        <div v-else slot="trigger" class="wrapper">
          <slot />
        </div>
      </div>
    </el-upload>
    <el-dialog
      title="图片裁剪"
      :visible.sync="dialogVisible"
      width="742px"
      :before-close="handleClose"
    >
      <div class="cropper-container">
        <div class="cropper-el">
          <div style="text-align: left; margin-bottom: 5px; font-weight: 600;">剪裁</div>
          <vue-cropper
            ref="cropper"
            :img="cropperImg"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
            :info-true="option.infoTrue"
            :enlarge="option.enlarge"
            :fixed="option.fixed"
            :fixed-number="fixedNumber"
            @realTime="realTime"
          />
        </div>

        <!-- 预览 -->

        <div class="prive-el">
          <div style="text-align: left; padding-left: 25px; margin-bottom: 5px; font-weight: 600;">
            预览
          </div>

          <div
            class="prive-style"
            :style="{
              height: '300px',
              overflow: 'hidden',
              margin: '0 25px',
              display: 'flex',
              'align-items': 'center',
            }"
          >
            <div class="preview" :style="previews.div">
              <img :src="previews.url" :style="previews.img">
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button :disabled="isLoading" @click="handleClose">取 消</el-button>

        <el-button v-loading="isLoading" type="primary" @click="saveImg">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { VueCropper } from 'vue-cropper'

export default {
  name: 'Cropper',
  components: {
    VueCropper
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: String,
      default: '160px'
    },
    height: {
      type: String,
      default: '90px'
    },
    classify: {
      type: String,
      default: '104'
    },
    imgType: {
      type: String,
      default: 'blob'
    },
    fixedNumber: {
      type: Array,
      default: () => [100, 193]
    }
  },
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      cropperImg: '',
      previews: {},

      option: {
        img: '', // 裁剪图片的地址

        size: 1, // 裁剪生成图片的质量

        full: false, // 是否输出原图比例的截图 默认false

        outputType: 'png', // 裁剪生成图片的格式 默认jpg

        canMove: true, // 上传图片是否可以移动

        fixedBox: false, // 固定截图框大小 不允许改变

        original: false, // 上传图片按照原始比例渲染

        canMoveBox: true, // 截图框能否拖动

        autoCrop: true, // 是否默认生成截图框

        // 只有自动截图开启 宽度高度才生效

        autoCropWidth: this.fixedNumber[0], // 默认生成截图框宽度

        autoCropHeight: this.fixedNumber[1], // 默认生成截图框高度

        centerBox: false, // 截图框是否被限制在图片里面

        high: false, // 是否按照设备的dpr 输出等比例图片

        enlarge: 10, // 图片根据截图框输出比例倍数

        mode: 'contain', // 图片默认渲染方式

        maxImgSize: 2000, // 限制图片最大宽度和高度

        limitMinSize: this.fixedNumber, // 更新裁剪框最小属性

        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高

        fixed: false // 是否开启截图框宽高固定比例  (默认:true)

        // fixedNumber: [16, 9] // 截图框的宽高比例
      }
    }
  },
  computed: {
    file: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },

  methods: {
    onHttpRequest(val) {
      console.log(val, '==== onHttpRequest ====')
      const _URL = window.URL || window.webkitURL
      this.cropperImg = _URL.createObjectURL(val.file)
      this.dialogVisible = true
    },
    onProgress(val) {
      console.log(val, '==== onProgress ====')
    },
    handleSuccess(files) {
      this.isLoading = true
      const formData = new FormData()
      formData.append('image', files)
      this.$axios({
        method: 'post',
        url: `${this.$API.uploadImage}?bucketType=${this.classify}`,
        data: formData
      }).then(res => {
        this.$emit('uploadImg', res.data || {})
        this.isLoading = false
        this.dialogVisible = false
      }, ear => {
        this.isLoading = false
        this.$message.error(ear.message)
      })
    },
    // 裁剪时触发的方法，用于实时预览

    realTime(data) {
      this.previews = data
    },

    // 重新上传

    uploadBth() {
      this.$emit('update-cropper')
    },

    // 取消关闭弹框

    handleClose() {
      this.$emit('colse-dialog', false)
      this.dialogVisible = false
    },

    // 获取裁剪之后的图片，默认blob，也可以获取base64的图片

    saveImg() {
      if (this.imgType === 'blob') {
        this.$refs.cropper.getCropBlob(data => {
          this.handleSuccess(data)
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          this.uploadFile = data
          this.$emit('uploadImg', data)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__footer {
    padding-top: 50px;
  }
}
.Cropper {
  .cropper-el {
    height: 300px;
    width: 300px;
  }
  .cropper-container {
    display: flex;
    justify-content: space-between;
    .prive-el {
      height: 164px;
      width: 94px;
      flex: 1;
      text-align: center;
      .prive-style {
        margin: 0 auto;
        flex: 1;
        -webkit-flex: 1;
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-justify-content: center;
        overflow: hidden;
        background: #ededed;
        margin-left: 40px;
      }
      .preview {
        overflow: hidden;
        border: 1px solid #999;
      }
      .el-button {
        margin-top: 20px;
      }
    }
  }
}
.upload-wrapper {
  ::v-deep .el-upload {
    width: 100%;
    height: 100%;
    .wrapper {
      font-size: 12px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #787878;
      background: #f5f5f5;
      border: 1px dashed #e1e1e1;
      transition: all 0.3s;
      &:hover {
        color: $color-primary;
        border: 1px dashed $color-primary;
        cursor: pointer;
      }
    }
    .shadow {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 2%);
    }
    .img-wrap {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      .proview-img {
        width: 100%;
        object-fit: cover;
        transition: all 0.3s;
        &:hover {
          transform: scale(1.5);
        }
      }
    }
  }
  &[disabled="disabled"] {
    .img-wrap {
      &:hover {
        cursor: not-allowed;
        color: #787878;
        background: #f5f5f5;
        border: 1px dashed #e1e1e1;
      }
    }
  }
}
</style>
