<template>
  <el-dialog class="change-dialog-box" title="选择组织" :visible.sync="dialogFormVisible">
    <div class="title-search-box">
      <el-input
        placeholder="搜索组织名称"
        v-model="searchVal"
        clearable
        style="margin-top: 10px;">
        <el-button slot="prefix" type="text" icon="el-icon-search" style="color: #A3A3A3;padding-left:3px;"></el-button>
      </el-input>
      <span>已选{{checkNodeKeyArr.length}}组织</span>
    </div>
    <el-table class="custom-table-box"
      :data="initOrgList"
      row-key="orgId"
      :tree-props="{children:'child'}"
      stripe
      height="300"
      :expand-row-keys="[defaultOpen]"
    >
      <el-table-column
        label="全部"
        width="auto">
        <template slot-scope="scope">
            <el-checkbox :value="hasSelectList.includes(scope.row.orgId)" :disabled="initSelectList.includes(scope.row.orgId) || scope.row.status !== 1 || scope.row.orgId === orgList[0].orgId" @change="onCheck(scope.row)">
                <div class="org-name-box">
                    <el-tooltip v-if="scope.row.status !== 1" effect="light" content="该组织已被冻结" placement="bottom">
                        <img src="@/assets/nav/forbid.png" alt="">
                    </el-tooltip>
                    {{scope.row.orgName}}
                </div>
            </el-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "SelectOrgItemModel",
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    formObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    initSelectList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    ...mapGetters(['orgList']),
    initOrgList:{
      get() {
        return this.filterList
      }
    },
    dialogFormVisible: {
      get() {
        this.open = this.isShowPop
        return this.isShowPop
      },
      set(val) {
        this.open = val
        this.$emit('onClose',val)
      }
    },
    defaultOpen: {
      get() {
        if(this.checkOrgId) {
          return this.checkOrgId
        }
        let obj = this.orgList[0] || {}
        return obj.orgId || ''
      }
    },
    hasSelectList() {
        return [...this.initSelectList,...this.checkNodeKeyArr]
    }
  },
  watch: {
    searchVal: {
      immediate: true,
      handler(newVal) {
        this.checkOrgId = ''
        this.filterList = []
        this.checkList(this.orgList)
      }
    }
  },
  data() {
    return {
      open: false,
      defaultProps: {
        children: 'child',
        label: 'orgName',
        value:'orgId',
        disabled: 'isSelect',
        checkStrictly: true
      },
      searchVal: '',
      checkOrgId: '',
      filterList: [],
      checkNodeKeyArr: [],
    }
  },
  methods: {
    ...mapActions(['getOrgList']),
    // ---------------- 工具函数 ------------------------------
    indexOf(val) {
      for(let i = 0; i<this.checkNodeKeyArr.length; i++) {
        if(this.checkNodeKeyArr[i] === val) {
          return i
        }
      }
    },
    remove(val) {
      let index = this.indexOf(val)
      if(index > -1) {
        this.checkNodeKeyArr.splice(index,1)
      }
    },
    // ----------------------- end --------------------------
    checkList(list) {
      for (let item of list) {
        if(item.orgName.toLowerCase().includes(this.searchVal.trim().toLowerCase())) {
          this.filterList.push(item)
        } else {
          if(item.child&&item.child.length) {
            this.checkList(item.child)
          }
        }
      }
    },
    submitForm() {
      this.dialogFormVisible = false
      this.$emit('onConfirmSelect',this.checkNodeKeyArr)
    },
    onCheck(data) {
        if(this.checkNodeKeyArr.includes(data.orgId)) {
            this.remove(data.orgId)
            return
        }
        this.checkNodeKeyArr.push(data.orgId)
    },

  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.change-dialog-box {
  ::v-deep .el-dialog .el-dialog__header {
    background-color: #ffffff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    .el-dialog__title {
      color: #0F0F0F;
      font-size: 14px;
    }
    .el-dialog__close {
      color: #909399 !important;
    }
  }
}
.dialog-footer {
  text-align: center;
}
.title-search-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  & > span {
    flex-shrink: 0;
  }
  & > div {
    width: 200px;
    margin: 0 !important;
  }
  ::v-deep .el-input__inner {
    background-color: #FAFAFA;
    border-radius: 20px;
  }
}
.custom-table-box {
  // ::v-deep div.cell {
  //   width: fit-content;
  //   .el-table__expand-icon {
  //     width: 300px;
  //     height: 100%;
  //     position: absolute;
  //     z-index: 100;
  //     opacity: 0;
  //   }
  //   .el-table__expand-icon--expanded {
  //     transform: rotate(0deg);
  //   }
  // }
  .scope-node {
    display: inline-block;
  }
  .org-name-box {
    display: inline;
    img {
        vertical-align: middle;
        margin-right: 1px;
    }
}
}
</style>
