<!--
 * @Author: your name
 * @Date: 2021-08-15 12:26:24
 * @LastEditTime: 2022-03-23 11:40:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/academic/VisitDetail.vue
-->
<template>
  <div class="look-train-data-box">
    <page-main title back>
      <el-row type="flex" justify="space-between">
        <el-row type="flex">
          <div class="grow-shrink0 mgn-r30" style="width:320px;height:180px;background:#D8D8D8;">
            <img :src="sceneDetail.coverUrl" alt="" style="width:100%;height:100%;"/>
          </div>
          <div style="min-width: 150px;">
            <div class="flex-between flex-direction-col vertical-start">
              <div class="col272e40 font-s18 font-bold">{{ sceneDetail.title }}</div>
            </div>
            <div class="mgn-t10 col7784a1 font-s14">
              <span>拜访素材数：</span>
              <span class="mgn-r10">共{{ materialCount}}个</span>
            </div>

            <el-row v-if="isShowBtn" type="flex" class="flex-wrap mgn-t10">
              <span class="col7784a1 font-s14">产品标签：</span>
              <template v-for="item in productList">
                <span :key="item.tagId" class="scene-tag">{{ item.tagName }}</span>
              </template>
              <span v-if="!productList.length > 0" class="col7784a1 font-s14" style="margin-top: 8px">/</span>
            </el-row>
            <el-row v-if="otherList.length > 0 && isShowBtn" type="flex" class="flex-wrap mgn-t10">
              <span class="col7784a1 font-s14">其它标签：</span>
              <template v-for="item in otherList">
                <span v-if="item.groupName !== '产品标签'" :key="item.tagId" class="scene-tag">{{ item.tagName }}</span>
              </template>
            </el-row>
            <div class="col7784a1 mgn-t10 font-s14">简介：{{sceneDetail.intro}}</div>
          </div>
        </el-row>
        <div class="funnel">
          <EchartsFunnel :option="option" v-if="showFunnel"></EchartsFunnel>
        </div>
      </el-row>
      <div class="icon-message-box">
        <el-popover
          placement="left"
          width="400"
          trigger="hover"
        >
          <div>总活动任务：目前实际发生的任务，撤回的任务不算在内</div>
          <div>已开始任务：代表发送给客户的任务数</div>
          <div>已完成学术任务：客户学习并完成的任务数</div>
          <img slot="reference" src="../../assets/iconImg/icon-mark.png" alt=""
                style="width:100%;height:100%;cursor:pointer;">
        </el-popover>
      </div>
    </page-main>
    <page-main style="margin-top: 0px">
      <el-row type="flex" justify="space-between" align="middle" class="flex-wrap">
        <span class="font-s18">数据报表</span>
        <el-row type="flex" v-if="$auth('acadSaas.actMgmt.viewExecData')">
          <el-button size="mini" type="primary" class="w120" plain @click="onJumpPanGu('rank')">执行报表</el-button>
          <el-button size="mini" type="primary" class="w120" plain @click="onJumpPanGu('rank','Represent')">代表数据报表</el-button>
          <el-button size="mini" type="primary" class="w120" plain @click="onJumpPanGu('doctor/data')">医生数据报表</el-button>
          <el-button size="mini" type="primary" class="w120" plain @click="onJumpPanGu('task/data')">任务数据报表</el-button>
          <el-button size="mini" type="primary" class="w120" plain @click="onJumpPanGu('exam/data')">答题报表</el-button>
        </el-row>
      </el-row>
    </page-main>
    <page-main style="margin-top: 0px">
      <el-tabs v-model="activeTab">
        <el-tab-pane v-for="item in tabList" :key='item.key' :label='item.name' :name='item.key'></el-tab-pane>
      </el-tabs>
      <div class="table-box">
        <div v-if="activeTab === 'material'">
          <el-table
            :data='materialList'
            borderd
          >
            <el-table-column
              label='标题/内容'
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>{{scope.row.contentTitle || '—'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label='类型'
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.contentType || '—'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label='任务完成条件'
              align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.contentType | visitFinishFilter}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label='操作'
              width="200"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="text-primary-link" @click="onLook(scope.row)">查看详情</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-else>
          <el-table
            :data='recordList'
          >
            <el-table-column
              label='分配时间'
              prop="createdAt"
            >
              <template slot-scope="scope">
                <span>{{$moment(scope.row.createdAt).format('YYYY-MM-DD HH:mm:ss')}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label='分配方式'
              align="center"
            >
              <template slot-scope="scope">
                <span>分配给{{scope.row.toType | allotUserType}}{{scope.row.toType === 1&&scope.row.autoAttrTime?`（${$moment(scope.row.autoAttrTime).format('YYYY-MM-DD HH:mm:ss')}）`:''}} </span>
              </template>
            </el-table-column>
            <el-table-column
              label='任务规则'
              align="center"
            >
              <template slot-scope="scope">
                <div>代表执行{{ scope.row.validDayRepresent }}天 + 医生学习{{ scope.row.validDayDoctor }}天</div>
              </template>
            </el-table-column>
            <el-table-column
              label='任务总数'
              align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.codeCount}}个</span>
              </template>
            </el-table-column>
            <el-table-column
              label='成功任务数'
              align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.successCount}}个</span>
              </template>
            </el-table-column>
            <el-table-column
              label='失败任务数'
              align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.failCount}}个</span>
              </template>
            </el-table-column>
            <el-table-column label="是否有委托" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.hasDelegate ? '是' : '否' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label='分配状态'
              align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.status === 1 ? '分配完成' :scope.row.status === 2 ? '分配中' :scope.row.status === 3 ? '分配失败':'' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label='分配操作人'
              prop="userName"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label='操作'
              align="center"
            >
              <template slot-scope="scope">
                <el-popover
                  width="200"
                  v-model='openObj[scope.row.id]'
                  trigger="manual"
                  placement='left'
                >
                  <div class="card-body" v-if='exportMsg'>{{exportMsg}}</div>
                  <div class="card-body" v-else>是否下载当前分配记录？</div>
                  <el-row type="flex" justify="end">
                      <el-button size="mini" type="text" @click="onCloseModal">取消</el-button>
                      <el-button type="primary" size="mini" @click="onJumpDownCenter" v-if='exportMsg'>前往</el-button>
                      <el-button type="primary" size="mini" @click="onDown(scope.row.id)" :loading='openObj[scope.row.id] === "loading"' v-else>确定</el-button>
                  </el-row>
                  <span slot="reference" class="text-primary-link" @click.stop="onShowDownToast(scope.row.id,true)">下载分配记录表</span>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </page-main>
    <fixed-action-bar>
      <!-- <el-button  type="primary" @click="onAllot">分配任务</el-button> -->
      <el-button  plain type="primary" @click="onOpenRecallPop" v-if="totalCodeCount - beginCodeCount > 0 && $auth('acadSaas.actMgmt.assignTask')">撤回任务</el-button>
      <el-button  plain type="primary" @click="onEdit" v-if="sceneDetail.canEdit && isShowBtn  && $auth('acadSaas.actMgmt.edit')">编辑活动</el-button>
      <el-button v-if="isShowBtn && $auth('acadSaas.actMgmt.del')"  plain type="primary" @click="onOpenOperationConfirmPop">删除活动</el-button>
      <el-button v-if="$auth('acadSaas.actMgmt.share')" plain type="primary" @click="onOpenSharePop">分享活动</el-button>
      <el-button v-if="isShowBtn && $auth('acadSaas.actMgmt.etag')"  plain type="primary" @click="onOpenTagPop">编辑标签</el-button>
    </fixed-action-bar>
    <video-play-modal
      :videoUrl='videoPriviewUrl'
      :visible='showVideoModal'
      @onClose='onCloseModal'
      title='拜访视频素材'
      ></video-play-modal>
    <ExamPre
      :visible="isShowExamPreview"
      :item-obj="currentPreviewExam"
      @onClose="onCloseModal"
    />
    <SceneShareDepModal
      :visible="isShowSharePop"
      :sceneType='sceneType'
      :is-creator="sceneDetail.isCreator"
      sceneTitle='拜访'
      :sceneId="sceneId"
      :isSubmit='isShareLoading'
      @onSubmit='onSubmitShareDep'
      @onClose="onCloseModal"

    />
    <RecallSceneCodeModal
      :visible="isShowRecallPop"
      :sceneId="sceneId"
      :sceneType="sceneType"
      @onClose="onCloseModal"/>
    <OperationConfirmPop
      :visible="isShowOperationConfirmPop"
      :message="message"
      :id="sceneId"
      title="删除操作确认"
      cancelFont="取消"
      confirmFont="确定删除"
      @onSubmit="onDel"
      @onClose="onCloseModal"
    />
    <ProductLabel
      :visible.sync="showTagModal"
      :disabled-ids="disabledIds"
      :selected-tag-list="sceneTagList"
      @onSubmit="onSubmitTag"
    />
  </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop'
import EchartsFunnel from '@/baseComponents/EchartsFunnel.vue'
import ExamItem from '@/bizComponents/ExamItem'
import RecallSceneCodeModal from '@/bizComponents/RecallSceneCodeModal'
import SceneShareDepModal from "@/bizComponents/SceneShareDepModal"
import VideoPlayModal from '@/bizComponents/VideoPlayModal'
import ProductLabel from '@/bizComponents/ProductLabel'
export default {
  name: 'MeetingDetail',          // 跟文件名称对应
  components: {
    SceneShareDepModal,
    RecallSceneCodeModal,
    ExamItem,
    OperationConfirmPop,
    VideoPlayModal,
    EchartsFunnel,
    ProductLabel
  },
  data() {
    return {
      // ------------------- 预览 -----------------------------
      isShowExamPreview: false,//发送手机预览modal
      showFunnel: false,
      videoPriviewUrl:'',
      showTagModal: false,
      bindTagLoading: false,
      showVideoModal: false,
      currentPreviewExam: {},
      videoList:[],
      examList:[],
      knowledgeList: [],
      tabList: [{
        name:'素材详情',
        key: 'material'
      }, {
        name:'分配记录表',
        key:'allot'
      }],
      activeTab: 'material',
      isShareLoading: false,
      userTagType: 11, //标签分类
      sceneTagList: [],
      disabledIds: [],
      openObj:{},
      materialList: [],
      materialCount:0,
      exportMsg: '',
      isShowSharePop: false,
      isShowRecallPop: false,
      isShowOperationConfirmPop: false,
      message: '请确认是否永久删除？',
      sceneId: '',
      sceneDetail: {},
      isClick: false,
      sceneType: 'academicVisit',
      recordList: [],
      summaryData: [
        { value: 60, name: '总活动任务：' },
        { value: 40, name: '已开始任务：' },
        { value: 20, name: '已完成学术任务：' }
      ],
      totalCodeCount: 0,
      beginCodeCount: 0,
      productList: [],
      otherList: [],
      option: {}
    }
  },
  filters: {
    allotUserType(type) {
      switch (type) {
        case 1:
          return '医生'
        case 2:
          return '代表'
        case 3:
          return '组织'
      }
    },
    visitFinishFilter (type) {
      switch (type) {
        case '视频':
          return '打开视频'
        case '文献':
          return '打开文献'
        case '题目':
        case '问卷':
          return '完成答题'
      }
    }
  },
  computed: {
    ...mapGetters(['tagGroupList','academicSourceType']),
    isShowBtn() {
        return this.academicSourceType === 'ower'
    },


  },
  watch: {
    activeTab (newVal) {
      // this.selectedTagList = []
      if (newVal === 'allot') {
        this.initRecord()
      }
    },
    sceneTagList: {
      deep: true,
      immediate: true,
      handler() {
        this.productList = []
        this.otherList = []
        this.sceneTagList.map(item => {
          if (item.groupName === '产品标签') {
            this.productList.push(item)
          } else {
            this.otherList.push(item)
          }
        })
        console.log(this.productList)
      }
    }
  },
  mounted() {
    this.sceneId = this.$route.params.sceneId
    this.init()
  },
  methods: {
    ...mapActions(['getAcademicVisitDetail','getSceneCodeUsedStatistic','getSceneDetailTag','onDownSceneAllotRecordData','getSceneAllotRecordList','onDelAcademicVisit','sceneShareToDep','getVideoUrl','getExamDetail','onBindSceneTag']),
        getOption() {
      this.option = {
        legend: [],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}'
        },
        series: [{
          name: '活动数据',
          type: 'funnel',
          left: 0,
          top: 0,
          bottom: 0,
          width: 240,
          min: 0,
          max: 100,
          minSize: '0%',
          maxSize: '100%',
          gap: 0,
          label: {
            show: true,
            position: 'right',
            color: '#272E40'
          },
          labelLine: {
            length: 30,
            lineStyle: {
              width: 1,
              type: 'solid'
            }
          },
          color: [
          '#B1BFF3',
          '#8AA0EE',
          '#6380E8',
          '#3D61E3'],
          data: this.summaryData
        }],

      }
      this.showFunnel = true
    },
    onChangeTab (e) {
      console.log(e)
    },
    onJumpPanGu(path,type='') {
      this.$qxsDataPageJump(`/${path}?sceneTitle=${encodeURIComponent(this.sceneDetail.title)}&rankType=${type}`)
      // window.location
    },
    initRecord () {
      this.getSceneAllotRecordList(this.sceneId).then(res=>{
        this.recordList = res
      })
    },
    onSubmitTag(arr){
      this.bindTagLoading = true
    	let req = arr.map((v) => {
  			return {
  				tagId: v.tagId,
  				groupId: v.groupId,
  				userTagType: this.userTagType,
  				memberId: this.sceneId,
          knowledgePointIds: v.knowledgePointIds
  			};
		  });
      this.onBindSceneTag(req).then(res=>{
        this.initTag()
        this.bindTagLoading = false
        this.showTagModal = false
        this.$message.success('标签绑定成功')
      },rea=>{
        this.showTagModal = false
        this.bindTagLoading = false
        this.$message.error(rea)
      })
    },
    onShowDownToast (openKey ='') {
      this.openObj = {...this.openObj,[openKey]: true}
    },
    onJumpDownCenter () {
      this.$router.push('/download/center')
    },
    onSubmitShareDep (arr, isSelectedPubResLib) {
      this.isShareLoading = true
      let req = {sceneIds: [this.sceneId], sceneType: this.sceneType,factoryIds: arr, isSelectedPubResLib}
      this.sceneShareToDep(req).then(res=>{
        this.isShareLoading = false
        this.isShowSharePop = false
        this.$message.success('分享成功')
      },rea=>{
        this.isShareLoading = false
        this.$message.error(rea.message)
      })
    },
    initArticle (itemId) {
      const routeUrl = this.$router.resolve(`/material/article/preview/${itemId}`)
      window.open(routeUrl.href, '_blank')
    },
    onOpenTagPop () {
      this.showTagModal = true
    },
    onLook (item) {
      switch (item.contentType) {
        case '视频':
          this.initVideoUrl(item.visitContentId)
          break
        case '问卷':
        case '题目':
          this.initExam(item.visitContentId)
          break
        case '文献':
          this.initArticle(item.visitContentId)
      }
    },
    initExam(examId) {
      this.getExamDetail(examId).then(res => {
        this.isShowExamPreview = true
        this.currentPreviewExam = res.data || {}
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    initVideoUrl (videoId) {
      this.getVideoUrl(videoId).then(res => {
        let resData = res.playUrl || []
        if (resData.length === 0) {
          this.$message.error({ message: '暂无视频资源' })
        }
        for (let item of resData) {
          if (item.indexOf('.mp4') > 0) {
            this.videoPriviewUrl = item
            this.showVideoModal = true
            return false
          }
          this.videoPriviewUrl = ''
        }
        this.$message.error({ message: '暂无视频资源' })
      }, rea => {
        this.$message.error({ message: rea })
      })
    },
    onDown (id) {
      if (this.openObj[id] === 'loading') return
      this.openObj[id] = 'loading'
      let req = {
        codeAssignRecordId: id,
      }
      this.onDownSceneAllotRecordData(req).then(res=>{
        this.openObj[id] = false
        this.$message.success('数据下载成功，请前往下载中心查看')
        this.exportMsg = res.data.data
      },rea=>{
        this.openObj[id] = false
        this.$message.error(rea.message)
      })
    },
    onOpenRecallPop () {
      this.isShowRecallPop = true
    },
    init() {
      this.getAcademicVisitDetail(this.sceneId).then(res => {
        this.sceneDetail = {...res,coverUrl: require('@/assets/academic/visit_cover.png')}
        this.materialList = res.contentInfos || []
        this.materialCount = this.materialList.length
        this.initTag()
        // this.getCodeInfo()
        this.onCodeUsedStatistic()
      }, message => {
        this.$message({
          message: message,
          type: 'error'
        })
      })
    },
    initTag () {
      let groupIds = this.tagGroupList.map(v=>v.id)
      let req = {
        memberIdList: [this.sceneId],
        userTagType: this.userTagType,
        tagType: 1,
        groupIds: groupIds
      }
      this.getSceneDetailTag(req).then(res=>{
        this.disabledIds = []
        this.sceneTagList = [...res].map(v=>{
          this.disabledIds.push(v.tagId)
          return {...v,disabled: true}
        })
      },rea=>{

      })
    },
    // ------------------------ 预览 -------------------
    onCloseModal() {
      this.openObj = {}
      this.showTagModal = false
      this.isShowSharePop = false
      this.isShowRecallPop = false
      this.isShowOperationConfirmPop = false
      this.showVideoModal = false
      this.isShowExamPreview =false
    },
    onAllot() {
      this.$router.push(`/academic/scene/${this.sceneType}/rule/${this.sceneId}?disabledBack=${!this.sceneDetail.canEdit}`)
    },
    onEdit() {
      this.$router.push(`/academic/scene/${this.sceneType}/edit/${this.sceneId}`)
    },
    onCodeUsedStatistic(){
      this.getSceneCodeUsedStatistic(this.sceneId).then(res=>{
        this.totalCodeCount = res.data.totalCodeCount
        this.beginCodeCount = res.data.beginCodeCount
        this.summaryData = [...this.summaryData].map((v,i)=>{
          let name = ''
          if (i === 0){
            name = '总活动任务：'+ res.data.totalCodeCount +'个'
          } else if(i === 1){
            name = '已开始任务：'+ res.data.beginCodeCount +'个'
          }else if(i === 2){
            name = '已完成学术任务：'+ res.data.finishedCodeCount +'个'
          }
          v={...v,name: name}
          return v
        })
        this.getOption()
      },rea=>{
        this.$message.error(rea.message)
      })
    },

    onOpenOperationConfirmPop() {
      this.message = this.sceneDetail.isRealCanDel ? '请确认是否删除，一经删除，不可恢复' : '请确认是否删除，删除后不可再分配，您也可以在个人中心回收站重新找回'
      this.isShowOperationConfirmPop = true
    },
    onOpenSharePop(id) {
      this.isShowSharePop = true
    },
    onDel() {
      this.onDelAcademicVisit(this.sceneId).then(res => {
        this.$message({
          message: '删除成功！',
          type: 'success'
        })
        this.onCloseModal()
        this.$router.back()
      }, rea => {
        this.$message({
          message: rea,
          type: 'error'
        })
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.content-box {
  position: relative;
  flex: 1;
  overflow-y: scroll;
  padding-left: 50px;
  padding-right: 50px;
  -ms-overflow-style: none;
  padding-bottom: 100px;
  overflow: -moz-scrollbars-none;
}

.content-box::-webkit-scrollbar {
  width: 0 !important;
}
.card-body ::v-deep {
  padding: 12px 14px;
  flex-shrink: 0;
  position: relative;
  .el-tabs {
    margin-top: -32px;
  }
  .el-tabs__header {
    margin-left: -34px;
    margin-right: -34px;
    .el-tabs__nav-wrap {
      padding-left: 34px;
    }
  }
}
.table-box {
  min-height: 400px;
}

.out-progress {
  width: 140px;
  height: 10px;
  background: #F0F0F0;
  border-radius: 7px;
}

.in-progress-doing {
  height: 10px;
  background: linear-gradient(270deg, #3DC8FB 0%, #417BF3 100%);
  border-radius: 7px;
}

.in-progress-end {
  height: 10px;
  background: linear-gradient(270deg, #00EA6E 0%, #14B4C2 100%);
  border-radius: 7px;
}
.video-cover {
  width: 107px;
  height: 60px;
  background: #D8D8D8;
  margin: auto;
}

.box-shadow {
  box-shadow: 2px -2px 4px 0px #e2e3e7;
}

.title-nav-box {
  position: relative;
  z-index: 10;
}

.funnel {
  height:170px;
  width: 440px;
  flex-shrink: 0;
  position: relative;
}
.scene-tag {
  color: #3d61e3;
  background: rgba(61, 97, 227, 0.12);
  border-radius: 16px;
  height: 20px;
  flex-shrink: 0;
  line-height: 12px;
  padding: 4px 10px;
  margin-right: 10px;
}
  .footer-box{
    height: 100px;
    padding-right: 120px;
    padding-left: 60px;
    display: flex;
    z-index: 1000;
    justify-content: flex-end;
    align-items: center;
    border-top:1px solid #E9EAEB;
    background-color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    .el-button {
      margin-left: 20px;
    }
  }
.icon-message-box {
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 100;
  right: 15px;
}
</style>
