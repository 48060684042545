<template>
  <div class="ordinary-List">
    <div class="search-packet">
      <div class="left">
        您可以<el-link :underline="false" type="primary" href="https://cdnfile.yaomaitong.cn/ksh/%E7%99%BD%E5%90%8D%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx">点击这里下载模板</el-link>，填写完成后<UploadSheetToJson @onUpload="onUpload">
          <el-button type="text">点击这里上传导入</el-button>
        </UploadSheetToJson>。
      </div>
      <div class="right">
        <el-input
          v-model="search.name"
          size="small"
          prefix-icon="el-icon-search"
          placeholder="搜索医生姓名或医院名称"
          @keydown.enter.native="onSearch"
        />
      </div>
    </div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%;">
      <el-table-column prop="name" label="医生" width="150" />
      <el-table-column prop="title" width="180">
        <template #header>
          <el-select
            v-model="search.titleName"
            style="width: 120px;"
            size="small"
            placeholder="请选择"
            @change="onSearch"
          >
            <el-option label="所有职称" value="" />
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="hospital" label="医院名称" />
      <el-table-column prop="department" label="科室" />
      <el-table-column prop="mobile" width="120" label="手机号" />
      <el-table-column prop="importTime" width="170" label="导入时间">
        <template slot-scope="scoped">
          {{ $moment(scoped.row.importTime).format('YYYY-MM-DD  HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column prop="address" width="80" label="操作">
        <template slot-scope="scoped">
          <el-button type="text" @click="onDel(scoped.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-sizes="pagination.sizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import UploadSheetToJson from '@/bizComponents/UploadSheetToJson'
import { getLiveWhiteList, importWhiteList, removeLiveWhiteList } from '@/services/liveService'
import { MessageBox } from 'element-ui'
export default {
  name: 'OrdinaryList',
  components: { UploadSheetToJson },
  mixins: [paginationMixin],
  props: {
    options: {
      type: Array,
      default: Array
    }
  },
  data() {
    return {
      loading: false,
      tableData: [],
      ext: ['xlsx', 'xlsm', 'xlsm', 'xml'],
      search: {
        pageNo: 1,
        pageSize: 10,
        titleName: '',
        liveId: '',
        name: ''
      }
    }
  },
  mounted() {
    this.search.liveId = this.$route.params.liveId
    this.getDataList()
  },
  methods: {
    async getDataList() {
      try {
        this.loading = true
        const params = this.getParams(this.search)
        const { data } = await getLiveWhiteList(params)
        this.tableData = data.data
        this.pagination.total = data.totalCount
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$message({
          type: 'error',
          message: error.message
        })
      }
    },
    onDel(row) {
      MessageBox.confirm('此操作将删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        try {
          const params = {
            liveId: this.search.liveId,
            mobile: row.mobile
          }
          await removeLiveWhiteList(params)
          this.getDataList()
          this.$message.success('删除成功')
        } catch (error) {
          console.log(error)
          this.$message.error(error)
          // this.$message.error(error)
        }
      })
    },
    onSearch() {
      this.search.pageNo = 1
      this.getDataList()
    },
    async onUpload(json) {
      const list = json
        .map(v => {
          return {
            name: v['姓名'],
            department: v['科室'],
            title: v['职称'],
            hospital: v['医院'],
            mobile: v['手机号（必填）'].toString()
          }
        })
        .filter(i => i)

      if (list.length === 0) {
        this.$message.error('您上传的文件内容为空，请完善数据后再上传！')
        return
      }
      try {
        const params = {
          liveId: this.search.liveId,
          list
        }
        await importWhiteList(params)
        this.$message.success('导入成功')
        this.getDataList()
      } catch (error) {
        console.log(error)
        this.$message.error(error.message)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.ordinary-List {
  ::v-deep .el-table__header-wrapper {
    th {
      background-color: #eff0f2;
    }
  }
  .search-packet {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
    }
    .right {
      display: flex;
      align-items: center;
      .el-input {
        width: 365px;
        margin-right: 40px;
        ::v-deep input {
          border-radius: 20px;
        }
      }
    }
  }
  .pagination {
    margin-top: 20px;
  }
}
</style>
