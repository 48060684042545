<template>
  <div class="freeze-dialog-box">
    <el-dialog title="温馨提示" :visible.sync="dialogFormVisible" :show-close="false">
      <div class="freeze-info">
        <div>当前直播未设置题目，暂不支持分配</div>
        <div>备注：只有创建活动的组织有编辑活动的权限</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">我知道了 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "LiveEmpty",
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    dialogFormVisible: {
      get() {
        this.open = this.isShowPop
        return this.isShowPop
      },
      set(val) {
        this.open = val
      }
    },
  },
  data() {
    return {
      open: false,
      title: '冻结',
      time: 15,
      timerNum: null,
    }
  },
  methods: {
    onClose(isGet = true) {
      this.dialogFormVisible = false
      this.$emit('onClose')
    },
    submitForm(type) {
      this.onClose(false)
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.freeze-dialog-box {
  font-size: 14px;
  ::v-deep .el-dialog {
        width: fit-content;
        min-width: 460px;
    }
  ::v-deep .el-dialog .el-dialog__header {
    background-color: #ffffff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    .el-dialog__title {
      color: #0F0F0F;
      font-size: 14px;
    }
    .el-dialog__close {
      color: #909399 !important;
    }
  }
}
.freeze-info {
  width: fit-content;
  margin: auto;
  font-weight: bold;
  font-size: 14px;
  padding: 36px 0 30px;
  color: #0F0F0F;
  text-align: left;
  line-height: 22px;
}
.left-align {
    text-align: left;
    width: fit-content;
    margin: auto;
}
.bold {
    font-weight: bold;
}
.dialog-footer {
  text-align: center;
}
.name-text {
  color: #3D61E3;
}

.center {
  text-align: center;
}
</style>
