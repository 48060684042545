<template>
  <el-dialog
    ref="dialog"
    class="collection-form"
    title="信息采集表单"
    width="840px"
    lock-scroll
    v-bind="$attrs"
    v-on="$listeners"
    @open="initFormData"
  >
    <div class="information-collection-form">
      <el-form
        ref="form"
        v-loading="loading"
        :model="form"
        :rules="formRule"
        label-suffix=":"
        label-width="100px !important"
        :disabled="form.isLimitUpdate === false"
        @submit.native.prevent
      >
        <el-form-item label="目标用户" prop="targetUserType">
          <el-radio-group v-model="form.targetUserType">
            <el-radio :label="1">全部用户</el-radio>
            <el-radio :label="2">已认证用户</el-radio>
            <el-radio :label="3">未认证用户</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="表单名称" prop="formName">
          <el-input
            v-model="form.formName"
            placeholder="请输入表单名称"
            style="width: 80%;"
            :maxlength="20"
            show-word-limit
          />
        </el-form-item>
        <el-divider />
        <div class="exam-list">
          <el-form-item v-if="!form.examBos.length" label="问题1" class="answer-title">
            <div>请点击“+”添加问题</div>
            <p>
              <el-popover popper-class="add-exam" placement="bottom" trigger="click">
                <el-button slot="reference" type="text" icon="el-icon-plus" />
                <div>
                  <el-button class="check-tag" @click="onAddAnswer('text_fill')"> 问答题 </el-button>
                  <el-button class="check-tag" value="single" @click="onAddAnswer('single')"> 选择题 </el-button>
                </div>
              </el-popover>
            </p>
          </el-form-item>
          <el-form-item
            v-for="(item, index) in form.examBos"
            :key="`${item.examId}${item.customId}`"
            :label="`问题${index + 1}`"
            class="exam-item"
          >
            <!-- 问答 -->
            <TextFill
              v-if="item.examTypeEnum === 'text_fill'"
              :ref="`${item.examId}${item.customId}`"
              :item="item"
              @updateExam="updateExam"
            />
            <!-- 单选 -->
            <Single
              v-else-if="item.examTypeEnum === 'single'"
              :ref="`${item.examId}${item.customId}`"
              :item="item"
              @updateExam="updateExam"
            />

            <p>
              <el-popover popper-class="add-exam" placement="bottom" trigger="hover">
                <el-button slot="reference" type="text" icon="el-icon-plus" />
                <div>
                  <el-button class="check-tag" @click="onAddExam(item,'text_fill')"> 问答题 </el-button>
                  <el-button class="check-tag" value="single" @click="onAddExam(item,'single')"> 选择题 </el-button>
                </div>
              </el-popover>
              <el-button
                style="margin-left: 7px;"
                type="text"
                icon="el-icon-minus"
                @click="onRemoveExam(item)"
              />
            </p>
          </el-form-item>
        </div>
      </el-form>
      <!-- 历史记录 -->
      <el-table
        :header-cell-style="headerStyle"
        :loading="historyLoading"
        :data="historyList"
        stripe
      >
        <template slot="empty">
          <div class="empty">
            <p>
              <img :src="require('@/assets/live/live-form-empty.png')" alt="">
            </p>
            <span>暂无表单记录</span>
          </div>
        </template>
        <el-table-column align="center" label="历史采集表单（最近10条）">
          <el-table-column prop="formName" />
          <el-table-column width="85px">
            <template slot-scope="scoped">
              <el-button type="text" :disabled="form.isLimitUpdate === false" @click="getFormInfoById(scoped.row.id,'history')">使用</el-button>
              <el-button type="text" style="color: #ed4e49;" @click="onDel(scoped.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>

    <div slot="footer">
      <el-button @click="$emit('update:visible', false)">取 消</el-button>
      <el-button type="primary" @click="onSubmitLiveForm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import TextFill from './TextFill.vue'
import Single from './Single.vue'

import {cloneDeep} from 'lodash-es'

export default {
  name: 'InformationCollectionForm',
  components: {
    TextFill,
    Single
  },
  props: {
    formId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      historyLoading: false,
      historyList: [],
      form: {
        targetUserType: 3,
        formName: '',
        examBos: []
      },
      formRule: {
        targetUserType: [{ required: true, message: '请选择目标用户', trigger: 'blur' }],
        formName: [{ required: true, message: '请输入表单名称', trigger: 'blur' }]
      },
      examBaseData: {
        title: '',
        examTypeEnum: '',
        type: 1, // 不分对错
        suitableType: 1,
        userType: 2
      }
    }
  },
  mounted() {
    this.getQueryHistoryList()

    this.$refs.dialog.rendered = true
  },
  methods: {
    async initFormData() {
      if (this.formId) {
        this.getFormInfoById(this.formId)
      } else {
        this.form = this.$options.data().form
      }
      this.getQueryHistoryList()
    },
    // 添加题目
    onAddAnswer(examTypeEnum) {
      const obj = { ...this.examBaseData, examTypeEnum, customId: this.$nanoid() }
      this.form.examBos.push(obj)
    },
    // 获取历史记录
    async getQueryHistoryList() {
      try {
        this.historyLoading = true
        const { data } = await this.$axios.get(this.$API.queryHistoryList)
        this.historyList = data
        this.historyLoading = false
      } catch (error) {
        this.historyLoading = false
        console.log(error)
      }
    },
    // 获取单个采集表的详情
    async getFormInfoById(formId, type) {
      try {
        this.loading = true
        const { data } = await this.$axios.get(this.$API.getFormInfoById, { params: { formId } })

        if (type === 'history') {
          this.form.targetUserType = data.targetUserType
          this.form.formName = data.formName
          this.form.examBos = data.examBos
        } else {
          this.form = data
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
        console.log(error)
      }
    },
    // 更新题目
    updateExam(row) {
      this.form.examBos = this.form.examBos.map(item => {
        if (`${item.examId}${item.customId}` === `${row.examId}${row.customId}`) {
          return row
        }
        return item
      })
    },
    // 添加题目
    onAddExam(row, examTypeEnum) {
      this.form.examBos.forEach((item, index) => {
        if (`${item.examId}${item.customId}` === `${row.examId}${row.customId}`) {
          this.form.examBos.splice(index + 1, 0, { ...this.examBaseData, examTypeEnum, customId: this.$nanoid() })
        }
      })
    },
    // 删除题目
    onRemoveExam(row) {
      this.form.examBos = this.form.examBos.filter(item => `${item.examId}${item.customId}` !== `${row.examId}${row.customId}`)
    },
    // 提交表单
    onSubmitLiveForm() {
      return new Promise(resolve => {
        this.$refs.form.validate(valid => {
          if (valid) {
            if (this.form.examBos.length < 1) {
              resolve(false)
              return this.$message.error('请添加至少一个题目')
            }
            Promise.all(this.form.examBos.map(item => this.$refs[`${item.examId}${item.customId}`][0].submit())).then(() => {
              this.$emit('update:visible', false)
              this.form.id = this.formId
              const params = cloneDeep(this.form)
              params.examBos = params.examBos.map((item, examIndex) => {
                delete item.examId
                item.examIndex = examIndex
                item.answerType = item.examTypeEnum

                if (item.answers && item.answers.length) {
                  item.answers = item.answers.map(answer => {
                    delete answer.examAnswerId
                    answer.title = answer.answer
                    return answer
                  })
                }
                return item
              })

              this.$axios.post(this.$API.addOrUpdateInfoCollection, params).then(({data: {id}}) => {
                this.$emit('update:formId', id)
                resolve(true)
              }).catch(error => {
                console.log(error)
              })
            })
              .catch(error => {
                resolve(false)
                this.$message.error(error.message)
              })
          } else {
            resolve(false)
            console.log('error submit!!')
            return false
          }
        })
      })
    },
    // 调整样式
    headerStyle(row) {
      if (row.rowIndex === 1) {
        return { display: 'none' }
      }
    },
    // 删除历史记录
    async onDel(formId) {
      try {
        await this.$axios.get(this.$API.delFormInfoById, { params: { formId } })
        this.getQueryHistoryList()
        this.$message.success('删除成功')
      } catch (error) {
        console.log('%c [ error ]-258', 'font-size:14px; background:pink; color:#bf2c9f;', error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.collection-form {
  ::v-deep .el-dialog {
    width: 840px !important;
  }
}
.information-collection-form {
  display: flex;
  .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    p {
      width: 112px;
      height: 84px;
      img {
        width: 100%;
      }
    }
    span {
      line-height: 20px;
    }
  }
  .el-form {
    flex: 1;
    margin: 0 15px;
    overflow: hidden;
  }
  .el-table {
    max-width: 305px;
    border-left: 1px solid #eee;
    &::before {
      height: 0;
    }
  }
  .exam-list {
    overflow-y: scroll;
    .exam-item,
    .answer-title {
      ::v-deep .el-form-item__content {
        display: flex;
        align-items: flex-start;
        > div {
          flex: 1;
          margin-right: 10px;
        }
        > p:nth-last-of-type(1) {
          width: 45px;
          display: flex;
          justify-content: space-between;
          [class*='el-icon-'] {
            color: #a3a3a3;
            font-weight: 600;
            background-color: #fbfbfb;
            overflow: hidden;
            border: 1px solid #d4d4d4;
            padding: 1px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
.add-exam {
  .check-tag {
    background-color: #fafafa;
    border: none;
    color: #8c8c8c;
    &:hover {
      background-color: #e7ecfc;
      color: #526ee5;
    }
  }
}
</style>
