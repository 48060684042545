<template>
  <div class="msg-item">
    <!-- 撤回消息 -->
    <div class="msg-text">
      <div :class="`${isMine?'out':'in'} item`">
        <div class="auther">
          <el-avatar :size="32" :src="message.avatar" />
          <div class="user-info">
            <p class="user-name">{{ message.nick }}</p>
            <template v-if="message.identity">
              <span v-if="message.identity ==='speaker'" class="tag lecturer-tag">讲师</span>
              <span v-if="message.identity === 'mainSpeaker'" class="tag speaker-tag">主讲人</span>
            </template>
          </div>
        </div>
        <div class="msg-status" @click.right.stop.prevent="(event) => rightEvent(event,message)">
          <template v-if="isMine">
            <span class="icon">
              <svg-icon v-if="messageIconClass === 'loading'" name="el-icon-loading" class="is-loading" />
              <span v-else-if="messageIconClass ==='warning-filled'" @click="onSendAgain">
                <svg-icon name="el-icon-warning" class="error" />
              </span>
            </span>
          </template>
          <slot name="handle" />
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { _tim } from '@/views/academic/tim/config/create-tim'
import {cloneDeep} from 'lodash-es'

export default {
  name: 'MsgLayout',
  props: {
    message: {
      type: Object
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    messageIconClass() {
      switch (this.message.status) {
        case 'unSend':
          return 'loading'
        case 'fail':
          return 'warning-filled'
        default:
          return ''
      }
    },
    isMine() {
      const { userID } = this.$store.state.tim.user
      return this.message.from === userID
    }
  },
  methods: {
    onSendAgain() {
      const copyMessage = cloneDeep(this.message)

      _tim
        .resendMessage(copyMessage)
        .then(() => {
          this.$store.commit('tim/currentSession/resendMsg', { message: {...copyMessage, ID: this.message.ID} })
        })
        .catch(error => {
          if (error.code == 10017) {
            this.$message.error('您被禁言，暂不能发送消息！')
          } else if (error.code === 10016) {
            this.$message.error('存在敏感词汇，消息发送失败')
          } else {
            this.$message.error(error)
          }
        })
    },
    addToBlackList(item) {
      _tim
        .addToBlacklist({ userIDList: [item.userID] })
        .then(() => {
          this.$store.dispatch('getBlacklist')
        })
        .catch(imError => {
          this.$store.commit('showMessage', {
            message: imError.message,
            type: 'error'
          })
        })
    },

    /**
     * 鼠标右击事件
     * 调用时机：点击鼠标右键
     * @param {Object} state
     * @param {Message[]|Message} data
     * @returns
     */
    rightEvent(event, message) {
      console.log(event, message)
      // emitter.emit('contextmenu', { ref: event, message })
    }
  }
}
</script>

<style lang='scss' scoped>
.msg-item {
  .msg-text {
    .item {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
    }
    .in {
      align-items: flex-start;
      .msg {
        order: -1;
      }
      .more-icon {
        margin-left: 5px;
      }
    }
    .out {
      align-items: flex-end;
      .user-info {
        order: -1;
        text-align: right;
      }
      .msg {
        order: 1;
      }
      .more-icon {
        margin-right: 5px;
      }
    }
    .auther {
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px;
      margin-bottom: 8px;
      .user-name {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0f0f0f;
        line-height: 14px;
      }
      .tag {
        font-size: 11px;
        zoom: 0.8;
        padding: 1px 8px;
        font-weight: 400;
        font-family: 'PingFangSC-Regular', 'PingFang SC';
        line-height: 11px;
        border-radius: 16px;
      }
      .lecturer-tag {
        color: #ff7600;
        background: rgba($color: #ff7600, $alpha: 0.2);
      }
      .speaker-tag {
        color: #fff;
        background-color: #a2b9ff;
      }
    }
    .msg-status {
      max-width: 80%;
      display: flex;
      align-items: center;
      position: relative;

      ::v-deep .msg {
        word-break: break-all;
        white-space: pre-wrap;
        border-radius: 5px;
        overflow: hidden;
      }
      .icon {
        border: 1px solid transparent;
        position: absolute;
        font-size: 25px;
        left: -30px;
      }

      .list-content {
        .more-item {
          line-height: 30px;
          transition: all 0.3s;
          :hover {
            cursor: pointer;
            background-color: #a5b5c1;
          }
        }
      }
      .error {
        color: red;
        cursor: pointer;
      }
    }
  }
}

.is-loading {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
