<template>
  <div style="height: 100%; display: flex; flex-direction: column;">
    <div class="add-aricle-box">
      <div class="left-box content-box">
        <el-row type="flex" justify="space-between" class="pdg-lr10 mgn-b10">
          <div class="col272e40 font-bold">文献</div>
          <span class="text-primary-link" @click="onCreate">新建文献</span>
        </el-row>
        <el-row type="flex" justify="end" class="pdg-lr10 mgn-b10">
          <el-input
            v-model="searchData.searchKey"
            placeholder="搜索内容"
            suffix-icon="el-icon-search"
            class="mgn-r10"
            @change="onSearchChange"
          />
          <el-select
            v-model="searchData.categoryId"
            placeholder="选择分类"
            @change="onSelectChange"
          >
            <el-option
              v-for="item in examClassifyList"
              :key="item.categoryId"
              :label="item.title"
              :value="item.categoryId"
            />
          </el-select>
        </el-row>

        <div class="content-box left-content" style="flex: 1;">
          <div v-if="!total > 0" class="col909399 text-ct pdg30">暂无数据</div>
          <div style="height: 100%;">
            <template v-for="(item, index) of sourceList">
              <el-row
                :key="index"
                type="flex"
                justify="space-between"
                align="middle"
                class="pdg-tb10 pdg-lr10"
                :class="index % 2 === 0 ? 'bgf6f7fb' : ''"
              >
                <div class="overflow-text grow-shrink0" style="width: 310px;">
                  <span>{{ item.title }}</span>
                  <span v-if="item.knowledgeType === 'PDF'">.PDF</span>
                </div>
                <el-row type="flex" justify="center" style="width: 45px;">
                  <span
                    v-if="isSelect(item.knowledgeId)"
                    style="color: #c8c9ce;"
                  >已选择</span>
                  <div v-else class="cursor" @click="onAdd(item)">
                    <i class="el-icon-circle-plus-outline opt-label" />
                  </div>
                </el-row>
              </el-row>
            </template>
          </div>
        </div>
      </div>
      <div class="right-box content-box">
        <el-row type="flex" justify="space-between" class="pdg-lr20 mgn-b10">
          <div class="col272e40 font-bold">当前选择文献：</div>
          <div class="col7784a1">请从左侧文献列表选择添加</div>
        </el-row>
        <div class="content-box check-box" style="flex: 1;">
          <div>
            <template v-for="(item, index) of targetList">
              <div
                :key="index"
                class="flex-between vertical-center pdg-tb10 pdg-lr20"
                :class="index % 2 === 0 ? 'bgf6f7fb' : ''"
              >
                <div class="overflow-text grow-shrink0" style="width: 310px;">
                  <span>{{ item.title }}</span>
                  <span v-if="item.knowledgeType === 'PDF'">.PDF</span>
                </div>
                <div class="cursor" @click="onDel(item.knowledgeId)">
                  <i class="el-icon-remove-outline opt-label" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      class="sorter-box"
      :current-page="searchData.pageNo"
      :page-size="searchData.pageSize"
      :total="total"
      :small="true"
      :pager-count="pagerCount"
      background
      hide-on-single-page
      layout="total, prev, pager, next, jumper"
      @current-change="onCurrentChange"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MaterialArticleChild',
  components: {},
  props: {
    selectList: {
      type: Array,
      default() {
        return []
      }
    },
    knowledgeType: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      // -------------------- submit ----------------
      btnLoading: false,
      // -------------------- 搜索、页数 -----------------
      pagerCount: 5,
      small: true,
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 10,
        type: 0,
        searchKey: '',
        categoryId: ''
      },
      // ---------------------- select -----------------
      examClassifyList: [],
      // --------------------- questionList ----------------------
      sourceList: [],
      targetList: []
    }
  },
  computed: {
    isSelect() {
      return currentId => {
        const targetSelect = [...this.targetList].map(v => v.knowledgeId)
        return targetSelect.indexOf(currentId) > -1
      }
    }
  },
  watch: {
    selectList(val) {
      if (val) {
        this.targetList = val
      }
    }
  },
  mounted() {
    this.initPage()
  },
  methods: {
    ...mapActions(['getArticleList', 'onGetCategoryList']),
    onClose() {
      this.$emit('onClose', false)
    },
    onCreate() {
      const routeUrl = this.$router.resolve('/material/knowledge/create')
      window.open(routeUrl.href, '_blank')
    },
    getValue() {
      return this.targetList
    },
    initPage() {
      this.searchData = {
        pageNo: 1,
        pageSize: 10,
        searchKey: '',
        categoryId: '',
        knowledgeType: this.knowledgeType || ''
      }
      this.sourceList = []
      this.targetList = this.selectList
      this.onGetArticleList()
      this.onGetExamCategoryList()
    },
    onGetArticleList() {
      this.getArticleList(this.searchData).then(
        ({ data: { data, totalCount } }) => {
          this.total = totalCount || 0
          this.sourceList = data || []
        }
      )
    },
    onSearchChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onGetArticleList()
    },
    onSelectChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onGetArticleList()
    },
    onGetExamCategoryList() {
      this.onGetCategoryList({ categoryType: 'knowledge' }).then(res => {
        const list = [
          {
            categoryId: '',
            title: '全部'
          }
        ]
        this.examClassifyList = list.concat(res.data || [])
      })
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
      this.onGetArticleList()
    },
    onAdd(item) {
      this.targetList = [...this.targetList, item]
    },
    onDel(currentId) {
      this.targetList = [...this.targetList].filter(
        v => v.knowledgeId !== currentId
      )
    }
  }
}
</script>

<style lang='scss' scoped>
.add-aricle-box {
  flex: 1;
  display: flex;
  overflow: hidden;
}
.opt-label {
  color: #3d61e3;
  font-size: 20px;
}
.item-box {
  width: 490px;
  height: 640px;
}
.left-box {
  width: 50%;
  border-right: 1px solid #e9eaeb;
}
.right-box {
  width: 50%;
}
.content-box {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}
.left-content {
  overflow-y: auto;
}
.check-box {
  overflow-y: auto;
}
.sorter-box {
  padding: 10px;
  text-align: right;
  background: #fff;
}
</style>
