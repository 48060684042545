<template>
  <el-dialog
    v-if="!isChangeModal"
    id="tag-dialog"
    :visible="visible"
    title="选择标签"
    class="customize-el-dialog"
    :show-close="false"
    destroy-on-close
  >
    <el-form :model="form" inline @submit.native.prevent>
      <el-form-item>
        <el-input
          v-model="form.title"
          prefix-icon="el-icon-search"
          clearable
          class="input"
        />
      </el-form-item>
    </el-form>
    <div class="scroll-wrapper">
      <el-row
        v-for="vo in tagGroupList"
        :key="vo.id"
        type="flex"
        class="scene-tag-wrapper"
      >
        <div class="tab-wrapper">
          <div><span v-if="vo.groupCode === 'PRODUCT_TAG_GROUP'" style="color: red;">*</span>{{ vo.groupName }}</div>
          <div>{{ vo.groupCode === "PRODUCT_TAG_GROUP" ? '（单选）':'' }}</div>
        </div>
        <div class="content-wrapper">
          <template v-for="item in tagList">
            <!-- 单选 -->
            <el-radio
              v-if="vo.groupCode === 'PRODUCT_TAG_GROUP' && item.groupId === vo.id"
              :key="item.tagId"
              v-model="selectedMap[`${item.groupId}_value`]"
              :label="`${item.tagId}_${item.groupId}`"
              :disabled="!isApprove&&((disabledList.indexOf(item.tagId)>-1) || (disabledGroup.indexOf(vo.id) > -1 && vo.groupCode === 'PRODUCT_TAG_GROUP'))"
              class="checkbox-box-reset"
              @change="onChange(item, vo.groupCode, true)"
            >
              <span v-highlight="{ hWord: form.title.replace(/ /g, '|'), word: item.tagName, style: highlightStyle }" />
            </el-radio>
            <!-- 多选 -->
            <el-checkbox
              v-else-if="item.groupId === vo.id"
              :key="item.groupId + item.tagId"
              v-model="selectedMap[`${item.tagId}_${vo.id}`]"
              :disabled="(disabledList.indexOf(item.tagId)>-1) || (disabledGroup.indexOf(vo.id) > -1 && vo.groupCode === 'PRODUCT_TAG_GROUP')"
              class="checkbox-box-reset"
              @change="onChange(item, vo.groupCode)"
            >
              <span v-highlight="{ hWord: form.title.replace(/ /g, '|'), word: item.tagName, style:highlightStyle }" />
            </el-checkbox>
          </template>
          <div v-if="vo.groupCode === 'PRODUCT_TAG_GROUP' && (!(tagList.length > 0) || list1.length === 0)">暂无产品标签</div>
          <div v-if="vo.groupCode === 'SPREAD_DEPARTMENT_GROUP' && (!(tagList.length > 0) || list2.length === 0)">暂无科室标签</div>
          <div v-if="vo.groupCode === 'CUSTOMIZE_TAG_GROUP' && (!(tagList.length > 0) || list3.length === 0)">暂无自定义标签</div>
        </div>
      </el-row>
      <div class="remark">备注：若需使用更多标签，可<span class="click-btn-style is-pointor" @click="onOpenEditTagModal">点击此处</span></div>
    </div>
    <el-row type="flex" justify="center" class="footer-warpper">
      <el-button plain @click="onClose">关闭</el-button>
      <el-button type="primary" :loading="loading" @click="onSubmit">保存</el-button>
    </el-row>
  </el-dialog>
  <div v-else>
    <EditSceneTagModal
      :visible.sync="showSceneTagModal"
      :active-tab-name.sync="activeTabName"
      @close="onClose"
      @onClose="onClose"
    />
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import EditSceneTagModal from '@/bizComponents/EditSceneTagModal'

export default {
  name: 'SelectSceneTagModal',
  components: {
    EditSceneTagModal
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    targetList: {
      type: Array,
      default() {
        return []
      }
    },
    isApprove: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    enable: {
      type: Boolean,
      default: true
    },
    max: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      currentSelectTagId: '',
      defaultGroupCode: 'PRODUCT_TAG_GROUP',
      tagList: [],
      selectedMap: {},
      isChangeModal: false,
      showSceneTagModal: false,
      activeTabName: '',
      list1: [],
      list2: [],
      list3: [],
      form: {
        title: ''
      },
      highlightStyle: 'background: transparent;color: #ff9941'
    }
  },
  computed: {
    ...mapGetters(['tagGroupList', 'userInfo']),
    selectLimit() {
      return code => {
        return code === 'PRODUCT_TAG_GROUP' ? 1 : this.max
      }
    },
    disabledList() {
      return [...new Set([...this.$props.targetList].filter(k => k.disabled).map(v => v.tagId))]
    },
    disabledGroup() {
      if (!this.enable) return []
      return [...new Set([...this.$props.targetList].filter(k => k.disabled).map(v => v.groupId))]
    }
  },
  watch: {
    visible(isOpen) {
      if (isOpen) {
        this.targetList.map(v => {
          this.selectedMap = {...this.selectedMap, [`${v.tagId}_${v.groupId}`]: true, [`${v.groupId}_value`]: `${v.tagId}_${v.groupId}`}
        })
        this.getTagGroup().then(() => {
          this.tagList = []
          this.tagGroupList.forEach(item => {
            this.getTagList(item.id)
          })
        })
      } else {
        this.selectedMap = {}
      }
    },
    tagList: {
      deep: true,
      handler() {
        this.list1 = []
        this.list2 = []
        this.list3 = []
        this.tagList.map(item => {
          if (item.groupName === '产品标签') {
            this.list1.push(item)
          } else if (item.groupName === '推广科室') {
            this.list2.push(item)
          } else if (item.groupName === '自定义标签') {
            this.list3.push(item)
          }
        })
        console.log(this.list1)
      }
    }
  },
  methods: {
    ...mapActions(['getTagGroup']),
    // 获取自己使用的标签
    async getTagList(tagGroupId) {
      try {
        const params = {tagGroupId}
        const {data} = await this.$axios.get(this.$API.tagList, {params})
        this.tagList = [...this.tagList, ...data]
      } catch (error) {
        console.log(error)
      }
    },
    onChange(v, code, isSingle = false) {
      const keys = Object.keys(this.selectedMap)
      let keyItem = {}
      keys.map(k => {
        const key = k.indexOf('_value') > 0 ? `${v.tagId}_${v.groupId}` : k
        if (this.selectLimit(code) === 1 && k.indexOf(v.groupId) > -1) {
          // 单选
          k.indexOf('_value') > 0 && (keyItem = {[key]: true})
          this.selectedMap = {...this.selectedMap, [key]: k.indexOf('_value') > 0}
        }
      })
      if (isSingle) {
      // 单选
        this.selectedMap = {...this.selectedMap, ...keyItem}
      }
    },
    onClose() {
      this.isChangeModal = false
      this.showSceneTagModal = false
      this.$emit('onClose')
    },
    onCheckReq() {
      for (const item of Object.keys(this.selectedMap)) {
        if (item.indexOf(this.tagGroupList[0].id) > 0 && this.selectedMap[item]) {
          return false
        }
      }
      return true
    },
    onOpenEditTagModal() {
      this.isChangeModal = true
      this.$nextTick(() => {
        this.showSceneTagModal = true
      })
    },
    onSubmit() {
      if (this.onCheckReq()) {
        return this.$message.error('请选择产品标签')
      }
      const selectedList = [...this.tagList].filter(v => {
        return this.selectedMap[`${v.tagId}_${v.groupId}`] && this.disabledList.indexOf(v.tagId) < 0
      })
      const disabledList = [...this.$props.targetList].filter(c => c.disabled)
      this.$emit('onSubmit', selectedList, disabledList)
    }
  }
}
</script>
<style lang="scss" scoped>
#tag-dialog ::v-deep {
  .el-dialog__body {
    padding: 20px;
  }
}
.footer-warpper {
  padding-top: 10px;
  .el-button {
    min-width: 90px;
  }
}
.scroll-wrapper {
  height: 310px;
  overflow-y: auto;
  .remark {
    position: sticky;
    bottom: 0;
    background-color: #fff;
  }
}
.input {
  width: 200px;
  ::v-deep .el-input__inner {
    border-radius: 20px;
  }
}
.checkbox-box-reset {
  margin-right: 20px;
  margin-bottom: 10px;
}
.scene-tag-wrapper {
  border: 1px solid rgb(233, 234, 235);
  margin-bottom: 15px;
}
.tab-wrapper {
  background-color: rgb(237, 238, 241);
  min-width: 100px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(8, 9, 34);
  padding: 12px;
}
.content-wrapper {
  padding: 20px 25px;
  flex: 1;
  background-color: #fff;
}
.click-btn-style {
  color: #3d61e3;
}
</style>
