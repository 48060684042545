<template>
  <el-dialog
    title="观看链接"
    :visible.sync="dialogVisible"
    width="300px"
    class="dialog"
  >
    <div class="content">
      <span>手机观看二维码</span>
      <div class="qrcode">
        <img :src="qrcodeUrl" alt="二维码">
      </div>
      <el-link type="primary" @click="onCopy">复制观看链接</el-link>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'QRCodeModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    },
    qrCode: {
      type: String,
      default: ''
    }
  },
  computed: {
    qrcodeUrl() {
      return this.qrCode || ''
    },
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('onClose', val)
      }
    }
  },
  methods: {
    onCopy() {
      this.$copyText(this.url).then(() => {
        this.$message.success('复制成功')
      }, () => {
        this.$message.error('复制失败')
      })
    }
  }

}
</script>
<style lang="scss" scoped>
.dialog {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  .qrcode {
    width: 200px;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

</style>
