<template>
  <el-form-item v-if="formData.strategyName || formData.strategyId" :label="isNeedProject?'是否参与项目':'策略名称'" class="strategy-project-form-item">
    【策略】{{ formData.strategyName }}
    <div v-if="projectList.length">
      <el-checkbox-group :disabled="disabled" :value="[formData.projectId]" @input="handleInput">
        <el-checkbox v-for="item in projectList" :key="item.projectId" :label="item.projectId">{{ item.projectName }}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div v-else-if="isNeedProject" style="color: #caccd0;">暂无项目</div>
  </el-form-item>
</template>

<script>
export default {
  name: 'StrategyProjectFormItem',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isNeedProject: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      projectList: []
    }
  },
  computed: {
    formData: {
      get() {
        return this.form
      },
      set(val) {
        this.$emit('update:form', val)
      }
    }
  },
  watch: {
    'formData.strategyId': {
      handler(newVal, oldVal) {
        if (newVal && oldVal && newVal !== oldVal) {
          this.$set(this.formData, 'projectId', '')
        }
        if (newVal !== oldVal && this.isNeedProject) {
          this.getProjectList()
        }
      },
      deep: true
    }
  },
  methods: {
    async getProjectList() {
      try {
        const params = {
          projectStatus: 1,
          pageNo: 1,
          pageSize: 999999,
          strategyIds: [this.formData.strategyId],
          isQueryExecOrgProject: this.isNeedProject
        }
        const {
          data: { data }
        } = await this.$axios.post(this.$API.projectList, params)
        this.projectList = data || []
        const flag = data.some(item => item.projectId === this.formData.projectId)
        console.log('flag: ', flag)
        if (!flag) {
          this.$set(this.formData, 'projectId', '')
          this.$set(this.formData, 'projectName', '')
        }
      } catch (error) {
        console.log(error)
        this.projectList = []
        this.$msg.error(error?.message)
      }
    },
    handleInput(val) {
      if (this.formData.projectId === val) {
        this.$set(this.formData, 'projectId', '')
      } else {
        this.$set(this.formData, 'projectId', val.pop())
      }
      this.$emit('projectChange', this.formData.projectId)
    }
  }
}
</script>
