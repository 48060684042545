<!--
 * @Author: your name
 * @Date: 2021-08-03 11:08:51
 * @LastEditTime: 2021-08-18 18:08:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/academic/child/SceneAllotForDoc.vue
-->
<template>
  <div class="allot-card-wrapper" :class="{'is-open':isOpen}">
    <el-row
      class="allot-card-header"
      type="flex"
      justify="space-between"
      align="middle"
    />
    <div class="allot-card-body">
      <el-table
        :data="doctorList"
        row-key="factoryId"
        style="width: 100%;"
        :max-height="scrollHeight"
      >
        <el-table-column
          prop="doctorName"
          label="医生姓名"
        />
        <el-table-column
          prop="doctorCode"
          label="医生编码"
          align="center"
        >
          <template slot-scope="scope">
            <span> {{ scope.row.doctorCode || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="职称"
          align="center"
        >
          <template slot-scope="scope">
            <span> {{ scope.row.title || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="医院"
          width="200"
          prop="hospital"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span> {{ scope.row.hospital || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="department"
          label="科室"
          align="center"
        >
          <template slot-scope="scope">
            <span> {{ scope.row.department || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="repName"
          label="绑定代表"
          align="center"
        />
        <el-table-column
          prop="orgName"
          label="所属组织"
          width="200"
          align="center"
        />
        <el-table-column
          width="100"
          label="操作"
        >
          <template slot-scope="scope">
            <span class="text-primary-link" @click="onDelDoc(scope.row.doctorId)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SceneAllotForDocItem',
  props: {
    codeMoney: {
      type: Number,
      default: 10
    },
    doctorList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isOpen: true
    }
  },
  computed: {
    scrollHeight() {
      return this.isOpen ? 400 : 0
    },
    totalCost() {
      return this.doctorList.length * this.codeMoney
    }
  },
  methods: {
    onDelDoc(currentId) {
      this.$emit('onDelDoc', currentId)
    }
  }
}
</script>
<style lang="scss" scoped>
  .allot-card-wrapper {
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #ebeef5;
    margin-bottom: 20px;
    .allot-card-header {
      background: #eee;
      font-size: 14px;
    }
    .label-wrapper {
      font-size: 24px;
      font-weight: bold;
      margin-left: 25px;
      cursor: pointer;
      transition: ease 250ms;
      transform: rotate(0deg);
      &.is-rotate {
        transform: rotate(180deg);
      }
    }
  }
</style>
