<template>
  <div class="report-preview">
    <PageMain title="数据报告" back>
      <iframe
        class="report-iframe"
        allow
        :style="`min-height: 90vh;height: ${iframeHeight}`"
        :src="src"
        frameborder="0"
      />
    </PageMain>
  </div>
</template>

<script>
import {panguUrl} from '@/utils/domain-yundata.js'
export default {
  name: 'ReportPreview',
  data() {
    return {
      panguUrl,
      iframeHeight: '90vh'
    }
  },
  computed: {
    src() {
      const query = {
        token: this.$store.state.user.userInfo.token,
        ...this.$route.query
      }
      return this.addQuery(`${panguUrl}/report/preview`, query)
    }
  },
  mounted() {
    // 父窗口
    window.addEventListener('message', evt => {
      // 监听子窗口的postMessage
      if (evt.data.type === 'refreshReport') {
        const { height } = evt.data.query
        this.iframeHeight = `${Math.ceil(height)}px`
        const iframe = document.querySelector('.report-iframe')
        const { name, login } = this.$store.state.user.userInfo
        const res = name + login
        iframe.contentWindow.postMessage({
          command: 'setReport',
          query: {
            sourceOrg: this.$store.state.user.userInfo.orgName,
            markOpt: {
              watermark_txt: res
            }
          }
        }, '*')
      }
    })
  },
  methods: {
    addQuery(url, query) {
      const params = []
      for (const key in query) {
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
      }
      return `${url}?${params.join('&')}`
    }
  }
}
</script>

<style lang="scss" scoped>
.report-preview {
  .report-iframe {
    width: 100%;
  }
}
</style>
