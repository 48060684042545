<!---- Emoji 表情 ----->
<template>
  <div class="emoji-wrapper">
    <div
      v-for="item in emojiName"
      :key="item"
      class="emoji"
      @click="$emit('chooseEmoji', item)"
    >
      <img :src="emojiUrl + emojiMap[item]" style="width:30px;height:30px">
    </div>
  </div>
</template>

<script>
import { emojiArr } from './emoji.json'
import { emojiMap, emojiName, emojiUrl } from './emojiMap'

export default {
  name: 'Emoji',
  data() {
    return {
      emojiArr,
      emojiName,
      emojiMap,
      emojiUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.emoji-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25px, 30px));
  gap: 5px;
  .emoji-item {
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s;
    &:hover {
      transform-origin: center center;
      transform: scale(1.3);
    }
    & ::v-deep img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding: 3px;
      zoom: 1.2;
      transform: scale(1.2);
    }
  }
  .emoji {
    border-radius: 4px;
    transition: all 0.3s;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #ddd;
      cursor: pointer;
    }
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
