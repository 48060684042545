<template>
  <div class="video-clip" @mouseup="mouseup">
    <div class="title">当前预览：{{ currentItem.title }}</div>
    <div id="clipdplayer" />
    <!-- <video id="video-clip" ref="video-clip" controls :src="currentItem.previewVo.mp4.sd" @mouseup="mouseup" /> -->

    <div class="controls">
      <p v-if="startShowDisabled" title="双击编辑时间" @dblclick="onDblclick('start')">{{ timeToMinute(startTime) }}</p>
      <ksh-input
        v-else
        ref="timeInput"
        v-model="startStr"
        :maxlength="8"
      />
      <div ref="progress-bar" class="progress-bar">
        <!-- 未选中的样式 -->
        <div
          v-for="(item, index) in deleteList"
          :key="index"
          class="del-wrap-bg"
          :style="`left: ${secondsToPixels(item.startTime)}px;z-index: ${current.zIndex - 1}`"
        >
          <div class="del-fragment" :style="`width: ${secondsToPixels(item.endTime) - secondsToPixels(item.startTime)}px`" />
        </div>
        <div ref="start-bar" :style="startStyle">
          <p
            class="start-bar"
            title="单击光标后，可以使用键盘左右键微调"
            :style="startBarStyle"
            tabindex="1"
            @keydown.left.right="e => keyDown(e, 'start')"
            @mousedown="mousedown($event, 'start')"
          />
        </div>
        <div ref="range" class="range" />
        <div ref="end-bar" :style="endStyle">
          <p
            class="end-bar"
            title="单击光标后，可以使用键盘左右键微调"
            tabindex="1"
            :style="endBarStyle"
            @keydown.left.right="e => keyDown(e, 'end')"
            @mousedown="mousedown($event, 'end')"
          />
        </div>
        <span :style="`left: ${secondsToPixels(current.durationNum) > parentDOMWidth ? parentDOMWidth : secondsToPixels(current.durationNum)}px`" class="current-progress" />
      </div>
      <p v-if="endShowDisabled" title="双击编辑时间" @dblclick="onDblclick('end')">{{ timeToMinute(endTime) }}</p>
      <ksh-input
        v-else
        ref="timeInput"
        v-model="endStr"
        :maxlength="8"
      />
    </div>

    <div class="btn-wrap">
      <p>
        <ksh-button plain :disabled="totalDuration === 0" @click="onPlaySelected">播放选中片段</ksh-button>
        <!-- <ksh-button plain @click="onDelSelected">删除选中片段</ksh-button> -->
        <ksh-button
          slot="reference"
          :disabled="totalDuration === 0"
          plain
          @click="onDelNoSelected"
        >
          删除未选中片段
        </ksh-button>
      </p>
      <ksh-button plain @click="onReset">重新剪辑</ksh-button>
    </div>
  </div>
</template>

<script>
import DPlayer from 'dplayer'

import chartResize from '@/mixins/chartResize'
import moment from 'moment'
export default {
  name: 'VideoClip',
  mixins: [chartResize],
  props: {
    currentItem: {
      type: Object,
      default: Object
    },
    minSecond: {
      type: Number,
      defualt: 20
    }
  },
  data() {
    return {
      dp: null,
      startStr: '',
      endStr: '',
      startShowDisabled: true,
      endShowDisabled: true,
      dblclickType: '',

      parentDOMWidth: 0,
      totalDuration: 0,
      startTime: 0,
      endTime: 0,
      current: {
        durationNum: 0,
        type: 'start',
        oldScreenX: 0,
        zIndex: 2
      },
      interceptList: [],
      playSelect: true,
      delSelect: false
    }
  },
  computed: {
    progressBarDOM() {
      return this.$refs['progress-bar']
    },
    startBarDOM() {
      return this.$refs['start-bar']
    },
    endBarDOM() {
      return this.$refs['end-bar']
    },
    rangeDOM() {
      return this.$refs['range']
    },
    startStyle() {
      const moveX = this.secondsToPixels(this.startTime) || 0
      return `width:${moveX}px;background-color:rgba(${this.delSelect ? '238,46,39' : ''}, 0.2);`
    },
    endStyle() {
      const moveX = this.parentDOMWidth - this.secondsToPixels(this.endTime) || 0
      return `width: ${moveX}px;background-color:rgba(${this.delSelect ? '238,46,39' : ''}, 0.12);`
    },
    startBarStyle() {
      const { zIndex, type } = this.current
      const moveX = this.secondsToPixels(this.startTime) || 0
      return `left:${moveX - 6}px;z-index: ${zIndex + (type === 'start' ? 1 : 0)}`
    },
    endBarStyle() {
      const moveX = this.secondsToPixels(this.endTime) || 0
      const { zIndex, type } = this.current
      return `left:${moveX - 6}px;z-index: ${zIndex + (type === 'end' ? 1 : 0)}`
    },
    deleteList() {
      const arr = this.interceptList.map(item => {
        return item.delList.map(item => {
          return item
        })
      })
      return arr.flat()
    }
  },
  watch: {
    currentItem: {
      handler: function(newVal) {
        this.startTime = (newVal.fragment && newVal.fragment[0]?.startTime) || 0
        this.endTime = (newVal.fragment && newVal.fragment[0]?.endTime) || newVal.duration
        this.current = this.$options.data().current

        const { hd, sd } = newVal.previewVo.mp4
        this.dp.switchVideo({ url: hd || sd })

        this.interceptList = newVal.fragment || []
      },
      deep: true
    }
  },
  mounted() {
    const { hd, sd } = this.currentItem.previewVo.mp4
    this.dp = new DPlayer({
      container: document.getElementById('clipdplayer'),
      video: {
        url: hd || sd
      }
    })

    this.parentDOMWidth = this.progressBarDOM.offsetWidth
    this.endTime = this.currentItem.duration
    this.registerEvent()

    document.addEventListener('click', this.bodyClick, true)
  },
  destroyed() {
    this.dp.destroy()
    document.removeEventListener('click', this.bodyClick, true)
  },
  methods: {
    // 监听屏幕变化
    chartResize() {
      this.parentDOMWidth = this.progressBarDOM.offsetWidth
    },
    bodyClick(e) {
      const tree = this.$refs.timeInput
      if (e.target !== tree?.$el.lastElementChild && this.dblclickType) {
        if (!this[`${this.dblclickType}ShowDisabled`]) {
          this[`${this.dblclickType}ShowDisabled`] = true
          const second = moment.duration(this[`${this.dblclickType}Str`]).as('seconds')

          if (!this.regTime(this[`${this.dblclickType}Str`])) {
            this.$message.error('格式错误')
            return
          }
          if (this.dblclickType === 'start') {
            if (!(second >= 0 && second <= this.endTime)) {
              this.$message.error('输入时间超出范围')
              return
            }
          } else if (this.dblclickType === 'end') {
            if (!(second >= this.startTime && second <= this.totalDuration)) {
              this.$message.error('输入时间超出范围')
              return
            }
          }

          this[`${this.dblclickType}Time`] = second
        }
      }
    },
    onReset() {
      this.startTime = 0
      this.endTime = this.totalDuration
      this.current.durationNum = this.startTime
      this.interceptList = []
      this.dp.seek(this.startTime)
      this.dp.pause()
      this.$emit('change', this.interceptList)
    },
    /**
         * 删除选中片段
         * 调用时机：点击选中删除片段
         */
    onDelSelected() {
      // this.$emit('change', this.interceptList)
    },
    // start 双击事件
    onDblclick(type) {
      this[`${type}ShowDisabled`] = false
      this[`${type}Str`] = this.timeToMinute(this[`${type}Time`])
      this.dblclickType = type
    },
    /**
     * 删除未选中内容
     * 调用时机：点击删除未选中片段
     */
    async onDelNoSelected() {
      if (this.startTime > this.endTime) return
      // if (this.interceptList.length !== 0) {
      //     try {
      //         await MessageBox.confirm('该操作与其他视频片段有冲突，继续操作会清除之前的内容, 是否继续?', '提示', {
      //             confirmButtonText: '确定',
      //             cancelButtonText: '取消',
      //             type: 'warning'
      //         })
      this.interceptList = this.$options.data().interceptList
      //     } catch (error) {
      //         return
      //     }
      // }
      // 保留的时间段
      this.interceptList.push({
        startTime: this.startTime,
        endTime: this.endTime,
        delList: [
          {
            startTime: 0,
            endTime: this.startTime
          },
          {
            startTime: this.endTime,
            endTime: this.totalDuration
          }
        ]
      })
      this.$emit('change', this.interceptList)
    },
    /**
     * 播放选中片段
     * 调用时机：点击播放选中片段按钮
     */
    onPlaySelected(e, startTime = this.startTime) {
      this.playSelect = true
      this.dp.seek(startTime)
      this.dp.play()
    },
    /**
     * 获取视频时长
     * 调用时机：视频资源准备好、开始播放
     * @returns
     */
    canplay() {
      // 使用接口返回的视频时长
      this.totalDuration = this.currentItem.duration
    },
    /**
         * 获取当前播放时长、光标移动位置
         * 调用时机：播放监听
         */
    timeupdate() {
      const { currentTime = 0 } = this.dp.video
      this.current.durationNum = currentTime

      // 跳过删除片段
      this.interceptList.delList?.some(item => {
        if (item.startTime < currentTime && item.endTime > currentTime) {
          this.dp.seek(item.endTime)
          return true
        }
      })
      // 选中片段播放结束
      if (this.playSelect && this.endTime <= currentTime) {
        this.dp.pause()
        this.playSelect = false
      }
    },
    /**
         * 移动 cursor-bar
         * 调用时机：点击 左右 方向键时执行
         * @param {Object} event
         */
    keyDown(e, type = this.current.type) {
      // 添加最小限制
      if (this.startTime - this.endTime === -1) {
        if (type === 'start') {
          if (e.key === 'ArrowLeft') {
            this[`${type}Time`]--
          }
        } else if (type === 'end') {
          if (e.key === 'ArrowRight') {
            this[`${type}Time`]++
          }
        }
        return
      }

      if (e.key === 'ArrowLeft') {
        if (this[`${type}Time`] <= 0) return
        this[`${type}Time`]--
      } else if (e.key === 'ArrowRight') {
        if (this[`${type}Time`] >= this.totalDuration) return
        this[`${type}Time`]++
      }
    },
    mouseup() {
      this.current.type = ''
    },
    /**
     * 收集鼠标按下时 位置 绑定 move 事件
     * 调用时机：鼠标按下
     * @param {Object} event
     * @param {Stirng} type
     * @returns false
     */
    mousedown(event, type) {
      // 按下时到父元素的距离 + 移动的距离
      this.current.type = type
      const downX = event.screenX
      const offsetWidth = this[`${type}BarDOM`].offsetWidth

      document.onmousemove = e => {
        // 判断移动方向
        if (e.screenX - this.current.oldScreenX > 0) {
          this.current.direction = 'right'
        } else if (e.screenX - this.current.oldScreenX < 0) {
          this.current.direction = 'left'
        }
        this.current.oldScreenX = e.screenX

        // 处理移动距离
        const offsetX = e.screenX - downX
        const X = type === 'start' ? offsetX + offsetWidth : offsetWidth - offsetX

        if (type === 'start') {
          this[`${type}Time`] = this.pixelsToSeconds(type, X)
        } else if (type === 'end') {
          this[`${type}Time`] = this.totalDuration - this.pixelsToSeconds(type, X)
        }
      }

      document.onmouseup = () => {
        document.onmousemove = null
        document.onmouseup = null
      }
      return false
    },
    compare(p) {
      // 这是比较函数
      return function(m, n) {
        const a = m[p]
        const b = n[p]
        return a - b // 升序
      }
    },
    /**
         * 注册监听事件
         * 调用时机：初始化页面
         */
    registerEvent() {
      // 视频可以播放触发得事件 一般是视频准备就绪触发得事件
      this.dp.on('canplay', this.canplay)

      // 视频当前播放事件发生变化 触发事件
      this.dp.on('timeupdate', this.timeupdate)
    },
    // 像素转秒
    pixelsToSeconds(type, pixels) {
      const totalPixelsWidth = this.progressBarDOM.offsetWidth || this.parentDOMWidth
      let seconds = Math.round((pixels / totalPixelsWidth) * this.totalDuration || 0)
      // 处理边界情况
      if (type === 'start') {
        if (seconds <= 0) {
          seconds = 0
        } else if (seconds >= this.endTime - 1) {
          seconds = this.endTime - 1
        }
      } else if (type === 'end') {
        if (seconds >= this.totalDuration - this.startTime - 1) {
          seconds = this.totalDuration - this.startTime - 1
        } else if (seconds >= this.totalDuration) {
          seconds = this.totalDuration
        }
      }
      return seconds <= 0 ? 0 : seconds
    },
    // 秒转像素
    secondsToPixels(seconds) {
      const totalPixelsWidth = this.parentDOMWidth
      return Math.round((seconds / this.totalDuration) * totalPixelsWidth)
    },
    regTime(text) {
      const reg = /^(0\d{1}|1\d{1}|2[0-3]):[0-5]\d{1}:([0-5]\d{1})$/
      return reg.test(text)
    },
    // 秒转换分钟00:00:00格式
    timeToMinute(times) {
      let t
      if (times > -1) {
        const hour = Math.floor(times / 3600)
        const min = Math.floor(times / 60) % 60
        const sec = times % 60
        t = hour < 10 ? `0${hour}:` : `${hour}:`

        if (min < 10) {
          t += '0'
        }
        t += `${min}:`
        if (sec < 10) {
          t += '0'
        }
        t += sec.toFixed(2)
      }
      t = t.substring(0, t.length - 3)
      return t
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.video-clip {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-left: 1px solid #eee;
  padding-left: 16px;
  .title {
    font-size: 14px;
    font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
    color: #5a5a5a;
    line-height: 14px;
    margin-bottom: 13px;
  }
  #clipdplayer {
    min-width: 400px;
    height: 400px;
    background-color: #000;
    ::v-deep {
      .dplayer-menu-show,
      .dplayer-setting-loop {
        display: none !important;
      }
    }
  }
  .scroll-bar {
    box-sizing: border-box;
  }
  .btn-wrap {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .controls {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    height: 30px;
    margin-top: 15px;
    > p,
    .el-input {
      ::v-deep .el-input__inner {
        width: 100px;
        height: 30px;
        text-align: center;
      }

      width: 100px;
      height: 30px;
      line-height: 30px;
      border: 1px solid #eee;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
    .progress-bar {
      flex: 1;
      margin: 0 8px;
      background-color: #f5f5f5;
      position: relative;
      display: flex;
      -moz-user-select: none; /* 火狐 */
      -webkit-user-select: none; /* webkit浏览器 */
      -ms-user-select: none; /* IE10 */
      -khtml-user-select: none; /* 早期浏览器 */
      user-select: none;
      .current-progress {
        position: absolute;
        width: 1px;
        height: 100%;
        z-index: 1;
        background-color: #3d61e3;
      }
      .del-wrap-bg {
        position: absolute;
        height: 100%;
        background-color: #f5f5f5;
        .del-fragment {
          height: 100%;
          background-color: rgba(238, 46, 39, 0.2);
        }
      }
      > div {
        flex-shrink: 0;
      }
      .start-bar {
        position: absolute;
        width: 12px;
        height: 15px;
        top: -8px;
        background-color: #3d61e3;
        cursor: col-resize;
        &:hover,
        &:active {
          cursor: col-resize;
        }
        &::before {
          content: '';
          position: absolute;
          top: 11px;
          left: 2px;
          width: 8px;
          height: 8px;
          background-color: #3d61e3;
          transform: rotate(45deg);
          transform-origin: center center;
        }
        &::after {
          content: '';
          position: absolute;
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
          width: 2px;
          height: 18px;
          background-color: #3d61e3;
        }
      }
      .range {
        flex: 1;
        overflow: hidden;
        background-color: rgba($color: #3d61e3, $alpha: 0.12);
      }
      .end-bar {
        flex-shrink: 0;
        position: absolute;
        width: 12px;
        height: 15px;
        top: -8px;
        background-color: #3d61e3;
        cursor: col-resize;
        &:hover,
        &:active {
          cursor: col-resize;
        }
        &::before {
          content: '';
          position: absolute;
          top: 11px;
          left: 2px;
          width: 8px;
          height: 8px;
          background-color: #3d61e3;
          transform: rotate(45deg);
          transform-origin: center center;
        }
        &::after {
          content: '';
          position: absolute;
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
          width: 2px;
          height: 18px;
          background-color: #3d61e3;
        }
      }
    }
  }
}
</style>
