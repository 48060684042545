<template>
  <div class="packet">
    <div class="search-packet">
      <div>
        <!-- :disabled="!!redPacketId" -->
        <template v-auth="['createLiveRedEnvelope']">
          <!-- <el-button plain @click="dialogVisible = true"> 普通积分红包 </el-button> -->
          <el-button plain @click="conditionaldialogVisible = true">发送积分红包</el-button>
        </template>
        <el-button plain @click="detaildialogVisible = true">当前红包详情</el-button>
        <el-button type="text" @click="onExport">导出红包记录</el-button>
      </div>
      <div class="right">
        <el-input
          v-model="search.keyWord"
          size="small"
          prefix-icon="el-icon-search"
          placeholder="搜索医生姓名或医院名称"
          @keyup.enter.native="onSearch"
        />
        <!-- <el-button type="text" @click="123">导出记录</el-button> -->
      </div>
    </div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%;">
      <el-table-column prop="name" width="180" label="医生" />
      <el-table-column prop="title">
        <template #header>
          <el-select
            v-model="search.title"
            style="width: 120px;"
            size="small"
            placeholder="请选择"
            @change="onSearch"
          >
            <el-option label="所有职称" value="" />
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="hospital" label="医院" />
      <el-table-column prop="" label="红包类型">
        <template slot-scope="scoped">
          {{ scoped.row.packetType | type(scoped.row.distributeType) }}
        </template>
      </el-table-column>
      <el-table-column prop="amount" width="180" label="领取红包积分">
        <template slot-scope="scoped"> {{ scoped.row.amount }}积分 </template>
      </el-table-column>
      <el-table-column prop="receiveTime" width="180" label="领取时间" />
    </el-table>
    <el-pagination
      class="pagination"
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />

    <!-- 发普通红包弹窗 -->
    <packet-send-ordinary v-model="dialogVisible" :get-packet-time="getPacketTime" />
    <!-- 发条件红包弹窗 -->
    <packet-send-conditional v-model="conditionaldialogVisible" :get-packet-time="getPacketTime" />
    <!-- 红包详情 -->
    <packet-details v-model="detaildialogVisible" :red-packet-id="redPacketId" :get-packet-time="getPacketTime" />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import { getLivePacketList } from '@/services/liveService'

import PacketDetails from './packet-dialog/packet-details.vue'
import PacketSendOrdinary from './packet-dialog/packet-send-ordinary.vue'
import PacketSendConditional from './packet-dialog/packet-send-conditional'

// import { JsonToExcel } from '@/utils/util'
export default {
  name: 'Packet',
  components: { PacketDetails, PacketSendOrdinary, PacketSendConditional },
  filters: {
    type(packetType, distributeType) {
      switch (packetType) {
        case 0:
          return distributeType === 0 ? '普通积分红包' : '随机手气红包'
        case 1:
          return '答题红包'
        case 2:
          return '观看时长限制红包'
        default:
          return '普通红包'
      }
    }
  },
  mixins: [paginationMixin],
  props: {
    options: {
      type: Array,
      default: Array
    },
    redPacketId: {
      type: String,
      default: ''
    },
    getPacketTime: {
      type: Function
    },
    liveTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      detaildialogVisible: false,
      answerdialogVisible: false,
      packetAnswerdialogData: {},
      conditionaldialogVisible: false,
      search: {
        liveId: '',
        pageNo: 1,
        pageSize: 10,
        keyWord: '',
        title: ''
      }
    }
  },
  mounted() {
    this.search.liveId = this.$route.params.liveId

    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.loading = true
      try {
        const params = this.getParams(this.search)
        const {
          data: { data, totalCount }
        } = await getLivePacketList(params)
        this.tableData = data || []
        this.pagination.total = totalCount || 0
        this.loading = false
        this.getPacketTime()
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$message({
          type: 'error',
          message: error.message
        })
      }
    },
    onSearch() {
      this.search.pageNo = 1
      this.getDataList()
    },
    /**
     * 导出表格
     * 调用时机：点击导出
     */
    async onExport() {
      // try {
      //   const header = ['医生', '职称', '医院', '红包类型', '领取红包积分', '领取时间']
      //   const {
      //     data: { data }
      //   } = await getLivePacketList({
      //     pageNo: 1,
      //     pageSize: 1000,
      //     liveId: this.search.liveId
      //   })
      //   const json = data.map(item => {
      //     return [item.name, item.title, item.hospital, this.$options.filters.type(item.packetType, item.distributeType), `${item.amount}积分`, item.receiveTime]
      //   })
      //   JsonToExcel(`${this.liveTitle} - 红包领取记录`, [header, ...json])
      // } catch (error) {
      //   console.log(error)
      // }
      const params = {
        sceneId: this.search.liveId,
        keyword: this.search.keyWord,
        title: this.search.title
      }
      this.$axios.get(this.$API.exportRedDetailList, {params}).then(res => {
        this.$message.success(res.data)
      }, rea => {
        this.$message.error(rea.message)

      })
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.packet {
  ::v-deep .el-table__header-wrapper {
    th {
      background-color: #eff0f2;
    }
  }
  .search-packet {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
      display: flex;
      align-items: center;
      .el-input {
        width: 365px;
        margin-right: 40px;
        ::v-deep input {
          border-radius: 20px;
        }
      }
    }
  }
  .toopt-tip {
    padding: 30px 20px;
  }
  .dialog-footer {
    text-align: center;
  }
  .pagination {
    margin-top: 20px;
  }
}
</style>
