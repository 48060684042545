<template>
  <div>
    <!-- 搜索栏 -->
    <div class="nav-wrapper">
      <div v-if="showBatchOperation" class="left">
        <el-button
          v-if="$auth('acadSaas.actMgmt.share')"
          class="reset-btn"
          type="primary"
          plain
          @click="onBatchShare"
        >
          批量分享
        </el-button>
      </div>
      <div v-if="!showBatchOperation" class="left">
        <el-input
          v-model="searchParams.title"
          v-debounce="[onSearch, 'input']"
          class="input"
          clearable
          prefix-icon="el-icon-search"
          placeholder="请输入问卷调研名称"
          @clear="onSearch"
          @keydown.enter.native="onSearch"
        />
        <div>
          <el-select
            v-model="academicSourceType"
            class="select"
            placeholder="活动来源"
            @change="onSelectSource"
          >
            <el-option label="我的项目" value="ower" />
            <el-option label="其他组织分享" value="other" />
            <el-option label="下级组织活动" value="down" />
          </el-select>
        </div>
        <el-select
          v-model="searchParams.questionnaireStatus"
          clearable
          placeholder="请选择活动状态"
          @change="onSearch"
        >
          <el-option
            v-for="item in challengeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <template v-if="academicSourceType === 'ower'">
          <div v-for="group in tagGroupListFilter" :key="group.id">
            <div :class="{ 'select-complex': true }">
              <el-select
                v-model="selectedTagMap[group.id]"
                class="select"
                clearable
                collapse-tags
                :multiple="group.groupCode !== 'PRODUCT_TAG_GROUP'"
                :placeholder="group.groupName"
                @change="onSelectTag"
              >
                <template v-for="item in tagList">
                  <el-option
                    v-if="group.id === item.groupId"
                    :key="group.id + item.tagId"
                    :value="item.tagId"
                    :label="item.tagName"
                  />
                </template>
              </el-select>
              <template>
                <div class="right-btn" @click.stop.prevent="onManageTag(group)">
                  <svg-icon name="manage-icon" />
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
      <div class="right">
        <el-row class="action-fix">
          <el-button
            class="reset-btn"
            type="primary"
            plain
            @click="onBatchOperation"
          >
            {{ !showBatchOperation ? '批量操作' : '取消批量操作' }}
          </el-button>
          <!-- 暂时关闭创建拜访 -->
          <el-button
            v-if="$auth('acadSaas.actMgmt.create')"
            class="reset-btn"
            type="primary"
            icon="el-icon-plus"
            @click="onCreate"
          >
            新建活动
          </el-button>
        </el-row>
      </div>
    </div>
    <!-- table -->
    <template>
      <el-table
        ref="tableRef"
        v-loading="loading"
        :data="tableList"
        style="width: 100%;"
        @selection-change="onSelectList"
      >
        <el-table-column
          v-if="showBatchOperation"
          type="selection"
          width="55"
        />
        <el-table-column
          label="活动名称"
          prop="title"
          width="150"
          fixed
          show-overflow-tooltip
        />
        <el-table-column
          label="状态"
          prop="Name"
          align="center"
        >
          <template #default="{row}">
            <span :class="{'success-text': row.status === 1 }">{{ row.status === 1 ? '已发布' : '未发布' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="产品标签" prop="productTagName" align="center" />
        <el-table-column label="题目数" prop="examCount" align="center" />
        <el-table-column label="创建组织" prop="createOrgName" align="center" />
        <el-table-column label="创建时间" prop="createdAt" align="center" />
        <el-table-column
          label="操作"
          prop="Address"
          width="200"
        >
          <template #default="{row}">
            <div class="operate-btn-box">
              <div class="btn-item" @click="onPreExam(row)">预览</div>
              <div class="btn-item" @click="onDetail(row)">查看</div>
              <div v-if="row.canEdit" class="btn-item" @click="onEditExam(row)">编辑</div>
              <div class="btn-item" @click="onDelegation(row)">委托</div>
              <div class="btn-item btn-del" @click="onDel(row)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="searchParams.pageNo"
        :page-size="searchParams.pageSize"
        class="pagination-box"
        :page-sizes="[10, 20, 50, 100]"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
    <PreviewPureSurvey
      :visible.sync="showPreview"
      :form-obj="formObj"
    />
    <!-- 批量分享 -->
    <SceneShareDepModal
      :visible="isShowSharePop"
      scene-title="问卷调研"
      :is-creator="searchParams.isCreator"
      :is-submit="isShareLoading"
      :scene-id="sceneIdsStr"
      :scene-type="searchParams.sceneType"
      @onSubmit="onSubmitShareDep"
      @onClose="isShowSharePop = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PreviewPureSurvey from './PreviewPureSurvey.vue'
import SceneShareDepModal from '@/bizComponents/SceneShareDepModal'
import apiObjs from '@/apis/api-objs.js'
export default {
  name: 'PureSurveyList',
  components: {
    PreviewPureSurvey,
    SceneShareDepModal
  },
  data() {
    return {
      formObj: {},
      showPreview: false,
      isPreviewLoading: false,
      selectedTagMap: {},
      loading: false,
      academicSourceType: 'ower', // 活动来源类型：ower：当前厂家，down：下级
      tagList: [], // 标签列表

      tableList: [],
      total: 0,
      searchParams: {
        questionnaireStatus: '',
        endTime: '',
        startTime: '',
        title: '',
        itemId: '',
        sceneType: 'questionnaire',
        isCreator: true,
        liveStatus: null,
        tagAndGroupIds: [],
        targetOrgId: '',
        questionnaireSubType: '1',
        podcastListType: '',
        reviewStatus: '',
        podcastSortType: '',
        pageSize: 10,
        pageNo: 1
      },
      showBatchOperation: false,
      selectList: [],
      isShowSharePop: false,
      isShareLoading: false,
      challengeList: [
        {
          label: '已发布',
          value: 1
        }, {
          label: '未发布',
          value: 0
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['tagGroupList']),
    tagGroupListFilter() {
      return this.tagGroupList.filter(item => item.groupCode === 'PRODUCT_TAG_GROUP')
    },
    selectListIds() {
      if (this.academicSourceType === 'down') {
        return this.selectList.map(item => item.sceneId)
      }
      return this.selectList.map(item => item.id)
    },
    sceneIdsStr() {
      return this.selectListIds.join(',')
    }
  },
  mounted() {
    this.onSearch()
    this.handelEditSceneTagModalClose()
  },
  activated() {
    this.getDataList()
  },
  methods: {
    ...mapActions(['getTagGroup', 'onDelQuestionnaire']),
    onPreExam(row) {
      if (this.isPreviewLoading) return
      this.isPreviewLoading = true
      this.onGetDetail(row.id || row.sceneId)
    },
    onGetDetail(id) {
      this.$axios.get(this.$API.getQuestionnaireDraftDetail, { params: { questionnaireId: id } }).then(res => {
        console.log(res, '=====')
        const { data = {} } = res
        this.formObj = {
          title: data.title,
          intro: data.intro,
          examList: data.questionnaireExamVoList,
          coverUrl: data.coverUrl
        }
        this.showPreview = true
      }).finally(() => {
        this.isPreviewLoading = false
      })
    },
    onEditExam(row) {
      this.$router.push(`/academic/scene/survey/edit/${row.id || row.sceneId}`)
    },
    onDelegation(row) {
      this.$axios.post(this.$API.updateQuestionnaireNormal, { id: row.id || row.sceneId, type: 1, status: 1 }).catch(err => {
        this.$message.error(err.message)
      })
      this.$router.push({ name: 'CreatePureSurvey', query: {questionnaireId: row.id || row.sceneId} })
    },
    onDetail(row) {
      this.$router.push(`/sub-app/academic-saas/questionnaire/detail/${row.id || row.sceneId}`)
    },
    onDel(row) {
      this.$MessageDialog({
        title: '删除',
        message: !row.canDelete ? '请确认是否删除，删除后不可再分配，' : '确认删除该素材么？一经删除，不可恢复',
        newLineMessage: !row.canDelete ? '您也可以在个人中心回收站重新找回' : ''
      }).then(() => {
        this.onConfirmDel(row.id || row.sceneId)
      })
    },
    onConfirmDel(id) {
      this.onDelQuestionnaire(id).then(
        () => {
          this.$message({
            message: '删除成功！',
            type: 'success'
          })
          this.getDataList()
        },
        rea => {
          this.$message({
            message: rea,
            type: 'error'
          })
        }
      )
    },
    // ---------------------------- 批量操作 start-------------------------------
    onSelectList(list) {
      this.selectList = list
    },
    onBatchOperation() {
      this.showBatchOperation = !this.showBatchOperation
      this.selectList = []
      this.$refs.tableRef.clearSelection()
    },
    onBatchShare() {
      if (!this.selectListIds.length) return this.$message.error('请先勾选活动')
      this.isShowSharePop = true
    },
    // ---------------------------- 批量操作 end-------------------------------
    onSubmitShareDep(arr, isSelectedPubResLib) {
      this.isShareLoading = true
      const req = {
        sceneIds: this.selectListIds,
        sceneType: this.searchParams.sceneType,
        factoryIds: arr,
        isSelectedPubResLib,
        questionnaireSubType: this.searchParams.questionnaireSubType
      }
      this.$requireApi(apiObjs.sceneShare, req).then(() => {
        this.isShareLoading = false
        this.isShowSharePop = false
        this.$message.success('分享成功')
      }, rea => {
        this.isShareLoading = false
        this.$message.error(rea.message)
      })
    },
    // --------------------- todo 分享公共资源库 --------------------
    onJumpCommonList() {
      this.$router.push({ name: 'CommonList' })
    },

    onSelectSource(val) {
      this.selectedTagMap = {}
      this.searchParams.tagAndGroupIds = []

      this.searchParams.isCreator = val === 'ower'
      this.searchParams.targetOrgId = ''
      this.onSearch()
    },
    handleSizeChange(size) {
      this.searchParams.pageSize = size
      this.getDataList()
    },
    handleCurrentChange(pageNo) {
      this.searchParams.pageNo = pageNo
      this.getDataList()
    },
    // 搜索
    onSearch() {
      this.searchParams.pageNo = 1
      this.$nextTick(() => {
        this.getDataList()
      })
    },
    onSelectTag() {
      const output = []
      Object.keys(this.selectedTagMap).map(v => {
        output.push({ groupId: v, tagIds: Array.isArray(this.selectedTagMap[v]) ? this.selectedTagMap[v] : this.selectedTagMap[v] ? [this.selectedTagMap[v]] : [] })
      })
      this.searchParams.tagAndGroupIds = output
      this.getDataList()
    },
    onCreate() {
      this.$router.push('/academic/scene/survey/create')
    },
    getDataList() {
      this.selectList = []
      if (this.academicSourceType !== 'down') {
        this.getCurrentList()
      } else {
        this.getDownList()
      }
    },
    // 获取下级组织数据
    async getDownList() {
      try {
        this.loading = true
        const params = {...this.searchParams}
        delete params.isCreator
        const { data: { total, pageData } } = await this.$axios.get(this.$API.getDownOrgList, { params })
        this.total = total
        this.tableList = pageData
        this.loading = false
      } catch (error) {
        this.$message.error(error)
        this.loading = false
      }
    },
    // 获取当前组织数据
    async getCurrentList() {
      try {
        this.loading = true
        const params = {...this.searchParams}
        const { data: { data, totalCount } } = await this.$axios.post(this.$API.getAcademicSceneList, params)
        this.tableList = data
        this.total = totalCount
      } catch (error) {
        error && error.message && this.$message.error(error.message)
      } finally {
        this.loading = false
      }
    },
    // 管理标签
    onManageTag(row) {
      this.$router.push({name: 'ProductLabel', query: {activeTabName: row.id}})
    },
    // 标签弹窗关闭后
    handelEditSceneTagModalClose() {
      // 搜索栏数据
      this.getTagGroup().then(() => {
        this.tagList = []
        this.tagGroupList.forEach(item => {
          this.getTagList(item.id)
        })
      })
    },
    // 获取我使用的标签列表
    async getTagList(tagGroupId) {
      try {
        const params = { tagGroupId }
        const { data } = await this.$axios.get(this.$API.tagList, { params })
        this.tagList = [...this.tagList, ...data]
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.action-fix {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
}
.reset-btn {
  margin-left: 10px;
}
.nav-wrapper {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
  }
  .left {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .select {
      width: 120px;
      ::v-deep .el-select__tags {
        >span {
          width: 100%;
          display: grid;
          grid-template-columns: auto auto;
          >span {
            border: none;
            overflow: hidden;
            .el-tag__close.el-icon-close {
              background-color: transparent;
              &:hover {
                background-color: #c0c4cc;
                color: #909399;
              }
            }
          }
        }
      }
    }
    .select-complex {
      display: flex;
      box-sizing: border-box;
      border: 1px solid #dcdfe6;
      background-color: #fbfbfb;
      transition: all 0.3s;
      &:hover {
        border-color: #c0c4cb;
      }
      &:focus {
        border-color: $color-primary;
      }
      ::v-deep .select {
        line-height: 26px;
        .el-input {
          .el-input__inner {
            height: 26px;
            line-height: 26px;
            background-color: transparent;
            border: 1px solid transparent;
            border-right: none;
          }
        }
      }
      .right-btn {
        width: 28px;
        line-height: 26px;
        text-align: center;
        color: #a3a3a3;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: 12px;
          background-color: #e1e1e1;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        &:hover {
          background-color: #ddd;
          cursor: pointer;
        }
      }
    }
    .input {
      width: 160px;
      ::v-deep .el-input__inner {
        background-color: #fafafa;
        border-radius: 20px;
      }
    }
  }
  .right {
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    gap: 9px;
  }
}
.success-text {
  color: #30c691;
}
.operate-btn-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .btn-item {
    margin-right: 10px;
    color: #3d61e3;
    cursor: pointer;
  }
  .btn-del {
    color: #e8312f;
  }
}
.pagination-box {
  padding-top: 15px;
}
</style>
