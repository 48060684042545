<template>
  <img
    :src="imageUrl"
    class="image-element msg"
    alt=""
    @load="imgLoad"
    @click.stop="onPreview"
  >
</template>

<script>
export default {
  name: 'MsgImage',
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    isLast: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    imageUrl() {
      if (!this.payload.imageInfoArray) return
      const { url } = this.payload.imageInfoArray[0]
      if (typeof url !== 'string') return ''
      return url.slice(0, 2) === '//' ? `https:${url}` : url
    }
  },
  methods: {
    onPreview() {
      this.$EventBus.$emit('previewImages', { currentImage: this.imageUrl })
    },
    imgLoad() {
      if (this.isLast) {
        this.$EventBus.$emit('scrollToBottom')
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.image-element {
  width: 100%;
  max-height: 200px;
  vertical-align: middle;
  cursor: zoom-in;
}
</style>
