<template>
  <div class="speakers-list">
    <empty v-if="!speakersList.length" />
    <el-scrollbar v-else class="scrollbar">
      <div v-for="item in speakersList" :key="item.id" class="item">
        <div class="auther">
          <img class="img" :src="item.avatar">
        </div>
        <div class="user-info">
          <div class="user-title">
            <p class="user-name" :title="item.name">{{ item.name }}</p><span v-if=" item.titleName" class="tag">{{ item.titleName }}</span>
          </div>
          <p class="online-hospital" :title="item.hospital">{{ item.hospital }}</p>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import empty from './components/empty'
export default {
  name: 'SpeakersList',
  components: {
    empty
  },
  props: {
    speakersList: {
      type: Array
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.speakers-list {
  width: 100%;
  box-sizing: border-box;
  .scrollbar {
    height: 100%;
    overflow-x: hidden;
    ::v-deep .el-scrollbar__view {
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        padding: 10px;

        .auther {
          width: 44px;
          height: 44px;
          flex-shrink: 0;
          text-align: center;
          line-height: 44px;
          background-color: #ddd;
          border-radius: 50%;
          margin-right: 5px;
          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
            overflow: hidden;
          }
        }
        .user-info {
          width: 80%;
          box-sizing: border-box;
          .user-title {
            display: flex;
            .user-name {
              max-width: 60px;
              font-size: 12px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #0f0f0f;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .tag {
              padding: 3px 7px;
              font-size: 11px;
              color: #ff7600;
              line-height: 11px;
              border-radius: 20px;
              background-color: rgba(255, 118, 0, 0.2);
              margin-left: 5px;
            }
          }
          .online-hospital {
            max-width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
