<template>
  <span class="msg msg-text">
    <template v-for="(item, index) in contentList">
      <span v-if="item.name === 'text'" :key="index" class="text-box">{{ item.text }}</span>
      <img
        v-else-if="item.name === 'img'"
        :key="index"
        :src="item.src"
        class="img"
      >
    </template>
  </span>
</template>
<script>
import { decodeText } from './decodeText'
export default {
  name: 'MsgText',
  props: {
    message: {
      type: Object
    },
    payload: {
      type: Object,
      default: Object
    },
    notip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    contentList() {
      return decodeText(this.payload)
    }
  }
}
</script>

<style lang='scss' scoped>
.img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  vertical-align: -4px;
}
.msg-text {
  color: #000;
  padding: 13px 12px;
  background: #fcfcfe;
  border: 1px solid #e1e1e1;
}
</style>
