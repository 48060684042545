<template>
  <el-dropdown
    v-if="message.status ==='success'"
    ref="dropdown"
    trigger="click"
    class="dropdown"
    :placement="isMine?'left-start':'right-start'"
    @command="(type) => handleCommand(type, message)"
  >
    <div class="more-icon">
      <svg-icon name="el-icon-more" />
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="UN_FAVORITE">取消收藏</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { dealChatMsg } from '@/services/liveService'
export default {
  name: 'Handle',
  components: {},
  props: {
    message: {
      type: Object,
      defualt: Object
    }
  },
  data() {
    return {}
  },
  computed: {
    isMine() {
      const { userID } = this.$store.state.tim.user
      return this.message.from === userID
    }
  },
  watch: {
    '$store.state.tim.currentSession.scrollTopCount': function() {
      if (this.$refs.dropdown.visible) {
        this.$refs.dropdown.hide()
      }
    }
  },
  methods: {
    async handleCommand(type, item) {
      const { conversationType, conversationID } = this.$store.state.tim.currentSession
      const params = {
        chatRoomId: item.to,
        senderImId: item.from,
        msgId: item.ID,
        actionType: type
      }
      try {
        await dealChatMsg(params)
        if (type === 'DELETE') {
          this.$store.commit('tim/currentSession/deleteMsg', { conversationID: conversationType + conversationID, message: item })
        }
        this.$message({
          type: 'success',
          message: '操作成功！'
        })
        this.$emit('change')
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.more-icon {
  width: 25px;
  height: 25px;
  border: 1px solid #e1e1e1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2px;
  transition: all 0.3s;
  margin: 0 5px;
  cursor: pointer;
  flex-shrink: 0;
  &:active {
    border-color: #333;
  }
}
</style>
