<template>
  <div class="live-item">
    <div class="live-detail">
      <div class="img">
        <img :src="item.coverUrl">
        <p class="time">{{ formDataDuration(item.duration) }}</p>
      </div>
      <div class="live-info">
        <slot name="title">
          <p class="title">{{ item.title }}</p>
        </slot>
        <span class="recording-time">录制时间：{{ item.recordAt }}</span>
        <div class="bottom">
          <div>
            <el-tag
              v-if="tag"
              size="mini"
              class="tag"
              :class="tag === '原片' ? 'green' : ''"
            >
              {{ tag }}
            </el-tag>
            <el-tag v-if="status" size="mini" class="tag">{{ status }}</el-tag>
          </div>
          <p v-if="!noBtn" class="btn-wrap">
            <template v-if="item.replayUse">
              <el-button class="btn" disabled type="text">已设为回放</el-button>
            </template>
            <template v-else-if="item.saveType != 3">
              <el-button
                class="btn"
                :display="item.replayUse"
                :disabled="liveStatus <= 1"
                type="text"
                @click="setPlay"
              >
                设为回放
              </el-button>
            </template>
            <el-link
              v-if="item.previewVo && needCheck"
              :disabled="status !== ''"
              class="btn"
              :underline="false"
              type="primary"
              target="_blank"
              :href="item.previewVo.mp4.hd || item.previewVo.mp4.sd"
            >
              预览{{ replayUse ? '回放' : '视频' }}
            </el-link>
          </p>
          <!-- <p v-if="!noBtn" class="btn-wrap">
            <el-button v-if="item.replayUse" class="btn" disabled type="text">已设为回放</el-button>
            <el-button v-else class="btn" :display="item.replayUse" type="text" @click="setPlay">设为回放</el-button>
            <el-link v-if="item.previewVo" class="btn" :underline="false" type="primary" target="_blank" :href="item.previewVo.mp4.sd">预览{{ replayUse?'回放':'视频' }}</el-link>
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onSettingReplayVideo } from '@/services/liveService'
export default {
  name: 'LiveItem',
  components: {},
  props: {
    item: {
      type: Object,
      require: true
    },
    replayUse: {
      type: Boolean,
      default: false
    },
    noBtn: {
      type: Boolean,
      default: false
    },
    needCheck: {
      type: Boolean,
      default: false
    },
    liveStatus: {
      type: Number,
      default: 0
    },
    liveId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      state: '',
      end: '',
      search: {}
    }
  },
  computed: {
    tag() {
      const { item, needCheck } = this
      return !needCheck ? '直播回放' : item.saveType == 1 ? '原片' : item.saveType == 2 ? '合成' : item.saveType == 3 ? '剪辑中' : ''
    },
    status() {
      const { transcodeStatus } = this.item
      return transcodeStatus == 0 ? '待转码' : transcodeStatus == 1 ? '转码中' : ''
    }
  },
  mounted() {
  },
  methods: {
    async setPlay(item) {
      this.search.liveId = this.liveId ? this.liveId : this.$route.params.liveId
      this.search.videoId = this.item.id
      if (item.transcodeStatus < 2) return this.$message.info('视频需要转码成功才能设置为回放视频')
      const params = Object.assign({}, this.search)
      try {
        const { data } = await onSettingReplayVideo(params)
        if (data) {
          this.$message({
            type: 'success',
            message: '设置成功'
          })
          this.$emit('setPlaySuccess')
        }
      } catch (error) {
        console.log(error)
        this.$message({
          type: 'error',
          message: error.message
        })
      }
    },
    formDataDuration(val) {
      const time = this.$moment.duration(val, 'seconds')
      const hours = time.hours()
      const minutes = time.minutes()
      const seconds = time.seconds()
      return [hours, minutes, seconds].join(':')
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.live-item {
  border: 1px solid #eee;
  padding: 15px;
  .live-detail {
    display: flex;
    .img {
      width: 122px;
      height: 68px;
      background-color: #d8d8d8;
      margin-right: 10px;
      overflow: hidden;
      position: relative;
      .time {
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 20px;
        text-align: center;
        color: #fff;
        line-height: 20px;
        background-color: rgba(0, 0, 0, 0.25);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .live-info {
      flex: 1;
      overflow: hidden;
      flex-shrink: 0;
      .headline-title {
        width: 200px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      ::v-deep .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC, sans-serif;
        font-weight: 400;
        color: #0f0f0f;
        line-height: 14px;
        .el-checkbox__label {
          width: 200px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-left: 0;
        }
        .el-checkbox__input {
          order: 1;
        }
      }
      .recording-time {
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC, sans-serif;
        font-weight: 400;
        color: #5a5a5a;
        line-height: 28px;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tag {
          font-size: 12px;
          padding: 0 10px;
          background-color: #f0f0f0;
          border-radius: 20px;
          color: #969696;
          margin-right: 5px;
          border-color: transparent;
          &.green {
            color: #290;
            background-color: rgba($color: #33cc07, $alpha: 0.2);
          }
        }
        .btn-wrap {
          display: flex;
          align-items: center;
          .btn {
            padding: 0;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>
