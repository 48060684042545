<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="30%"
    :before-close="cancel"
  >
    <div>
      <el-row type="flex">
        <div class="title">活动名称：</div>
        <el-input
          v-model="activity.activityName"
          placeholder="输入活动名称"
          class="none-input"
          maxlength="40"
          show-word-limit
        />
      </el-row>
      <el-row type="flex">
        <div class="title">活动链接：</div>
        <el-input
          v-model="activity.activityLink"
          placeholder="输入活动链接"
          class="none-input"
        />
      </el-row>
      <el-row class="mgn-b30">
        <div class="title mgn-b10">上传活动图:（图片建议尺寸21:6）</div>
        <el-upload
          class="upload-img"
          :action="uploadApi"
          :headers="headers"
          name="image"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          list-type="picture-card"
          accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
        >
          <img v-if="activity.activityImageUrl" :src="activity.activityImageUrl" style="width: 100%; height: 100%;">
          <div v-else slot="trigger" class="wrapper">
            <div>
              <svg-icon name="image-upload" class="image-upload" />
            </div>
            <div>上传活动图</div>
          </div>
        </el-upload>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import API from '@/apis/api-types'
import {getToken} from '@/utils/auth'

export default {
  name: 'CreateActivity',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    activityInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      headers: {
        token: getToken()
      }
    }
  },
  computed: {
    activity: {
      get() {
        return this.activityInfo
      },
      set(val) {
        this.$emit('update:activityInfo', val)
      }
    },
    uploadApi() {
      return `${API.uploadImage}?bucketType=104`
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.activity.activityImageUrl = URL.createObjectURL(file.raw)
      this.$set(this.activity, 'activityImageName', res.data.imageName)
    },
    save() {
      // 判断是否都填写完整
      if (!this.activity.activityName) {
        this.$message.warning('请输入活动名称')
        return
      }
      if (!this.activity.activityLink) {
        this.$message.warning('请输入活动链接')
        return
      }
      if (!this.activity.activityImageName) {
        this.$message.warning('请上传活动图')
        return
      }
      // 正则校验链接格式
      const reg = /^(http|https):\/\/([\w.]+\/?)\S*/
      if (!reg.test(this.activity.activityLink)) {
        this.$message.warning('请输入正确的链接格式')
        return
      }
      this.$emit('save', this.activity)
    },
    cancel() {
      this.$emit('cancel')
    }
  }

}
</script>

<style lang="scss" scoped>
.none-input {
  width: 300px;
  margin-bottom: 10px;
  ::v-deep {
    .el-input__inner {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      background: none;
    }
    &.el-input--mini .el-input__inner {
      height: 24px;
      line-height: 24px;
    }
    .el-input__count-inner {
      background: none;
    }
  }
}
.title {
  font-size: 12px;
}
.upload-img ::v-deep .el-upload--picture-card {
  width: 177px;
  height: 100px;
  background: #f5f5f5;
  border: 1px solid #e1e1e1;
}
.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  height: 100%;
  font-size: 12px;
  color: #787878;
}
.image-upload {
  width: 38px;
  height: 30px;
  color: #a3a3a3;
  margin-bottom: 10px;
}
</style>
