<template>
  <el-dialog
    class="customize-el-dialog select-material"
    title="选择素材"
    width="900px"
    :visible.sync="materialVisible"
  >
    <el-tabs v-model="activeTab">
      <el-tab-pane
        v-for="item in tabList"
        :key="item.key"
        :label="item.name"
        :name="item.key"
      />
    </el-tabs>
    <div class="scroll-wrapper">
      <MaterialVideoChild
        v-show="activeTab === 'video'"
        ref="video"
        :init-checked-list="videoList"
      />
      <MaterialArticleChild
        v-show="activeTab == 'article'"
        ref="article"
        :select-list="articleList"
      />
      <MaterialExamChild
        v-show="activeTab == 'exam'"
        ref="exam"
        key="exam"
        :exam-type="0"
        :select-list="examList"
      />
      <MaterialExamChild
        v-show="activeTab == 'question'"
        ref="question"
        key="question"
        :exam-type="1"
        :select-list="questionList"
      />
    </div>
    <el-row
      slot="footer"
      type="flex"
      justify="center"
      class="pdg-t20"
    >
      <el-button plain @click="onClose">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
import MaterialVideoChild from './MaterialVideoChild'
import MaterialArticleChild from './MaterialArticleChild'
import MaterialExamChild from './MaterialExamChild'
export default {
  name: 'MaterialSelectModal',
  components: {
    MaterialVideoChild,
    MaterialArticleChild,
    MaterialExamChild
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    articleList: {
      type: Array,
      default() {
        return []
      }
    },
    videoList: {
      type: Array,
      default() {
        return []
      }
    },
    examList: {
      type: Array,
      default() {
        return []
      }
    },
    questionList: {
      type: Array,
      default() {
        return []
      }
    },
    showVideo: {
      type: Boolean,
      default: true
    },
    showArticle: {
      type: Boolean,
      default: true
    },
    showExam: {
      type: Boolean,
      default: true
    },
    showQuestion: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activeTab: 'video'
    }
  },
  computed: {
    tabList() {
      return [
        { key: 'video', name: '视频', disabled: !this.showVideo },
        { key: 'article', name: '文献', disabled: !this.showArticle },
        { key: 'exam', name: '题目', disabled: !this.showExam },
        { key: 'question', name: '问卷', disabled: !this.showQuestion }
      ].filter(item => !item.disabled)
    },
    materialVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    onSubmit() {
      const examList = this.$refs.exam.getValue()
      const articleList = this.$refs.article.getValue()
      const videoList = this.$refs.video.getValue()
      const questionList = this.$refs.question.getValue()
      this.$emit('onSubmit', {
        examList,
        articleList,
        videoList,
        questionList
      })
    },
    onClose() {
      this.$emit('onClose')
    }
  }
}
</script>
<style lang="scss" scoped>
.select-material ::v-deep {
  .el-dialog__body {
    padding: 0;
  }
  .scroll-wrapper {
    height: 400px;
    // overflow-y: auto;
    position: relative;
  }
  .el-tabs__header {
    .el-tabs__nav-wrap {
      padding-left: 34px;
    }
  }
}
</style>
