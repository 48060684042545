import store from '@/store'
import { getLiveOldMessageList } from '@/services/liveService'
import { filterSpeakerIdentity } from '@/views/academic/tim/config/msg-filter'

import moment from 'moment'
const beforeTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
export const useList = (options, listWrap) => {
  const { conversationID } = store.state.tim.currentSession
  // 方法
  /**
    * 获取列表数据
    * 调用时机：进入房间时获取消息列表
    */
  const getDataList = async() => {
    if (options.isCompleted || options.loading) return
    try {
      const params = Object.assign({}, {
        chatRoomId: conversationID,
        beforeTime,
        ...options
      })
      options.loading = true
      const { data } = await getLiveOldMessageList(params)
      if (params.pageSize !== data.length) {
        options.isCompleted = true
      } else {
        options.pageNo++
      }
      const oldList = store.state.tim.currentSession.conversation || []
      const copyData = filterSpeakerIdentity(data, store.state.tim.user.qresenterIDList)
      let list = []
      if (params.pageNo === 1) {
        list = copyData
      } else {
        list = [...copyData, ...oldList]
      }

      // 处理下拉加载
      if (listWrap && listWrap.scrollTopCount < 5) {
        listWrap.$refs.elscroll.wrap.scrollTop = 1
      }
      store.commit('tim/currentSession/setConversation', { message: list || [] })
      options.loading = false
      return list
    } catch (error) {
      options.loading = false
      console.log(error)
    }
  }

  return {
    isCompleted: options.isCompleted,
    getDataList,
    loading: options.loading
  }
}
