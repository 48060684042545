<template>
  <div class="collection-list">
    <empty v-if="!collectionList.length" />
    <msg-list-scroll
      v-else
      ref="listWrap"
      :loading="search.loading"
      class="tim-list-wrap"
      :is-completed="search.isCompleted"
      @load="getDataList"
    >
      <template v-for="(item,index) in collectionList">
        <MsgLayout
          v-if="item.type === TIM.TYPES.MSG_TEXT"
          :key="item.ID"
          class="msg msg-image"
          :message="item"
        >
          <msg-text :payload="item.payload" :message="item" />
          <template #handle>
            <Handle :message="item" @change="onUnCollection" />
          </template>
        </MsgLayout>
        <MsgLayout
          v-else-if="item.type === TIM.TYPES.MSG_FACE"
          :key="item.ID"
          class="msg msg-image"
          :message="item"
        >
          <msg-face :payload="item.payload" :message="item" />
          <template #handle>
            <Handle :message="item" @change="onUnCollection" />
          </template>
        </MsgLayout>
        <MsgLayout
          v-else-if="item.type === TIM.TYPES.MSG_IMAGE"
          :key="item.ID"
          class="msg msg-image"
          :message="item"
        >
          <msg-image :is-last="index === collectionList.length-1" :payload="item.payload" :message="item" />
          <template #handle>
            <Handle :message="item" @change="onUnCollection" />
          </template>
        </MsgLayout>
        <div v-else :key="item.ID" class="center">暂未支持的消息类型：{{ item.type }}</div>
      </template>
      <div id="last-stance" style="height: 1px" />
    </msg-list-scroll>
    <ImageViewer
      v-model="showImageViewer"
      :url-list="imageViewerList"
      :src="currentSrc"
      :infinite="false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ImageViewer from '@/components/ImageViewer'
import MsgListScroll from '../components/MsgListScroll'

import MsgText from '../components/MsgItem/msg-text.vue'
import MsgFace from '../components/MsgItem/msg-face.vue'
import MsgImage from '../components/MsgItem/msg-image.vue'
import MsgLayout from '../components/msg-layout'
import Handle from './handle.vue'

import empty from '../components/empty'
import { TIM } from '../config/tim-config'
import { getLiveOldMessageList } from '@/services/liveService'
import {filterSpeakerIdentity} from '@/views/academic/tim/config/msg-filter'
import moment from 'moment'
const beforeTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
export default {
  name: 'CollectionList',
  components: {
    MsgListScroll,
    ImageViewer,
    MsgText,
    MsgFace,
    MsgImage,
    MsgLayout,
    Handle,
    empty
  },
  data() {
    return {
      TIM,
      loading: false,
      showImageViewer: false,
      imageViewerList: [],
      currentSrc: '',
      search: {
        pageNo: 1,
        pageSize: 10,
        isFavorite: true,
        loading: false,
        isCompleted: false,
        beforeTime
      },
      collectionList: []
    }
  },
  computed: {
    listWrap() {
      return this.$refs.listWrap
    },
    ...mapState({
      conversation: state => state.tim.msg.conversation,
      conversationType: state => state.tim.currentSession.conversationType,
      conversationID: state => state.tim.currentSession.conversationID
    })
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      const { conversationID } = this
      if (this.search.isCompleted || this.search.loading) return
      try {
        const params = Object.assign(
          {},
          {
            chatRoomId: conversationID
          },
          this.search
        )
        this.search.loading = true

        const { data } = await getLiveOldMessageList(params)
        if (params.pageSize !== data.length) {
          this.search.isCompleted = true
        } else {
          this.search.pageNo++
        }
        let list = []
        if (params.pageNo === 1) {
          list = filterSpeakerIdentity(data)
        } else {
          list = [...filterSpeakerIdentity(data), ...this.collectionList]
        }
        // 处理下拉加载
        if (this.listWrap && this.listWrap.scrollTopCount < 5) {
          this.listWrap.$refs.elscroll.wrap.scrollTop = 1
        }
        this.collectionList = list
        this.search.loading = false
      } catch (error) {
        this.search.loading = false
        console.log(error)
      }
    },
    onUnCollection() {
      this.search.pageNo = 1,
      this.search.isCompleted = false
      this.getDataList()
    },
    /**
     * 预览图片
     * 调用时机：点击图片消息
     * @param {String} src
     * @param {Array} list
     */
    proxyPreviewImages(src, list) {
      this.showImageViewer = true
      this.imageViewerList = list
      this.currentSrc = src
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.collection-list {
  width: 100%;
  position: relative;
  height: 100%;
  .tim-list-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    .msg-list-box {
      flex: 1;
      flex-shrink: 0;
    }
  }
  .center {
    text-align: center;
    font-size: 12px;
    color: #a5b5c1;
    margin: 15px 0;
  }
}
</style>
