<template>
  <el-dialog
    title='批量添加错误'
    width="50%"
    :visible.sync="visible"
    :show-close='false'
    :close-on-click-modal='false'
    :close-on-press-escape='false'
  >
    <el-row type="flex" justify="space-between" class="mgn-b20">
      <el-row type="flex" align="middle">
        <i class="el-icon-warning warn-label"></i>
        <span class="font-weight">以下医生导入分配的时候出错了（共{{errorList.length}}人）</span>
      </el-row>
      <span class="text-primary-link" @click="onExport">导出数据为Excel</span>
    </el-row>
    <el-table
      :data='errorList'
      height="300"
    >
      <el-table-column
        label='医生姓名'
        width="150"
        prop="doctorName"
      >
      </el-table-column>
      <el-table-column
        label='医生编码'
        width="150"
        prop="doctorCode"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label='代表姓名'
        width="150"
        prop="repName"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label='错误原因'
        prop="errorReason"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
    </el-table>
    <el-row justify="center" type="flex" slot="footer">
      <el-button type="primary"  @click="onClose">我知道了</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
export default {
  name: 'DoctorErrorImportModal',
  data () {
    return {

    }
  },
  props :{
    visible: {
      type: Boolean,
      default: false,
    },
    errorList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    onClose () {
      this.$emit('onClose')
    },
    onExport () {
      this.$emit('onExport')
    }
  }
}
</script>
<style lang="scss" scoped>
.warn-label {
  color: rgba(246, 98, 94, 1);
  margin-right: 10px;
  font-size: 20px;
}
</style>
