<template>
  <el-dialog
    title="标签选择"
    :visible.sync="visible"
    width="50%"
    center
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @opened="opened"
  >
    <div class="content">
      <div class="title">用户属性满足：</div>
      <el-row>
        <el-row
          v-for="(v, i) in result"
          :key="i"
          type="flex"
          justify="space-between"
          class="select-list"
        >
          <el-row>
            <el-col :span="6">
              <el-select
                v-model="v.attributeType"
                placeholder="请选择"
                style="width: 100%;"
                @change="selectAttributeChange($event, i)"
              >
                <el-option
                  v-for="item in selectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select
                v-model="v.operatorType"
                placeholder="请选择"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in v.operatorList"
                  :key="item.operatorType"
                  :label="item.operatorValue"
                  :value="item.operatorType"
                />
              </el-select>
            </el-col>
            <el-col v-if="v.type === 1" :span="6">
              <el-input
                v-model="v.attributeValue"
                placeholder="请输入"
                style="width: 100%;"
              />
            </el-col>
            <el-col v-else-if="v.type === 2" :span="6">
              <el-select
                v-model="v.attributeValue"
                placeholder="请选择"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in v.valueList"
                  :key="item.attributeValue"
                  :label="item.attributeValueDesc"
                  :value="item.attributeValue"
                />
              </el-select>
            </el-col>
            <el-col v-else-if="v.type === 3" :span="6">
              <el-cascader
                v-model="v.attributeValue"
                :options="v.valueList"
                :props="valueProps"
                placeholder="请选择"
                style="width: 100%;"
                filterable
              />
            </el-col>
          </el-row>
          <el-row>
            <el-button type="text" @click="addSelectItem">新增</el-button>
            <el-button
              type="text"
              style="color: #e8312f;"
              :disabled="i === 0 && result.length === 1"
              @click="delSelectItem(i)"
            >
              删除
            </el-button>
          </el-row>
        </el-row>
      </el-row>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button type="primary" @click="onSearch">查询</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'FilterDoctorByTag',
  components: {

  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    reset: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      result: [
        {
          attributeType: '',
          operatorType: '',
          attributeValue: '',
          valueList: [],
          operatorList: []
        }
      ],
      selectList: [],
      filterItems: [],
      valueProps: {
        value: 'attributeValue',
        label: 'attributeValueDesc',
        children: 'childAttributes',
        checkStrictly: true
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {

  },
  created() {

  },
  methods: {
    opened() {
      // 初始化
      if (this.reset) {
        this.result = [
          {
            attributeType: '',
            operatorType: '',
            attributeValue: '',
            valueList: [],
            operatorList: []
          }
        ]
        this.getFilterList()
      }
    },
    getFilterList() {
      this.$axios.get(this.$API.getAssignFilterItem).then(res => {
        this.filterItems = res.data.filterItems
        this.selectList = this.filterItems.map(item => ({
          label: item.attribute.attributeTypeDesc,
          value: item.attribute.attributeType
        }))
      })
    },
    selectAttributeChange(attributeType, index) {
      // 获取对应索引的结果对象引用
      const resultItem = this.result[index]
      // 清空操作符类型和属性值
      resultItem.operatorType = ''
      resultItem.attributeValue = ''
      // 查找对应的属性类型项，如果未找到则使用空对象作为回退
      const item = this.filterItems.find(item => item.attribute.attributeType === attributeType) || {}
      // 更新结果对象的相关属性
      resultItem.operatorList = item.operators || []
      resultItem.valueList = item.attributeValues || []
      resultItem.type = item.attributeValueType || ''
    },
    addSelectItem() {
      this.result.push({
        attributeType: '',
        operatorType: '',
        attributeValue: ''
      })
    },
    delSelectItem(index) {
      this.result.splice(index, 1)
    },
    onSearch() {
      const list = this.result.map(item => ({
        attributeType: item.attributeType,
        operatorType: item.operatorType,
        attributeValue: item.type === 3 ? item.attributeValue[item.attributeValue.length - 1] : item.attributeValue
      }))
      // 如果有值为空，提示用户
      if (list.some(item => !item.attributeType || !item.operatorType || !item.attributeValue)) {
        this.$message.error('请填写完整的查询条件')
        return
      }
      this.$emit('onSubmit', list)
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.content {
  padding: 0 20px;
  margin: 10px;
  border-left: #eee 1px solid;
}
.title {
  color: #0f0f0f;
  font-size: 12px;
  margin-bottom: 10px;
}
.select-list {
  margin-bottom: 20px;
  .el-col {
    margin-right: 10px;
  }
}
</style>
