<template>
  <div class="online-info">
    <ul class="live-info-num">
      <li>
        <span>在线观看人数</span>
        <span @click="onEdit('online')">
          <svg-icon class="edit" name="edit" />
        </span>
        <p class="num">{{ onLineData.onlineNum||0 }}</p>
      </li>
      <li>
        <span>总观看人次</span>
        <p class="num">{{ onLineData.viewCount || 0 }}</p>
      </li>
      <li>
        <span>总观看人数</span>
        <p class="num">{{ onLineData.viewPeopleCount||0 }}</p>
      </li>
    </ul>
    <!-- tim -->
    <tim ref="tim" :speakers-list="speakersList" :room-able="roomAble" />
    <!-- 在线人数 -->
    <el-dialog
      class="edit-online-num"
      title="编辑在线观看人数"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <el-row>
        <el-col :offset="6" :span="12">
          <el-form label-suffix="：" inline>
            <el-form-item label="实际在线观看人数">
              <template>{{ onLineData.onlineNum - bc }}</template>人
            </el-form-item>
            <el-form-item label="新增人数">
              <el-input
                v-model.number="onLineForm.count"
                :min="0"
                class="input"
                placeholder="请输入数字"
              />
            </el-form-item>
          </el-form>
          <p class="note">备注：修改在线观看人数，总观看人次会做1.5倍增加，总观看人数会增加1倍</p>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="onSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { editViewCount, getIMUserInfo } from '@/services/liveService'
import tim from '../../tim'
export default {
  name: 'OnlineInfo',
  components: {tim},
  props: {
    onLineData: {
      type: Object,
      default: Object
    },
    bc: {
      type: Number,
      default: 0
    },
    chatRoomId: {
      type: String,
      default: ''
    },
    speakersList: {
      type: Array
    },
    liveStatus: {
      type: Number
    },
    roomAble: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      onLineForm: {
        liveId: '',
        count: 0
      },
      loading: false
    }
  },
  mounted() {
    this.onLineForm.liveId = this.$route.params.liveId
    this.onLineForm.count = this.bc
    this.getTimInfo()
  },
  methods: {
    // 获取房间信息
    async getTimInfo() {
      try {
        const {
          data: { imId, userSig }
        } = await getIMUserInfo()
        this.$refs.tim?.timLogin({ userId: imId, userSig: userSig, chatRoomId: this.chatRoomId, roomAble: this.roomAble })
      } catch (error) {
        console.log(error)
      }
    },
    async onSubmit() {
      try {
        if (this.loading) return
        if (!this.vaildCount(this.onLineForm.count)) return this.$message.error('请输入合理的数值')
        this.loading = true
        const params = Object.assign({}, this.onLineForm)
        const { code } = await editViewCount(params)
        if (code === 0) {
          this.$message({
            type: 'success',
            message: '编辑成功'
          })
        }
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
      this.dialogVisible = false
    },

    vaildCount(val) {
      let bool = true
      if (!/^[0-9]\d*$/.test(val)) {
        bool = false
      }
      if (val > 1000000) {
        bool = false
      }
      return bool
    },
    onEdit(type) {
      if (!this.$auth('editLiveViewData')) return this.$message.info('暂无权限')
      this.editViewType = type
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.online-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  .live-info-num {
    display: flex;
    height: 200px;
    align-items: center;
    padding-top: 25px;
    > li {
      flex: 1;
      flex-shrink: 0;
      text-align: center;
      list-style-type: none;
      .edit {
        margin-left: 5px;
        color: #c8c8c8;
        transition: all 0.3s;
        &:hover {
          fill: #333;
          cursor: pointer;
        }
      }
      .num {
        font-size: 40px;
        font-family: DINAlternate-Bold, DINAlternate, sans-serif;
        font-weight: bold;
        color: #0f0f0f;
        line-height: 40px;
        margin-top: 21px;
      }
    }
  }
}
</style>
