import { render, staticRenderFns } from "./VueCropper.vue?vue&type=template&id=2e3539b4&scoped=true"
import script from "./VueCropper.vue?vue&type=script&lang=js"
export * from "./VueCropper.vue?vue&type=script&lang=js"
import style0 from "./VueCropper.vue?vue&type=style&index=0&id=2e3539b4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e3539b4",
  null
  
)

export default component.exports