<template>
  <el-dialog :title="title" lock-scroll :visible.sync="visible" width="600px">
    <div class="select-tag">
      <img class="img" :src="src" alt="">
      <span v-if="label" :style="`top: ${top};left: ${left}`" class="label">{{ label }}</span>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="visible = false">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'LiveMap',
  props: {
    src: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    top: {
      type: String
    },
    left: {
      type: String
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible: {
      get: function() {
        return this.value
      },
      set: function(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
<style scoped lang="scss">
// scss
.select-tag {
    text-align: center;
    margin: 0 auto;
    position: relative;
    .img {
        width: 150px;
        object-fit: cover;
        margin-bottom: 20px;
    }
    .label {
        color: #4265E3;
        position: absolute;
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            width: 100px;
            height: 1px;
            background-color: #4265E3;
        }
    }
    .dialog-footer {
        text-align: center;
    }
}
</style>