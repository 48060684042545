<template>
  <div>
    <el-dialog
      class="packet-details"
      title="红包详情"
      :visible.sync="packetDetaildialogVisible"
      destroy-on-close
      width="60%"
      @open="open"
    >
      <el-table v-loading="packetListLoading" size="small" :data="packetList">
        <el-table-column
          align="center"
          width="180"
          prop="sendTime"
          label="红包发放时间"
        />
        <el-table-column align="center" label="红包类型">
          <template slot-scope="scoped">
            {{ scoped.row.packetType | type(scoped.row.distributeType) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="totalCount" label="发放个数" />
        <el-table-column align="center" prop="receiveCount" label="领取个数" />
        <el-table-column align="center" prop="withDrawCount" label="撤回个数" />
        <el-table-column align="center" prop="remainCount" label="剩余个数" />
        <el-table-column align="center" width="150" label="操作">
          <template slot-scope="scoped">
            <span>
              <el-button type="text" :disabled="scoped.row.redPacketStatus !== 1" @click="handelRecall(scoped.row)">撤回</el-button>
              <el-button :disabled="scoped.row.packetType !== 1" type="text" @click="handelCheck(scoped.row)">查看</el-button>
              <el-button v-if="scoped.row.packetType === 1" type="text" @click="onDownLoad(scoped.row)">导出</el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
      <div slot="footer" style="text-align: center;">
        <el-button plain @click="packetDetaildialogVisible = false">我知道了</el-button>
      </div>
    </el-dialog>
    <!-- 查看答题详情 -->
    <packet-answer v-model="answerdialogVisible" :red-packet-id="redPacketId" :exam-list="examList" />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import {
  exportRedPacketExamDetail,
  getLiveRedPacketList,
  getRedPacketExamDetail,
  recallIMRedEnvelope
} from '@/services/liveService'
import PacketAnswer from './packet-answer.vue'

export default {
  name: 'PacketDetails',
  components: { PacketAnswer },
  filters: {
    type(packetType, distributeType) {
      switch (packetType) {
        case 0:
          return distributeType === 0 ? '普通积分红包' : '随机手气红包'
        case 1:
          return '答题红包'
        case 2:
          return '观看时长限制红包'
        default:
          return '普通红包'
      }
    }
  },
  mixins: [paginationMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    redPacketId: {
      type: String,
      require: true
    },
    getPacketTime: {
      type: Function,
      require: true
    }
  },
  data() {
    return {
      search: {
        liveId: this.$route.params.liveId
      },
      packetList: [],
      examList: [],
      packetListLoading: false,
      answerdialogVisible: false
    }
  },
  computed: {
    packetDetaildialogVisible: {
      get: function() {
        return this.value
      },
      set: function(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    /**
     * 获取列表数据
     * 调用时机：分页
     */
    async getDataList() {
      this.packetListLoading = true
      try {
        const params = this.getParams(this.search)
        const {
          data: { data, totalCount }
        } = await getLiveRedPacketList(params)

        if (data.remainCount !== 0) {
          // this.packetDetaildialogVisible = true
        } else {
          this.getPacketTime()
        }
        this.packetList = data || []
        this.packetListLoading = false
        this.pagination.total = totalCount
      } catch (error) {
        console.log(error)
        this.packetListLoading = false
      }
    },
    open() {
      this.getDataList()
    },
    /**
     * 撤回红包
     * 调用时机：点击撤回
     */
    handelRecall(row) {
      // if (row.remainMoney < 1) {
      //   this.$message.info('红包已发放完毕，可重新创建红包')
      //   return
      // }
      this.$confirm('此操作将撤回该红包, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        try {
          this.packetListLoading = true
          const params = {
            redPacketId: row.redPacketId
          }
          await recallIMRedEnvelope(params)
          this.$message.success('红包撤回成功')
          this.getPacketTime()
          this.packetDetaildialogVisible = false
          this.packetListLoading = false
        } catch (error) {
          this.packetListLoading = false
          this.$message.error(error)
        }
      })
    },
    /**
     * 查看答题情况
     * 调用时机：点击查看
     */
    async handelCheck(row) {
      this.answerdialogVisible = true
      try {
        const res = await getRedPacketExamDetail({redPacketId: row.redPacketId})
        this.examList = res.data.sort(this.compare('examIndex'))
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * 导出某个答题红包对应的医生答题信息列表
     * 调用时机：点击导出
     */
    async onDownLoad(row) {
      try {
        const res = await exportRedPacketExamDetail({redPacketId: row.redPacketId})
        this.$message.success(res.data)
      } catch (error) {
        this.$message.error(error)
      }
    },
    compare(p) { // 这是比较函数
      return function(m, n) {
        const a = m[p]
        const b = n[p]
        return a - b // 升序
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.packet-details {
  ::v-deep .el-dialog__body {
    padding: 20px !important;
    .pagination {
      margin-top: 20px;
    }
  }
}
</style>
