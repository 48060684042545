<template>
  <div>
    <el-checkbox
      v-if="isShowSelect"
      :value="isSelectAll"
      :indeterminate="isHasSelect"
      label="全部"
      style="margin-bottom: 10px;"
      @change="onChangeAll"
    />
    <div v-if="!(list && list.length)" style="height: 60px; color: #909399; text-align: center;">暂无数据</div>
    <div v-else ref="scroll-wrapper" class="scroll-wrapper">
      <div v-for="item in initList" :key="item.id" class="col">
        <academic-scene-item
          :item="checkedKeys(item)"
          :scene-type="sceneType"
          :is-show-select="isShowSelect"
          @onSelect="onSelect"
          @onJumpDetail="onJumpDetail(item.id)"
          @onAllot="onAllot(item.id, item.canEdit)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AcademicSceneItem from '@/bizComponents/AcademicSceneItem'
import {List} from 'immutable'

export default {
  name: 'SceneChildCard',
  components: {
    AcademicSceneItem
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    defaultCheckedKeys: {
      type: Array,
      default: () => []
    },
    isCreator: {
      type: Boolean,
      default: false
    },
    isShowSelect: {
      type: Boolean,
      default: false
    },
    sceneType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectList: [],
      initList: [],
      isSelectAll: false,
      isHasSelect: false
    }
  },
  watch: {
    list(val) {
      this.initList = List(val).toArray()
      this.onChangeAll(false)
    },
    defaultCheckedKeys(val) {
      if (!val?.length) {
        this.isHasSelect = false
        this.isSelectAll = false
        this.selectList =  []
      }
    }
  },
  methods: {
    // -------------------- 工具函数 --------------------
    onSelectItem(item) {
      const list = []
      let num = 0
      for (const it of this.selectList) {
        ++num
        if (item.id === it.id) {
          it.isSelect = item.isSelect
        }
        if (it.isSelect) {
          this.isHasSelect = true
          list.push(it)
        }
      }
      if (num === this.selectList.length) {
        if (item.isSelect) {
          this.isHasSelect = true
          list.push(item)
        }
      }
      if (list.length === this.initList.length) {
        this.isSelectAll = true
        this.isHasSelect = false
      }
      if (list.length === 0) {
        this.isSelectAll = false
        this.isHasSelect = false
      }
      this.selectList = list
    },
    onSelectAll(val) {
      if (val) {
        this.isHasSelect = false
      }
      this.initList = this.initList.map(item => {
        this.$set(item, 'isSelect', val)
        return item
      })
      this.selectList = val ? this.initList : []
    },
    checkedKeys(item) {
      return {...item, isSelect: this.defaultCheckedKeys.includes(item.id)}
    },
    // -------------------- 工具函数 --------------------
    onAllot(sceneId = '', canEdit) {
      this.$router.push(`/academic/scene/${this.sceneType}/rule/${sceneId}?disabledBack=${!canEdit}`)
    },
    onJumpDetail(sceneId = '') {
      this.$router.push(`/academic/${this.sceneType}/detail/${sceneId}?isCreator=${this.isCreator}`)
    },
    onChangeAll(val) {
      this.isSelectAll = val
      this.isHasSelect = false
      this.onSelectAll(val)
      this.$emit('onSelect', this.selectList)
    },
    onSelect(val) {
      console.log(val, '111111111111111')
      this.onSelectItem(val)
      this.$emit('onSelect', this.selectList)
    }
  }
}
</script>
<style lang="scss" scoped>
.scroll-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  .col {
    border: 1px solid #eee;
  }
}
</style>
