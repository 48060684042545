<!--
 * @Author: your name
 * @Date: 2021-08-31 16:29:51
 * @LastEditTime: 2022-01-05 12:04:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/academic/child/MeetingCommentItem.vue
-->
<template>
  <div class="comment-item" @click="checkAll" v-loading="loading">
    <el-row type="flex" justify="space-between" class="box-line pdg-b20">
      <div>
        <el-row type="flex">
          <el-avatar icon="el-icon-user-solid" class="flex-shrink user-photo" fit="fill" :src="item.avatar"></el-avatar>
          <div>
            <div class="comment-name">
              <span>{{ item.userName }}：{{ item.content }}</span>
              <span class="comment-label" v-if="item.topCommentStatus">置顶</span>
              <span class="comment-label" v-if="item.selectiveCommentStatus">精选</span>
            </div>
            <div class="comment-time">{{ item.dateTime }}</div>
          </div>
        </el-row>
        <!-- <el-row type="flex" class="mgn-t10">
          <span class="tag-base">问</span>
          <span>{{item.content}}</span>
        </el-row> -->
      </div>
      <el-row type="flex" align="middle" v-if="canSetting" class="flex-shrink">
        <span class="mgn-l14 text-info-link" @click.stop="onSetTop">
          <i class="el-icon-thumb mgn-r4"></i>
          <span>{{ item.topCommentStatus ? '取消置顶' : '置顶评论' }}</span>
        </span>
        <span class="mgn-l14 text-info-link" @click.stop="onSetActive">
          <i :class="{ 'el-icon-star-on active-start': item.selectiveCommentStatus, 'el-icon-star-off': !item.selectiveCommentStatus }" class="mgn-r4" style="font-size:16px;"></i>
          <span>{{ item.selectiveCommentStatus ? '取消精选' : '设为精选' }}</span>
        </span>
        <el-popover placement="bottom" width="360" v-model="actionMap[item.commentId]">
          <div>
            <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="textarea"> </el-input>
            <el-row type="flex" style="padding-top:15px;" justify="end">
              <span class="text-primary-link" @click="actionMap[item.commentId] = false">取消</span>
              <span class="text-primary-link mgn-l14" @click="onReply(item)">发送回复</span>
            </el-row>
          </div>
          <span class="mgn-l14 text-info-link" slot="reference" @click.stop="">回复</span>
        </el-popover>
        <span class="mgn-l14 text-info-link" @click.stop="onShield">{{ !!item.status ? '屏蔽评论' : '解除屏蔽' }}</span>
        <el-popover placement="bottom" width="370" trigger="hover">
          <div class="font-s16 col272e40">
            <div>什么是屏蔽评论？</div>
            <div>点击“屏蔽评论”后，该评论将对其他用户不可见，</div>
            <div>仅活动创建者，评论者本人可见，该操作可解除。</div>
          </div>
          <img slot="reference" src="../../../assets/iconImg/icon-mark.png" alt="" class="tips-image" />
        </el-popover>
        <i class="el-icon-caret-bottom rotate-ad" :class="{ active: showReply }"></i>
      </el-row>
    </el-row>
    <div class="collapse-box" v-show="showReply">
      <div class="reply box-line" v-for="(reply, index) in replyList" v-bind:key="index">
        <el-row type="flex" justify="space-between">
          <el-row type="flex">
            <el-avatar icon="el-icon-user-solid" class="flex-shrink user-photo" fit="fill" :src="reply.avatar"></el-avatar>
            <div>
              <div class="comment-name">{{ reply.userName }}</div>
              <div class="comment-time">{{ reply.dateTime }}</div>
            </div>
          </el-row>
          <el-row type="flex" v-if="canSetting">
            <el-popover placement="bottom" width="360" v-model="actionMap[reply.commentId]">
              <div>
                <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="textarea"> </el-input>
                <el-row type="flex" style="padding-top:15px;" justify="end">
                  <span class="text-primary-link" @click="actionMap[reply.commentId] = false">取消</span>
                  <span class="text-primary-link mgn-l14" @click="onReply(reply)">发送回复</span>
                </el-row>
              </div>
              <span class="mgn-l14 text-info-link" slot="reference" @click.stop="">回复</span>
            </el-popover>
            <span class="mgn-l14 text-info-link" @click.stop="onShieldReply(reply)">{{ !!reply.status ? '屏蔽评论' : '解除屏蔽' }}</span>
          </el-row>
        </el-row>
        <el-row type="flex" class="mgn-t10 mgn-l20">
          <span>回复{{ reply.toUserName }}：{{ reply.content }}</span>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'SceneCommentItem',
  data() {
    return {
      replyList: [],
      loading: false,
      isLoad: false,
      actionMap: {},
      textarea: '',
      showReply: false,
    }
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    sceneType: {
      type: null,
      default: '',
    },
    sceneId: {
      type: null,
      default: '',
    },
    canSetting: {
      type: Boolean,
      default: false,
    },
  },
  // watch:{
  // 	item(newVal){
  // 		this.digg = newVal.isDigg
  // 	}
  // },
  computed: {},
  methods: {
    ...mapActions(['getSceneReplyList', 'onReplyComment', 'onShieldSceneComment']),
    checkAll() {
      if (this.loading || this.isLoad) {
        this.showReply = !this.showReply
        return
      }
      this.loading = true
      this.getSceneReplyList(this.item.commentId).then(
        (res) => {
          this.replyList = res
          this.loading = false
          this.isLoad = true
          this.$nextTick(() => {
            this.showReply = true
          })
        },
        (rea) => {
          this.loading = false
        }
      )
    },
    onReply(item) {
      let text = this.textarea.trim()
      if (!text) return this.$message.error('请输入回复内容')
      this.onReplyComment({ parentCommentId: item.commentId, content: text, sceneType: this.sceneType, sceneId: this.sceneId }).then((res) => {
        this.textarea = ''
        this.actionMap[item.commentId] = false
        this.showReply = true
        this.replyList = [...this.replyList, res]
        this.$nextTick(() => {})
        this.$message.success('回复成功')
      })
    },
    onShield() {
      this.$emit('onShield')
    },
    onShieldReply(item) {
      this.onShieldSceneComment({ commentId: item.commentId, isOpen: !item.status, sceneId: this.sceneId, sceneType: this.sceneType }).then((res) => {
        this.replyList = [...this.replyList].map((v) => {
          if (v.commentId === item.commentId) {
            v = { ...v, status: !item.status }
          }
          return v
        })
      })
    },
    onSetActive() {
      if (!this.item.status) return this.$message.error('请先解除屏蔽')
      this.$emit('onSetActive')
    },
    onSetTop() {
      if (!this.item.status) return this.$message.error('请先解除屏蔽')
      this.$emit('onSetTop')
    },
  },
}
</script>
<style lang="scss" scoped>
.comment-item {
  cursor: pointer;
  padding-top: 20px;
  .box-line {
    border-bottom: 1px solid rgb(233, 234, 235);
  }
  .user-photo {
    margin-right: 10px;
  }
  .comment-name {
    font-size: 14px;
    color: #111;
    padding-bottom: 5px;
    font-weight: bold;
    display: flex;
  }
  .comment-label {
    width: 50px;
    height: 18px;
    border-radius: 11px;
    font-size: 12px;
    text-align: center;
    color: #3d61e3;
    flex-shrink: 0;
    margin-left: 12px;
    border: 1px solid #3d61e3;
  }
  .comment-time {
    color: rgb(119, 132, 161);
  }
  .active-start {
    color: #ff9100;
  }
  .tips-image {
    margin-top: 2px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-left: 6px;
  }
  .rotate-ad {
    font-size: 16px;
    cursor: pointer;
    margin-left: 15px;
    transition: all 150ms;
    &.active {
      transform: rotate(180deg);
    }
  }
  .collapse-box {
    overflow: hidden;
    padding-left: 50px;
  }
  .reply {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .tag-base {
    width: 20px;
    height: 20px;
    background: #fb4e62;
    color: #fff;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    margin-right: 15px;
    margin-left: 20px;
  }
}
</style>
