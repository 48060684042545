<template>
  <div>
    <template v-for="item in schemeList">
      <BaseIndexVue
        :key="item.id"
        :item="item"
        :root="schemeList[0]"
      />
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {isMobile} from '@/utils/util.js'
export default {
  name: 'CommonMaterial',
  data() {
    return {
      isMobile: isMobile(),
      activeTabName: '',
      // schemeList
      schemeList: [
        {
          that: 'this',
          baseVue: 'BasePageMain',
          title: '公共资源库 - 学术资料数据',
          back: true,
          data: {
            auditStatus: 'one',
            searchObj: {
              tagAndGroupIds: [],
              sceneStatus: '',
              pageNo: 1,
              pageSize: 10,
              startTime: null,
              endTime: null
            },
            initDate: [],
            tagItemList: {
              PRODUCT_TAG_GROUP: [],
              SPREAD_DEPARTMENT_GROUP: []
            },
            tableData: [],
            total: 0
          },
          init: function({root, runInitFun}) {
            const { onGetTagGroup, getTagList, getTableDataList, onGetDate } = root.initFun
            runInitFun(onGetDate)
            runInitFun(onGetTagGroup).then(res => {
              res.forEach(item => {
                runInitFun(getTagList, {tagGroupId: item.id, groupCode: item.groupCode})
              })
            })
            runInitFun(getTableDataList)
          },
          initFun: {
            onGetDate: function(options = 'one', {root, setRootItem}) {
              const obj = {
                one: 1,
                three: 3,
                six: 6
              }
              const num = obj[options]
              const end = `${this.$moment(new Date()).format('YYYY-MM-DD')  } ` + '23:59:59'
              let start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * num * 30)
              start = `${this.$moment(start).format('YYYY-MM-DD')  } ` + '00:00:00'
              setRootItem(root.data.searchObj, 'startTime', start)
              setRootItem(root.data.searchObj, 'endTime', end)
              setRootItem(root.data, 'initDate', [start, end])

            },
            onGetTagGroup: function(options, {root, setRootItem}) {
              return this.$axios.get(this.$API.getTagGroup).then(res => {
                console.log(res)
                const {data = []} = res
                const groupList = []
                const list = []
                data.forEach(v => {
                  if (v.groupCode === 'PRODUCT_TAG_GROUP') {
                    list[0] = v
                    groupList[0] = {
                      groupId: v.id,
                      tagIds: []
                    }
                  } else if (v.groupCode === 'SPREAD_DEPARTMENT_GROUP') {
                    list[1] = v
                    groupList[1] = {
                      groupId: v.id,
                      tagIds: []
                    }
                  }
                }, rea => this.$message.error(rea.message))
                setRootItem(root, 'data.searchObj.tagAndGroupIds', groupList)
                return list
              })
            },
            // 请求获取分组标签列表
            getTagList: function({tagGroupId, groupCode}, {setRootItem, root}) {
              setRootItem(root, `data.${groupCode}_ID`, tagGroupId)
              const params = {tagGroupId}
              this.$axios.get(this.$API.tagList, { params }).then(res => {
                const {data} = res
                const list = []
                data.map(item => {
                  list.push({label: item.tagName, value: item.tagId})
                })
                setRootItem(root.data.tagItemList, groupCode, list)
              }, rea => this.$message.error(rea.message))
            },
            // 请求table列表
            getTableDataList: function(options, {root, setRootItem}) {
              console.log(root.data.searchObj, 'searchObj')
              const reqData = root.data.searchObj
              this.$axios.post(this.$API.literatureDataList, reqData).then(res => {
                console.log(res)
                const {data, totalCount} = res.data || {}
                setRootItem(root, 'data.tableData', data)
                setRootItem(root, 'data.total', totalCount)
              }, rea => this.$message.error(rea.message))
            }
          },
          childSchemeList: [
            {
              baseVue: 'BaseRow',
              colCount: 2,
              style: {
                paddingTop: '10px'
              },
              colStyle: [
                {
                  display: 'flex'
                },
                {
                  textAlign: 'right',
                  paddingRight: '30px'
                }
              ],
              childSchemeList: [
                // 时间段搜索
                {
                  id: this.$nanoid(),
                  baseVue: 'BaseButtonGroup',
                  columnIndex: 1,
                  key: 'data.auditStatus',
                  value: 'getRootItem(root, item.key)',
                  style: {
                    border: '1px solid #e1e1e1'
                  },
                  btnStyle: {
                    marginLeft: 0,
                    borderLeft: '1px solid #e1e1e1',
                    width: '120px'
                  },
                  buttonList: [
                    {
                      label: 'one',
                      name: '近1个月',
                      style: {
                        borderLeft: ''
                      }
                    },
                    {
                      label: 'three',
                      name: '近3个月'
                    },
                    {
                      label: 'six',
                      name: '近半年'
                    }
                  ],
                  methods: {
                    click: function(options, {root, runInitFun, setRootItem}) {
                      console.log(options, ' ============== tab =================')
                      setRootItem(root.data, 'auditStatus', options.value)
                      setRootItem(root.data, 'initDate', [])
                      setRootItem(root.data.searchObj, 'pageNo', 1)
                      const {onGetDate, getTableDataList} = root.initFun
                      runInitFun(onGetDate, options.value)
                      runInitFun(getTableDataList)
                    }
                  }
                },
                // 时间搜索
                {
                  id: this.$nanoid(),
                  columnIndex: 1,
                  baseVue: 'BaseDatePicker',
                  key: 'data.initDate',
                  value: 'getRootItem(root, item.key)',
                  type: 'daterange',
                  clearable: false,
                  startPlaceholder: '开始时间',
                  endPlaceholder: '结束时间',
                  defaultTime: ['00:00:00', '23:59:59'],
                  valueFormat: 'yyyy-MM-dd HH:mm:ss',
                  minDate: null,
                  maxDate: null,
                  pickerOptions: function({item}) {
                    return {
                      disabledDate: function(time) {
                        if (item.minDate !== null && item.maxDate === null) {
                          const minMonth = item.minDate.getMonth()
                          const lastYear = new Date(item.minDate).setMonth(minMonth - 12)
                          const nextYear = new Date(item.minDate).setMonth(minMonth + 12)
                          return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf()
                        }
                        return false
                      },
                      onPick: function({minDate, maxDate}) {
                        item.minDate = minDate
                        item.maxDate = maxDate
                      }
                    }
                  },
                  style: {
                    marginLeft: '10px',
                    color: '#5a5a5a',
                    width: '270px'
                  },
                  methods: {
                    change: function(options, {root, runInitFun, setRootItem}) {
                      setRootItem(root.data, 'auditStatus', '')
                      setRootItem(root.data.searchObj, 'startTime', options.value[0])
                      setRootItem(root.data.searchObj, 'endTime', options.value[1])
                      setRootItem(root.data.searchObj, 'pageNo', 1)
                      setRootItem(root.data, 'initDate', [options.value[0], options.value[1]])
                      const {getTableDataList} = root.initFun
                      runInitFun(getTableDataList)
                    }
                  }
                },

                // 产品标签搜索
                {
                  columnIndex: 1,
                  this: this,
                  baseVue: 'BaseSelect',
                  placeholder: '产品标签',
                  class: 'select-complex',
                  selectClass: 'select',
                  style: {
                    width: '120px',
                    marginLeft: '10px'
                  },
                  clearable: true,
                  value: '',
                  key: 'PRODUCT_TAG_GROUP',
                  selectList: 'getRootItem(root,item.selectListKey)',
                  selectListKey: 'data.tagItemList.PRODUCT_TAG_GROUP',
                  methods: {
                    change: function(options, {root, setRootItem, runInitFun}) {
                      setRootItem(root.data.searchObj.tagAndGroupIds[0], 'tagIds', options.value ? [options.value] : [])
                      setRootItem(root.data.searchObj, 'pageNo', 1)
                      const {getTableDataList} = root.initFun
                      runInitFun(getTableDataList)
                    }
                  }
                },
                // 科室标签搜索
                {
                  columnIndex: 1,
                  this: this,
                  baseVue: 'BaseSelect',
                  placeholder: '推广科室',
                  class: 'select-complex',
                  selectClass: 'select',
                  multiple: true,
                  style: {
                    width: '120px',
                    marginLeft: '10px'
                  },
                  clearable: true,
                  value: '',
                  key: 'SPREAD_DEPARTMENT_GROUP',
                  selectList: 'getRootItem(root,item.selectListKey)',
                  selectListKey: 'data.tagItemList.SPREAD_DEPARTMENT_GROUP',
                  methods: {
                    change: function(options, {root, setRootItem, runInitFun}) {
                      setRootItem(root.data.searchObj.tagAndGroupIds[1], 'tagIds', options.value)
                      setRootItem(root.data.searchObj, 'pageNo', 1)
                      const {getTableDataList} = root.initFun
                      runInitFun(getTableDataList)
                    }
                  }
                },
                // 状态搜索
                {
                  columnIndex: 1,
                  baseVue: 'BaseSelect',
                  placeholder: '任务状态',
                  style: {
                    width: '120px',
                    marginLeft: '10px'
                  },
                  clearable: true,
                  value: '',
                  key: 'type',
                  selectList: [
                    { label: '未完成', value: '0' },
                    { label: '已完成', value: '2' }
                  ],
                  methods: {
                    change: function(options, {root, setRootItem, runInitFun}) {
                      console.log(options)
                      const type = {
                        '0': 0,
                        '2': 2
                      }
                      setRootItem(root.data.searchObj, 'sceneStatus', type[options.value],)
                      setRootItem(root.data.searchObj, 'pageNo', 1)
                      const {getTableDataList} = root.initFun
                      runInitFun(getTableDataList)
                    }
                  }
                },
                // 导出按钮
                {
                  baseVue: 'BaseButton',
                  columnIndex: 2,
                  type: 'text',
                  text: '导出为Excel',
                  isShow: function() {
                    return !this.isMobile
                  },
                  methods: {
                    click: function(options, {root}) {
                      this.$axios.post(this.$API.literatureDataDownload, root.data.searchObj).then(res => {
                        this.$message.success(res.data)
                      }, rea => this.$message.error(rea.message))
                    }
                  }
                }
              ]
            },
            // table
            {
              baseVue: 'BaseTable',
              style: {
                marginTop: '10px'
              },
              methods: {
                selectionChange: options => {
                  console.log(options)
                }
              },
              tableData: 'getRootItem(root,item.tableDataKey)',
              tableDataKey: 'data.tableData',
              tableColumn: [
                {
                  label: '引用代表',
                  prop: 'refUserName',
                  fixed: 'left'
                },
                {
                  label: '推送对象',
                  prop: 'pushUserName',
                  align: 'center'
                },
                {
                  label: '推送时间',
                  prop: 'pushTime',
                  align: 'center'
                },
                {
                  label: '任务状态',
                  prop: 'sceneStatus',
                  align: 'center',
                  slot: {
                    column: [
                      {
                        baseVue: 'BaseSpan',
                        style: {
                          justifyContent: 'center'
                        },
                        text: function({item}) {
                          const obj = {
                            '0': '未完成',
                            '2': '已完成'
                          }
                          return obj[String(item.row.sceneStatus)]
                        }
                      }
                    ]
                  }
                },
                {
                  label: '资料名称',
                  prop: 'resourceTitle',
                  align: 'center'
                },
                {
                  label: '产品标签',
                  prop: 'projectTag',
                  align: 'center'
                },
                {
                  label: '推广科室标签',
                  prop: 'promotionDeptTags',
                  align: 'center'
                },
                {
                  label: '代表所属组织',
                  prop: 'createOrgName',
                  align: 'center'
                }

              ]
            },
            // 分页器
            {
              baseVue: 'BasePagination',
              pageSize: 'getRootItem(root,item.pageSizeKey)',
              pageSizeKey: 'data.searchObj.pageSize',
              currentPage: 'getRootItem(root,item.currentPageKey)',
              currentPageKey: 'data.searchObj.pageNo',
              pageSizes: [10, 20, 50, 100],
              background: true,
              total: 'getRootItem(root, item.totalKey)',
              totalKey: 'data.total',
              layout: 'total, sizes, prev, pager, next',
              small: true,
              style: {
                marginTop: '10px'
              },
              methods: {
                currentChange: function(options, {root, setRootItem, runInitFun}) {
                  console.log(options)
                  setRootItem(root.data.searchObj, 'pageNo', options.value)
                  const {getTableDataList} = root.initFun
                  runInitFun(getTableDataList)
                },
                sizeChange: function(options, {root, setRootItem, runInitFun}) {
                  console.log(options)
                  setRootItem(root, 'data.searchObj.pageNo', 1)
                  setRootItem(root, 'data.searchObj.pageSize', options.value)
                  const {getTableDataList} = root.initFun
                  runInitFun(getTableDataList)
                }
              }
            }
          ]
        }
      ]

    }
  },
  computed: {
  },
  created() {
    this.schemeList[0].that = this
  },
  mounted() {
    this.getTagGroup()
  },
  methods: {
    ...mapActions(['getTagGroup'])
  }

}
</script>

