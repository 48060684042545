<template>
  <div class="packet-send-ordinary">
    <el-dialog
      title="普通积分红包"
      :visible.sync="dialogVisible"
      destroy-on-close
      width="40%"
    >
      <el-form
        ref="form"
        v-loading="loading"
        :model="form"
        hide-required-asterisk
        :rules="formRules"
        label-width="100px"
        label-position="left"
        label-suffix="："
      >
        <el-form-item label="参与用户" prop="targetUser">
          <el-select v-model="form.targetUser" style="width: 320px;" class="form-label">
            <el-option :value="1" label="平台已认证医生" />
            <el-option :value="2" label="企业绑定医生" />
            <el-option :value="3" label="组织绑定医生" />
          </el-select>
        </el-form-item>
        <el-form-item label="红包类型" prop="distributeType">
          <el-select v-model="form.distributeType" style="width: 320px;" class="form-label">
            <el-option :value="0" label="普通积分红包" />
            <el-option :value="1" label="随机手气红包" />
          </el-select>
        </el-form-item>
        <el-form-item label="红包个数" prop="count">
          <el-input
            v-model.number.trim="form.count"
            style="width: 320px;"
            class="form-label input"
            placeholder="请输入红包个数"
          />
        </el-form-item>
        <el-form-item v-if="form.distributeType === 0" label="单个面额" prop="oneAmount">
          <el-input
            v-model.number.trim="form.oneAmount"
            style="width: 320px;"
            class="form-label input"
            placeholder="请输入单个面额"
          />
        </el-form-item>
        <el-form-item v-else-if="form.distributeType === 1" label="总金额" prop="amount">
          <el-input
            v-model.number.trim="form.amount"
            style="width: 320px;"
            class="form-label input"
            placeholder="请输入总面额"
          />
        </el-form-item>
        <el-form-item label="掉落时间" prop="interval">
          <el-select v-model="form.interval" style="width: 320px;" class="form-label">
            <el-option :value="60" label="1分钟后" />
            <el-option :value="120" label="2分钟后" />
            <el-option :value="180" label="3分钟后" />
            <el-option :value="240" label="4分钟后" />
            <el-option :value="300" label="5分钟后" />
            <el-option :value="0" label="立即掉落" />
          </el-select>
        </el-form-item>
        <el-form-item label="祝福语" prop="blessWorld">
          <el-input
            v-model.trim="form.blessWorld"
            style="width: 320px;"
            :maxlength="20"
            class="form-label input"
            placeholder="最多20字"
          />
        </el-form-item>
      </el-form>
      <div class="note">
        备注：请保证您的积分账户可余额充足，观众领取的红包积分将会自动存入账户。
      </div>
      <div class="count-num">
        <span class="label">本次红包累计：</span><span class="num">{{ (form.distributeType === 1?form.amount:form.oneAmount*form.count)|| 0 }}<span class="unit">积分</span></span>
      </div>
      <div slot="footer" style="text-align: center;" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="onSubmit">确认发布红包</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { useSendMsg } from '@/views/academic/tim/components/MsgSendBox/useSendMsg'
import { submitRedEnvelope } from '@/services/liveService'
export default {
  name: 'PacketSendOrdinary',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    getPacketTime: {
      type: Function,
      require: true
    }
  },
  data() {
    return {
      loading: false,
      form: {
        amount: '',
        count: '',
        distributeType: 0,
        packetType: 0,
        uid: '',
        interval: 60,
        liveId: '',
        blessWorld: '',
        targetUser: 1,
        oneAmount: ''
      },
      formRules: {
        amount: [
          { required: true, message: '请填写金额', trigger: 'blur' },
          { type: 'number', message: '总金额应该是数字', trigger: 'blur' }
        ],
        count: [
          { type: 'number', required: true, message: '请填写红包个数', trigger: 'blur' },
          { type: 'number', message: '红包个数应该是数字', trigger: 'blur' }
        ],
        blessWorld: [{ required: true, message: '请填写祝福语', trigger: 'blur' }]
      }
    }
  },
  computed: {
    dialogVisible: {
      get: function() {
        return this.value
      },
      set: function(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    this.form.liveId = this.$route.params.liveId
  },
  methods: {
    onSubmit() {
      if (this.loading) return
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            const params = Object.assign({}, this.form, { liveId: this.$route.params.liveId })
            if (params.distributeType === 0) {
              params.amount = params.count * params.oneAmount
            }
            delete params.oneAmount
            await submitRedEnvelope(params)
            this.$message.success('红包发放成功！')
            this.sendPacketMsg()
            this.getPacketTime()
            this.form = this.$options.data().form
            this.loading = false
            this.dialogVisible = false
          } catch ({ message }) {
            this.$message({
              type: 'error',
              message: message
            })
            this.loading = false
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 发送红包自定义消息
    sendPacketMsg() {
      const { onSend } = useSendMsg({
        type: 'createCustomMessage',
        payload: {
          description: 'send_chatroom_red_envelope'
        }
      })
      onSend()
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.packet-send-ordinary {
  ::v-deep .el-dialog__body {
    padding-bottom: 20px;
    .total-count {
      height: 100px;
      background-color: #f5f5f5;
      color: #0f0f0f;
      text-align: center;
      margin-bottom: 27px;
      line-height: 100px;
      .num {
        font-size: 40px;
        font-family: DINAlternate-Bold, DINAlternate, sans-serif;
        font-weight: bold;
        color: #0f0f0f;
        line-height: 40px;
      }
    }
    .form-label {
      width: 200px;
      .selected {
        border: none;
      }
    }
    .note {
      font-size: 14px;
      font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
      font-weight: 400;
      color: #b4b4b4;
      line-height: 22px;
      margin-bottom: 20px;
    }
    .count-num {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e1e1e1;
      padding: 20px 0;
      .num {
        color: #ff9100;
        font-size: 20px;
        display: flex;
        align-items: center;
        .unit {
          font-size: 14px;
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
