<!--
 * @Author: your name
 * @Date: 2021-08-19 15:14:53
 * @LastEditTime: 2021-08-19 15:49:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/academic/child/WebinarSpeakerListModal.vue
-->
<template>
  <el-dialog
    :title='title'
    width="30%"
    :visible.sync="visible"
    :show-close='false'
    :close-on-click-modal='false'
    :close-on-press-escape='false'
  >
    <el-table
      :data='speakerList'
      height="300"
    >
      <el-table-column
        label='头像'
        prop="avatar"
      >
        <template slot-scope="scope">
          <img v-if="scope.row.avatarVO" :src="scope.row.avatarVO.middle" alt="" style="width: 40px; height: 40px; border-radius: 50%;">
        </template>
      </el-table-column>
      <el-table-column
        label='播客姓名'
        prop="name"
        center
      >
      </el-table-column>
      <el-table-column
        label='职称'
        prop="titleName"
        center
      />
      <el-table-column
        label='机构'
        prop="hospital"
        show-overflow-tooltip
        center
      />
      <el-table-column
        label='科室'
        prop="department"
        center
      />
    </el-table>
    <el-row justify="center" type="flex" slot="footer">
      <el-button type="primary"  @click="onClose">我知道了</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
export default {
  name: 'WebinarSpeakerListModal',
  data () {
    return {

    }
  },
  props :{
    title:{
      type: String,
      default: '嘉宾详情'
    },
    visible: {
      type: Boolean,
      default: false,
    },
    speakerList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    onClose () {
      this.$emit('onClose')
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
