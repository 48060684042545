<template>
  <el-dialog
    class="select-represent-modal"
    :title="title"
    width="60"
    :visible.sync="visible"
    v-bind="$attrs"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @opened="opened"
    @close="onCloseModel"
    v-on="$listeners"
  >
    <el-row
      class="lay-box"
      type="flex"
      justify="space-between"
      align="middle"
    >
      <el-input
        v-model="form.searchKey"
        clearable
        placeholder="请输入医生名称、医院名称"
        class="search-input"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          type="primary"
          round
          @click="onSearch"
        />
      </el-input>
      <div class="font-size-small">已选 {{ selectedList.length }}人</div>
    </el-row>
    <div class="lay-box">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        size="small"
        :data="doctorList"
        stripe
        :height="300"
        style="width: 100%;"
        :row-key="(row) => row.doctorId"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          :selectable="(row) => !row.isCancel && !row.isFrozen"
          type="selection"
          reserve-selection
          width="55"
          align="center"
        />
        <el-table-column
          prop="doctorName"
          label="医生姓名"
          fixed
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.doctorName }}
              <span v-if="scope.row.isCancel" style="color: #ff4921; padding-left: 3px;">(已注销)</span>
              <span v-if="scope.row.isFrozen" style="color: #fb9c35; padding-left: 3px;">(已冻结)</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="titleName" align="center" label="所属职称" />
        <el-table-column
          prop="hospital"
          align="center"
          show-overflow-tooltip
          label="医院"
          width="200"
        />
        <el-table-column
          prop="hospital"
          align="center"
          label="代表"
          width="200"
        >
          <template slot-scope="scoped">
            <el-select
              :key="scoped.row.selectId"
              v-model="scoped.row.selectId"
              size="mini"
              placeholder="请选择"
              @change="onSelectChange($event, scoped.row)"
            >
              <el-option
                v-for="item in scoped.row.representInfoList"
                :key="item | selectId"
                :label="item | selectName"
                :value="item | selectId"
              />
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="end" class="t-p">
        <el-pagination
          background
          layout="prev, pager, next, total, sizes"
          :page-sizes="[10, 50, 100, 500]"
          :page-size="form.pageSize"
          :current-page="form.pageNo"
          :total="totalCount"
          @size-change="onPageSizeChange"
          @current-change="onChange"
        />
      </el-row>
    </div>
    <template slot="footer">
      <el-row type="flex" justify="center">
        <el-button
          type="primary"
          plain
          style="width: 140px;"
          @click="onClose"
        >
          取消
        </el-button>
        <el-button type="primary" style="width: 140px;" @click="onSubmit">确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
export default {
  name: 'SelectDoctorModal',
  filters: {
    selectName(item) {
      return `${item.representName  }(${  item.orgName  })`
    },
    selectId(item) {
      return item.representId + item.orgId
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: 1
    },
    selected: {
      type: Array,
      default() {
        return []
      }
    },
    title: {
      type: String,
      default: '选择医生'
    },
    projectTargetId: {
      type: String,
      default: ''
    },
    filterList: {
      type: Array,
      default() {
        return []
      }
    },
    includeDelegation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedList: [], // 选择的医生列表
      doctorList: [],
      form: {
        pageNo: 1,
        pageSize: 10,
        status: 4,
        searchKey: ''
      },
      totalCount: 0,
      loading: false,
      unSelect: []
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    handleSelectionChange(val) {
      if (this.max === 1) {
        const lastRow = val.pop()
        this.$refs.multipleTable?.toggleRowSelection(lastRow, true)
        val.forEach(item => {
          this.$refs.multipleTable?.toggleRowSelection(item, false)
        })
        this.selectedList = lastRow ? [lastRow] : []
        return
      }
      if (val.length > this.max) {
        const lastRow = val.pop()
        this.$refs.multipleTable?.toggleRowSelection(lastRow, false)
        return this.$message.error(`最多选择${this.max}个医生`)
      }
      this.selectedList = val
    },
    onChange(pageNo) {
      this.form.pageNo = pageNo
      this.getDataList()
    },
    onPageSizeChange(pageSize) {
      this.form.pageNo = 1
      this.form.pageSize = pageSize
      this.getDataList()
    },
    onSearch() {
      this.form.pageNo = 1
      this.getDataList()
    },
    onClose() {
      this.$emit('onClose')
      this.visible = false
    },
    onCloseModel() {
      this.$refs.multipleTable.clearSelection()
    },
    onSelectChange(value, item) {
      for (let i = 0; i < this.selectedList.length; i++) {
        if (item.doctorId === this.selectedList[i].doctorId) {
          this.selectedList.splice(i, 1, item)
          break
        }
      }
    },
    onSubmit() {
      this.selectedList = this.selectedList.map(item => {
        const obj = {
          ...item,
          title: item.titleName ? item.titleName : item.title,
          doctorCode: item.code ? item.code : item.doctorCode,
          department: item.departmentName ? item.departmentName : item.department
        }
        obj?.representInfoList?.forEach(element => {
          if (element.representId + element.orgId === item.selectId) {
            obj.repName = element.representName
            obj.representId = element.representId
            obj.orgId = element.orgId
            obj.orgName = element.orgName
          }
        })
        return obj
      })
      this.$emit('onSubmit', this.selectedList)
    },
    isSelect(currentId) {
      return [...this.selectedList].map(v => v.doctorId).indexOf(currentId) > -1
    },
    onSelect(current) {
      if (current.isCancel || current.isFrozen) return
      if (this.isSelect(current.doctorId)) {
        this.selectedList = [...this.selectedList].filter(v => v.doctorId !== current.doctorId)
      } else {
        if (this.max === 1) {
          this.selectedList = [current]
          return
        }
        if (this.selectedList.length >= this.max) {
          return this.$message.error(`最多选择${this.max}个医生`)
        }
        this.selectedList = [...this.selectedList, current]
      }
    },
    async getDataList() {
      const params = {
        sceneId: this.$route.params.id,
        sceneType: this.$route.params.sceneType,
        projectTargetId: this.projectTargetId,
        ...this.form,
        filterRequest: this.filterList.length ? { attributeFilters: this.filterList } : {},
        includeDelegation: this.includeDelegation
      }
      try {
        if (this.loading) return
        this.loading = true
        const res = await this.$axios.post(this.$API.getDoctorListV2, params)
        const {data: {data, totalCount}} = res
        this.loading = false
        const list = data.map(item => {
          for (const it of this.selected) {
            if (it.doctorId === item.doctorId) {
              return {
                ...item,
                selectId: it.selectId,
                selectName: it.selectName
              }
            }
          }
          return {
            ...item,
            selectId: item.representInfoList[0]?.representId + item.representInfoList[0]?.orgId,
            selectName: `${item.representInfoList[0].representName  }(${  item.representInfoList[0].orgName  })`
          }
        })
        this.doctorList = list
        this.totalCount = totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    opened() {
      this.form.searchKey = ''
      this.form.pageNo = 1
      this.getDataList()
      this.selectedList = JSON.parse(JSON.stringify(this.selected))
      this.$nextTick(() => {
        this.selectedList.forEach(item => {
          this.$refs.multipleTable.toggleRowSelection(item, true)
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.lay-box {
  padding-top: 12px;
}
.t-p {
  padding-top: 15px;
}
.l-m {
  margin-left: 20px;
}
.picker-rd {
  width: 100%;
}
.search-input {
  width: 280px;
}
.checbox-wrapper {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #3d61e3;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .el-icon-success {
    color: #3d61e3;
    font-size: 20px;
  }
}
.disable-select {
  background-color: #c8c8c8;
}
.disable-select:hover {
  cursor: no-drop;
  cursor: not-allowed;
}
::v-deep .search-input .el-input__inner {
  border-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
</style>
