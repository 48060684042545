<!--
 * @Author: your name
 * @Date: 2021-08-09 18:47:50
 * @LastEditTime: 2021-08-09 19:35:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/bizComponents/UploadExcelToJsonModal.vue
-->
<template>
  <el-dialog
    :title='title'
    width="40%"
    :visible.sync="visible"
    :show-close='false'
    :close-on-click-modal='false'
    :close-on-press-escape='false'
    destroy-on-close
  >
    <div class="link-wrapper">
      <span>请</span>
      <span class="text-primary-link" @click="onSave">点击此处下载分配模版</span>
      <span>，填写完成信息后在下面上传。</span>
    </div>
    <el-row type="flex">
      <span class="label-wrapper">上传文件</span>
      <upload-excel-to-json @onUpload='onUpload' :start="start">
        <div class="upload-cover" :class="{'is-uploaded': isUploaded}">
          <div>
            <img :src="imageUrl" alt="">
            <div>{{ uploadText }}</div>
          </div>
        </div>
      </upload-excel-to-json>
    </el-row>
    <el-row slot="footer" type="flex" justify="center">
      <el-button @click="onClose" >取消</el-button>
      <el-button :disabled='!isUploaded' type="primary" @click="onSubmit" >批量导入</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
import { saveAs } from 'file-saver'
import UploadExcelToJson from './UploadSheetToJson'
import { mapActions } from 'vuex'

export default {
  name: 'UploadExcelToJsonModal',
  data() {
    return {
      uploadedList: [],
      isUploaded: false,
      start:'A2:'
    }
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '批量导入代表'
    },
    fileUrl: {
      type: String,
      default: '',
    }
  },
  watch: {
    visible(show) {
      if (show) {
        this.isUploaded = false
      }
    }
  },
  computed: {
    imageUrl() {
      return this.isUploaded ? require('@/assets/common/icon_success.png') : require('@/assets/common/file_icon.png')
    },
    uploadText() {
      return this.isUploaded ? '模板文件上传成功，重新上传' : '上传文件'
    }
  },
  components: {
    UploadExcelToJson
  },
  methods: {
    ...mapActions(['getAttributeDoctorFile','getAttributeRepresentFile']),
    onUpload(arr) {
      this.isUploaded = true
      this.uploadedList = arr
    },
    onSubmit() {
      this.$emit('success', this.uploadedList)
    },
    onClose() {
      this.$emit('onClose')
    },
    onSave() {
      if (this.title === '批量导入代表') {
        this.getAttributeRepresentFile().then(res => {
          saveAs(res.data, '批量分配代表模版')
        })
      } else {
        this.getAttributeDoctorFile().then(res => {
          saveAs(res.data, '批量分配医生模版')
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.link-wrapper {
  padding-bottom: 20px;
  color: #7784A1;
  font-weight: bold;
}

.label-wrapper {
  color: #7784A1;
  margin-right: 15px;
  flex-shrink: 0;
  font-weight: bold;
}

.upload-cover {
  flex-shrink: 0;
  width: 240px;
  height: 135px;
  background: #e9ecf1;
  border: 1px dashed #f3f3f3;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all .3s;
  transition: all .3s;

  img {
    width: 50px;
    margin-bottom: 5px;
  }

  &.is-uploaded {
    background-color: rgb(231, 252, 230);
    border: 1px solid rgb(9, 187, 7);
    color: rgb(9, 187, 7);
  }
}
</style>
