import { render, staticRenderFns } from "./represent-watch-list copy.vue?vue&type=template&id=36014faa&scoped=true"
import script from "./represent-watch-list copy.vue?vue&type=script&lang=js"
export * from "./represent-watch-list copy.vue?vue&type=script&lang=js"
import style0 from "./represent-watch-list copy.vue?vue&type=style&index=0&id=36014faa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36014faa",
  null
  
)

export default component.exports