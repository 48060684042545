<template>
  <div class="meeting-item" @click="onJumpDetail">
    <div
      class="cover"
      :title="item.title"
      :style="backStyle"
    >
      <template v-if="sceneType === 'live' || sceneType === 'webinar'">
        <el-tag
          v-if="tipsText.label"
          class="tag"
          :color="tipsText.color"
          :type="tipsText.type"
          effect="dark"
        >
          {{ tipsText.label }}
        </el-tag>
      </template>
      <div v-if="isShowSelect" class="select-mask" @click.self="onSelectItem">
        <el-checkbox v-model="isSelect" class="checkbox-item" />
      </div>
      <el-row
        v-if="showAllotTips"
        class="allot-tips"
        type="flex"
        justify="space-between"
      >
        <el-row type="flex" align="middle">
          <i class="el-icon-warning" />
          <span @click.stop="onAllot">{{ item.selfCanUseCount || 0 }}个活动任务待分配</span>
        </el-row>
      </el-row>
    </div>
    <div class="scene-content-wrapper">
      <div class="scene-title" :title="item.title">{{ item.title }}</div>
      <div class="bottom-fix">
        <div class="occupy">
          <el-tooltip effect="dark" :content="toolText" placement="top">
            <div v-if="item.totalCount > 0" class="scene-progress">
              <div :style="progressStyle" />
            </div>
          </el-tooltip>
        </div>
        <el-row type="flex" justify="space-between" align="middle">
          <el-row class="text-primary-link" type="flex" align="middle">
            <svg-icon name="distribute" style="margin-right: 4px;" />
            <span @click.stop="onAllot">分配任务</span>
          </el-row>
          <div class="scene-time">
            <!-- 开始时间： -->
            <span v-if="sceneType === 'meeting'">
              {{ $moment(item.createdAt).format('YYYY-MM-DD HH:mm') }}
            </span>
            <span v-else>
              {{ $moment(item.startAt).format('YYYY-MM-DD HH:mm') }}
            </span>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import {Map} from 'immutable'

export default {
  name: 'AcademicSceneItem',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    isShowSelect: {
      type: Boolean,
      default: false
    },

    sceneType: {
      type: String,
      default: 'meeting'
    }
  },
  data() {
    return {
      loading: false,
      initItem: Map(this.item).toObject()
    }
  },
  computed: {
    isSelect: {
      get() {
        return this.initItem.isSelect
      },
      set(val) {
        this.initItem.isSelect = val
        this.$emit('onSelect', this.initItem)
      }
    },
    showTips() {
      return this.sceneType === 'meeting' || ((this.sceneType === 'live' || this.sceneType === 'webinar') && (this.item.status === 2 || this.item.status === 3))
    },
    tipsText() {
      if (this.sceneType === 'live' || this.sceneType === 'webinar') {
        if (this.item.status === 0) {
          return {
            color: '#30C691',
            label: '即将开始',
            icon: '',
            type: 'success'
          }
        } else if (this.item.status === 1) {
          return {
            color: '#E8312F',
            label: '进行中',
            icon: '',
            type: 'danger'
          }
        } else if (this.item.status === 2) {
          return {
            color: '',
            label: '录播处理中',
            icon: ''
          }
        } else if (this.item.status === 3) {
          return {
            color: '',
            label: '已结束',
            icon: ''
          }
        }
      }
      return ''
    },
    showAllotTips() {
      // 胡椒说不用限制状态
      return this.item.selfCanUseCount > 0
    },
    sceneTitle() {
      switch (this.sceneType) {
        case 'meeting':
          return '科会'
        case 'live':
          return '直播'
        case 'webinar':
          return '会议'
        default:
          return ''
      }
    },
    backStyle() {
      return { backgroundImage: `url(${this.item.coverUrl})` }
    },
    progressStyle() {
      const output = {
        position: 'absolute',
        borderRadius: '7px',
        top: 0,
        left: 0,
        bottom: 0
      }
      const width = `${(this.item.completeCount / (this.item.totalCount || 1)) * 100}%`
      output['width'] = width
      const startcolor = this.item.completeCount && this.item.completeCount === this.item.totalCount ? '#14B4C2' : '#417BF3'
      const endcolor = this.item.completeCount && this.item.completeCount === this.item.totalCount ? '#00EA6E' : '#3DC8FB'
      const background = `linear-gradient(to right,${startcolor},${endcolor})`
      output['background'] = background
      return output
    },
    toolText() {
      return `医生已完成/总任务数：${this.item.completeCount}/${this.item.totalCount}`
    }
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        this.initItem = Map(val).toObject()
      }
    }
  },
  methods: {
    onAllot() {
      this.$emit('onAllot')
    },
    onJumpDetail() {
      if (this.isShowSelect) {
        return
      }
      this.$emit('onJumpDetail')

    },
    onSelectItem() {
      this.isSelect = !this.isSelect
    }
  }
}
</script>
<style lang="scss" scoped>
.meeting-item {
  height: 180px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  &:hover {
    box-shadow: 0 0 18px 0 rgb(191, 197, 208);
  }
}
.cover {
  flex-shrink: 0;
  position: relative;
  height: 105px;
  overflow: hidden;
  left: 0;
  right: 0;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  .tag {
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 2px;
  }
}
.scene-content-wrapper {
  flex: 1;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .bottom-fix {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.time-wrapper {
  font-size: 12px;
  color: #fff;
  height: 20px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  position: absolute;
  right: 10px;
  top: 7px;
}
.status-wrapper {
  height: 20px;
  position: absolute;
  right: 10px;
  top: 7px;
}
.select-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.1);
}
.checkbox-item {
  margin: 5px 8px;
}
.code-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.75);
  transition: all 200ms;
  transform: translate3d(0, 100%, 0);
  z-index: 900;
  &.active {
    transform: translate3d(0, 0, 0);
  }
}
.cost {
  font-size: 18px;
  font-weight: bold;
}
.icon-wrapper {
  width: 12px;
  height: 12px;
  margin-left: 6px;
  flex-shrink: 0;
}
.wrapper-line {
  width: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  margin-left: 15px;
  margin-right: 15px;
}
.invite-wrapper {
  padding-top: 3px;
  padding-bottom: 3px;
}
.occupy {
  flex: 1;
  display: flex;
  align-items: center;
  .scene-progress {
    width: 75%;
    position: relative;
    background: #e1e1e1;
    height: 5px;
    border-radius: 7px;
    // margin: 3px 0;
  }
}
.text-primary-link {
  font-size: 12px;
}
.invite-card-label {
  background-image: url('../assets/academic/item_card_label.png');
  height: 18px;
  width: 40px;
  background-repeat: no-repeat;
  font-size: 12px;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  span {
    flex-shrink: 0;
    transform: scale(0.8334);
  }
  img {
    width: 8px;
    height: 8px;
    flex-shrink: 0;
  }
}
.scene-title {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  cursor: pointer;
  flex-shrink: 0;
}
.scene-time {
  color: #7784a1;
}
.allot-tips {
  color: #fb9c35;
  font-size: 12px;
  background-color: #ffe9d1;
  padding: 6px 9px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
</style>
