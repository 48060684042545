<template>
  <div class="live-form">
    <div class="approver-box">
      <div class="approver-left">
        <el-form
          ref="form"
          v-loading="loading"
          :model="form"
          :rules="formRules"
          label-suffix="："
          :show-message="false"
          label-width="140px"
          style="width: 900px; margin-top: 30px;"
        >
          <el-form-item prop="title" label="播客标题">
            <el-input
              v-model="form.title"
              class="scene-form-input"
              placeholder="请输入播客标题"
              maxlength="40"
              :disabled="!form.canEdit"
              show-word-limit
            />
          </el-form-item>
          <el-form-item prop="liveDesc" label="播客简介">
            <el-input
              v-model="form.intro"
              class="scene-form-input"
              type="textarea"
              placeholder="请输入播客简介"
              rows="8"
              maxlength="400"
              :disabled="!form.canEdit"
              show-word-limit
            />
          </el-form-item>
          <el-form-item prop="startAt" label="播客开始时间">
            <el-row type="flex" align="middle" class="scene-form-content">
              <el-date-picker
                v-model="form.startAt"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
                :editable="false"
                :disabled="!form.canEdit"
                placeholder="选择日期时间"
              />
            </el-row>
          </el-form-item>
          <el-form-item required prop="coverImg" label="播客视频">
            <el-row type="flex" align="middle" class="scene-form-content tips">
              <el-image
                style="width: 200px; height: 120px; margin-right: 20px;"
                :src="require('@/assets/academic/podcast_cover.png')"
                @click="onViewPodcast"
              />
            </el-row>
          </el-form-item>
          <el-form-item required label="标签/关键信息">
            <div class="scene-form-content">
              <ProductLabelKnowledge :selected-tag-list="selectedTagList" :disabled-ids="disabledIds" @change="onTagChange" />
              <div>
                <el-button
                  :disabled="!form.canEdit"
                  plain
                  type="info"
                  @click="onOpenTagModal"
                >
                  添加标签<svg-icon name="el-icon-plus" />
                </el-button>
              </div>
            </div>
          </el-form-item>
          <StrategyProjectFormItem :form.sync="form" />
          <el-form-item required label="播客嘉宾">
            <el-row class="scene-form-content">
              <el-table class="cover-table customize-el-table" :data="form.speakers" row-key="id">
                <el-table-column
                  prop="avatar"
                  fixed
                  label="头像"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-avatar :size="42" :src="scope.row.avatar" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="姓名"
                  align="center"
                  show-overflow-tooltip
                />
                <el-table-column align="center" label="所属职称">
                  <template slot-scope="scope">
                    <span>{{ scope.row.titleName || '/' }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="权限身份">
                  <template>
                    <span>主讲人</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-form-item>
          <el-form-item label="观看时长不低于">
            <el-input-number
              v-model="form.mustViewMin"
              controls-position="right"
              :min="defaultViewMin"
            />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-show="isShowToast" class="toast-box" :class="{ errorBg: isErrorBg }">{{ toastMessage }}</div>
    <ProductLabel
      :visible.sync="showTagModal"
      :disabled-ids="disabledIds"
      :form.sync="form"
      :selected-tag-list="selectedTagList"
      @onSubmit="onSubmitTag"
    />
    <OperationConfirmPop
      :visible="isShowOperationConfirmPop"
      :message="message"
      title="操作确认"
      cancel-font="继续编辑"
      confirm-font="确定离开"
      @onClose="isShowOperationConfirmPop = false"
      @onSubmit="$router.push('/academic/list')"
    />
    <VideoPlayModal
      :visible="showVideoPreviewModal"
      :title="form.title"
      :is-down="false"
      :video-url="videoUrl"
      @onClose="onCloseExamReSourceUploadModal"
    />
  </div>
</template>

<script>
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop.vue'
import ProductLabel from '@/bizComponents/ProductLabel'
import ProductLabelKnowledge from '@/bizComponents/ProductLabelKnowledge'
import VideoPlayModal from '@/bizComponents/VideoPlayModal'
import StrategyProjectFormItem from './strategy-project-form-item.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'PodcastForm',
  components: {
    ProductLabel,
    ProductLabelKnowledge,
    OperationConfirmPop,
    VideoPlayModal,
    StrategyProjectFormItem
  },
  props: {
    // 提交 loading
    isSubmit: {
      type: Boolean,
      default: false
    },
    // 是否是审批
    isApprove: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      require: true
    },
    defaultViewMin: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      selectedTagList: [],
      disabledIds: [],
      tableData: [],
      userTagType: 16, // 标签分类
      sceneTypeId: 12, // 播客
      sceneType: 'MEDICAL_PODCAST',
      loading: false,
      isShowToast: false,
      toastMessage: '',
      isErrorBg: false,
      isSaveConfirm: false,
      showTagModal: false,
      isShowOperationConfirmPop: false,
      pickerOptions: {
        disabledDate: current => {
          return (current && current < this.$moment(new Date()).add(-1, 'days').endOf('day') - 1) || current > this.$moment(new Date()).add(90, 'days').endOf('day') - 1
        }
      },
      message: '当前页面未编辑完成，您确定要离开当前编辑页面吗？',
      countdownStr: '',
      disableSpeakerList: [],
      isShowPop: false,
      defaultForm: {
        canEdit: true,
        sceneType: 12,
        title: '',
        intro: '',
        startAt: '',
        speakers: [],
        mustViewMin: 10,
        memberTagInfo: [],
        formId: '',
        projectId: '',
        strategyId: '',
        strategyName: ''
      },
      formRules: {
        title: [{ required: true, message: '请填写播客标题', trigger: 'blur' }],
        intro: [{ required: true, message: '请填写播客简介', trigger: 'blur' }],
        startAt: [{ required: true, message: '请选择播客开始时间', trigger: 'blur' }]
      },
      showVideoPreviewModal: false,
      videoUrl: ''
    }
  },
  computed: {
    ...mapGetters(['tagGroupList']),
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    isShowToast(isShow) {
      if (isShow) {
        setTimeout(() => {
          this.toastMessage = ''
          this.isShowToast = false
        }, 3000)
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  mounted() {
    this.timer = setInterval(() => {
      let str = ''
      const start_data = this.$moment(this.form.startAt, 'YYYY-MM-DD HH:mm:ss')
      const end_data = this.$moment(new Date(), 'YYYY-MM-DD HH:mm:ss')
      const seconds = start_data.diff(end_data, 'seconds')
      if (seconds < 0 || !this.form.startAt) {
        str = '00天00时00分00秒'
      } else {
        str = this.secondsFormat(seconds)
      }
      this.countdownStr = str
    }, 1000)
    this.getTagGroup()
  },
  methods: {
    ...mapActions(['onEditLive', 'getSceneDetailTag', 'getTagGroup']),
    onGetTagName(list = [], groupCode = 'PRODUCT_TAG_GROUP') {
      return list?.filter(v => v.groupCode === groupCode)?.map(v => v.tagName).join('-') || '-'
    },
    onViewPodcast() {
      if (this.form.recordPlayVo?.mp4) {
        this.videoUrl = this.form.recordPlayVo.mp4.hd || this.form.recordPlayVo.mp4.sd
        this.showVideoPreviewModal = true
      } else {
        this.$message.error('暂无视频')
      }
    },
    onCloseExamReSourceUploadModal() {
      this.showVideoPreviewModal = false
    },
    // 标签
    initTag(id) {
      const memberId = id || this.$route.params.id
      const groupIds = this.tagGroupList.map(v => v.id)
      const req = {
        memberIdList: [memberId],
        userTagType: this.userTagType,
        tagType: 1,
        groupIds: groupIds
      }
      this.getSceneDetailTag(req).then(res => {
        this.disabledIds = res.map(v => v.tagId)
        this.selectedTagList = res.map(v => {
          v = { ...v, disabled: true }
          return v
        })
      })
    },
    onSubmitTag(selectedList) {
      this.showTagModal = false
      this.selectedTagList = [...selectedList]
    },
    onTagChange(tagList) {
      this.selectedTagList = tagList
    },
    onOpenTagModal() {
      this.showTagModal = true
    },
    onOpenOperationConfirmPop() {
      if (!this.isSaveConfirm) {
        this.isShowOperationConfirmPop = true
      } else {
        this.$router.back()
      }
    },
    // 下一步
    onNext() {
      this.$router.push(`/academic/scene/${this.sceneType}/rule/${this.form.id}`)
    },
    onCancelPop() {
      this.onOpenOperationConfirmPop()
    },
    onSaveData(isNext = false) {
      return new Promise((reslove, reject) => {
        if (!this.form.canEdit && !isNext) return this.$message.error('当前播客状态无法编辑')
        this.form.memberTagInfo = this.selectedTagList.map(v => {
          return {
            userTagType: this.userTagType,
            tagId: v.tagId,
            groupId: v.groupId,
            tagName: v.tagName,
            knowledgePointIds: v.knowledgePointIds,
            memberId: this.sceneId
          }
        })
        if (!this.onValid(this.form) || this.isSubmit) {
          reject(false)
          return false
        }
        if (isNext === true && !this.form.canEdit) {
          this.onNext()
          return
        }
        const params = Object.assign({}, this.form)
        if (!params.id) delete params.id
        this.$emit('upload:isSubmit', true)
        reslove(params)
      })
    },
    // 验证表单
    onValid(form) {
      if (!form.title) {
        this.isShowToast = true
        this.toastMessage = '请填写播客标题'
        this.isErrorBg = true
        return false
      }
      if (!form.intro) {
        this.isShowToast = true
        this.toastMessage = '请填写播客简介'
        this.isErrorBg = true
        return false
      }
      if (!form.startAt) {
        this.isShowToast = true
        this.toastMessage = '请选择播客开始时间'
        this.isErrorBg = true
        return false
      }
      if (this.onCheckReq()) {
        this.isShowToast = true
        this.toastMessage = '请选择产品标签'
        this.isErrorBg = true
        return false
      }
      if (!form.speakers.length) {
        this.isShowToast = true
        this.toastMessage = '无播客嘉宾'
        this.isErrorBg = true
        return false
      }
      return true
    },
    onCheckReq() {
      for (const item of this.form.memberTagInfo || []) {
        if (this.tagGroupList[0].id === item.groupId) {
          return false
        }
      }
      return true
    },
    /*
     *@param s : 时间秒
     */
    secondsFormat(s) {
      const day = Math.floor(s / (24 * 3600)) // Math.floor()向下取整
      const hour = Math.floor((s - day * 24 * 3600) / 3600)
      const minute = Math.floor((s - day * 24 * 3600 - hour * 3600) / 60)
      const second = s - day * 24 * 3600 - hour * 3600 - minute * 60
      return `${day}天${hour}时${minute}分${second}秒`
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.live-form {
  .scene-form-item {
    margin-bottom: 12px;
  }
  .scene-form-label {
    flex-shrink: 0;
    color: #272e40;
    line-height: 50px;
    min-width: 120px;
    padding-right: 10px;
    text-align: right;
  }
  .scene-form-content {
    flex: 1;
  }
  ::v-deep .scene-form-input {
    flex: 1;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
      padding-left: 20px;
      font-size: 14px;
    }
    .el-textarea__inner {
      padding-top: 10px;
      padding-left: 20px;
      font-size: 14px;
    }
    .el-input__count {
      font-size: 14px;
      color: #c8c9ce;
    }
  }
  .custom-tag {
    margin-right: 10px;
  }
  .wait-btn-wrapper {
    padding-top: 10px;
  }
  .cover-table {
    margin-top: 20px;
  }
  .exam-item {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #272e40;
    font-size: 14px;
    line-height: initial;
    .el-icon-delete {
      color: #e1e2e6;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
.toast-box {
  color: #fff;
  background: green;
}
.errorBg {
  background: #d8384a;
}
.auto-start-icon {
  color: rgb(138, 138, 138);
  margin-left: 10px;
  font-size: 20px;
}
.collection-form {
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
  }
}
.el-button--mini {
  font-size: 14px;
}
.input_number {
  ::v-deep .el-input--mini .el-input__inner {
    font-weight: bold;
  }
}
.approver-box {
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.approver-left {
  flex: auto;
  min-width: 400px;
  padding-right: 10px;
}
.approver-right {
  flex-shrink: 0;
  width: 400px;
  padding-left: 10px;
  border-left: 1px solid #eee;
}
.tips {
  @media screen and (max-width: 768px) {
    display: block;
  }
}
</style>
