<template>
  <div class="add-question-pop-box">
    <el-dialog
      title="添加问题"
      :visible.sync="visible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="content" style="border-bottom: 1px solid #e9eaeb;">
        <div class="item-box left-box content-box">
          <div style="margin-bottom: 20px;">
            <el-row type="flex" justify="space-between" class="pdg-lr10 pdg-tb10">
              <div class="col272e40 font-bold">
                <span>题库</span>
                <el-tooltip effect="dark" content="刷新题库" placement="bottom">
                  <span class="mgn-l10" @click="onGetQuestionList">
                    <svg-icon name="el-icon-refresh" />
                  </span>
                </el-tooltip>
              </div>
              <span class="text-primary-link" @click="onCreate">新建题目</span>
            </el-row>
            <el-row type="flex" justify="space-between">
              <div class="material-question-search-box">
                <el-input
                  v-model="searchData.searchKey"
                  placeholder="搜索内容"
                  suffix-icon="el-icon-search"
                  @change="onSearchChange"
                />
              </div>
              <div class="pdg-lr10">
                <el-select v-model="searchData.categoryId" placeholder="选择分类" @change="onSelectChange">
                  <el-option
                    v-for="item in examClassifyList"
                    :key="item.categoryId"
                    :label="item.title"
                    :value="item.categoryId"
                  />
                </el-select>
              </div>
            </el-row>
          </div>

          <div class="content-box" style="flex: 1;">
            <div v-if="!total > 0" class="col909399 text-ct pdg30">暂无数据</div>
            <template v-for="(item, index) of sourceList">
              <el-row
                :key="index"
                type="flex"
                justify="space-between"
                align="middle"
                class="pdg-tb10 pdg-lr20"
                :class="index % 2 === 0 ? 'bgf6f7fb' : ''"
              >
                <el-tooltip
                  :disabled="item.title.length < 10"
                  :open-delay="1000"
                  class="item"
                  effect="dark"
                  :content="item.title | textFilter(item.examTypeEnum)"
                  placement="top-start"
                >
                  <div class="overflow-text grow-shrink0" style="width: 310px;">
                    {{ item.title | textFilter(item.examTypeEnum) }}
                  </div>
                </el-tooltip>
                <el-row type="flex" justify="center" style="width: 50px; height: 20px;">
                  <span v-if="isSelect(item.examId)" style="color: #c8c9ce;">已选择</span>
                  <div
                    v-else
                    class="cursor"
                    style="text-align: center;"
                    @click="onAdd(item)"
                  >
                    <i class="el-icon-circle-plus-outline opt-label" />
                  </div>
                </el-row>
              </el-row>
            </template>
            <el-pagination
              class="sorter-box"
              :current-page="searchData.pageNo"
              :page-size="searchData.pageSize"
              :total="total"
              :small="true"
              :pager-count="pagerCount"
              background
              hide-on-single-page
              layout="total, prev, pager, next, jumper"
              @current-change="onCurrentChange"
            />
          </div>
        </div>
        <div class="item-box right-box content-box">
          <div style="margin-bottom: 28px;">
            <div class="col272e40 font-bold pdg-lr10 pdg-tb10">当前选择问题（已选择{{ targetList.length || 0 }}题）</div>
            <div class="col7784a1 pdg-lr10">请从左侧问题列表选择添加</div>
          </div>
          <div class="content-box check-box" style="flex: 1;">
            <div>
              <template v-for="(item, index) of targetList">
                <div
                  :key="index"
                  class="flex-between vertical-center pdg-tb10 pdg-lr20"
                  :class="index % 2 === 0 ? 'bgf6f7fb' : ''"
                >
                  <div class="overflow-text grow-shrink0" style="width: 310px;">
                    {{ item.title | textFilter((item.examTypeEnum)) }}
                  </div>
                  <div class="cursor" style="width: 50px; height: 20px; text-align: center;" @click="onDel(item.examId)">
                    <i class="el-icon-remove-outline opt-label" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer flex-center mgn-t20">
        <el-button class="w100" plain @click="onClose">取消</el-button>
        <el-button
          class="w100"
          :loading="btnLoading"
          type="primary"
          @click="onSubmit"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'AddExamModal',
  components: {
  },
  filters: {
    textFilter(text, type) {
      let answerType = ''
      switch (type) {
        case 'single':
          answerType = '（单选题）'
          break
        case 'multiple':
          answerType = '（多选题）'
          break
        case 'text_fill':
          answerType = '（问答题）'
          break
        case 'blank_fill':
          answerType = '（填空题）'
          break
        case 'sort':
          answerType = '（排序题）'
          break
        case 'scale':
          answerType = '（量表题）'
          break
      }
      return text.replace(/<filter><\/filter>/g, ' ______ ') + answerType
    }
  },
  props: {
    visible: {       // 是否显示，默认true
      type: Boolean,
      default: false
    },
    selectList: {
      type: Array,
      default() {
        return []
      }
    },
    examType: {
      type: Number,
      default: 0
    },
    suitableType: {
      type: String,
      default: '1'
    },
    limit: {
      type: Number
    },
    showAllExam: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // -------------------- submit ----------------
      btnLoading: false,
      // -------------------- 搜索、页数 -----------------
      pagerCount: 5,
      small: true,
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 10,
        type: 0,
        searchKey: '',
        categoryId: ''
      },
      // ---------------------- select -----------------
      examClassifyList: [],
      // --------------------- questionList ----------------------
      sourceList: [],
      targetList: []
    }
  },
  computed: {
    isSelect() {
      return currentId => {
        const targetSelect = [...this.targetList].map(v => v.examId)
        return targetSelect.indexOf(currentId) > -1
      }
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.initPage()
      }
    }
  },
  methods: {
    ...mapActions(['onGetTrainExamList', 'onGetCategoryList']),
    onClose() {
      this.$emit('onClose', false)
    },
    onCreate() {
      const routeUrl = this.$router.resolve(`/material/edit/exam?from=addExamModal&type=${this.suitableType}`)
      window.open(routeUrl.href, '_blank')
    },
    onSubmit() {
      if (this.limit && this.targetList.length > this.limit) {
        this.$message.error(`您最多可以选择${this.limit}道题目`)
        return
      }
      this.$emit('onSubmit', this.targetList)
      this.onClose()
    },
    initPage() {
      this.searchData = {
        pageNo: 1,
        pageSize: 10,
        isSetCorrectAnswer: !this.examType ? !this.examType : '',
        searchKey: '',
        answerType: [],
        categoryId: '',
        suitableType: this.suitableType
      }
      this.sourceList = []
      this.targetList = this.selectList
      this.onGetQuestionList()
      this.onGetExamCategoryList()
    },
    onGetQuestionList() {
      this.onGetTrainExamList(this.searchData).then(({ data: { data, totalCount } }) => {
        this.total = totalCount || 0
        this.sourceList = data || []
      })
    },
    onGetExamCategoryList() {
      this.onGetCategoryList({ categoryType: 'exam' }).then(res => {
        const list = [
          {
            categoryId: '',
            title: '全部'
          }
        ]
        this.examClassifyList = list.concat(res.data || [])
      })
    },
    onSearchChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onGetQuestionList()
    },
    onSelectChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onGetQuestionList()
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
      this.onGetQuestionList()
    },
    onAdd(item) {
      if (this.limit) {
        if (this.limit > this.targetList.length) {
          this.targetList = [...this.targetList, item]
        } else {
          this.$message.error(`您最多只能添加${this.limit}题`)
        }
      } else {
        this.targetList = [...this.targetList, item]
      }
    },
    onDel(currentId) {
      this.targetList = [...this.targetList].filter(v => v.examId !== currentId)
    }
  }
}
</script>

<style>
.add-question-pop-box .el-dialog {
  width: 1000px !important;
}
.add-question-pop-box .el-dialog__body {
  padding: 0;
}
</style>
<style lang='scss' scoped>
.material-question-search-box ::v-deep {
  flex: 1;
  padding-right: 15px;
  .el-input input {
    border-radius: 20px;
  }
}
.opt-label {
  color: #3d61e3;
  font-size: 20px;
}
.content {
  display: flex;
  justify-content: space-between;
}
.item-box {
  width: 490px;
  height: 530px;
  @media screen and (max-width: 768px) {
    height: 300px;
    overflow: scroll;
  }
}
.left-box {
  border-right: 1px solid #e9eaeb;
}
.content-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}
.check-box {
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}
//.check-box::-webkit-scrollbar {
//  width: 0 !important;
//}
.sorter-box {
  padding: 10px;
  text-align: right;
  background: #fff;
}
</style>
