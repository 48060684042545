<template>
  <el-dialog
    class="select-represent-modal"
    title="部分客户无法邀请"
    width="60"
    border
    :visible.sync="visible"
    v-bind="$attrs"
    @opened="opened"
    v-on="$listeners"
  >
    <el-row
      class="lay-box base-align hint-row"
      type="flex"
      justify="space-between"
    >
      <i class="el-icon-warning warn-tips" />
      当前活动中包含的视频和调研/病例题目，该部分用户学习已经达到最大次数限制，请联系任务创建者更换视频内容重新分配。
    </el-row>
    <el-row
      type="flex"
      justify="end"
    >
      <el-button type="text" @click="exportExcel">导出数据为Excel表</el-button>
    </el-row>
    <div v-loading="loading" class="lay-box">
      <el-table
        size="small"
        :data="list"
        stripe
        :height="350"
        row-class-name="cursor"
      >
        <el-table-column
          prop="doctorName"
          label="医生姓名"
          width="100"
          fixed
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="hospital"
          align="center"
          show-overflow-tooltip
          label="医院"
        />
        <el-table-column
          prop="titleName"
          width="150"
          align="center"
          label="职称"
        />
        <el-table-column
          prop="reason"
          align="center"
          show-overflow-tooltip
          label="失败原因"
        />
      </el-table>
      <el-row type="flex" justify="end" class="t-p">
        <!-- <el-pagination background hide-on-single-page layout="prev, pager, next" :page-size="form.pageSize" :current-page="form.pageNo" @current-change="onChange" :total="totalCount"></el-pagination> -->
      </el-row>
    </div>
    <template slot="footer">
      <el-row type="flex" justify="center">
        <el-button
          plain
          @click="onClose"
        >
          我知道了
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import { JsonToExcel } from '@/utils/util'

export default {
  name: 'CanHasInviteModal',
  filters: {
    selectName(item) {
      return `${item.representName}(${item.orgName})`
    },
    selectId(item) {
      return item.representId + item.orgId
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: 1
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      selectedMap: {},
      form: {
        pageNo: 1,
        pageSize: 10,
        status: 4,
        searchKey: ''
      },
      totalCount: 0,
      loading: false,
      isEnd: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose')
      this.visible = false
    },
    opened() {
      console.log(this.list)
    },
    exportExcel() {
      if (!this.list.length) return this.$message.error('暂无需要导出的数据')
      const json = [...this.list].map(item => {
        return [item.doctorName, item.hospital, item.titleName, item.reason]
      })
      const options = ['姓名', '医院', '职称', '无法邀请原因']
      JsonToExcel('无法邀请客户列表', [options, ...json])
    }
  }
}
</script>
<style lang="scss" scoped>
  .hint-row {
    font-size: 16px;
    margin-top: -15px;
    color: #272e40;
    line-height: 1.5;
  }
  .lay-box {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .base-align {
    align-items: baseline;
    i {
      color: red;
      margin-right: 8px;
    }
  }
  .t-p {
    padding-top: 15px;
  }
  .l-m {
    margin-left: 20px;
  }
  .picker-rd {
    width: 100%;
  }
  .search-input {
    width: 280px;
  }
  .checbox-wrapper {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #3d61e3;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .el-icon-success {
      color: #3d61e3;
      font-size: 20px;
    }
  }
  ::v-deep .search-input .el-input__inner {
    border-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

</style>
