<template>
  <el-dropdown
    v-if="message.status ==='success'"
    ref="dropdown"
    trigger="click"
    class="dropdown"
    :placement="isMine?'left-start':'right-start'"
    @command="(type) => handleCommand(type, message)"
  >
    <div class="more-icon">
      <svg-icon name="el-icon-more" />
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="FAVORITE">收藏评论</el-dropdown-item>
      <template v-if="message.type !== TIM.TYPES.MSG_IMAGE">
        <el-dropdown-item v-if="message.top" command="UN_TOP">取消置顶</el-dropdown-item>
        <el-dropdown-item v-else command="TOP">置顶评论</el-dropdown-item>
      </template>
      <el-dropdown-item command="DELETE">删除评论</el-dropdown-item>
      <!-- <el-dropdown-item command='addBlackList'>加入黑名单</el-dropdown-item> -->
      <el-dropdown-item v-if="!isMine" :command="1">禁言</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { dealChatMsg, handleBlackList } from '@/services/liveService'
import { TIM } from '../config/tim-config'
import {cloneDeep} from 'lodash-es'
import { useSendMsg } from '../components/MsgSendBox/useSendMsg'
export default {
  name: 'Handle',
  props: {
    message: {
      type: Object,
      defualt: Object
    }
  },
  data() {
    return {TIM}
  },
  computed: {
    isMine() {
      const { userID } = this.$store.state.tim.user
      return this.message.from === userID
    }
  },
  watch: {
    '$store.state.tim.currentSession.scrollTopCount': function() {
      if (this.$refs.dropdown && this.$refs.dropdown.visible) {
        this.$refs.dropdown.hide()
      }
    }
  },
  mounted() {},
  methods: {
    async handleCommand(type, item) {
      const params = {
        chatRoomId: item.to,
        senderImId: item.from,
        msgId: item.ID,
        actionType: type
      }
      if (type === 1) {
        try {
          await handleBlackList(params)
          this.$message({
            type: 'success',
            message: '操作成功'
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          const copyMessage = cloneDeep(this.message)
          await dealChatMsg(params)
          if (type === 'DELETE') {
            this.$store.commit('tim/currentSession/deleteMsg', { message: item })
          } else if (type === 'TOP' || type === 'UN_TOP') {
            copyMessage.top = type === 'TOP'

            this.$store.commit('tim/currentSession/resendMsg', { message: copyMessage })
          }
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          const msgParams = {
            type: 'createCustomMessage',
            payload: {
              description: 'deal_chat_msg'
            }
          }
          const { onSend } = useSendMsg(msgParams)
          onSend()
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.more-icon {
  width: 25px;
  height: 25px;
  border: 1px solid #e1e1e1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2px;
  transition: all 0.3s;
  margin: 0 5px;
  cursor: pointer;
  flex-shrink: 0;
  &:active {
    border-color: #333;
  }
}
</style>
