<template>
  <div class="share-course-box">
    <el-dialog :visible.sync="visible" :close-on-click-modal="false" :show-close="false">
      <div class="font-weight" slot="title">分享{{ sceneTitle }}（开启分享{{ sceneTitle }}，下级组织/厂家即可使用该{{ sceneTitle }}）</div>
      <el-checkbox v-if="tongList.length && treeList.length" :value="selectAll && selectTongAll" :indeterminate="!(selectAll && selectTongAll) && (selectAllIndmiddle || selectTongAllIndmiddle || selectAll || selectTongAll)" @change="handleCheckOrgAllChange" class="check-box">全选</el-checkbox>
      <div class="content-tree-box">
        <template v-if="showPublic">
          <div>
            <el-checkbox v-model="selectCommon" :disabled="isHasCommon" class="check-box">公共资源库</el-checkbox>
          </div>
        </template>
        <template v-if="tongList.length">
          <div><el-checkbox :disabled="tongListLength === 0" v-model="selectTongAll" :indeterminate="selectTongAllIndmiddle" @change="handleCheckTongAllChange" class="check-box">同级组织</el-checkbox></div>
          <el-tree ref="tongTree" style="margin-left: 48px;" :data="tongList" stripe show-checkbox :check-strictly="true" node-key="orgId" :default-checked-keys="disabledList" @check="onTongTreeChang" :props="defaultProps">
            <template slot-scope="{ data }">
              <div class="">
                <span class="col272e40"> {{ data.orgName }}</span>
              </div>
            </template>
          </el-tree>
        </template>
        <template v-if="treeList.length">
          <div><el-checkbox :disabled="treeListLength === 0" v-model="selectAll" :indeterminate="selectAllIndmiddle" @change="handleCheckAllChange" class="check-box">下级组织</el-checkbox></div>
          <el-tree ref="tree" style="margin-left: 48px;" :data="treeList" stripe show-checkbox :check-strictly="true" node-key="orgId" :default-checked-keys="disabledList" :indent="32" @check="onTreeChang" :props="defaultProps">
            <template slot-scope="{ data }">
              <div class="">
                <span class="col272e40"> {{ data.orgName }}</span>
                <span v-if="data.isAll" class="col272e40 font-weight">共{{ data.child ? data.child.length : 0 }}个地区组织</span>
              </div>
            </template>
          </el-tree>
        </template>
      </div>
      <div slot="footer" class="flex-center mgn-t20">
        <el-button @click="onClose">取消</el-button>
        <el-button :loading="isSubmit" type="primary" @click="onSubmit">确认分享</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SceneShareDepModal',
  data() {
    return {
      disabledList: [],
      defaultProps: {
        children: 'child',
        label: 'orgName',
        disabled: 'isSelect',
        checkStrictly: true,
      },
      treeList: [],
      treeListLength: 0,
      tongList: [],
      tongListLength: 0,
      selectAll: false,
      selectAllIndmiddle: false,

      initSelectCommon: false,
      isHasCommon: false,
      selectOrgAll: false,
      selectTongAll: false,
      selectTongAllIndmiddle: false,
    }
  },
  computed: {
    selectCommon: {
      get() {
        return this.initSelectCommon || this.isHasCommon
      },
      set(val) {
        this.initSelectCommon = val
      },
    },
    showPublic() {
      return this.sceneType !== 'visit' && this.sceneType !== 'academicVisit' && this.sceneType !== 'training' && this.isCreator
    },
    sceneIds() {
      return this.sceneId.split(',')
    },
  },
  props: {
    visible: {
      // 是否显示，默认true
      type: Boolean,
      default: true,
    },
    sceneId: {
      type: String,
      default: '',
    },
    sceneType: {
      type: String,
      default: '',
    },
    sceneTitle: {
      type: String,
      default: '科会',
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    isCreator: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        this.init()
      }
    },
  },
  methods: {
    ...mapActions(['getSceneDepList', 'onTrainCourseDistribute', 'getOrganizationSiblingList']),
    handleCheckOrgAllChange(v) {
      this.selectOrgAll = v
      this.selectTongAll = v
      this.selectAll = v
      this.handleCheckTongAllChange(v)
      this.handleCheckAllChange(v)
    },
    handleCheckTongAllChange(v) {
      if (v) {
        let select = []
        select = [...this.tongList]
        this.tongList.length && this.$refs.tongTree.setCheckedNodes(select)
      } else {
        this.tongList.length && this.$refs.tongTree.setCheckedNodes([])
      }
      this.selectTongAllIndmiddle = false
    },
    handleCheckAllChange(v) {
      if (v) {
        let select = []
        let call = (item) => {
          if (item?.child?.length > 0) {
            select.push(...item.child)
            item.child.map((it) => {
              call(it)
            })
          }
        }

        this.treeList.map((v) => {
          call(v)
        })

        select = [...this.treeList, ...select]
        this.treeList.length && this.$refs.tree.setCheckedNodes(select)
      } else {
        this.treeList.length && this.$refs.tree.setCheckedNodes([])
      }
      this.selectAllIndmiddle = false
    },
    onTongTreeChang(v) {
      let selectNum = this.$refs.tongTree
        .getCheckedNodes()
        .map((v) => v.orgId)
        .filter((d) => this.disabledList.indexOf(d) < 0)?.length
      if (this.tongListLength === selectNum) {
        this.selectTongAll = true
        this.selectTongAllIndmiddle = false
      } else if (selectNum) {
        this.selectTongAllIndmiddle = true
      } else {
        this.selectTongAll = false
        this.selectTongAllIndmiddle = false
      }
    },
    onTreeChang(v) {
      let selectNum = this.$refs.tree
        .getCheckedNodes()
        .map((v) => v.orgId)
        .filter((d) => this.disabledList.indexOf(d) < 0)?.length
      if (this.treeListLength === selectNum) {
        this.selectAll = true
        this.selectAllIndmiddle = false
      } else if (selectNum) {
        this.selectAllIndmiddle = true
      } else {
        this.selectAll = false
        this.selectAllIndmiddle = false
      }
    },
    init() {
      this.onResetSelect()
      this.onGetOrgTong()
      this.onGetSceneShareStatus()
      this.treeList = []
      this.treeListLength = 0
      this.getSceneDepList({ sceneType: this.sceneType, sceneIds: this.sceneIds }).then(
        (res) => {
          this.treeList = res || []
          let call = (data) => {
            return data?.map((k) => {
              if (k.isSelect) {
                this.disabledList = [...this.disabledList, k.orgId]
              } else {
                this.treeListLength += 1
              }
              if (k.child) {
                call(k.child)
              }
              return k
            })
          }
          call(res)
        },
        (rea) => {}
      )
    },
    onGetSceneShareStatus() {
      if (this.showPublic && this.sceneId) {
        this.$axios.post(this.$API.getSceneShareStatus, { sceneType: this.sceneType, sceneIds: this.sceneIds }).then((res) => {
          this.isHasCommon = res.data
        })
      }
    },
    onGetOrgTong() {
      this.tongList = []
      this.tongListLength = 0
      this.getOrganizationSiblingList({ sceneType: this.sceneType, sceneId: this.sceneId }).then((res) => {
        this.tongList = res || []
        let call = (data) => {
          return data?.map((k) => {
            if (k.isSelect) {
              this.disabledList = [...this.disabledList, k.orgId]
            } else {
              this.tongListLength += 1
            }
            return k
          })
        }
        call(this.tongList)
      })
    },
    onResetSelect() {
      this.selectOrgAll = false
      this.selectTongAll = false
      this.selectTongAllIndmiddle = false
      this.selectAll = false
      this.selectAllIndmiddle = false
      this.initSelectCommon = false
      this.disabledList = []
    },
    onClose() {
      this.onResetSelect()
      this.$emit('onClose')
    },
    onSubmit() {
      let outputTong =
        (this.tongList.length &&
          this.$refs.tongTree
            .getCheckedNodes()
            .map((v) => v.orgId)
            .filter((d) => this.disabledList.indexOf(d) < 0)) ||
        []
      let output =
        (this.treeList.length &&
          this.$refs.tree
            .getCheckedNodes()
            .map((v) => v.orgId)
            .filter((d) => this.disabledList.indexOf(d) < 0)) ||
        []
      if (!outputTong.length && !output.length && !this.initSelectCommon) return this.$message.error('请至少勾选一项')
      this.$emit('onSubmit', [...outputTong, ...output], this.initSelectCommon)
      return
    },
  },
}
</script>

<style lang="scss" scoped>
.share-course-box :deep(.el-dialog) {
  width: 850px;
  .el-dialog__header {
    background: #ffffff;
    border-bottom: 1px solid #eeeeee;
  }
  .el-dialog__body {
    padding: 0px;
  }
  .check-tream-search-box .el-input input {
    width: 250px;
    border-radius: 20px;
  }

  .content-tree-box ::v-deep {
    position: relative;
    height: 540px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    margin-top: 10px;
    .el-tree-node__content {
      height: 60px;
      line-height: 60px;
    }

    .el-tree-node[role='treeitem']:nth-child(2n-1) > div.el-tree-node__content {
      background: #f6f7fb;
    }

    .el-tree-node[role='treeitem'] > div.el-tree-node__content {
      background: #ffffff;
    }
  }

  .content-tree-box::-webkit-scrollbar {
    width: 0 !important;
  }

  .tree-item-box {
    height: 60px;
    line-height: 60px;
    position: relative;
    z-index: 10;
  }

  .table-empry {
    height: 50px;
    line-height: 50px;
    background: #ffffff;
    text-align: center;
    border-bottom: 1px solid #e9eaeb;
  }
  .check-box {
    margin: 0 0 10px 25px;
    color: #272e40;
  }
}
</style>
