<template>
  <div class="scroll-box">
    <div class="load-more" :style="`top:${-scrollTopCount}px`">
      <div v-if="!isCompleted && loading" class="center check-more" type="text">
        加载中
        <svg-icon name="el-icon-loading" class="is-loading loading-icon" />
      </div>
      <div v-else-if="isCompleted" class="center" type="text">没有更多了</div>
    </div>
    <el-scrollbar ref="elscroll" class="msg-scrollbar" view-class="scroll-content">
      <slot />
    </el-scrollbar>
  </div>
</template>

<script>
// import { useScrollBottom } from './useScrollBottom'
export default {
  name: 'MsgListScroll',
  props: {
    isCompleted: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    scrollTopCount() {
      return this.$store.getters['tim/currentSession/getScrollTopCount'] || 0
    }
  },
  mounted() {
    const scrollbarEl = this.$refs.elscroll.wrap
    scrollbarEl.onscroll = this.onScroll
  },
  methods: {
    /**
     * 监听滚动
     * 调用时机：加载更多时
     * @param {event|Object} event
     */
    async onScroll(e) {
      if (e.target.scrollTop - this.scrollTopCount > 0) {
        this.$store.commit('tim/currentSession/setScrollDirection', 'bottom')
      } else {
        this.$store.commit('tim/currentSession/setScrollDirection', 'top')
      }

      this.$store.commit('tim/currentSession/setScrollTopCount', e.target.scrollTop)
      if (this.isCompleted) return
      if (e.target.scrollTop === 0) this.$emit('load')
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-box {
  overflow: hidden;
  position: relative;

  ::v-deep .scroll-content {
    padding: 14px;
  }

  .load-more {
    position: absolute;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);

    .check-more {
      display: flex;
      align-items: center;

      .loading-icon {
        margin-left: 4px;
      }
    }

    .center {
      text-align: center;
      font-size: 12px;
      color: #a5b5c1;
    }
  }
  .msg-scrollbar{
    height: 100%;
  }
}
</style>
