<!--
 * @Author: your name
 * @Date: 2021-08-03 11:05:10
 * @LastEditTime: 2021-08-18 18:06:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/academic/child/SenceAllotItem.vue
-->
<template>
  <div class="allot-card-wrapper" :class="{'is-open':isOpen}">
    <el-row
      class="allot-card-header"
      type="flex"
      justify="space-between"
      align="middle"
    >
      <div>
        <div class="font-s18 font-weight color-main">{{ title }}</div>
        <div>覆盖医生：{{ currentItemData.doctorCount }}人</div>
      </div>
      <el-row type="flex" align="middle">
        <el-input
          v-model="currentInvite"
          placeholder="批量设置任务数"
          type="number"
          :min="0"
          @change="onChangeInvite"
        />
      </el-row>
    </el-row>
    <div class="allot-card-body">
      <el-table
        :data="departmentList"
        row-key="factoryId"
        style="width: 100%;"
        :max-height="scrollHeight"
      >
        <el-table-column
          prop="factoryName"
          label="组织名称"
        />
        <el-table-column
          prop="representCount"
          label="组织人数"
          align="center"
        />
        <el-table-column
          prop="doctorCount"
          label="绑定医生数"
          align="center"
        />
        <el-table-column
          label="分配任务数"
          align="center"
        >
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.inviteCount" size="small" :min="0" />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
        >
          <template slot-scope="scope">
            <span class="text-primary-link" @click="onDelDep(scope.row.factoryId)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SceneAllotForDepItem',
  props: {
    title: {
      type: String,
      default: ''
    },
    codeMoney: {
      type: Number,
      default: 10
    },
    departmentList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isOpen: true,
      currentInvite: ''
    }
  },
  computed: {
    scrollHeight() {
      return this.isOpen ? 400 : 0
    },
    currentItemData() {
      let doctorCount = 0
      let teamCount = 0
      let representCount = 0
      let cost = 0
      this.departmentList.map(v => {
        doctorCount += v.doctorCount
        teamCount += v.teamCount
        representCount += v.representCount
        cost += (v.inviteCount || 0) * this.codeMoney
      })
      return {
        doctorCount: doctorCount,
        representCount: representCount,
        teamCount: teamCount,
        totalCost: cost
      }
    }
  },
  methods: {
    onDelDep(currentId) {
      this.$emit('onDelDep', currentId)
    },
    onChangeInvite(value) {
      this.$emit('onChangeInvite', value)
    }
  }
}
</script>
<style lang="scss" scoped>
  .allot-card-wrapper {
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #ebeef5;
    margin-bottom: 20px;
    .allot-card-header {
      background: #eee;
      padding: 10px;
      font-size: 14px;
    }
    .label-wrapper {
      font-size: 24px;
      font-weight: bold;
      margin-left: 25px;
      cursor: pointer;
      transition: ease 250ms;
      transform: rotate(0deg);
      &.is-rotate {
        transform: rotate(180deg);
      }
    }
  }
</style>
