<template>
  <div class="text-fill">
    <el-input
      v-model.trim="subject.title"
      show-word-limit
      maxlength="50"
      placeholder="请输入问题"
    />
  </div>
</template>

<script>
export default {
  name: 'TextFill',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {

    }
  },
  computed: {
    subject: {
      get() {
        return this.item
      },
      set(val) {
        this.$emit('updateExam', val)
      }
    }
  },
  methods: {
    submit() {
      return new Promise((resolve, reject) => {
        const obj = {
          examItem: this.subject
        }
        if (!this.subject.title) {
          obj.message = '请输入问题题目'
          reject(obj)
        }
        resolve(obj)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.text-fill {
  ::v-deep .el-input__inner {
    padding-right: 45px;
  }
}
</style>
