<template>
  <el-dialog
    width="700px"
    v-bind="$attrs"
    title="标签管理"
    class="customize-el-dialog"
    v-on="$listeners"
    @open="onSelectTag"
  >
    <div style="position: relative;">
      <el-form
        class="search-form"
        :model="form"
        inline
        @submit.native.prevent
      >
        <el-form-item>
          <el-input
            v-model="form.title"
            v-clear-emoij
            prefix-icon="el-icon-search"
            clearable
            class="none-input"
            size="large"
            placeholder="搜索标签名称"
          />
        </el-form-item>
      </el-form>
      <el-tabs v-model="selectedGroup" @tab-click="onSelectTag">
        <el-tab-pane
          v-for="item in tagGroupList"
          :key="item.id"
          :label="item.groupName"
          :name="item.id"
        >
          <el-row
            style="margin: 10px 0;"
            type="flex"
            justify="space-between"
            align="middle"
          >
            <el-radio-group
              v-if="!isTopOrg && isCustomGroupID"
              v-model="isSelectMine"
              class="tag-button"
              @change="getTagList({ isSelectMine })"
            >
              <el-radio-button :label="false">公司标签</el-radio-button>
              <el-radio-button :label="true">我的标签</el-radio-button>
            </el-radio-group>
            <!-- -->
            <el-row
              v-if="isCustomGroupID && isSelectMine || isTopOrg"
              type="flex"
              style="height: 30px; flex: 1;"
              align="middle"
            >
              <el-input
                v-model="createTagNameText"
                v-clear-emoij
                maxlength="8"
                class="none-input"
                size="large"
                show-word-limit
                placeholder="请输入标签名称"
              />
              <el-button
                type="info"
                class="w100"
                plain
                @click="onCreate"
              >
                添加标签
              </el-button>
            </el-row>
          </el-row>

          <el-table
            :data="currentTagList"
            stripe
            :height="200"
            style="border: 1px solid #f4f1f5; border-bottom: 0;"
          >
            <el-table-column prop="tagName" label="所有标签">
              <template slot-scope="scoped">
                <div v-if="editStatusMap[scoped.row.tagId]">
                  <el-input
                    v-clear-emoij
                    maxlength="8"
                    class="none-input"
                    show-word-limit
                    placeholder="请输入标签名称"
                    size="mini"
                    :value="editNameMap[scoped.row.tagId]"
                    @input="e => onChangeTag(e, scoped.row.tagId)"
                  />
                </div>
                <span
                  v-else
                  v-highlight="{ hWord: form.title.replace(/ /g, '|'), word: scoped.row.tagName, style: highlightStyle }"
                />
              </template>
            </el-table-column>
            <el-table-column width="120px" align="center" label="操作">
              <template slot-scope="scoped">
                <template v-if="isTopOrg || isCustomGroupID && isSelectMine">
                  <template v-if="editStatusMap[scoped.row.tagId]">
                    <el-button type="text" style="margin-left: 10px;" @click="onEdit(scoped.row)">
                      完成
                    </el-button>
                    <el-button type="text" @click="onSetEdit(scoped.row, false)">取消</el-button>
                  </template>
                  <template v-else>
                    <el-button
                      type="text"
                      class="text-info-link"
                      style="margin-right: 15px;"
                      @click="onSetEdit(scoped.row)"
                    >
                      编辑
                    </el-button>
                    <el-popconfirm title="确定删除吗？" @confirm="onDel(scoped.row.tagId)">
                      <el-button
                        slot="reference"
                        style="color: #e8312f;"
                        type="text"
                        class="text-info-link"
                      >
                        删除
                      </el-button>
                    </el-popconfirm>
                  </template>
                </template>
                <span v-else>
                  <el-button
                    v-if="!isUseing(scoped.row)"
                    :loading="scoped.row.btnLoading"
                    type="text"
                    @click="onSelectUse(scoped.row)"
                  >选择使用</el-button>
                  <el-button
                    v-else
                    type="text"
                    style="color: #e8312f;"
                    :loading="scoped.row.btnLoading"
                    @click="onSelectUse(scoped.row)"
                  >取消使用</el-button>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div style="padding: 10px 0;">备注：若需新增标签，可联系企业顶级账号负责人</div>
    <el-row slot="footer" class="footer-warpper">
      <el-button plain class="w100" @click="onClose">关闭</el-button>
      <!-- <el-button type="primary" class="w100" @click="onClose">保存</el-button> -->
    </el-row>
  </el-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'EditSceneTagModal',
  props: {
    activeTabName: {
      type: String,
      default: ''
    },
    isTopOrg: {
      type: Boolean,
      default: function() {
        return this.$store.state.user.userInfo.isTopOrg
      }
    }
  },
  data() {
    return {
      isSelectMine: false,
      btnLoading: false,
      createTagNameText: '',
      editStatusMap: {},
      editNameMap: {},
      echoList: [],
      pagination: {
        pageSize: 10
      },
      form: {
        title: ''
      },
      highlightStyle: 'background: transparent;color: #ff9941'
    }
  },
  computed: {
    ...mapGetters(['tagGroupList', 'tagList']),
    currentGroupId() {
      const currentGroup = this.tagGroupList.filter(k => k.id === this.selectedGroup)[0] || {}
      return currentGroup.id || ''
    },
    currentTagList() {
      const list = JSON.parse(JSON.stringify(this.tagList))
      return list.filter(v => v.groupId === this.currentGroupId)
    },
    selectedGroup: {
      get() {
        return this.activeTabName || this.tagGroupList[0]?.id
      },
      set(val) {
        this.$emit('update:activeTabName', val)
      }
    },
    isUseing() {
      return row => {
        return this.echoList.some(item => item.tagId === row.tagId)
      }
    },
    isCustomGroupID() {
      return this.tagGroupList.some(item => {
        if (item.id === this.selectedGroup) {
          if (item.groupName === '自定义标签') return true
        }
        return false
      })
    }
  },
  methods: {
    ...mapActions(['onEditTag', 'getTagList', 'onDelTag', 'onCreateTag']),
    onSelectTag() {
      this.$nextTick(() => {
        this.createTagNameText = ''
        if (!this.isCustomGroupID) {
          this.isSelectMine = false
        }
        this.getTagList({ isSelectMine: this.isSelectMine })
        this.getIsUseTag()
      })
    },
    // 获取我使用的标签
    async getIsUseTag() {
      try {
        const params = {
          tagGroupId: this.selectedGroup
        }
        const { data } = await this.$axios.get(this.$API.tagList, { params })
        this.echoList = data
      } catch (error) {
        console.log(error)
      }
    },

    onCreate() {
      if (!this.$auth('createTag') && !this.isTopOrg) return this.$message.error('暂无权限')
      const text = this.createTagNameText.replace(/^\s*|\s*$/g, '')
      if (!text) return this.$message.error('请输入标签名称')
      const req = {
        groupId: this.currentGroupId,
        tagName: text,
        tagType: 1
      }
      this.onCreateTag(req).then(
        () => {
          this.createTagNameText = ''
          this.$message.success('标签创建成功')
        },
        rea => {
          this.$message.error(rea)
        }
      )
    },
    onSetEdit(item, status = true) {
      this.editStatusMap = { ...this.editStatusMap, [item.tagId]: status }
      this.editNameMap = { ...this.editNameMap, [item.tagId]: item.tagName }
    },
    onChangeTag(text, tagId) {
      this.editNameMap = { ...this.editNameMap, [tagId]: text }
    },
    onEdit(item) {
      this.onEditTag({ tagId: item.tagId, tagName: this.editNameMap[item.tagId] }).then(() => {
        this.$message.success('编辑成功')
        this.onSetEdit(item, false)
      }, rea => {
        this.$message.error(rea)
      })
    },
    // 选择使用  、取消使用
    async onSelectUse(row) {
      try {
        this.$set(row, 'btnLoading', true)
        const params = {
          tagId: row.tagId,
          isSelect: !this.isUseing(row)
        }
        await this.$axios.get(this.$API.selectSupportReverseTag, { params })

        this.$set(row, 'btnLoading', false)
        if (params.isSelect) {
          this.echoList.push(row)
          this.$message.success('选择使用成功')
        } else {
          this.echoList = this.echoList.filter(item => item.tagId !== row.tagId)
          this.$message.success('取消使用成功')
        }
        this.getIsUseTag()
      } catch (error) {
        this.$set(row, 'btnLoading', false)
        this.$message.error(error.message)
      }
    },
    // 查询引用数量
    async queryQuoteNum(row) {
      try {
        this.onDel(row.tagId)
      } catch (error) {
        console.log(error)
      }
    },
    onDel(tagId) {
      console.log('%c [ tagId ]-286', 'font-size:13px; background:pink; color:#bf2c9f;', tagId)
      this.onDelTag(tagId).then(
        () => {
          this.$message.success('删除成功')
        },
        rea => {
          this.$message.error(rea)
        }
      )
    },
    onClose() {
      this.form.title = ''
      this.$emit('onClose')
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-title {
  padding: 15px 24px;
  font-weight: bold;
  color: #080922;
  font-size: 16px;
}
::v-deep .tag-button {
  display: flex;
  .el-radio-button {
    margin-right: 10px;
  }
  .el-radio-button:last-child .el-radio-button__inner,
  .el-radio-button:first-child .el-radio-button__inner {
    border-radius: 0 !important;
  }
  .el-radio-button:last-child .el-radio-button__inner {
    border-left: 1px solid #dcdfe6;
  }
}
.tag-header {
  height: 50px;
  background-color: rgba(239, 240, 242, 1);
  font-weight: bold;
  padding-left: 30px;
  color: rgba(39, 46, 64, 1);
  padding-right: 30px;
  .tag-header-child {
    width: 70px;
    text-align: center;
  }
}
.o-q-item {
  height: 40px;
  padding-left: 30px;
  padding-right: 30px;
  color: rgba(39, 46, 64, 1);
  &:nth-child(odd) {
    background: #f6f7fb;
  }
  &:nth-child(even) {
    background: #fff;
  }
}
.none-input ::v-deep {
  .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    color: rgba(39, 46, 64, 1);
    background: none;
  }
  &.el-input--mini .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  .el-input__count-inner {
    background: none;
  }
}
.search-form {
  position: absolute;
  right: 0;
  z-index: 9999;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
</style>
