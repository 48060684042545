<!--
 * @Author: your name
 * @Date: 2021-07-27 10:27:02
 * @LastEditTime: 2021-08-24 18:28:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/bizComponents/AddArticleModal.vue
-->
<template>
  <div class="add-question-pop-box">
    <el-dialog :visible.sync="visible" :close-on-click-modal="false" :show-close="false">
      <div slot="title">添加文献</div>
      <div class="flex-between content" style="border-bottom:1px solid #E9EAEB">
        <div class="item-box left-box content-box">
          <div style="height:112px;">
            <el-row type="flex" justify="space-between" class="pdg-lr30 pdg-tb20">
              <div class="col272e40 font-bold">
                <span>文献</span>
                <el-tooltip effect="dark" content="刷新文献" placement="bottom">
                  <span class="mgn-l10" @click="onGetArticleList">
                    <svg-icon name="el-icon-refresh" />
                  </span>
                </el-tooltip>
              </div>
              <span class="text-primary-link" @click="onCreate">新建文献</span>
            </el-row>
            <el-row type='flex' justify="end" class="pdg-lr20">
              <div class="material-question-search-box">
                <el-input placeholder="搜索内容" suffix-icon="el-icon-search" @change="onSearchChange"
                  v-model="searchData.searchKey">
                </el-input>
              </div>
              <div class="pdg-lr10">
                <el-select v-model="searchData.categoryId" @change="onSelectChange" placeholder="选择分类">
                  <el-option v-for="item in examClassifyList" :key="item.categoryId" :label="item.title"
                    :value="item.categoryId">
                  </el-option>
                </el-select>
              </div>
            </el-row>
          </div>

          <div class="content-box" style="flex:1;">
            <div v-if="!total > 0" class="col909399 text-ct pdg30">暂无数据</div>
            <div style="height:100%;">
              <template v-for="(item, index) of sourceList">
                <el-row :key="index" type='flex' justify="space-between" align="middle" class="pdg-tb10 pdg-lr20"
                  :class="index % 2 === 0 ? 'bgf6f7fb' : ''">
                  <div class="overflow-text grow-shrink0" style="width:310px;">
                    <span>{{ item.title }}</span>
                    <span v-if="item.knowledgeType === 'PDF'">.PDF</span>
                  </div>
                  <el-row type="flex" justify="center" style="width:50px;height:20px;">
                    <span v-if='isSelect(item.knowledgeId)' style="color:#C8C9CE">已选择</span>
                    <div class="cursor" @click="onAdd(item)" v-else>
                      <i class="el-icon-circle-plus-outline opt-label"></i>
                    </div>
                  </el-row>
                </el-row>
              </template>
            </div>
            <el-pagination class="sorter-box" @current-change="onCurrentChange" :current-page="searchData.pageNo"
              :page-size="searchData.pageSize" :total="total" :small="true" :pager-count="pagerCount" background
              hide-on-single-page layout="total, prev, pager, next, jumper"></el-pagination>
          </div>

        </div>
        <div class="item-box right-box content-box">
          <div style="height:112px;">
            <div class="col272e40 font-bold pdg-lr30 pdg-t30">当前选择问题：</div>
            <div class="col7784a1 pdg-lr30">请从左侧列表选择添加</div>
          </div>
          <div class="content-box check-box" style="flex:1;">
            <div>
              <template v-for="(item, index) of targetList">
                <div :key="index" class="flex-between vertical-center pdg-tb10 pdg-lr20"
                  :class="index % 2 === 0 ? 'bgf6f7fb' : ''">
                  <div class="overflow-text grow-shrink0" style="width:310px;">
                    <span>{{ item.title }}</span>
                    <span v-if="item.knowledgeType === 'PDF'">.PDF</span>
                  </div>
                  <div class="cursor" @click="onDel(item.knowledgeId)" style="width:50px;height:20px;">
                    <i class="el-icon-remove-outline opt-label"></i>
                  </div>
                </div>
              </template>
            </div>

          </div>

        </div>
      </div>
      <div slot="footer" class="flex-center mgn-t20">
        <el-button plain @click="onClose">取消</el-button>
        <el-button :loading="btnLoading" type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "AddArticleModal",
  props: {
    visible: {       // 是否显示，默认true
      type: Boolean,
      default: false,
    },
    selectList: {
      type: Array,
      default() {
        return []
      }
    },
    knowledgeType: {
      type: null,
      default: ''
    }
  },
  components: {
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        this.initPage();
      }
    }
  },
  computed: {
    isSelect() {
      return (currentId) => {
        let targetSelect = [...this.targetList].map(v => v.knowledgeId)
        return targetSelect.indexOf(currentId) > -1
      }
    }
  },
  data() {
    return {
      // -------------------- submit ----------------
      btnLoading: false,
      // -------------------- 搜索、页数 -----------------
      pagerCount: 5,
      small: true,
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 10,
        type: 0,
        searchKey: "",
        categoryId: ""
      },
      // ---------------------- select -----------------
      examClassifyList: [],
      // --------------------- questionList ----------------------
      sourceList: [],
      targetList: []
    }
  },
  methods: {
    ...mapActions(['getArticleList', 'onGetCategoryList']),
    onClose() {
      this.$emit("onClose", false)
    },
    onCreate() {
      const routeUrl = this.$router.resolve('/material/knowledge/create?from=addArticleModal')
      window.open(routeUrl.href, '_blank')
    },
    onSubmit() {
      this.$emit("onSubmit", this.targetList);
      this.onClose();
    },
    initPage() {
      this.searchData = {
        pageNo: 1,
        pageSize: 10,
        searchKey: "",
        categoryId: "",
        knowledgeType: this.knowledgeType || ''
      };
      this.sourceList = [];
      this.targetList = this.selectList
      this.onGetArticleList();
      this.onGetExamCategoryList();
    },
    onGetArticleList() {
      this.getArticleList(this.searchData).then(({ data: { data, totalCount } }) => {
        this.total = totalCount || 0;
        this.sourceList = data || [];
      })
    },
    onSearchChange() {
      this.$set(this.searchData, 'pageNo', 1);
      this.onGetArticleList();
    },
    onSelectChange() {
      this.$set(this.searchData, 'pageNo', 1);
      this.onGetArticleList();
    },
    onGetExamCategoryList() {
      this.onGetCategoryList({ categoryType: 'knowledge' }).then(res => {
        let list = [
          {
            categoryId: "",
            title: "全部"
          }
        ]
        this.examClassifyList = list.concat(res.data || [])
      })
    },
    onCurrentChange(options) {
      this.$set(this.searchData, "pageNo", options);
      this.onGetArticleList();
    },
    onAdd(item) {
      this.targetList = [...this.targetList, item]
    },
    onDel(currentId) {
      this.targetList = [...this.targetList].filter(v => v.knowledgeId !== currentId)
    }
  }
}
</script>

<style>
.add-question-pop-box .el-dialog {
  width: 1000px;
}

.add-question-pop-box .el-dialog__body {
  padding: 0px;
}
</style>
<style lang='scss' scoped>
.content {
  @media screen and (max-width: 768px) {
    height: 300px;
    overflow: scroll;
  }
}

.material-question-search-box ::v-deep {
  flex: 1;
  padding-right: 15px;

  .el-input input {
    border-radius: 20px;
  }
}

.opt-label {
  color: #3D61E3;
  font-size: 20px;
}

.item-box {
  width: 490px;
  height: 640px;
  @media screen and (max-width: 768px) {
    height: 300px;
    overflow: scroll;
  }
}

.left-box {
  border-right: 1px solid #E9EAEB;
}

.right-box {
  border-left: 1px solid #E9EAEB;
}

.content-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.check-box {
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.check-box::-webkit-scrollbar {
  width: 0 !important;
}

.sorter-box {
  padding: 10px;
  text-align: right;
  background: #ffffff;
}
</style>
