<template>
  <div class="msg-send-box">
    <div class="input-nav">
      <div class="icon-wrap">
        <div class="icon-item">
          <el-popover
            v-model="showIconWrap"
            placement="top"
            :width="310"
            trigger="click"
          >
            <template #reference>
              <svg-icon class="icon face" name="face" />
            </template>
            <el-scrollbar style="height:150px">
              <emoji @chooseEmoji="chooseEmoji" />
            </el-scrollbar>
          </el-popover>
        </div>
        <div class="icon-item">
          <ImagesUpload
            :size="20"
            class="img-upload"
            notip
            :width="30"
            :height="24"
            @on-change="chooseImage"
          >
            <template #icon>
              <svg-icon class="icon image" name="image" />
            </template>
          </ImagesUpload>
        </div>
      </div>
    </div>
    <el-input
      ref="sendBoxRef"
      v-model="form.payload.text"
      :maxlength="1024"
      class="textarea"
      type="textarea"
      placeholder="请输入您的讨论内容"
      @keydown.enter.native="onSend($event)"
      @keyup.ctrl.enter.prevent="handleLine"
    />
    <div class="btn-wrap">
      <el-button type="primary" class="send-btn" @click="onSend">发送</el-button>
    </div>
    <!-- <div v-if="!$store.state.tim.settings.roomStatus.disable" class="maker" @click="$store.commit('tim/settings/showDisableMsg')" /> -->
    <div v-if="!roomAble" class="overlay">
      <span>暂时无法支持聊天</span>
    </div>
  </div>
</template>

<script>
import { useSendMsg } from './useSendMsg'

import emoji from '@/views/academic/tim/config/emoji/emoji.vue'
import ImagesUpload from '@/components/ImagesUpload'

export default {
  name: 'MsgSendBox',
  components: { emoji, ImagesUpload },
  props: {
    roomAble: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {
        type: 'createTextMessage',
        payload: {
          text: ''
        }
      },
      showIconWrap: false
    }
  },
  methods: {
    onSend(event) {
      const e = window.event || event
      e.returnValue = false
      useSendMsg(this.form).onSend()
      return false
    },
    /**
     * 选择表情
     * 调用时机：发送表情消息时触发
     * @param {String} item
     */
    chooseEmoji(item) {
      this.$set(this.form.payload, 'text', `${this.form.payload.text ? this.form.payload.text : ''}${item}`)
      // this.form.payload.text = `${this.form.payload.text ? this.form.payload.text : ''}${item}`
      this.showIconWrap = false
      this.$refs.sendBoxRef.focus()
    },
    /**
     * 选择图片
     * 调用时机：发送图片消息时
     * @param {Object|{file,fileList}} file
     */
    chooseImage({ file }) {
      this.form.type = 'createImageMessage'
      this.form.payload.file = file.raw
      this.onSend()
    },
    /**
     * 换行
     * 调用时机：组合键 ctrl+enter 时
     */
    handleLine() {
      this.form.payload.text += '\n'
    }
  }
}
</script>

<style lang='scss' scoped>
.msg-send-box {
  display: flex;
  align-items: center;
  border: 1px solid #e1e1e1;
  border-left: none;
  background-color: rgba(246, 247, 251, 0.41);
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1300;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  .maker{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: not-allowed;
    background-color: rgba(0,0,0,0.1);
  }

  ::v-deep .textarea{
    >.el-textarea__inner {
      resize: none;
    }
  }

  .btn-wrap {
    .send-btn {
      margin: 15px;
      border-radius: 0;
    }
  }

  .input-nav {
    display: flex;
    justify-content: space-between;
    padding: 5px 14px;
    background-color: transparent;
  }
}

.input-nav {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: #fff;

  .icon-wrap {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 30px);
    gap: 0 5px;

    .icon-item {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
      }

      .img-upload {
        ::v-deep .el-upload-dragger {
          background-color: transparent;
          border: none;
        }
      }

      .icon {
        width: 24px;
        height: 24px;
        opacity: 0.6;
        transition: all 0.2s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
</style>
