<template>
  <el-dialog
    title="活动预览弹窗"
    :visible.sync="dialogVisible"
    width="750px"
  >
    <el-row>
      <el-col :span="12">
        <div class="view-item-box">
          <div>
            <img :src="require('@/assets/academic/create_questionnaire.png')" alt="">
          </div>
          <div class="text-info-box">
            <div class="title">{{ formObj.title }}</div>
            <div class="intro-box">
              <span>简介：</span>
              <span>{{ formObj.intro }}</span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="content-box">
          <img src="@/assets/training/bg_mobile.png" alt="">
          <div class="content-iframe">
            <div class="page-box" :style="{'backgroundImage': !isClick?`url(${cover})`:''}">
              <template v-if="!isClick">
                <div class="page-title">
                  {{ formObj.title }}
                </div>
                <div class="page-des">
                  <div>尊敬的老师，您好：</div>
                  在探索医疗新纪元的征途中，您的专业视角与深刻见解是最为宝贵的财富。趣学术特此发起诚挚邀请，期待您以专业之心，真诚发声，您的每一份问卷都将是独一无二的宝贵贡献。让我们携手并肩，以您的智慧为笔，共同绘制一幅更加多彩、更加精准、更加温情的医疗领域未来图景。本次调研全凭自愿，您有权随时退出调研，且不影响正常使用产品。
                  感谢您的信任与支持
                </div>
                <div class="page-des">
                  <div>
                    <span>题目：</span>
                    <span>{{ num }}道题</span>
                  </div>
                  <div>
                    <span>预计时间：</span>
                    <span>5-10分钟</span>
                  </div>
                </div>
                <div class="page-footer" @click="isClick = true">
                  接受并开始
                </div>
              </template>
              <template v-else>
                <div>
                  <template v-for="(item, index) in formObj.examList || []">
                    <div :key="item.examId" class="exam-item-box">
                      <exam-item
                        :index="index + 1"
                        :item="item"
                      />
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import ExamItem from '@/bizComponents/ExamItem'
export default {
  components: {
    ExamItem
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    formObj: {
      type: Object,
      default: () => {
        return {}
      }
    }

  },
  data() {
    return {
      isClick: false
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.isClick = val
        this.$emit('update:visible', val)
      }
    },
    num() {
      return this.formObj.examList?.length
    },
    cover() {
      return this.formObj.coverUrl?.middle || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.view-item-box {
  margin-top: 15px;
  background: #f5f5f5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
}
.text-info-box {
  padding-left: 15px;
}
.title {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: bold;
  display: -webkit-box; /* 使用伸缩盒模型 */
  -webkit-line-clamp: 1; /* 限制为3行 */
  -webkit-box-orient: vertical; /* 盒子的纵向排列 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
}
.intro-box {
  padding-top: 10px;
  display: -webkit-box; /* 使用伸缩盒模型 */
  -webkit-line-clamp: 2; /* 限制为3行 */
  -webkit-box-orient: vertical; /* 盒子的纵向排列 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
}
.content-box {
  position: relative;
  padding: 15px;
  img {
    width: 100%;
  }
  .content-iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 90px 15px 50px 15px;
  }
}
.page-box {
  background: #f5f5f5;
  padding: 15px;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  .page-title {
    color: #1e1e1e;
    font-size: 16px;
    font-weight: bold;
    display: -webkit-box; /* 使用伸缩盒模型 */
    -webkit-line-clamp: 1; /* 限制为3行 */
    -webkit-box-orient: vertical; /* 盒子的纵向排列 */
    overflow: hidden; /* 隐藏超出部分 */
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    text-align: center;
  }
  .page-des {
    padding: 15px 0;
    color: #0f0f0f;
  }
  .page-footer {
    cursor: pointer;
    width: 160px;
    height: 32px;
    background: #3d61e3;
    color: #fff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    bottom: 90px;
    left: calc(50% - 80px);
  }
  .exam-item-box {
    background: #fff;
    margin-bottom: 10px;
  }
}
.btn {
  padding: 10px 12px;
  background: white;
  font-size: 14px;
  border-radius: 20px;
  width: 140px;
  margin: 0 7px;
  color: $color-primary;
  &.btn-default {
    background: $color-primary;
    color: white;
  }
}
.footer {
  margin-top: 15px;
}
</style>
