<!--
 * @Author: your name
 * @Date: 2021-11-02 09:41:25
 * @LastEditTime: 2022-01-05 18:26:40
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ksh-factory-vue/src/views/academic/tim/components/empty.vue
-->
<template>
  <div class="empty">
    <img :src="require('@/assets/common/empty.png')" alt="">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'Empty',
  components: {

  },
  props: {
    text: {
      type: String,
      default: '暂无内容'
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
// scss
.empty {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 40px;
  .text{
    margin-top: 15px;
    opacity: 0.7;
  }
}
</style>
