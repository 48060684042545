<template>
  <div class="single">
    <div>
      <el-input
        v-model.trim="subject.title"
        placeholder="请输入题目"
        :maxlength="50"
        show-word-limit
      />
      <slot />
    </div>
    <div class="answer">
      <p v-for="(answer,index) in subject.answers" :key="alphabet[index]">
        <span>{{ alphabet[index] }}. <el-input
          v-model.trim="answer.answer"
          :maxlength="50"
          show-word-limit
          :placeholder="`请输入选项${alphabet[index]}`"
        /></span>
        <span>
          <el-button
            v-if="subject.answers.length < 8"
            type="text"
            icon="el-icon-plus"
            @click="onAddAnswer(answer)"
          />
          <el-button
            :disabled="subject.answers.length === 1"
            type="text"
            icon="el-icon-minus"
            @click="onRemoveAnswer(answer)"
          />
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import {cloneDeep} from 'lodash-es'
export default {
  name: 'Single',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      answerBaseData: {
        answer: ''
      }
    }
  },
  computed: {
    // 英语字母
    alphabet() {
      return Array.from(new Array(26), (ele, index) => {
        return String.fromCharCode(65 + index)
      })
    },
    subject: {
      get() {
        return this.item
      },
      set(val) {
        this.$emit('updateExam', val)
      }
    }
  },
  watch: {
    item: {
      handler(newVal) {
        if (!newVal.answers || newVal.answers.length === 0) {
          const obj = cloneDeep(newVal)
          obj.answers = []
          Array.from(new Array(4).keys()).forEach(() => {
            obj.answers.push({...this.answerBaseData, customAnswerId: this.$nanoid()})
          })
          this.$emit('updateExam', obj)
        }
      },
      immediate: true
    }
  },
  methods: {
    // 添加答案
    onAddAnswer(row) {
      const obj = cloneDeep(this.subject)
      obj.answers.forEach((item, index) => {
        if (`${item.examAnswerId}${item.customAnswerId}` === `${row.examAnswerId}${row.customAnswerId}`) {
          obj.answers.splice(index + 1, 0, {...this.answerBaseData, customAnswerId: this.$nanoid()})
        }
      })
      this.$emit('updateExam', obj)
    },
    // 删除答案
    onRemoveAnswer(row) {
      const obj = cloneDeep(this.subject)
      obj.answers = obj.answers.filter(item => `${item.examAnswerId}${item.customAnswerId}` !== `${row.examAnswerId}${row.customAnswerId}`)
      this.$emit('updateExam', obj)
    },
    submit() {
      return new Promise((resolve, reject) => {
        const obj = {examItem: this.subject}
        if (!this.subject.title) {
          obj.message = '请输入问题题目'
          reject(obj)
        }
        const flag = this.subject.answers.some(item => !item.answer)
        if (flag) {
          obj.message = '请输入问题答案'
          reject(obj)
        } else if (this.subject.answers.length < 2) {
          obj.message = '选项至少两条'
          reject(obj)
        }
        resolve(obj)
      })
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.single {
  ::v-deep .el-input__inner {
    padding-right: 45px;
  }
  .answer {
    width: 100%;
    background-color: #f7f6f9;
    margin-top: 15px;
    border-top: 1px solid #eee;
    position: relative;
    padding: 10px 15px;
    box-sizing: border-box;
    &::before {
      content: '';
      width: 13px;
      height: 13px;
      background-color: #f7f6f9;
      border-top: 1px solid #eee;
      border-left: 1px solid #eee;
      position: absolute;
      top: -8px;
      left: 40px;
      transform: rotate(45deg);
    }
    p {
      display: flex;
      align-items: center;
      >span:nth-child(1) {
        display: flex;
        flex: 1;
        align-items: center;
        font-weight: 500;
        .el-input {
          margin: 0 5px;
        }
      }
      > span:nth-last-child(1) {
        display: flex;
        justify-content: space-between;
        ::v-deep[class*="el-icon-"] {
          color: #a3a3a3;
          font-weight: 600;
          background-color: #fbfbfb;
          overflow: hidden;
          border: 1px solid #d4d4d4;
          padding: 1px;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
