<template>
  <el-upload
    :action="uploadApi"
    :headers="headers"
    name="image"
    :show-file-list="false"
    :before-upload="beforeUpload"
    :on-success="handleSuccess"
    :on-progress="onProgress"
    accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
    v-bind="$attrs"
    class="upload-wrapper"
    :style="{width, height}"
  >
    <div class="img-wrap" :class="{'shadow': Object.keys(file).length&&file.middle}">
      <img v-if="Object.keys(file).length&&file.middle" :src="file.middle" class="proview-img">
      <div v-else slot="trigger" class="wrapper">
        <slot />
      </div>
    </div>
  </el-upload>
</template>

<script>
import { getToken } from '@/utils/auth'
export default {
  name: 'ImgUpload',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: String,
      default: '160px'
    },
    height: {
      type: String,
      default: '90px'
    }
  },
  data() {
    return {
      headers: {
        token: getToken()
      }
    }
  },
  computed: {
    file: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    uploadApi() {
      return `${this.$API.uploadImage}?bucketType=104`
    }
  },
  mounted() {},
  methods: {
    // 上传中
    onProgress(...row) {
      console.log(row)
    },
    handleSuccess({data}) {
      this.file = data
      this.$emit('handle-success', data)
    },
    beforeUpload(file) {
      const isSize = new Promise(function(resolve, reject) {
        const width = 750
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function() {
          const valid = img.width === width
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(
        () => {
          return file
        },
        () => {
          return Promise.resolve()
        }
      )
      return isSize
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.upload-wrapper {
  ::v-deep .el-upload {
    width: 100%;
    height: 100%;
    .wrapper {
      font-size: 12px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #787878;
      background: #f5f5f5;
      border: 1px dashed #e1e1e1;
      transition: all 0.3s;
      &:hover {
        color: $color-primary;
        border: 1px dashed $color-primary;
        cursor: pointer;
      }
    }
    .shadow {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 2%);
    }
    .img-wrap {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      .proview-img {
        width: 100%;
        object-fit: cover;
        transition: all 0.3s;
        &:hover {
          transform: scale(1.5);
        }
      }
    }
  }
  &[disabled="disabled"] {
    .img-wrap {
      &:hover {
        cursor: not-allowed;
        color: #787878;
        background: #f5f5f5;
        border: 1px dashed #e1e1e1;
      }
    }
  }
}
</style>
