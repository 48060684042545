<template>
  <div class="tim">
    <el-tabs v-model="activeName" class="tim-tabs">
      <el-tab-pane
        lazy
        name="live-interaction"
        class="interaction-tab"
        label="直播互动"
      >
        <div class="interaction">
          <!-- 历史消息 -->
          <div style="height: 100%">
            <msg-list-box v-if="timInfo.chatRoomId" :speakers-list="speakersList" class="layout-wrap" />
          </div>
          <!-- 消息发送器 -->
          <msg-send-box :room-able="roomAble" />
        </div>
      </el-tab-pane>
      <el-tab-pane lazy name="instructor-management" label="播客管理">
        <speakers-list v-if="activeName === 'instructor-management'" :speakers-list="speakersList || []" />
      </el-tab-pane>
      <el-tab-pane lazy name="collection" label="收藏评论">
        <collection-list v-if="activeName === 'collection'" />
      </el-tab-pane>
      <el-tab-pane lazy name="blacklist" label="黑名单">
        <special-user v-if="activeName === 'blacklist'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SpecialUser from './SpecialUser'
import MsgSendBox from './components/MsgSendBox'
import MsgListBox from './MsgListBox'
import collectionList from './collection-list/index'
import SpeakersList from './speakers-list.vue'
import { TIM } from '@/views/academic/tim/config/tim-config'

export default {
  name: 'Tim',
  components: {
    SpecialUser,
    MsgSendBox,
    MsgListBox,
    collectionList,
    SpeakersList
  },
  props: {
    speakersList: {
      type: Array
    },
    roomAble: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      activeName: 'live-interaction',
      timInfo: {}
    }
  },
  beforeDestroy() {
    this.$tim.quitGroup(this.timInfo.chatRoomId)
    this.$tim && this.$tim.logout()
  },
  methods: {
    timLogin(data) {
      this.timInfo = data
      const {userId, chatRoomId, userSig, roomAble }  = data
      this.$store.commit('tim/user/setUserID', userId)
      this.$store.commit('tim/user/setGroupID', chatRoomId)
      this.$store.commit('tim/currentSession/setConversationType', TIM.TYPES.CONV_GROUP)
      this.$store.commit('tim/currentSession/setConversationID', chatRoomId)
      this.$store.commit('tim/currentSession/setTo', chatRoomId)

      this.$tim.login({ userID: userId, userSig: userSig })

      if (roomAble) {
        this.$tim.joinGroup({ groupID: chatRoomId, type: TIM.TYPES.GRP_AVCHATROOM }).then(() => {
          this.$store.commit('tim/settings/setRoomStatus', {
            type: 'error',
            message: '',
            disable: true
          })
        }).catch(error => {
          this.$store.commit('tim/settings/setRoomStatus', {
            type: 'error',
            message: error.message,
            disable: false
          })
          this.$message({
            type: 'error',
            message: error.message
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tim {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .tim-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    ::v-deep .el-tabs__content {
      flex: 1;
      .el-tab-pane {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        display: flex;
        &.interaction-tab {
          .interaction {
            flex: 1;
            display: flex;
            flex-direction: column;
            .layout-wrap {
              flex: 1;
            }
          }
        }
      }
    }
  }
}
</style>
