import store from '@/store'
import {
  _tim
} from '../../config/create-tim'
import {
  filterSpeakerIdentity
} from '../../config/msg-filter'
import {
  Message
} from 'element-ui'
import {cloneDeep} from 'lodash-es'
/**
 * 发送消息 hook
 * 调用时机：发消息事件触发时
 * @param {Object} form
 * @returns {Boolean} loading
 * @returns {Function} onSend
 */
export function useSendMsg(form) {
  let loading = false
  const {
    conversationType,
    to
  } = store.state.tim.currentSession

  /**
   * 点击事件
   * 调用时机：回车或点击发送按钮
   */
  const onSend = (option = {}) => {
    const params = Object.assign({}, {
      to,
      conversationType: conversationType
    }, form, option)

    if (params.type === 'createTextMessage') {
      if (!params.payload?.text) return
      if (params.payload.text.trim() === '') {
        Message.error('消息不能为空')
        return
      }
    }
    sendMsg(form.type, params)
    form.payload = {}
    form.type = 'createTextMessage'
  }

  /**
   * 创建消息实例
   * 调用时机：收/发消息事件触发时 回顶
   * @param {String} type
   * @param {Object} options
   * @returns {tim{}|tim} tim
   */
  const createMsg = (type, options) => {
    // 创建文本实例
    return _tim[type](options)
  }

  /**
   * 发送消息
   * 调用时机：发消息事件触发时
   * @param {String} type
   * @param {Object} options
   */
  const sendMsg = async(type, options) => {
    if (loading) return
    const message = createMsg(type, options)
    const [msg] = filterSpeakerIdentity([message], store.state.tim.user.qresenterIDList)
    const copyMessage = cloneDeep(msg)
    try {
      store.commit('tim/currentSession/sendMsg', {
        message: msg
      })
      const {
        data: {
          message
        }
      } = await _tim.sendMessage(copyMessage)
      console.log(type, '消息发送成功', message)
      store.commit('tim/currentSession/refresh', {
        copyMessage: message,
        message: msg
      })
    } catch (error) {
      store.commit('tim/currentSession/refresh', {
        copyMessage: message,
        message: msg
      })
      loading = false
      if (error.code == 10017) {
        Message.error('您被禁言，暂不能发送消息！')
      } else if (error.code === 10016) {
        Message.error('存在敏感词汇，消息发送失败')
      } else {
        Message.error(error)
      }
      console.warn('sendMessage error:', error)
    }
  }

  return {
    loading,
    onSend
  }
}
