<template>
  <el-scrollbar v-loading="loading" class="special-scroll">
    <empty v-if="!specialUserList.length" description="描述文字" />
    <div v-else ref="scrollbar" class="special-user">
      <user-item
        v-for="item in specialUserList"
        :key="item.userId"
        class="item"
        :item="item"
        :is-expand="true"
      >
        <el-button type="text" @click="onRemove(item)">解除禁止</el-button>
      </user-item>
    </div>
  </el-scrollbar>
</template>

<script>
import UserItem from '../components/UserItem'
import empty from '../components/empty.vue'
import { getBlackLiveList, handleBlackList } from '@/services/liveService'
export default {
  name: 'SpecialUser',
  components: { UserItem, empty },
  data() {
    return {
      loading: false,
      search: {
        chatRoomId: '',
        pageNo: 1,
        pageSize: 10
      },
      specialUserList: []
    }
  },
  computed: {
    conversationID() {
      return this.$store.state.tim.currentSession.conversationID
    }
  },
  mounted() {
    this.search.chatRoomId = this.conversationID
    this.getSpecialUser()
    if (this.$refs.scrollbar) {
      const scrollbarEl = this.$refs.scrollbar.wrap
      scrollbarEl.onscroll = this.onScroll
    }
  },
  methods: {
    async getSpecialUser() {
      try {
        if (this.loading || this.search.isCompleted) return
        const params = Object.assign({}, this.search)
        this.loading = true
        const { data } = await getBlackLiveList(params)
        if (params.pageSize !== data.length) {
          this.search.isCompleted = true
        } else {
          this.search.pageNo++
        }
        let list = []
        if (params.pageNo === 1) {
          list = this.formatList(data)
        } else {
          list = [...this.formatList(data), ...this.specialUserList]
        }
        this.specialUserList = list
        this.$store.commit('tim/user/setBannedUserList', list)

        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
        this.$message({
          type: 'error',
          message: error
        })
      }
    },
    async onRemove(item) {
      try {
        const params = { actionType: -1, senderImId: `${item.userType}_${item.userId}`, chatRoomId: this.conversationID }
        await handleBlackList(params)
        this.search.isCompleted = false
        this.search.pageNo = 1
        this.getSpecialUser()
        this.$message.success('移出成功')
      } catch (error) {
        console.log(error)
        this.$message({
          type: 'error',
          message: error.message
        })
      }
    },
    formatList(list) {
      return list.map(item => {
        return {
          avatar: item.avatarUrl,
          name: item.userName,
          ...item
        }
      })
    },
    onScroll(e) {
      if (e.target.scrollTop === 0) this.getSpecialUser()
    }
  }
}
</script>

<style lang='scss' scoped>
.special-scroll {
  width: 100%;
  height: 100%;
  .special-user {
    padding: 0 15px;
    box-sizing: border-box;
    .item {
      padding: 15px 0;
      border-bottom: 1px solid #eeeeee;
      &:nth-last-child(1) {
        border: none;
      }
    }
  }
}
</style>
