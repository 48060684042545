<template>
    <el-dialog
        class="select-represent-modal"
        :title="title"
        width="60"
        :visible.sync="visible"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <el-row type="flex" justify="space-between" align="middle">
            <el-input clearable placeholder="请输入姓名" class="search-input" v-model="searchText">
                <el-button
                    slot="append"
                    icon="el-icon-search"
                    type="primary"
                    round
                    @click="onSearch"
                ></el-button>
            </el-input>
        </el-row>
        <div class="lay-box" v-loading="loading">
            <el-table
                :data="representList"
                stripe
                :height="300"
                row-class-name="cursor"
                @row-click="onSelect"
            >
                <el-table-column width="55">
                    <template slot-scope="scope">
                        <div class="checbox-wrapper">
                            <i class="el-icon-success" v-if="isSelect(scope.row.representId)"></i>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="representName"
                    label="代表姓名"
                    align="center"
                    show-overflow-tooltip
                ></el-table-column>
                <el-table-column prop="representMobile" align="center" label="手机号码"></el-table-column>
            </el-table>
            <el-row type="flex" justify="end" class="t-p">
                <el-pagination
                    background
                    hide-on-single-page
                    layout="prev, pager, next"
                    :page-size="pageSize"
                    :current-page="pageNo"
                    @current-change="onChange"
                    small
                    :total="totalCount"
                ></el-pagination>
            </el-row>
        </div>
        <template slot="footer">
            <el-row type="flex" justify="center">
                <el-button type="primary" plain @click="onClose" style="width: 140px">取消</el-button>
                <el-button type="primary" @click="onSubmit" style="width: 140px">确定</el-button>
            </el-row>
        </template>
    </el-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'SelectRepresentModal',
    data() {
        return {
            selectedList: [], // 选择的医生列表
            representList: [],
            selectedMap: {},
            searchText: '',
            pageNo: 1,
            pageSize: 10,
            status: 1,
            totalCount: 0,
            loading: false
        }
    },

    components: {},
    computed: {},
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        max: {
            type: Number,
            default: 1
        },
        selected: {
            type: Array,
            default() {
                return []
            }
        },
        title: {
            type: String,
            default: '选择代表'
        }
    },
    filters: {},
    methods: {
        ...mapActions(['orgStaffList']),
        onSearch() {
            this.pageNo = 1
            this.init()
        },
        onClose() {
            this.$emit('onClose')
        },
        onSubmit() {
            this.selectedList = this.selectedList.map(item => ({
                ...item,
                uid: item.id,
                name: item.representName,
                mobile: item.representMobile
            }))
            this.$emit('onSubmit', this.selectedList)
        },
        onChange(pageNo) {
            this.pageNo = pageNo
            this.init()
        },
        isSelect(currentId) {
            return [...this.selectedList].map(v => v.representId).indexOf(currentId) > -1
        },
        onSelect(current) {
            if (this.isSelect(current.representId)) {
                this.selectedList = [...this.selectedList].filter(v => v.representId !== current.representId)
            } else {
                if (this.max === 1) {
                    this.selectedList = [current]
                    return
                }
                if (this.selectedList.length >= this.max) {
                    return this.$message.error(`最多选择${this.max}个代表`)
                }
                this.selectedList = [...this.selectedList, current]
            }
        },
        init() {
            this.loading = true
            this.orgStaffList({
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                status: this.status,
                representName: this.searchText
            }).then(
                res => {
                    this.loading = false
                    let { pageData = [], total = 0 } = res.data || {}
                    this.representList = pageData
                    this.totalCount = total
                },
                rea => {
                    this.loading = false
                }
            )
        }
    },
    watch: {
        visible(show) {
            if (show) {
                this.searchText = ''
                this.init()
                this.selectedList = this.selected
            }
        }
    },
    mounted() { }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
    padding-top: 20px;
    padding-bottom: 0;
}
.lay-box {
    padding-top: 5px;
}

.t-p {
    padding-top: 15px;
    @media screen and (max-width: 768px) {
        .el-pagination {
            padding: 0;
        }
    }
}

.l-m {
    margin-left: 20px;
}

.picker-rd {
    width: 100%;
}

.search-input {
    width: 280px;
}

.checbox-wrapper {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #3D61E3;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .el-icon-success {
        color: #3D61E3;
        font-size: 20px;
    }
}
</style>
